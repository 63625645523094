//NG+
import { AppSettings } from "../app-settings";
import { featureType, ILayer, featureGeometryTypes } from "../definitions";

import { Inject, Injectable } from "@angular/core";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { NGXLogger } from "ngx-logger";
import { getExMessage } from "../map/map-utils";


export interface IGisDataService {

    loadVectorDataFromServer(layer: ILayer, sourceProjection, cqlQuery): Promise<any>;
    customLoadImageDataFromServer(src: string, data: any): Promise<any>;
    loadShapeFileDataFromServer(layer: ILayer, sourceProjection, cqlQuery): Promise<any>;

    loadLayerColumnInfo(layer: ILayer): Promise<any>;
    loadRasterSelectInfo(id, query): Promise<any>;
    loadRasterSelectInfoGreentopResult(id, query): Promise<any>;
    loadRasterLegend(layer: ILayer): Promise<any>;
    loadRasterStyle(layer: ILayer): Promise<any>

    getImagePngFromServer(layer: ILayer, bbox: Array<number>, time: string): Promise<any>;
    getImageOSMPngFromServer(bbox: Array<number>, crs: string): Promise<any>;
    getWCSImage(urlIn: string): Promise<any>;
    getImageXmlFromWCSServer10(layer: ILayer, bbox: Array<number>, date: string): Promise<any>;

}

@Injectable({
    providedIn: 'root',
  })
export class GisDataService implements IGisDataService {
    //
    public constructor(
        @Inject(HttpClient) private $http: HttpClient,
        @Inject(NGXLogger) private $log: NGXLogger
         ) {

    }

    public loadVectorDataFromServer(layer: ILayer, sourceProjection, cqlQuery): Promise<any> {
        return this.$http.get(
            AppSettings.serverPath + '/layer/load-wfs/' + layer.id + '?' + '&srs=' + sourceProjection + '&' + cqlQuery,
            { observe: "response" }
        )
            .toPromise()
            .then((response) => {
                return response.body;
            })
    }

    public customLoadImageDataFromServer(src: string, data: any): Promise<any> {
        return this.$http.post(
            src,
            data,
            {
                headers: {
                    'Accept': 'image/webp,image/apng,image/*,*/*;q=0.8',
                    'Content-Type': 'text/xml'
                },
                responseType: 'arraybuffer',
                observe: 'response'
            })
            .toPromise()
            .then((response) => {
                return response.body
            })
    }

    public loadShapeFileDataFromServer(layer: ILayer, sourceProjection, cqlQuery): Promise<any>{
        return this.$http.get(
            AppSettings.serverPath + '/layer/load-shapefile/' + layer.id + '?' + '&srs=' + sourceProjection + '&' + cqlQuery,
            {
            responseType: 'arraybuffer',
            observe: 'response'
        })
        .toPromise()
        .then((response) => {
            return response.body;
        })
    }

    public loadLayerColumnInfo(layer: ILayer): Promise<any> {
        return this.$http.get(
            AppSettings.serverPath + '/layer/load-feature-info/' + layer.id,
            {observe: 'response'}
        )
        .toPromise()
        .then((response) => {
            if (response.body !== Object(response.body)) {
                this.$log.error("Eroare extragere info coloane ", getExMessage(response.body));
                return;
            }
            //
            if ("targetNamespace" in (response.body as any)) {
                layer.targetNamespace = response.body["targetNamespace"];
            }
            if ("targetPrefix" in (response.body as any)) {
                layer.targetPrefix = response.body["targetPrefix"];
            }
            if ("featureTypes" in (response.body as any)) {
                layer.typeName = response.body['featureTypes'][0]['typeName'];
                let properties = response.body['featureTypes'][0]['properties'];
                layer.infoColumns = [];
                layer.infoGeometry = [];
                //todo get the correct list
                if (jQuery.isArray(properties)) {
                    for (var i = 0; i < properties.length; i++) {
                        let columnInfo = { name: properties[i].name, type: properties[i].type };
                        if (featureGeometryTypes.indexOf(columnInfo.type) >= 0) {
                            layer.infoGeometry.push(columnInfo);
                        }
                        else {
                            layer.infoColumns.push(columnInfo);
                        }

                    }
                }
            }
        }).catch((reason) => {
            this.$log.error("Eroare extragere info coloane ", getExMessage(reason));
            return;
        })
    }

    public loadRasterSelectInfo(id, query): Promise<any> {
        return this.$http.get(
            AppSettings.serverPath + '/layer/load-raster-info/' + id + '?' + query,
            {observe: 'response'}
        )
        .toPromise()
        .then((response) => {
            if (response.body !== Object(response.body)) {
                this.$log.error("Eroare extragere info coloane ", getExMessage(response.body));
                return;
            }
            if (response.body['type'] == undefined || response.body['type'] !== "FeatureCollection") {
                throw new Error("nu exista tip date");
            }
            if (response.body['features'] == undefined || response.body['features'].length === 0) {
                throw new Error("nu exista date");
            }
            let infoData = response.body['features'][0];
            if (infoData['properties'] == undefined || infoData['properties'] == null) {
                throw new Error("nu sunt proprietati");
            }
            return infoData['properties'];
            
        }).catch((reason) => {
            this.$log.error("Eroare extragere info raster ", getExMessage(reason));
            return null;
        })
    }

    public loadRasterSelectInfoGreentopResult(id, query): Promise<any> {
        return this.$http.get(
            AppSettings.serverPath + '/greentop/load-clasify-raster-info/' + id + '?' + query,
            {observe: 'response'}
        )
        .toPromise()
        .then((response) => {
            if (response.body !== Object(response.body)) {
                this.$log.error("Eroare extragere info coloane ", getExMessage(response.body));
                return;
            }
            if (response.body['type'] == undefined || response.body['type'] !== "FeatureCollection") {
                throw new Error("nu exista tip date");
            }
            if (response.body['features'] == undefined || response.body['features'].length === 0) {
                throw new Error("nu exista date");
            }
            let infoData = response.body['features'][0];
            if (infoData['properties'] == undefined || infoData['properties'] == null) {
                throw new Error("nu sunt proprietati");
            }
            return infoData['properties'];
            
        }).catch((reason) => {
            return {"eroare": reason.message};
        })
    }
    
    public loadRasterLegend(layer: ILayer): Promise<any> {
        if (layer.featureType !== featureType.tile &&
            layer.featureType !== featureType.image) {
            return;
        }
        return this.$http.get(
             AppSettings.serverPath + '/layer/load-raster-legend/' + layer.id + '?REQUEST=GetLegendGraphic',
            {
             headers: {
                'Accept': 'image/webp,image/apng,image/*,*/*;q=0.8'
            },
            responseType: 'arraybuffer',
            observe: 'response'
        })
        .toPromise()
        .then((response) => {
            if (response.status == 200 &&
                response.headers.get('content-type') === 'image/png') {
                var arrayBufferView = Uint8Array.from(response.body as any);
                var blob = new Blob([response.body as any], { type: 'image/png' });
                var urlCreator = window.URL || window['webkitURL'];
                layer.legendUrl = urlCreator.createObjectURL(blob);
                this.$log.debug(layer.name +' legend url ' + layer.legendUrl);
            } else {
                this.$log.error("Eroare raspuns extragere legenda raster ", layer.id );
                this.$log.debug(response.body);
            }
        }).catch((reason) => {
            this.$log.error("Eroare extragere legenda raster ", getExMessage(reason));
            return null;
        })
    }
    //
    public loadRasterStyle(layer: ILayer): Promise<any> {
        //
        if (layer.featureType !== featureType.tile &&
            layer.featureType !== featureType.image) {
            return;
        }
        layer.sldStyle = {xmlSrv: '', sld: null, script: '', xmlResult: '', visible: false}
        return this.$http.get(
            AppSettings.serverPath + '/layer/load-raster-style/' + layer.id ,
            {
            headers: {
                'Accept': '*/*'
            },
            observe: 'response',
            responseType: 'text'
           // responseType: 'arraybuffer'
        })
        .toPromise()
        .then((response) => {
            if (response.status == 200 &&
                response.headers.get('content-type') === 'application/vnd.ogc.sld+xml') {
                layer.sldStyle.xmlSrv = response.body as string;
                // this.mapOlInit.prepareRasterStyle(layer);
                return true;
            } else {
                this.$log.error("Eroare raspuns extragere style raster ", JSON.stringify(response.body));
                return null;
            }
        }).catch((reason) => {
            this.$log.error("Eroare extragere style raster ", getExMessage(reason));
            return null;
        })
    }

    //
    public getImagePngFromServer(layer: ILayer, bbox: Array<number>, time: string): Promise<any> {
        //
        let width = Math.trunc((bbox[2] - bbox[0]) / 10);
        let height = Math.trunc((bbox[3] - bbox[1]) / 10);
        let tmpSrc = AppSettings.serverPath + '/layer/load-wms/' + layer.id;
        tmpSrc += '?' + 'REQUEST=GetMap&FORMAT=image/png&VERSION=1.1.1';
        tmpSrc += `&WIDTH=${width}&HEIGHT=${height}`
        tmpSrc += `&TIME=${time}`
        tmpSrc += '&SRS=EPSG:3857' + '&' + 'BBOX=' + bbox.join(',');

        let data = null;
        if (layer.sldStyle && layer.sldStyle.xmlResult && layer.sldStyle.xmlResult.length > 0) {
            data = layer.sldStyle.xmlResult;
        }
        //
        return this.$http.post(tmpSrc, data,
            {
                headers: {
                    'Accept': 'image/webp,image/apng,image/*,*/*;q=0.8',
                    'Content-Type': 'text/xml'
                },
                observe: "response",
                responseType: 'arraybuffer',
            }
        )
        .toPromise()
        .then((response) => {
            if (response.status == 200 &&
                response.headers.get('content-type') === 'image/png') {
                // var arrayBufferView = Uint8Array.from(response.data as any);
                var fileBlob = new Blob([response.body as any], { type: 'image/png' });
                const image = new Image(width, height);
                image.src = URL.createObjectURL(fileBlob);
                return image;
            } else {
                throw Error("eroare incarcare imagine")
            }
        })

    }

    public getImageOSMPngFromServer(bbox: Array<number>, crs: string): Promise<any> {
        //
        let width = Math.trunc((bbox[2] - bbox[0]) / 10);
        let height = Math.trunc((bbox[3] - bbox[1]) / 10);
        let tmpSrc = AppSettings.serverPath + '/layer/osm-wms?';
        tmpSrc += `&WIDTH=${width}&HEIGHT=${height}`
        tmpSrc += `&CRS=` + crs + '&' + 'BBOX=' + bbox.join(',');
        //
        return this.$http.get( tmpSrc,
        {
            headers: {
                'Accept': 'image/webp,image/apng,image/*,*/*;q=0.8',
                'Content-Type': 'text/xml'
            },
            observe: "response",
            responseType: 'arraybuffer'
        })
        .toPromise()
        .then((response) => {
            if (response.status == 200 &&
                response.headers.get('content-type') === 'image/png') {
                var fileBlob = new Blob([response.body as any], { type: 'image/png' });
                const image = new Image(width, height);
                image.src = URL.createObjectURL(fileBlob);
                return image;
            } else {
                throw Error("eroare incarcare imagine")
            }
        })

    }

    public getWCSImage(urlIn: string): Promise<any> {
        //
        let url = AppSettings.serverPath + '/layer/load-wcs-image?'
        //let url = "http://localhost" + '/layer/load-wcs-image?'
        url += "urlImage=" + encodeURIComponent(urlIn);

        return this.$http.get( url,
           {
            headers: {
                'Accept': 'image/tiff,image/apng,image/*,*/*;q=0.8',

            },
            observe: "response",
            responseType: 'arraybuffer'
        })
        .toPromise()
        .then((response) => {
            var arrayBufferView = Uint8Array.from(response.body as any);
            return response.body as any;
        })

    }
    
    public getImageXmlFromWCSServer(layer: ILayer, bbox: Array<number>, time: string) {
        //
        let tmpSrc = AppSettings.serverPath + '/layer/load-wcs/' + layer.id;

        let data = `<?xml version="1.0" encoding="UTF-8"?>
          <GetCoverage version="1.1.1" service="WCS" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns="http://www.opengis.net/wcs/1.1.1" xmlns:ows="http://www.opengis.net/ows/1.1" xmlns:gml="http://www.opengis.net/gml" xmlns:ogc="http://www.opengis.net/ogc" xsi:schemaLocation="http://www.opengis.net/wcs/1.1.1 http://schemas.opengis.net/wcs/1.1.1/wcsAll.xsd">
          <ows:Identifier>$layerName$</ows:Identifier>
          <DomainSubset>
            <ows:BoundingBox crs="urn:ogc:def:crs:EPSG::3857">
              <ows:LowerCorner>${bbox[0]} ${bbox[1]}</ows:LowerCorner>
              <ows:UpperCorner>${bbox[2]} ${bbox[3]}</ows:UpperCorner>
            </ows:BoundingBox>
          </DomainSubset>
          <Output store="true" format="image/tiff;application=geotiff">
            <GridCRS>
              <GridBaseCRS>urn:ogc:def:crs:EPSG::3857</GridBaseCRS>
              <GridType>urn:ogc:def:method:WCS:1.1:2dSimpleGrid</GridType>
              <GridOffsets>10.0 10.0</GridOffsets>
              <GridCS>urn:ogc:def:cs:OGC:0.0:Grid2dSquareCS</GridCS>
            </GridCRS>
          </Output>
        </GetCoverage>`;
        //
        return this.$http.post(tmpSrc, data,
            {
                headers: {
                    'Accept': 'image/webp,image/apng,image/*,*/*;q=0.8',
                    'content-type': 'text/xml'
                },
                observe: "response",
                //responseType: 'arraybuffer',
                //responseType: 'text/xml'
            })
        .toPromise()
        .then((response) => {
            if (response.body) {
                return response.body;
            } else {
                throw Error("eroare interogare xml wcs")
            }
        })

    }

    public getImageXmlFromWCSServer10(layer: ILayer, bbox: Array<number>, date: string):Promise<any> {
        //
        let width = Math.trunc((bbox[2] - bbox[0]) / 10);
        let height = Math.trunc((bbox[3] - bbox[1]) / 10);
        //
        let tmpSrc = AppSettings.serverPath + '/layer/load-wcs/' + layer.id;

        let data = `
        <GetCoverage service="WCS" version="1.0.0" xmlns="http://www.opengis.net/wcs" xmlns:nurc="http://www.nurc.nato.int" xmlns:ogc="http://www.opengis.net/ogc" xmlns:gml="http://www.opengis.net/gml" xmlns:ows="http://www.opengis.net/ows" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="http://www.opengis.net/wcs http://schemas.opengis.net/wcs/1.0.0/getCoverage.xsd">
            <sourceCoverage>$layerName$</sourceCoverage>
            <domainSubset>
                <spatialSubset>
                    <gml:Envelope srsName="EPSG:3857">
                        <gml:pos>${bbox[0]} ${bbox[1]}</gml:pos>
                        <gml:pos>${bbox[2]} ${bbox[3]}</gml:pos>
                    </gml:Envelope>
                    <gml:Grid dimension="2" srsName="EPSG:3857">
                        <gml:limits>
                            <gml:GridEnvelope>
                                <gml:low>0 0</gml:low>
                                <gml:high>${width} ${height}</gml:high>
                            </gml:GridEnvelope>
                        </gml:limits>
                        <gml:axisName>E</gml:axisName>
                        <gml:axisName>N</gml:axisName>
                    </gml:Grid>
                </spatialSubset>
                <temporalSubset>
                    <gml:timePosition>${date}T00:00:00Z</gml:timePosition>
                </temporalSubset>
            </domainSubset>
            <rangeSubset>
                <axisSubset name="Band">
                    <singleValue>1</singleValue>
                </axisSubset>
            </rangeSubset>
            <output>
                <crs>EPSG:3857</crs>
                <format>TIFF</format>
            </output>
        </GetCoverage>`;
        //
        return this.$http.post(tmpSrc, data,
            {
                headers: {
                    'Accept': 'image/webp,image/apng,image/*,*/*;q=0.8',
                    'content-type': 'text/xml'
                },
                observe: "response",
                responseType: 'arraybuffer',
                //responseType: 'text/xml',
            })
        .toPromise()
        .then((response) => {
            if (response.body) {
                return response.body;
            } else {
                throw Error("eroare interogare xml wcs")
            }
        })

    }



    

    


}