//NG
import { Component, Inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import moment, { Moment } from "moment";

import { IFeatureInfo, ILayerFile, formatDateTime, ILayer, authOpt, authType, authItemDateTime, authItemAccess, editFeatureInfo, layerFileType, layerFilesStatus, WFSTActionType, ISelectedFeatures } from "../../definitions";
import { MapController } from "../../map/map-controller";
import { UserSettingsService } from "../../services/user-settings-service";
import { WindowMessageService } from "../../services/window-message-service";
import { NGXLogger } from 'ngx-logger';
import { NgbActiveModal, NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerI18n, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
import { I18n, NgbCustomDatepickerI18n } from '../../shared-components/ngb-datepicker-i18n';
import { NgbDateMomentAdapter, NgbDateMomentParserFormatter } from '../../shared-components/ngb-moment-adapter-formater';
import { isNumber } from '../../map/map-utils';

import Feature from 'ol/Feature';
import Collection from 'ol/Collection';


@Component({
    selector: 'info-group-features',
    providers: [I18n,
        { provide: NgbDatepickerI18n, 
             useFactory: () => { return new NgbCustomDatepickerI18n({language : 'ro'})} },
        { provide: NgbDateParserFormatter, 
            useFactory: () => { return new NgbDateMomentParserFormatter("YYYY-MM-DD") } },
        { provide: NgbDateAdapter, useClass: NgbDateMomentAdapter},
    ],
    templateUrl: './app/features/features-components/info-group-features.component.html',
    styleUrls: ['./app/features/features-components/info-group-features.component.css']
})
export class  InfoGroupFeaturesComponent implements OnInit {

    public controlType = {
        text: "text",
        textArea: "textArea",
        dateTimeLocal: "dateTimeLocal",
        rate: "rate"
    }
    private selectedFeaturesOnLayer: ISelectedFeatures;
    public layer: ILayer;
    public features: Array<Feature>;
    public selectedFeature: Feature;
    public selectedFeatureId: number;
    public featuresCount: number;
    public featureProps: any[];

    @Input() data: any;

    public constructor(
        @Inject(NgbActiveModal) private activeModal: NgbActiveModal,
        @Inject(NGXLogger) private $log: NGXLogger,
        @Inject(UserSettingsService) private userSettingsSrvs: UserSettingsService,
        @Inject(WindowMessageService) private windowMessageSrvs: WindowMessageService,
        @Inject(DomSanitizer) private sanitizer: DomSanitizer,
        @Inject(NgbDateParserFormatter) private formaterService: NgbDateParserFormatter
        ) {
            
    }

    public ngOnInit(): void {
        this.layer = null;
        this.features = [];
        this.selectedFeature = null;
        this.selectedFeatureId = 0;
        this.featuresCount = 0;
        this.featureProps = [];

    
    if (this.data.selectedFeaturesOnLayer && this.data.selectedFeaturesOnLayer.layer) {
        //this.selectedFeaturesOnLayers = data.selectedFeaturesOnLayers as Collection<ISelectedFeatures>;
        this.layer = this.data.selectedFeaturesOnLayer.layer['appLayer'];
        this.features = this.cloneLayerFeatures(this.data.selectedFeaturesOnLayer.features as any);
        //
        //this.featuresInTables = [];
        if (this.features.length > 0) {
            this.featuresCount = this.features.length;
            this.selectedFeature = this.features[0];
            this.selectedFeatureId = 0;
            this.buildFeaturesInfo(this.layer, this.selectedFeature, this.featureProps);
        }

    } else {
        this.$log.warn("straturile cu date nu exista");
        this.activeModal.close(false);
    }
    }

    private cloneLayerFeatures(selectedFeature: Collection<Feature>): Array<Feature> {
        
            let tmpSelectFeatures =  [];
            
            selectedFeature.forEach((feature) => {
                let tmpFeature = feature.clone();
                tmpFeature.setId(feature.getId());
                tmpSelectFeatures.push(tmpFeature);
            });
            
        return tmpSelectFeatures;
    }

    private buildFeaturesInfo(layer: ILayer, feature: Feature, featureProps: Array<IFeatureInfo> ) {
        let props: Array<IFeatureInfo> = layer.infoColumns;
        props.forEach((propItem: IFeatureInfo) => {

            let inInfo = this.userSettingsSrvs.isAuthForItemOption(authOpt.in_info_group_features,
                layer.name, propItem.name, authType.layer);
            if (inInfo == null || inInfo != "false") {
                let propValue = feature.get(propItem.name) || '';
                let featureProp: IFeatureInfo =
                    {
                        name: propItem.name,
                        type: propItem.type,
                        control: this.controlType.text,
                        value: propValue
                    };
                // check if prop is datetime local
                let str = this.userSettingsSrvs.isAuthForItemOption(authOpt.input_text_area, layer.name, propItem.name, authType.layer);
                if (str && str.length > 0) {
                    featureProp.control = this.controlType.textArea;
                    featureProp['rows'] = 10;
                } else {
                    // check if prop is rate input
                    let ra = this.userSettingsSrvs.isAuthForItemOption_Name_FullInfo(authOpt.input_rate_item, layer.name, propItem.name, authType.layer);
                    if (ra && ra.idItem > -1) {
                        featureProp.control = this.controlType.rate;
                        featureProp['min'] = 0;
                        featureProp['max'] = 5;
                        featureProp['step'] = 0.2;

                        //check settings for rate
                        try {
                            let minSetting = this.userSettingsSrvs.isAuthForItemOption_Id_FullInfo(authOpt.input_rate_min, layer.name, ra.idItem, authType.layer);
                            if (minSetting && isNumber(minSetting.descriere)) {
                                featureProp['min'] = Number(minSetting.descriere);
                            }
                            let maxSetting = this.userSettingsSrvs.isAuthForItemOption_Id_FullInfo(authOpt.input_rate_max, layer.name, ra.idItem, authType.layer);
                            if (maxSetting && isNumber(maxSetting.descriere)) {
                                featureProp['max'] = Number(maxSetting.descriere);
                            }
                            let stepSetting = this.userSettingsSrvs.isAuthForItemOption_Id_FullInfo(authOpt.input_rate_step, layer.name, ra.idItem, authType.layer);
                            if (stepSetting && isNumber(stepSetting.descriere)) {
                                featureProp['step'] = Number(stepSetting.descriere);
                            }
                        } catch (e) {
                            this.$log.error("eroare extragere limite rate control")
                        }
                    }
                }

                //
                featureProps.push(featureProp);
            }
        });
    }
    
    //
    public next(): void {
        let indSel = this.features.indexOf(this.selectedFeature);
        if (this.features.length > 1 && indSel < this.features.length - 1) {
            this.selectedFeature = this.features[indSel + 1];
            this.selectedFeatureId = indSel + 1;
            this.featureProps = []
            this.buildFeaturesInfo(this.layer, this.selectedFeature, this.featureProps);
        }
    }
    //
    public prev(): void {
        let indSel = this.features.indexOf(this.selectedFeature);
        if (this.features.length > 1 && indSel > 0) {
            this.selectedFeature = this.features[indSel - 1];
            this.selectedFeatureId = indSel - 1;
            this.featureProps = []
            this.buildFeaturesInfo(this.layer, this.selectedFeature, this.featureProps);
        }
    }

    public enablePrev() {
        return this.selectedFeatureId > 0;
    }

    public enableNext() {
        return this.features.length - 1 > this.selectedFeatureId;
    }

    public cancel(): void {
        this.activeModal.close(false);
    }
}