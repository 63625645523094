//NG+
import { AppSettings } from "../app-settings";
import { IItem } from "../definitions";
import { UserSettingsService } from "./user-settings-service";

import { NGXLogger } from "ngx-logger";
import { HttpClient, HttpParams } from "@angular/common/http";

export interface IDbStoreRole {
    getRoleFromStorage(idRole: number): Promise<{ id: number, nume: string, descriere: string }>;
    setAddRoleToStorage(role: { nume: string, descriere: string }): Promise<boolean>;
    setUpdateRoleToStorage(role: { id: number, nume: string, descriere: string }): Promise<boolean>;
    setDeleteRoleFromStorage(role: { id: number, nume: string }): Promise<boolean>;
    getAvailableRoles(): Promise<Array<IItem>>;
}
//
export class DbStoreRole implements IDbStoreRole {
    //
    constructor(
        private $http: HttpClient,
        private $log: NGXLogger,
        private settingsService: UserSettingsService) { }

    public getAvailableRoles(): Promise<Array<IItem>> {
        return this.$http.get(
            AppSettings.serverPath + 'data/roles',
            { observe: "response" }
        )
            .toPromise()
            .then((response) => {
                let roleList: Array<any> = <Array<any>>response.body;
                let roles: Array<IItem> = [];
                for (var i = 0; i < roleList.length; i++) {
                    let role: IItem = { id: roleList[i].id, text: roleList[i].nume };
                    roles.push(role);
                }
                return roles;
            });
    }

    public getRoleFromStorage(idRole: number): Promise<{ id: number, nume: string, descriere: string }> {
        return this.$http.get(
            AppSettings.serverPath + 'data/role/' + idRole,
            { observe: "response" }
        )
            .toPromise()
            .then((response) => {
                let role: { id: number, nume: string, descriere: string } = { id: null, nume: null, descriere: null }
                try {
                    let resRole = response.body;
                    role.id = resRole['id'];
                    role.nume = resRole['nume'];
                    role.descriere = resRole['descriere'];
                } catch (e) {
                    throw new Error("erroare extragere rol" + e.message);
                }
                return role;
            });
    }

    public setAddRoleToStorage(role: { nume: string, descriere: string }): Promise<boolean> {
        return this.$http.post(
            AppSettings.serverPath + 'data/add-role',
            JSON.stringify({ nume: role.nume, descriere: role.descriere }),
            {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                },
                observe: "response"
            })
            .toPromise()
            .then((response) => {
                if ('id' in <any>response.body) {
                    return true;
                } else {
                    return false;
                }
            });
    }

    public setUpdateRoleToStorage(role: { id: number, nume: string, descriere: string }): Promise<boolean> {
        return this.$http.post(
            AppSettings.serverPath + 'data/update-role',
            JSON.stringify({ id: role.id, nume: role.nume, descriere: role.descriere }),
            {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                },
                observe: "response"
            })
            .toPromise()
            .then((response) => {
                if ('success' in <any>response.body) {
                    return true;
                } else {
                    return false;
                }
            });
    }

    public setDeleteRoleFromStorage(role: { id: number, nume: string }): Promise<boolean> {
        return this.$http.post(
            AppSettings.serverPath + 'data/delete-role',
            JSON.stringify({ id: role.id, nume: role.nume }),
            {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                },
                observe: "response"
            })
            .toPromise()
            .then((response) => {
                if ('success' in <any>response.body) {
                    return true;
                } else {
                    return false;
                }
            });
    }

}
