//NG+
import { Inject, Injectable } from "@angular/core";
import { ILayer } from "../definitions";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AddEditRouteComponent } from "./route-components/add-edit-route.component";
import { AddEditStationComponent } from "./route-components/add-edit-station.component";


import Feature from 'ol/Feature';

export interface IRouteDialogsService {
    showAddEditRouteDialog(layer: ILayer, source: ILayer, isAddElseEdit: boolean, routeDialogReturn: string, routeId: number): any;
    showAddEditStationDialog(layer: ILayer, feature: Feature, isAddElseEdit: boolean): any;
}

@Injectable({
    providedIn: 'root',
})
export class RouteDialogServiceNg implements IRouteDialogsService {
    constructor(@Inject(NgbModal) private modalService) {
        //
    }

    public showAddEditRouteDialog(layer: ILayer, source: ILayer, isAddElseEdit: boolean, routeDialogReturn: string, routeId: number): any {
        const modalOpt = { backdrop: false, windowClass: 'modalAddEditRoute'}
        const modalRef = this.modalService.open(AddEditRouteComponent, modalOpt);
        modalRef.componentInstance.data = { layer, source, "isAddElseEdit": isAddElseEdit, routeDialogReturn: routeDialogReturn, routeId: routeId };
        return modalRef.result;
    }
    
    public showAddEditStationDialog(layer: ILayer, feature: Feature, isAddElseEdit: boolean): any {
        const modalOpt = { backdrop: false, windowClass: 'modalAddEditStation'}
        const modalRef = this.modalService.open(AddEditStationComponent, modalOpt);
        modalRef.componentInstance.data = { layer, "isAddElseEdit": isAddElseEdit, "feature": feature };
        return modalRef.result;
    }

}
