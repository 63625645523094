import { Directive, ElementRef, Injector, Input, Output, EventEmitter, Renderer2, Inject, HostListener } from 
'@angular/core';

@Directive({
    selector: '[print-section]'
})
export class PrintSectionDirective {
    //
    constructor(
        @Inject(ElementRef) private elementRef: ElementRef,
        @Inject(Renderer2) private renderer: Renderer2,
        //private injector: Injector
        ) {
    }
    //
    ngOnInit(): void {
        this.renderer.addClass(this.elementRef.nativeElement, 'printSection');
    }
}

@Directive({
    selector: '[print-hide]'
})
export class PrintHideDirective {
    //
    constructor(
        @Inject(ElementRef) private elementRef: ElementRef,
        @Inject(Renderer2) private renderer: Renderer2,
        //injector: Injector
        ) {
    }
    //
    ngOnInit(): void {
        this.renderer.addClass(this.elementRef.nativeElement, 'printHide');
    }
}

@Directive({
    selector: '[print-remove]'
})
export class PrintRemoveDirective {
    //
    constructor(
        @Inject(ElementRef) private elementRef: ElementRef,
        @Inject(Renderer2) private renderer: Renderer2,
        //injector: Injector
        ) {
    }
    //
    ngOnInit(): void {
        this.renderer.addClass(this.elementRef.nativeElement, 'printRemove');
    }
}

@Directive({
    selector: '[print-only]'
})
export class PrintOnlyDirective {
    //
    constructor(
        @Inject(ElementRef) private elementRef: ElementRef,
        @Inject(Renderer2) private renderer: Renderer2,
        //injector: Injector
        ) {
    }
    //
    ngOnInit(): void {
        this.renderer.addClass(this.elementRef.nativeElement, 'printOnly');
    }
}

@Directive({
    selector: '[print-avoid-break]'
})
export class PrintAvoidBreakDirective {
    //
    constructor(
        @Inject(ElementRef) private elementRef: ElementRef,
        @Inject(Renderer2) private renderer: Renderer2,
        //injector: Injector
        ) {
    }
    //
    ngOnInit(): void {
        this.renderer.addClass(this.elementRef.nativeElement, 'printAvoidBreak');
    }
}

@Directive({
    selector: '[print-btn]'
})
export class PrintBtnDirective {
    @HostListener('click', ['$event']) onClick($event){
        setTimeout(()=>{
            window.print();
        },100)
    }
    //
    constructor(
        @Inject(ElementRef) private elementRef: ElementRef,
        @Inject(Renderer2) private renderer: Renderer2,
        //injector: Injector
        ) {
    }
    //
    ngOnInit(): void {
        //this.renderer.addClass(this.elementRef.nativeElement, 'printSection');
    }
}

@Directive({
    selector: '[print-if]'
})
export class PrintIfDirective {
    //
    constructor(
        @Inject(ElementRef) private elementRef: ElementRef,
        @Inject(Renderer2) private renderer: Renderer2,
        //injector: Injector
        ) {
    }
    //
    ngOnInit(): void {
        //this.renderer.addClass(this.elementRef.nativeElement, 'printSection');
    }
}

@Directive({
    selector: '[print-landscape]'
})
export class PrintLandscapeDirective {
    //
    private style: any = null;
    constructor(
        @Inject(ElementRef) private elementRef: ElementRef,
        @Inject(Renderer2) private renderer: Renderer2,
        //injector: Injector
        ) {
    }
    //
    ngOnInit(): void {
        var sheet = (() => {
            this.style = document.createElement('style');
            this.style.appendChild(document.createTextNode(''));
            document.head.appendChild(this.style);
            return this.style.sheet;
        })();
        sheet.insertRule('@page{size:landscape;}', 0);
    }

    ngOnDestroy(): void {
        if (this.style){
            document.head.removeChild(this.style)
        }
    }
}

@Directive({
    selector: '[print-table]'
})
export class PrintTableDirective {
    //
    constructor(
        @Inject(ElementRef) private elementRef: ElementRef,
        @Inject(Renderer2) private renderer: Renderer2,
        //injector: Injector
        ) {
    }
    //
    ngOnInit(): void {
        //this.renderer.addClass(this.elementRef.nativeElement, 'printSection');
    }
}