//NG+
import { Component, Inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AppSettings } from '../../app-settings';
import { ILayer, authAs, authOpt, authType, featureType } from '../../definitions';
import { MapController } from '../map-controller';
import { IDescarcareDateSatelitareIL, IUnitateAdministrativaDescarcareIL, IIntegrateLandsatDownloadData } from "./map-integrate.definitions";
import { IAntrenamentModelClasificare, IDescarcareDateSatelitare, IIndiceAntrenament, IIndiceDescarcare, IProdusDescarcare, IZonaAntrenament, TaskActiune, TaskStatus } from '../../services/greentop-data-service';
import { I18n, NgbCustomDatepickerI18n } from '../../shared-components/ngb-datepicker-i18n';
import { NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateMomentAdapter, NgbDateMomentParserFormatter } from '../../shared-components/ngb-moment-adapter-formater';
import moment, { Moment } from "moment";
import { IGreentopTrainingData, listaSursaDateClasificare } from './map-greentop-definitions';
import { convertDataDecadaToString, convertDataStringToDecada, createGeomFromWKT, createWKTFromGeom, findStratAsociatToIndice, getExMessage } from '../map-utils';
import { NGXLogger } from 'ngx-logger';

import VectorLayer from 'ol/layer/Vector';
import Draw from 'ol/interaction/Draw';
import Modify from 'ol/interaction/Modify';
import Feature from 'ol/Feature';
import Polygon from 'ol/geom/Polygon';
import * as olExtent from 'ol/extent';
import VectorSource from "ol/source/Vector";
import WKTFormater from "ol/format/WKT";
import OlStyle from "ol/style/Style";
import StrokeStyle from "ol/style/Stroke";
import FillStyle from "ol/style/Fill";
import TextStyle from "ol/style/Text";
import * as olColor from 'ol/color';

@Component({
    selector: 'greentop-training',
    providers: [
        I18n,
        { provide: NgbDatepickerI18n, 
             useFactory: () => { return new NgbCustomDatepickerI18n({language : 'ro'})} },
        { provide: NgbDateParserFormatter, 
            useFactory: () => { return new NgbDateMomentParserFormatter("YYYY-MM-DD") } },
        { provide: NgbDateAdapter, useClass: NgbDateMomentAdapter},
    ],
    templateUrl: './app/map/map-components/map-greentop-training.component.html',
    styleUrls: ['./app/map/map-components/map-greentop-training.component.css']
})
export class GreentopTrainingComponent implements OnInit, OnChanges {
    private desStyle = new OlStyle({
        fill: new FillStyle({
            color: olColor.asArray('rgba(50,0,0,0)')
        }),
        stroke: new StrokeStyle({
            color: 'rgba(255,0,0,1)',
            width: 2
        })
    });
    private zoneStyle = [
        new OlStyle({
            fill: new FillStyle({
                color: olColor.asArray('rgba(0,255,0,0)')
            }),
            stroke: new StrokeStyle({
                color: 'rgba(0,255,0,1)',
                width: 2,
            }),

        }),
        new OlStyle({
            fill: new FillStyle({
                color: olColor.asArray('rgba(0,255,0,0)')
            }),
            stroke: new StrokeStyle({
                color: 'rgba(255,0,0,1)',
                width: 2,
                lineDash: [4, 8],
            }),

        }),
    
];
    private zoneText(text: string) {
        return new OlStyle({
            text: new TextStyle({
                text: text,
                scale: 1,
                offsetX: 0,
                offsetY: 0,
                fill: new FillStyle({
                    color: 'rgba(0, 0, 0, 1)'
                })
            })
        })
    }
    private monthList = [
        { denumire: "Ian", nrLuna: 1 },
        { denumire: "Feb", nrLuna: 2 },
        { denumire: "Mar", nrLuna: 3 },
        { denumire: "Apr", nrLuna: 4 },
        { denumire: "Mai", nrLuna: 5 },
        { denumire: "Iun", nrLuna: 6 },
        { denumire: "Iul", nrLuna: 7 },
        { denumire: "Aug", nrLuna: 8 },
        { denumire: "Sept", nrLuna: 9 },
        { denumire: "Oct", nrLuna: 10 },
        { denumire: "Nov", nrLuna: 11 },
        { denumire: "Dec", nrLuna: 12 }
    ];
    
    @Input("mapctrl") mapCtrl: MapController = null;

    //
    public infoSearch = "info model antrenament"
    public infoProducts = "info detalii antrenament"
    private greentopTrainingData : IGreentopTrainingData;
    public constructor(
        @Inject(NGXLogger) private $log: NGXLogger,
    ){
        //
    }

    public ngOnInit(): void {
        if (this.mapCtrl && this.mapCtrl.greentopTrainingData) {
            this.greentopTrainingData = this.mapCtrl.greentopTrainingData;
            this.mapCtrl.greentopTrainingData.funcInit = this.initGreentopTrainingTool;
            this.mapCtrl.greentopTrainingData.funcClear = this.clearGreentopTrainingTool;
        }
    }

    public ngOnChanges(changes: SimpleChanges) {
        //
    }

    public changeNewExistsTab(index) {
        if (index === "0") {
            if (this.greentopTrainingData.activeNewTab0) {
                // this.greentopTrainingData.activeNewTab0 = false
            } else {
                this.greentopTrainingData.activeExistsTab1 = false;
                this.greentopTrainingData.activeNewTab0 = true;
                this.greentopTrainingData.activeTab = 0
                this.initNewTrainingData();
            }

        } else if (index === "1") {
            if (this.greentopTrainingData.activeExistsTab1) {
                // this.greentopTrainingData.activeExistsTab1 = false
            } else {

                this.greentopTrainingData.activeExistsTab1 = true;
                this.greentopTrainingData.activeNewTab0 = false;
                this.greentopTrainingData.activeTab = 1
                this.initExistingTrainingData(null);
            }
        }
    }
    //
    public initGreentopTrainingTool = () => {
        //
        this.addInterestAreaLayer();
        this.addTrainingZonesLayer();
        //
        this.greentopTrainingData.activeExistsTab1 = true;
        this.greentopTrainingData.activeNewTab0 = false;
        this.greentopTrainingData.activeTab = 1;
        this.greentopTrainingData.activeDataTab = 2;
        //this.initNewTrainingData();
        this.initExistingTrainingData(null);
    }
    //
    public clearGreentopTrainingTool = () => {
        this.removeSelectLayerInteraction();
        this.removeModifyLayerInteraction();
        this.removeInterestAreaLayer();
        this.removeTrainingZonesLayer();
        this.removeSelectLayer();

    }
    //
    private initNewTrainingData() {
        //
        //
        this.greentopTrainingData.activeDataTab = 0;
        this.greentopTrainingData.selectedDescarcare = null;
        this.greentopTrainingData.selectedStructuraDate = null;
        this.greentopTrainingData.selectednewIndice = null;
        this.clearLayers();
        //
        this.greentopTrainingData.currentAntrenament = {
            denumire: "",
            descriere: "",
            idAlgoritm: 1,
            idStructuraDate: 2,
            descarcariAntrenament: [],
            zoneAntrenament: [],
            indiciAntrenament: [],
            idAntrenamentParinte: null,
            status: "initiat",
            mesajStatus: ""
        }
        //
        this.greentopTrainingData.istoolActive_neweditTrainingZone = false;
        this.greentopTrainingData.istoolActive_importTrainingZones = false;

        this.greentopTrainingData.errorMessage = "";
        this.greentopTrainingData.errorMessageZona = "";
        this.greentopTrainingData.errorMessageImport = "";
        this.greentopTrainingData.resultMessageImport = "";
        //
        this.initNewAvailableData(false);
    }
   
    private initNewFromExisting(){
        if (this.greentopTrainingData.currentAntrenament == null){
            throw new Error("nu este selectat antrnament curent");
        }
        this.greentopTrainingData.activeExistsTab1 = false;
        this.greentopTrainingData.activeNewTab0 = true;
        this.greentopTrainingData.activeTab = 0;
        this.greentopTrainingData.activeDataTab = 0;
        //
        this.greentopTrainingData.istoolActive_neweditTrainingZone = false;
        this.greentopTrainingData.istoolActive_importTrainingZones = false;
        //
        this.greentopTrainingData.currentAntrenament.descarcariAntrenament.forEach((item)=>{
            item.id = item.idDescarcare;
        });
        // 
        let selDes = this.greentopTrainingData.availableDescarcari.filter((item)=>item.id === this.greentopTrainingData.currentAntrenament.descarcariAntrenament[0].idDescarcare);
        if (selDes && selDes.length > 0){
            this.greentopTrainingData.selectedDescarcare = selDes[0];
        }
        

        this.greentopTrainingData.errorMessage = "";
        this.greentopTrainingData.errorMessageZona = "";
        this.greentopTrainingData.errorMessageImport = "";
        this.greentopTrainingData.resultMessageImport = "";
        this.greentopTrainingData.currentAntrenament.denumire = "";
        this.greentopTrainingData.currentAntrenament.idAntrenamentParinte = this.greentopTrainingData.currentAntrenament.id;
        this.greentopTrainingData.currentAntrenament.status = "initiat";
        this.greentopTrainingData.currentAntrenament.mesajStatus = "";
        //
        this.initNewAvailableData(true);
    }

    private initNewAvailableData(fromExisting = false){
        this.greentopTrainingData.loadingActive = true;
        this.mapCtrl.greentopDataService.getDescarcariDateSatelitare(['finalizat'])
            .then((data) => {
                this.greentopTrainingData.availableDescarcari = data;
                if (fromExisting == true 
                    && this.greentopTrainingData.currentAntrenament.descarcariAntrenament
                    && this.greentopTrainingData.currentAntrenament.descarcariAntrenament.length > 0) {
                    let resDes = this.greentopTrainingData.availableDescarcari.filter((aitem)=>aitem.id === this.greentopTrainingData.currentAntrenament.descarcariAntrenament[0].idDescarcare);
                    if (resDes && resDes.length > 0){
                     this.greentopTrainingData.selectedDescarcare = resDes[0];
                    }
                    this.setFilteredIndiciFromIndiciDescarcare();
                 }
                return true;
            })
            .then((status) => {
                return this.mapCtrl.greentopDataService.getAlgoritmiClasificare();
            })
            .then((data) => {
                this.greentopTrainingData.availableAlgoritmi = data;
                if (fromExisting == false 
                    && this.greentopTrainingData.availableAlgoritmi.length > 0) {
                    this.greentopTrainingData.selectedAlgoritm = this.greentopTrainingData.availableAlgoritmi[0];
                }
                if (fromExisting == true && this.greentopTrainingData.currentAntrenament.idAlgoritm) {
                   let resAlg = this.greentopTrainingData.availableAlgoritmi.filter((aitem)=>aitem.id === this.greentopTrainingData.currentAntrenament.idAlgoritm);
                   if (resAlg && resAlg.length > 0){
                    this.greentopTrainingData.selectedAlgoritm = resAlg[0];
                   }
                }
                return true;
            })
            .then((status) => {
                return this.mapCtrl.greentopDataService.getStructuraDateAlgoritmi();
            })
            .then((data) => {
                this.greentopTrainingData.availableStructuriDateAlgoritmi = data;
                if (fromExisting == false) {
                    let itcol = this.greentopTrainingData.availableStructuriDateAlgoritmi.filter((item) => item.id == 2);
                    if (itcol && itcol.length > 0) {
                        this.greentopTrainingData.selectedStructuraDate = itcol[0];
                    }
                }
                if (fromExisting == true && this.greentopTrainingData.currentAntrenament.idStructuraDate) {
                    let resStrd = this.greentopTrainingData.availableStructuriDateAlgoritmi.filter((aitem)=>aitem.id === this.greentopTrainingData.currentAntrenament.idStructuraDate);
                    if (resStrd && resStrd.length > 0){
                     this.greentopTrainingData.selectedStructuraDate = resStrd[0];
                    }
                 }
                return true;
            })
            .then((status) => {
                return this.mapCtrl.greentopDataService.getAvailableUtilizariTeren();
            })
            .then((data) => {
                this.greentopTrainingData.availableUtilizariTeren = data;
                return true;
            })
            .catch((reason) => {
                this.greentopTrainingData.errorMessage = "Eroare interogare valori selectie disponibile";
                this.$log.error("Eroare interogare valori selectie disponibile: ", getExMessage(reason));
            }).finally(() => {
                this.greentopTrainingData.loadingActive = false;
            })
    }
    //
    private initExistingTrainingData(idPreselected: number) {
        this.greentopTrainingData.selectedAntrenament = null;
        //
        this.clearLayers();

        this.greentopTrainingData.currentAntrenament = {
            denumire: "",
            descriere: "",
            idAlgoritm: 1,
            idStructuraDate: 2,
            descarcariAntrenament: [],
            zoneAntrenament: [],
            indiciAntrenament: [],
            idAntrenamentParinte: null,
            status: "initiat",
            mesajStatus: ""
        }

        this.configAutorefresh();

        this.greentopTrainingData.errorMessage = "";
        this.greentopTrainingData.loadingActive = true;
        //
        this.mapCtrl.greentopDataService.getAntrenamenteModelClasificare(null)
            .then((data) => {
                this.greentopTrainingData.availableAntrenamente = data;
                return true;
            }).catch((reason) => {
                this.greentopTrainingData.errorMessage = "Eroare interogare antrenamente";
                this.$log.error("Eroare interogare antrenamente ", getExMessage(reason));
            }).finally(() => {
                this.greentopTrainingData.loadingActive = false;
            }).then((state) => {
                if (state as any === true && typeof idPreselected === "number") {
                    let tmpPres = this.greentopTrainingData.availableAntrenamente.filter((item) => item.id === idPreselected);
                    if (tmpPres && tmpPres.length > 0) {
                        this.greentopTrainingData.selectedAntrenament = tmpPres[0];
                        this.onChangeAntrenament();
                    }
                }
            }).catch((reason) => {
                this.$log.error("Eroare activare preselectie:", getExMessage(reason));
            })

    }
    //
    private clearLayers() {
        if (this.greentopTrainingData.selectLayer) {
            this.greentopTrainingData.selectLayer.getSource().clear();
        }
        if (this.greentopTrainingData.interestAreaLayer) {
            this.greentopTrainingData.interestAreaLayer.getSource().clear();
        }
        if (this.greentopTrainingData.trainingZonesLayer) {
            this.greentopTrainingData.trainingZonesLayer.getSource().clear();
        }
    }
   
    //
    public onSaveTraining() {
        this.greentopTrainingData.errorMessage = "";
        try {
            if (this.greentopTrainingData.istoolActive_neweditTrainingZone === true){
                throw Error("Adaugare zona noua este activ");
            }
            if (this.greentopTrainingData.istoolActive_importTrainingZones === true){
                throw Error("Importare zone este activ");
            }
            if (this.greentopTrainingData.currentAntrenament.denumire == null
                || this.greentopTrainingData.currentAntrenament.denumire == "") {
                throw Error("Denumire este obligatoriu");
            }
            if (this.greentopTrainingData.currentAntrenament.idAlgoritm == null) {
                throw Error("Algoritm este obligatoriu");
            }
            if (this.greentopTrainingData.currentAntrenament.idStructuraDate == null) {
                throw Error("Structura date este obligatorie");
            }
            if (this.greentopTrainingData.currentAntrenament.descarcariAntrenament == null
                || this.greentopTrainingData.currentAntrenament.descarcariAntrenament.length == 0) {
                throw Error("Descarcare date satelitare este obligatoriu");
            }
            if (this.greentopTrainingData.currentAntrenament.zoneAntrenament == null
                || this.greentopTrainingData.currentAntrenament.zoneAntrenament.length == 0) {
                throw Error("Nu sunt specificate zone antrenament");
            }
            if (this.greentopTrainingData.currentAntrenament.indiciAntrenament == null
                || this.greentopTrainingData.currentAntrenament.indiciAntrenament.length == 0) {
                throw Error("Nu sunt specificati indici antrenament");
            }
            let ctr = this.greentopTrainingData.currentAntrenament;
            let tmpTraining: IAntrenamentModelClasificare = {
                denumire: ctr.denumire,
                descriere: ctr.descriere,
                idAlgoritm: ctr.idAlgoritm,
                idStructuraDate: ctr.idStructuraDate,
                idAntrenamentParinte: ctr.idAntrenamentParinte,
                status: ctr.status,
                mesajStatus: ctr.mesajStatus,
                descarcariAntrenament: [],
                zoneAntrenament: [],
                indiciAntrenament: [],
            }
            ctr.descarcariAntrenament.forEach((item) => {
                tmpTraining.descarcariAntrenament.push({
                    idDescarcare: item.id
                })
            })
            ctr.zoneAntrenament.forEach((item) => {
                tmpTraining.zoneAntrenament.push({
                    descriere: item.descriere,
                    idUtilizareTeren: item.idUtilizareTeren,
                    anUtilizare: item.anUtilizare,
                    startPerioadaUtilizare: item.startPerioadaUtilizare,
                    endPerioadaUtilizare: item.endPerioadaUtilizare,
                    idStratOrigine: item.idStratOrigine,
                    idZonaOrigine: item.idZonaOrigine,
                    geom: createWKTFromGeom(item.geom, this.mapCtrl.mapConfig.projection, "EPSG:4326"),
                    status: item.status,
                    mesajStatus: item.mesajStatus

                })
            })
            ctr.indiciAntrenament.forEach((item) => {
                tmpTraining.indiciAntrenament.push({
                    idIndice: item.idIndice,
                    codIndice: item.codIndice,
                    idStratSursaIndici: item.idStratSursaIndici || null
                })
            })
            //
            this.mapCtrl.greentopDataService.setSalvareAntrenamentModelClasificare(tmpTraining)
                .then((result) => {
                    if (typeof result === "number") {
                        return result;
                    }
                    return null;
                }).catch((reason) => {
                    this.greentopTrainingData.errorMessage = "Eroare salvare training model clasificare";
                    this.$log.error("Erare salvare antrenament: ", getExMessage(reason));
                }).finally(() => {
                    //
                    //clear
                }).then((idAntrenament) => {
                    if (typeof idAntrenament === "number") {
                        this.greentopTrainingData.activeNewTab0 = false;
                        this.greentopTrainingData.activeExistsTab1 = true;
                        this.greentopTrainingData.activeTab = 1;
                        this.initExistingTrainingData(idAntrenament as any);
                    }
                }).catch((reason) => {
                    this.$log.error("Eroare la schimbare tab spre existente: ", getExMessage(reason));
                })

        } catch (error) {
            this.greentopTrainingData.errorMessage = error.message || "";
            return;
        }
    }
    //
    public onCancelTraining() {
        this.clearGreentopTrainingTool();
        this.mapCtrl.greentopTrainingButtonStateOn = false;
    }
    //
    public onClickClose() {
        this.clearGreentopTrainingTool();
        this.mapCtrl.greentopTrainingButtonStateOn = false;
    }

    //
    public onChangeDescarcare() {
        if (this.greentopTrainingData.selectedDescarcare == null) {
            return;
        }
        this.greentopTrainingData.currentAntrenament.descarcariAntrenament.length = 0;
        this.greentopTrainingData.currentAntrenament.indiciAntrenament.length = 0;
        this.greentopTrainingData.filteredIndici.length = 0;
        this.greentopTrainingData.errorMessage = "";
        this.greentopTrainingData.loadingActive = true;
        this.mapCtrl.greentopDataService.getDescarcareDateSatelitare(this.greentopTrainingData.selectedDescarcare.id, true)
            .then((data) => {
                this.greentopTrainingData.currentAntrenament.descarcariAntrenament.push(data);
                //
                let wkt = new WKTFormater();
                let geomwgs = wkt.readGeometry(data.geom);
                let geom = geomwgs.transform("EPSG:4326", this.mapCtrl.mapConfig.projection) as Polygon;
                //
                let tmpFeature = new Feature(geom);
                this.greentopTrainingData.interestAreaLayer.getSource().clear();
                this.greentopTrainingData.interestAreaLayer.getSource().addFeature(tmpFeature);
                this.mapCtrl.map.getView().setCenter(olExtent.getCenter(geom.getExtent()));
                //
                this.setFilteredIndiciFromIndiciDescarcare();
               
               // this.setFilteredIndici();
            }).catch((reason) => {
                this.greentopTrainingData.errorMessage = "Eroare interogare descarcare";
                this.$log.error("Eroare interogare descarcare: ", getExMessage(reason));
            }).finally(() => {
                this.greentopTrainingData.loadingActive = false;
            })
    }
    //
    private setFilteredIndiciFromIndiciDescarcare(){
        this.greentopTrainingData.filteredIndici.length = 0;
        if(this.greentopTrainingData.currentAntrenament.descarcariAntrenament && 
            this.greentopTrainingData.currentAntrenament.descarcariAntrenament.length > 0){
              let des =  this.greentopTrainingData.currentAntrenament.descarcariAntrenament[0] as IDescarcareDateSatelitare;
               if( des.indiciDescarcare && des.indiciDescarcare.length > 0){
                des.indiciDescarcare.forEach(item => {
                    this.greentopTrainingData.filteredIndici.push(item);
                });
               }
            }
    }
    //
    public onChangeAlgoritm(){
        if (this.greentopTrainingData.selectedAlgoritm == null){
            return;
        }
        this.greentopTrainingData.currentAntrenament.idAlgoritm = this.greentopTrainingData.selectedAlgoritm.id;
        this.greentopTrainingData.currentAntrenament.denumireAlgoritm = this.greentopTrainingData.selectedAlgoritm.denumire;
    }

    public onChangeStructuraDateAntrenament(){
        if (this.greentopTrainingData.selectedStructuraDate == null){
            return;
        }
        this.greentopTrainingData.currentAntrenament.idStructuraDate = this.greentopTrainingData.selectedStructuraDate.id;
        this.greentopTrainingData.currentAntrenament.denumireStructuraDate = this.greentopTrainingData.selectedStructuraDate.denumire;
    }
    
    //
    //layers and interactions
    //
    public addSelectLayer() {
        this.greentopTrainingData.selectLayer = new VectorLayer<any>({
            source: new VectorSource({ wrapX: false })
        });
        this.mapCtrl.map.addLayer(this.greentopTrainingData.selectLayer);
    }
    //
    public removeSelectLayer() {
        if (this.greentopTrainingData.selectLayer) {
            this.mapCtrl.map.removeLayer(this.greentopTrainingData.selectLayer);
            this.greentopTrainingData.selectLayer = null;
        }
    }
    //
    public addModifyLayerInteraction() {
        if (this.greentopTrainingData.selectLayer == null) {
            this.addSelectLayer();
        }
        this.greentopTrainingData.selectEditInteraction = new Modify({
            source: this.greentopTrainingData.selectLayer.getSource()
        } as any);
        this.mapCtrl.map.addInteraction(this.greentopTrainingData.selectEditInteraction);
    }
    //
    public removeModifyLayerInteraction() {
        if (this.greentopTrainingData.selectEditInteraction) {
            this.mapCtrl.map.removeInteraction(this.greentopTrainingData.selectEditInteraction);
            this.greentopTrainingData.selectEditInteraction = null;
        }
    }
    //
    public addSelectLayerInteraction() {
        if (this.greentopTrainingData.selectLayer == null) {
            this.addSelectLayer();
        }
        this.greentopTrainingData.selectDrawInteraction = new Draw({
            source: this.greentopTrainingData.selectLayer.getSource(),
            type: 'Polygon',// 'Circle',//'Polygon',
            //geometryFunction: Draw.createBox()
        });
        this.mapCtrl.map.addInteraction(this.greentopTrainingData.selectDrawInteraction);
        this.greentopTrainingData.selectDrawInteraction.on('drawend', this.onDrawSelectBoxEnd);
        this.greentopTrainingData.selectDrawInteraction.on('drawstart', this.onDrawSelectBoxStart);
    }
    //
    public removeSelectLayerInteraction() {
        if (this.greentopTrainingData.selectDrawInteraction) {
            this.mapCtrl.map.removeInteraction(this.greentopTrainingData.selectDrawInteraction);
            this.greentopTrainingData.selectDrawInteraction = null;
        }
    }
    //
    public addInterestAreaLayer() {
        this.greentopTrainingData.interestAreaLayer = new VectorLayer<any>({
            source: new VectorSource({ wrapX: false }),
            style: this.desStyle,
        });
        this.mapCtrl.map.addLayer(this.greentopTrainingData.interestAreaLayer);
    }
    //
    public removeInterestAreaLayer() {
        if (this.greentopTrainingData.interestAreaLayer) {
            this.mapCtrl.map.removeLayer(this.greentopTrainingData.interestAreaLayer);
            this.greentopTrainingData.interestAreaLayer = null;
        }
    }
    //
    public addTrainingZonesLayer() {
        this.greentopTrainingData.trainingZonesLayer = new VectorLayer<any>({
            source: new VectorSource({ wrapX: false }),
            style: this.zoneStyle,
            zIndex: 9999
        } as any);
        this.mapCtrl.map.addLayer(this.greentopTrainingData.trainingZonesLayer);
    }
    //
    public removeTrainingZonesLayer() {
        if (this.greentopTrainingData.trainingZonesLayer) {
            this.mapCtrl.map.removeLayer(this.greentopTrainingData.trainingZonesLayer);
            this.greentopTrainingData.trainingZonesLayer = null;
        }
    }
    //
    public addImportZonesLayer() {
        this.greentopTrainingData.importZonesLayer = new VectorLayer<any>({
            source: new VectorSource({ wrapX: false })
        });
        this.mapCtrl.map.addLayer(this.greentopTrainingData.importZonesLayer);
    }
    //
    public removeImportZonesLayer() {
        if (this.greentopTrainingData.importZonesLayer) {
            this.mapCtrl.map.removeLayer(this.greentopTrainingData.importZonesLayer);
            this.greentopTrainingData.importZonesLayer = null;
        }
    }
    public changeDataTab(tabid: string){
        this.greentopTrainingData.errorMessage = "";
    }


    //
    // training zone
    //
    private initNewEditTrainingZone(zoneItem: IZonaAntrenament = null) {
        if (zoneItem == null) {
            this.greentopTrainingData.selectedZonaAntrenament = null;
            this.greentopTrainingData.selectedneweditUtilizareTeren = null;
            //
            this.greentopTrainingData.neweditZona = {
                startPerioadaUtilizare: { luna: 1, decada: 2 },
                endPerioadaUtilizare: { luna: 2, decada: 3 },
                idStratOrigine: null,
                idZonaOrigine: null,
                anUtilizare: 2020,
                geom: null,
                idUtilizareTeren: null,
                denumireUtilizareTeren: null,
                status: "initiat",
                descriere: ""
            }
        } else {
            this.greentopTrainingData.selectedZonaAntrenament = zoneItem;
            this.greentopTrainingData.neweditZona = {
                startPerioadaUtilizare: convertDataStringToDecada(zoneItem.startPerioadaUtilizare as any),
                endPerioadaUtilizare: convertDataStringToDecada(zoneItem.endPerioadaUtilizare as any),
                idStratOrigine: zoneItem.idStratOrigine,
                idZonaOrigine: zoneItem.idZonaOrigine,
                anUtilizare: zoneItem.anUtilizare,
                geom: zoneItem.geom,
                idUtilizareTeren: zoneItem.idUtilizareTeren,
                denumireUtilizareTeren: zoneItem.denumireUtilizareTeren,
                status: zoneItem.status,
                feature: zoneItem.feature.clone(),
                descriere: zoneItem.descriere || ""
            }
            //
            let resVeg = this.greentopTrainingData.availableUtilizariTeren.filter((item) => item.id === this.greentopTrainingData.neweditZona.idUtilizareTeren);
            if (resVeg && resVeg.length > 0) {
                this.greentopTrainingData.selectedneweditUtilizareTeren = resVeg[0];
            } else {
                this.greentopTrainingData.selectedneweditUtilizareTeren = null;
            }

        }
        if (this.greentopTrainingData.selectLayer == null) {
            this.addSelectLayer();
        }
        this.greentopTrainingData.errorMessageZona = "";
        this.greentopTrainingData.selectButtonStateOn = false;
        this.greentopTrainingData.selectLayer.getSource().clear();
        if (zoneItem && this.greentopTrainingData.neweditZona.feature) {
            this.greentopTrainingData.selectLayer.getSource().addFeature(this.greentopTrainingData.neweditZona.feature);
        }
    }
    //
    private clearNewexistingZone() {
        this.greentopTrainingData.errorMessageZona = "";
        this.removeModifyLayerInteraction();
        this.removeSelectLayerInteraction();
        this.removeSelectLayer();
    }

    //
    public onClickSelectButton = (event) => {
        try {
            if (this.greentopTrainingData.selectedZonaAntrenament == null) {
                if (!this.greentopTrainingData.selectButtonStateOn) {
                    this.greentopTrainingData.selectLayer.getSource().clear();
                    this.greentopTrainingData.neweditZona.geom = null;
                    this.addSelectLayerInteraction();
                    this.greentopTrainingData.selectButtonStateOn = true;
                } else {
                    if (this.greentopTrainingData.selectDrawInteraction.getActive()) {
                        this.greentopTrainingData.selectDrawInteraction.finishDrawing();
                    } else {
                        this.removeSelectLayerInteraction();
                        this.greentopTrainingData.selectButtonStateOn = false;
                    }
                }
            } else {
                if (!this.greentopTrainingData.selectButtonStateOn) {
                    this.addModifyLayerInteraction();
                } else {
                    this.removeModifyLayerInteraction();
                    this.greentopTrainingData.selectButtonStateOn = false;
                }
            }

        } catch (error) {
            this.$log.error("eroare selectie zona");
        }
        //todo
    }
    //
    public ontoolAddNeweditTrainingZone() {
        this.greentopTrainingData.istoolActive_neweditTrainingZone = !this.greentopTrainingData.istoolActive_neweditTrainingZone;
        if (this.greentopTrainingData.istoolActive_neweditTrainingZone === true) {
            this.initNewEditTrainingZone();
        }
    }
    //
    public ontoolImportTrainingZone() {
        this.greentopTrainingData.istoolActive_importTrainingZones = !this.greentopTrainingData.istoolActive_importTrainingZones;
        if (this.greentopTrainingData.istoolActive_importTrainingZones === true) {
            this.initImportTrainingZone();
        }
    }
    //
    public showzonetoolsButons() {
        return this.greentopTrainingData.istoolActive_importTrainingZones === false
            && this.greentopTrainingData.istoolActive_neweditTrainingZone === false
    }
    //
    public showZoneTrainingList() {
        return this.greentopTrainingData.istoolActive_neweditTrainingZone === false
    }

    //
    public onSaveNeweditTrainingZone() {
        try {
            if (this.greentopTrainingData.neweditZona.geom == null) {
                throw Error("Zona selectie nu este definita");
            }
            if (this.greentopTrainingData.neweditZona.anUtilizare == null) {
                throw Error("An cultura este obligatoriu");
            }
            if (this.greentopTrainingData.neweditZona.idUtilizareTeren == null) {
                throw Error("Utilizare zonă este obligatorie");
            }
            if (this.greentopTrainingData.neweditZona.startPerioadaUtilizare["luna"] == null
                || this.greentopTrainingData.neweditZona.startPerioadaUtilizare["decada"] == null) {
                throw Error("Inceput perioada utilizare este obligatoriu");
            }
            if (this.greentopTrainingData.neweditZona.endPerioadaUtilizare["luna"] == null
                || this.greentopTrainingData.neweditZona.endPerioadaUtilizare["decada"] == null) {
                throw Error("Sfarsit perioada utilizare este obligatoriu");
            }
            if (this.greentopTrainingData.neweditZona.endPerioadaUtilizare["luna"] < this.greentopTrainingData.neweditZona.startPerioadaUtilizare["luna"] ) {
                throw Error("luna sfarsit perioada este mai mica decat luna inceput perioada");
            }
            if (this.greentopTrainingData.neweditZona.endPerioadaUtilizare["luna"] === this.greentopTrainingData.neweditZona.startPerioadaUtilizare["luna"] 
                && this.greentopTrainingData.neweditZona.endPerioadaUtilizare["decada"] < this.greentopTrainingData.neweditZona.startPerioadaUtilizare["decada"]
            ) {
                throw Error("decada sfarsit perioada este mai mica decat decada inceput perioada");
            }
            
            //todo check 
            //
            if (this.greentopTrainingData.selectedZonaAntrenament == null) {
                let tmpzone: IZonaAntrenament = {
                    anUtilizare: this.greentopTrainingData.neweditZona.anUtilizare,
                    startPerioadaUtilizare: convertDataDecadaToString(this.greentopTrainingData.neweditZona.startPerioadaUtilizare as any),
                    endPerioadaUtilizare: convertDataDecadaToString(this.greentopTrainingData.neweditZona.endPerioadaUtilizare as any),
                    idStratOrigine: this.greentopTrainingData.neweditZona.idStratOrigine || null,
                    idZonaOrigine: this.greentopTrainingData.neweditZona.idZonaOrigine || null,
                    geom: this.greentopTrainingData.neweditZona.geom,
                    idUtilizareTeren: this.greentopTrainingData.neweditZona.idUtilizareTeren,
                    denumireUtilizareTeren: this.greentopTrainingData.neweditZona.denumireUtilizareTeren,
                    status: this.greentopTrainingData.neweditZona.status
                }
                this.createZoneFeature(tmpzone);
                this.greentopTrainingData.trainingZonesLayer.getSource().addFeature(tmpzone.feature);
                this.greentopTrainingData.currentAntrenament.zoneAntrenament.push(tmpzone);
                this.removeSelectLayerInteraction();
            } else {
                this.greentopTrainingData.selectedZonaAntrenament.anUtilizare = this.greentopTrainingData.neweditZona.anUtilizare;
                this.greentopTrainingData.selectedZonaAntrenament.startPerioadaUtilizare = convertDataDecadaToString(this.greentopTrainingData.neweditZona.startPerioadaUtilizare as any);
                this.greentopTrainingData.selectedZonaAntrenament.endPerioadaUtilizare = convertDataDecadaToString(this.greentopTrainingData.neweditZona.endPerioadaUtilizare as any);
                this.greentopTrainingData.selectedZonaAntrenament.idStratOrigine = this.greentopTrainingData.neweditZona.idStratOrigine || null;
                this.greentopTrainingData.selectedZonaAntrenament.idZonaOrigine = this.greentopTrainingData.neweditZona.idZonaOrigine || null;
                this.greentopTrainingData.selectedZonaAntrenament.geom = this.greentopTrainingData.neweditZona.feature.getGeometry() as any;
                this.greentopTrainingData.selectedZonaAntrenament.idUtilizareTeren = this.greentopTrainingData.neweditZona.idUtilizareTeren;
                this.greentopTrainingData.selectedZonaAntrenament.denumireUtilizareTeren = this.greentopTrainingData.neweditZona.denumireUtilizareTeren;
                this.greentopTrainingData.selectedZonaAntrenament.status = this.greentopTrainingData.neweditZona.status;
                this.updateZoneFeature(this.greentopTrainingData.selectedZonaAntrenament);
                this.removeModifyLayerInteraction();
            }

            this.greentopTrainingData.selectLayer.getSource().clear();
            this.greentopTrainingData.errorMessageZona = "";
            this.greentopTrainingData.istoolActive_neweditTrainingZone = false;
        } catch (error) {
            this.greentopTrainingData.errorMessageZona = error.message || "";
            this.$log.error("Eroare salvare zona antrenament");
        }
    }
    //
    public onRemoveNeweditTrainingZone() {
        try {
            if (this.greentopTrainingData.selectedZonaAntrenament) {
                let idzone = this.greentopTrainingData.currentAntrenament.zoneAntrenament.indexOf(this.greentopTrainingData.selectedZonaAntrenament);
                if (idzone >= 0) {
                    this.greentopTrainingData.currentAntrenament.zoneAntrenament.splice(idzone, 1);
                    this.greentopTrainingData.trainingZonesLayer.getSource().removeFeature(this.greentopTrainingData.selectedZonaAntrenament.feature);
                }
                this.greentopTrainingData.selectedZonaAntrenament = null;
            }
            this.onCancelNeweditTrainingZone();
        } catch (error) {
            this.$log.error("eroare stergere zona antrenament");
        }
    }
    //
    private createZoneFeature(zone: IZonaAntrenament) {
        try {
            let tmpgeom: string | Polygon = zone.geom;
            if (typeof tmpgeom === "string") {
                tmpgeom = createGeomFromWKT(tmpgeom,"EPSG:4326", this.mapCtrl.mapConfig.projection);
            }
            zone.feature = new Feature(tmpgeom as any);
            zone.feature.set("utilizare", zone.idUtilizareTeren);
            zone.feature.setStyle([
                this.zoneStyle[0],
                this.zoneStyle[1],
                this.zoneText(zone.denumireUtilizareTeren)
            ])
        } catch (error) {
            this.$log.error("eroare creare locatie zona");
        }
    }
    //
    private updateZoneFeature(zone: IZonaAntrenament) {
        if (zone.feature && zone.geom) {
            zone.feature.setGeometry(zone.geom as any);
            zone.feature.set("utilizare", zone.idUtilizareTeren);
            zone.feature.setStyle([
                this.zoneStyle[0],
                this.zoneStyle[1],
                this.zoneText(zone.denumireUtilizareTeren)
            ])
        }
    }
    //
    private periodaUtilizareTerenVizualizare(start: string, end: string): string{
        let result = "";
        try {
            let startdec = convertDataStringToDecada(start);
            let enddec = convertDataStringToDecada(end);
            let startlunares = this.monthList.filter((item) => item.nrLuna === startdec.luna);
            if (startlunares && startlunares.length > 0) {
                result += startlunares[0].denumire;
            }
            result += " - de_" + startdec.decada;
            result += " -> "
            let endlunares = this.monthList.filter((item) => item.nrLuna === enddec.luna);
            if (endlunares && endlunares.length > 0) {
                result += endlunares[0].denumire;
            }
            result += " - de_" + enddec.decada;
        } catch (error) {
            result = start + " -> " + end;
        }
        return result;
    }
    //
    public onChangeneweditUtilizareTeren() {
        if (this.greentopTrainingData.selectedneweditUtilizareTeren) {
            this.greentopTrainingData.neweditZona.idUtilizareTeren = this.greentopTrainingData.selectedneweditUtilizareTeren.id as any;
            this.greentopTrainingData.neweditZona.denumireUtilizareTeren = this.greentopTrainingData.selectedneweditUtilizareTeren.denumire;

            this.greentopTrainingData.neweditZona.startPerioadaUtilizare = convertDataStringToDecada(this.greentopTrainingData.selectedneweditUtilizareTeren.startPerioadaUtilizare as any);
            this.greentopTrainingData.neweditZona.endPerioadaUtilizare = convertDataStringToDecada(this.greentopTrainingData.selectedneweditUtilizareTeren.endPerioadaUtilizare as any);
        }
    }
    //
    public onEditZoneItem(zoneItem: IZonaAntrenament) {
        if (this.greentopTrainingData.istoolActive_neweditTrainingZone === false) {
            this.greentopTrainingData.istoolActive_neweditTrainingZone = true;
            this.initNewEditTrainingZone(zoneItem);
        }

    }
    //
    public onCancelNeweditTrainingZone() {
        this.$log.error("cancel zone")
        this.clearNewexistingZone();
        this.greentopTrainingData.istoolActive_neweditTrainingZone = false;
    }
    
    //
    public onDrawSelectBoxStart = (event) => {
        this.greentopTrainingData.selectLayer.getSource().clear();
        this.greentopTrainingData.neweditZona.geom = null;
    }
    //
    public onDrawSelectBoxEnd = (event) => {
        let extentFeature = event.feature as Feature;
        this.greentopTrainingData.neweditZona.geom = extentFeature.getGeometry() as any || null;
        this.greentopTrainingData.selectDrawInteraction.setActive(false);
        // to do get extent list details
        if (extentFeature) {
            let extentPolygon = extentFeature.getGeometry() as Polygon;

        }
    }
    //
    public onClickZoneItem(zone: IZonaAntrenament) {
        try {
            if (zone) {
                if (zone.feature) {
                    let geom = zone.feature.getGeometry().getExtent();
                    this.mapCtrl.map.getView().setCenter(olExtent.getCenter(geom));
                }
            }
        } catch (error) {
            this.$log.error("Eroare centrare pe locatie: ", getExMessage(error));
        }
    }

    //
    //import training zone
    //
    public initImportTrainingZone() {
        try {
            //available layers
            this.greentopTrainingData.selectedImportLayer = null;
            this.initImportZoneData();
            this.greentopTrainingData.availableImportLayers.length = 0;
            this.mapCtrl.categories.forEach((catItem) => {
                catItem.layers.forEach((layItem) => {
                    if (layItem.featureType === featureType.poly
                        || layItem.featureType === featureType.polyText) {
                        //to do check if config is for import
                        if (this.mapCtrl.userSettingsSrvs.isAuthForOption(authOpt.greentop_import_source_zones, layItem.name, authType.layer)) {
                            this.greentopTrainingData.availableImportLayers.push(layItem);
                        }
                    }
                })
            });
            if (this.greentopTrainingData.availableImportLayers.length === 0){
                throw Error("Nu sunt straturi sursa import");
            }
            //

        } catch (error) {
            this.$log.error("Eroare initializare import", getExMessage(error));
        }
    }

    public onChangeImportLayer(){
        this.initImportZoneData();
        this.greentopTrainingData.availableImportLayers.forEach((item)=>{
            item.visible = false;
            if (this.greentopTrainingData.selectedImportLayer
                && item.id === this.greentopTrainingData.selectedImportLayer.id){
                item.visible = true;
            }
            this.mapCtrl.mapMenuLayers.showHideLayer(null, item)
        })
        if (this.greentopTrainingData.selectedImportLayer == null) {
            return;
        }
        this.greentopTrainingData.availableImportFields.length = 0;
        this.greentopTrainingData.selectedImportLayer.infoColumns.forEach((item)=>{
            this.greentopTrainingData.availableImportFields.push(item);
        })
    }

    public onChangeImport(){

    }

    private initImportZoneData(){
        this.greentopTrainingData.errorMessageImport = "";
        this.greentopTrainingData.resultMessageImport = "";
        this.greentopTrainingData.selectedImportUtilizare = null;
        this.greentopTrainingData.selectedImportAn = null;
        this.greentopTrainingData.selectedImportStartPerioada = null;
        this.greentopTrainingData.selectedImportEndPerioada = null;
        this.greentopTrainingData.selectedImportDescriere = null;
        this.greentopTrainingData.importPeriodFromDefault = true;
        //
        this.greentopTrainingData.importSettings = {
            campSursaAn: null,
            campSursaUtilizare: null,
            campSursaStartPerioadaUtilizare: null,
            campSursaEndPerioadaUtilizare: null,
            campSursaDescriere: null,
            periodaDinValoriDefault: false
        }
    }
    
    public onImportTrainingZone() {
        try {
            this.greentopTrainingData.errorMessageImport = "";
            this.greentopTrainingData.resultMessageImport = "";
            if (this.greentopTrainingData.selectedImportLayer == null){
                throw Error("Nu este selectat strat import");
            }
            if (this.greentopTrainingData.selectedImportUtilizare == null){
                throw Error("Nu este specificat camp utilizare");
            }
            if (this.greentopTrainingData.selectedImportAn == null){
                throw Error("Nu este specificat camp an utilizare");
            }
            this.greentopTrainingData.importSettings.campSursaUtilizare = this.greentopTrainingData.selectedImportUtilizare.name;
            this.greentopTrainingData.importSettings.campSursaAn = this.greentopTrainingData.selectedImportAn.name;
            this.greentopTrainingData.importSettings.periodaDinValoriDefault = this.greentopTrainingData.importPeriodFromDefault;
            
            if (this.greentopTrainingData.importPeriodFromDefault === false){
                if (this.greentopTrainingData.selectedImportStartPerioada == null){
                    throw Error("Nu este specificat camp inceput perioada");
                } else {
                    this.greentopTrainingData.importSettings.campSursaStartPerioadaUtilizare = this.greentopTrainingData.selectedImportStartPerioada.name;
                }
                if (this.greentopTrainingData.selectedImportEndPerioada == null){
                    throw Error("Nu este specificat camp sfarsit perioada");
                } else {
                    this.greentopTrainingData.importSettings.campSursaEndPerioadaUtilizare = this.greentopTrainingData.selectedImportEndPerioada.name;
                }
            } 
            let tmpImportZones: Array<IZonaAntrenament> = [];
            let hasZoneError = false;
            //
            (this.greentopTrainingData.selectedImportLayer.internalLayer as VectorLayer<any>).getSource().getFeatures().forEach((fitem)=>{
                try {
                    //check if utilizare exista
                    let tmpUtilizare = fitem.get(this.greentopTrainingData.importSettings.campSursaUtilizare)
                    if (tmpUtilizare == null || tmpUtilizare.length === 0) {
                        throw Error("nume utilizare teren nu este specificat");
                    }
                    let rezAvUt = this.greentopTrainingData.availableUtilizariTeren.filter(item => item.denumire.toLowerCase() === tmpUtilizare.toLowerCase());
                    if (rezAvUt == null || rezAvUt.length == 0) {
                        throw Error("nume utilizare nu exista in tip predefinit " + tmpUtilizare);
                    }
                    let tmpAn = fitem.get(this.greentopTrainingData.importSettings.campSursaAn);
                    tmpAn = Number(tmpAn);
                    if (tmpAn == null || typeof tmpAn !== "number"){
                        throw Error("an utilizare nu este definit sau nu este numeric");
                    }
                    let tmpDes = "";
                    if (this.greentopTrainingData.importSettings.campSursaDescriere 
                        && this.greentopTrainingData.importSettings.campSursaDescriere.length > 0){
                            tmpDes = fitem.get(this.greentopTrainingData.importSettings.campSursaDescriere) || "";
                    }
                    
                    let tmpZonaAnt:any = {
                        idStratOrigine: this.greentopTrainingData.selectedImportLayer.id,
                        idZonaOrigine: fitem.getId(),
                        geom: fitem.getGeometry(),
                        feature: null,
                        idUtilizareTeren: rezAvUt[0].id,
                        denumireUtilizareTeren: rezAvUt[0].denumire,
                        status: "initiat",
                        mesajStatus: "",
                        descriere: tmpDes,
                        anUtilizare: tmpAn,
                        startPerioadaUtilizare: rezAvUt[0].startPerioadaUtilizare,
                        endPerioadaUtilizare: rezAvUt[0].endPerioadaUtilizare,
                    }
                    this.createZoneFeature(tmpZonaAnt);
                    //
                    if (this.greentopTrainingData.importSettings.periodaDinValoriDefault === false){
                        //tray parse decada and month
                        let tmpStPerUt = fitem.get(this.greentopTrainingData.importSettings.campSursaStartPerioadaUtilizare)
                        if (tmpStPerUt == null || tmpStPerUt.length === 0) {
                            throw Error("start perioada nu este specificat");
                        }
                        let tryExtractStPerUt = convertDataStringToDecada(tmpStPerUt);
                        if (tryExtractStPerUt.decada == null || tryExtractStPerUt.luna == null) {
                            throw Error("start perioada nu poate fi extrasa");
                        }
                        let tmpEndPerUt = fitem.get(this.greentopTrainingData.importSettings.campSursaEndPerioadaUtilizare)
                        if (tmpEndPerUt == null || tmpEndPerUt.length === 0) {
                            throw Error("end perioada nu este specificat");
                        }
                        let tryExtractEndPerUt = convertDataStringToDecada(tmpEndPerUt);
                        if (tryExtractEndPerUt.decada == null || tryExtractEndPerUt.luna == null) {
                            throw Error("end perioada nu poate fi extrasa");
                        }
                        tmpZonaAnt.startPerioadaUtilizare = convertDataDecadaToString(tryExtractStPerUt);
                        tmpZonaAnt.endPerioadaUtilizare = convertDataDecadaToString(tryExtractEndPerUt);
                    }
                    //
                    tmpImportZones.push(tmpZonaAnt);
                    
                } catch (error) {
                    this.$log.error("Eroare import zona: ", getExMessage(error));
                    hasZoneError = true;
                }
                
            })
            if (hasZoneError){
                this.greentopTrainingData.errorMessageImport = "Eroare import zone";
            }
            //
            //this.$log.log("zone pt import " + tmpImportZones.length);
            let importate = 0;
            if (tmpImportZones.length > 0){
                tmpImportZones.forEach((zitem)=>{
                    //check if exists
                    let fexist = this.greentopTrainingData.currentAntrenament.zoneAntrenament
                    .filter(fitem=> fitem.idStratOrigine === zitem.idStratOrigine &&  fitem.idZonaOrigine === zitem.idZonaOrigine);
                    if (fexist == null || fexist.length === 0){
                        this.greentopTrainingData.currentAntrenament.zoneAntrenament.push(zitem);
                        this.greentopTrainingData.trainingZonesLayer.getSource().addFeature(zitem.feature);
                        //add to layer
                        importate++;
                    }
                })
            }
            this.greentopTrainingData.resultMessageImport = `Zone pt. import ${tmpImportZones.length}, importate ${importate}, existente ${tmpImportZones.length - importate}`
            this.$log.error(this.greentopTrainingData.resultMessageImport);
            
        } catch (error) {
            this.greentopTrainingData.errorMessageImport = "Eroare import: ", getExMessage(error);
            this.$log.error(this.greentopTrainingData.errorMessageImport);
        }
    }

    public onCancelImportTrainingZone() {
        this.greentopTrainingData.errorMessageImport = "";
        this.greentopTrainingData.resultMessageImport = "";
        this.greentopTrainingData.availableImportLayers.forEach((item)=>{
            item.visible = false;
            this.mapCtrl.mapMenuLayers.showHideLayer(null, item)
        })
        this.greentopTrainingData.istoolActive_importTrainingZones = false;
        //todo
    }

    ///
    // Indici
    //
    public onAddNewIndice() {
        try {
            if (this.greentopTrainingData.selectednewIndice) {
                let exist = this.greentopTrainingData.currentAntrenament
                    .indiciAntrenament.filter((item) => item.codIndice === this.greentopTrainingData.selectednewIndice.codIndice)
                if (exist && exist.length > 0) {
                    return;
                }
                let tmpIndice: IIndiceAntrenament = {
                    codIndice: this.greentopTrainingData.selectednewIndice.codIndice,
                    idIndice: this.greentopTrainingData.selectednewIndice.idIndice,
                    sursaDate: this.greentopTrainingData.selectednewIndice.sursaDate,
                    idStratSursaIndici: null
                }
                findStratAsociatToIndice(tmpIndice, this.mapCtrl);
                this.greentopTrainingData.currentAntrenament
                    .indiciAntrenament.push(tmpIndice);
            }
        } catch (error) {
            this.$log.error("eroare la adaugare indice antrenament");
        }

    }

    public onRemoveIndice(indice: IIndiceAntrenament) {
        try {
            if (indice) {
                let ind = this.greentopTrainingData.currentAntrenament.indiciAntrenament.indexOf(indice);
                if (ind >= 0) {
                    this.greentopTrainingData.currentAntrenament.indiciAntrenament.splice(ind, 1);
                }
            }
        } catch (error) {
            this.$log.error("eroare la inlaturare indice antrenament")
        }

    }

    public onChangenewIndice(){

    }

    public onAnimateIndiceLayer(indice: IIndiceAntrenament){
        //activate strat asociat
        (this.greentopTrainingData.currentAntrenament.indiciAntrenament as Array<IIndiceAntrenament>).forEach((itind)=>{
            if (itind.stratAsociat){
                if(itind.stratAsociat.id === indice.stratAsociat.id){
                    if (itind.stratAsociat.visible === false){
                        itind.stratAsociat.visible = true;
                        this.mapCtrl.mapMenuLayers.showHideLayer(null, itind.stratAsociat);
                    }
                } else {
                    if ((itind).stratAsociat.visible === true){
                        //set invisible
                        itind.stratAsociat.visible = false;
                        this.mapCtrl.mapMenuLayers.showHideLayer(null, itind.stratAsociat);
                    }
                }
            }
            
        })
        //
        let timeList = [];
        this.greentopTrainingData.currentAntrenament.descarcariAntrenament.forEach((itdes)=>{
            itdes.produseDescarcare.forEach((itProd)=>{
                if (itProd.dataProdus && itProd.dataProdus.length > 0){
                    timeList.push(itProd.dataProdus);
                }
            })
        })
        this.mapCtrl.mapCtrlAnimateTimeRaster.onAnimateTimeRasterAction(indice.stratAsociat, timeList);
    }

    //
    //Existing antrenament
    //
    public onChangeAntrenament() {
        if (this.greentopTrainingData.selectedAntrenament) {
            ///
            this.greentopTrainingData.interestAreaLayer.getSource().clear();
            this.greentopTrainingData.trainingZonesLayer.getSource().clear();
            //
            this.clearRefreshTimeout();
            //
            this.greentopTrainingData.errorMessage = "";
            this.greentopTrainingData.loadingActive = true;
            //
            this.mapCtrl.greentopDataService.getAntrenamentModelClasificare(this.greentopTrainingData.selectedAntrenament.id)
                .then((data) => {
                    if (data.id == null) {
                        throw Error("Antrenament nu exista");
                    }
                    this.greentopTrainingData.currentAntrenament = data;
                    //
                    this.greentopTrainingData.currentAntrenament.zoneAntrenament.forEach((itemzone) => {
                        itemzone.geom = createGeomFromWKT(itemzone.geom, "EPSG:4326", this.mapCtrl.mapConfig.projection);;
                        this.createZoneFeature(itemzone);
                        this.greentopTrainingData.trainingZonesLayer.getSource().addFeature(itemzone.feature);
                    });
                    this.greentopTrainingData.currentAntrenament.descarcariAntrenament.forEach((itemdes) => {
                        let geom = createGeomFromWKT(itemdes.geom, "EPSG:4326", this.mapCtrl.mapConfig.projection);
                        itemdes.geom = geom;
                        let tmpFeature = new Feature(geom);
                        this.greentopTrainingData.interestAreaLayer.getSource().addFeature(tmpFeature);
                        this.mapCtrl.map.getView().setCenter(olExtent.getCenter(geom.getExtent()));
                    });
                    this.greentopTrainingData.currentAntrenament.indiciAntrenament.forEach((itind)=>{
                        findStratAsociatToIndice(itind, this.mapCtrl);
                    })
                    //
                }).catch((reason) => {
                    this.greentopTrainingData.errorMessage = "Eroare interogare antrenament";
                    this.$log.error("Eroare interogare antrenament ", getExMessage(reason));
                }).finally(() => {
                    this.greentopTrainingData.loadingActive = false;
                })
        }
    }

    public onClickReloadExisting(){
         this.onChangeAntrenament();
    }

    public canRestartTraining(){
        if (this.greentopTrainingData.currentAntrenament.actiune
            && this.greentopTrainingData.currentAntrenament.actiune.toLowerCase() === TaskActiune.start) {
            return false;
        } else {
            return this.greentopTrainingData.currentAntrenament.status.toLowerCase() !== TaskStatus.in_lucru;
        }
    }

    public onRestartTraining(){
        if (this.greentopTrainingData.currentAntrenament.status 
            && this.greentopTrainingData.currentAntrenament.status.toLowerCase() === TaskStatus.finalizat){
            if (confirm("Confirmă că dorești sa repornești antrenamentul de la început") === false){
                return;
            }
        }
        //
        this.greentopTrainingData.errorMessage = "";
        this.greentopTrainingData.loadingActive = true;
            this.mapCtrl.greentopDataService.setNotificareAntrenamentModelClasificare(this.greentopTrainingData.currentAntrenament.id, null)
            .then((result)=>{
                //
                return true;
            }).catch((reason)=>{
                this.$log.error("Eroare restart antrenament");
            }).finally(()=>{
                this.onChangeAntrenament();
            });
            
    }

    public canStopTraining(){
        if (this.greentopTrainingData.currentAntrenament.actiune
            && this.greentopTrainingData.currentAntrenament.actiune.toLowerCase() === TaskActiune.stop) {
            return false;
        } else if (this.greentopTrainingData.currentAntrenament.actiune
            && this.greentopTrainingData.currentAntrenament.actiune.toLowerCase() === TaskActiune.start
            && this.greentopTrainingData.currentAntrenament.status.toLowerCase() === TaskStatus.initiat) {
                return true;
        } else {
            return this.greentopTrainingData.currentAntrenament.status.toLowerCase() === TaskStatus.in_lucru
        }
     }

     public onStopTraining(){
         this.greentopTrainingData.errorMessage = "";
         this.greentopTrainingData.loadingActive = true;
             this.mapCtrl.greentopDataService.setNotificareAntrenamentModelClasificare(this.greentopTrainingData.currentAntrenament.id, {isStop: true})
             .then((result)=>{
                 //
                 return true;
             }).catch((reason)=>{
                 this.$log.error("Eroare oprire antrenament");
             }).finally(()=>{
                 this.onChangeAntrenament();
             });
             
     }

     public canContinueTraining(){
        if (this.greentopTrainingData.currentAntrenament.actiune
            && this.greentopTrainingData.currentAntrenament.actiune.toLowerCase() === TaskActiune.start) {
            return false;
        } else {
            return this.greentopTrainingData.currentAntrenament.status.toLowerCase() !== TaskStatus.in_lucru
                && this.greentopTrainingData.currentAntrenament.status.toLowerCase() !== TaskStatus.finalizat;
        }
     }

     public onContinueTraining(){
         this.greentopTrainingData.errorMessage = "";
         this.greentopTrainingData.loadingActive = true;
             this.mapCtrl.greentopDataService.setNotificareAntrenamentModelClasificare(this.greentopTrainingData.currentAntrenament.id, {isContinue: true})
             .then((result)=>{
                 //
                 return true;
             }).catch((reason)=>{
                 this.$log.error("Eroare continuare antrenament");
             }).finally(()=>{
                 this.onChangeAntrenament();
             });
             
     }

     public newFromTraining(){
         try {
             this.initNewFromExisting();
         } catch (error) {
             this.$log.error("Eroare initiere antrenament nou din cel existent", getExMessage(error));
         }
     }

     //
     public onDownloadWekaInfo(){
         try {
             window.open("/greentop/antrenament-raport-clasificare/" + this.greentopTrainingData.currentAntrenament.id, '_blank')
         } catch (error) {
             this.$log.error("Eroare descarcare raport weka: ", getExMessage(error));
         }
     }

     //
     private configAutorefresh(): void {
        //init interval values
       this.greentopTrainingData.autorefreshInterval = -1;
       let indexOption = this.mapCtrl.userSettingsSrvs.isAuthForOptionFullInfo(authOpt.auto_refresh_interval, authAs.control_grentop_cl_button_visible, authType.object);
       if (indexOption && indexOption.idItem) {
           this.greentopTrainingData.autorefreshInterval = indexOption.idItem;
           if (this.greentopTrainingData.autorefreshInterval < 3) { this.greentopTrainingData.autorefreshInterval = 3; }
           if (this.greentopTrainingData.autorefreshInterval > 30) { this.greentopTrainingData.autorefreshInterval = 30; }
           this.greentopTrainingData.autorefreshTimeout = this.greentopTrainingData.autorefreshInterval;
       }
       //init interval instance
       if (this.greentopTrainingData.resIntervalRef == null){
        this.greentopTrainingData.resIntervalRef = setInterval(
            ()=>{
               try {
                   if (this.greentopTrainingData.autorefreshInterval > 0 ) {
                       
                       //
                       if (this.greentopTrainingData.autorefreshTimeout <= 0) {
                           if (this.greentopTrainingData.autorefreshInterval > 0) {
                               this.greentopTrainingData.autorefreshTimeout = this.greentopTrainingData.autorefreshInterval;
                           }
                           //toto refresh
                           if (this.greentopTrainingData.activeExistsTab1 
                               && this.greentopTrainingData.activeDataTab === 0
                               && this.greentopTrainingData.currentAntrenament.status !== "finalizat") {
                               this.onChangeAntrenament();
                           }
                           
                       } else {
                           if (this.greentopTrainingData.activeExistsTab1 
                               && this.greentopTrainingData.activeDataTab === 0) {
                               this.greentopTrainingData.autorefreshTimeout = this.greentopTrainingData.autorefreshTimeout - 1;
                           }
                       }
                   }
               } catch (error) {
                   this.$log.error("eroare refresh :", getExMessage(error));
               }
            }, 1000
           )
       } else {
           this.clearRefreshTimeout();
       }
    }

    private clearRefreshTimeout(){
        if (this.greentopTrainingData.resIntervalRef && this.greentopTrainingData.autorefreshInterval > 0){
           this.greentopTrainingData.autorefreshTimeout = this.greentopTrainingData.autorefreshInterval;
        }
    }

    public showHideIndiceLayer(indice){
        this.mapCtrl.mapMenuLayers.showHideLayer(null, indice.stratAsociat)
    }
}
