//NG+
import { AppSettings } from "../app-settings";
import { IAccessResursa, IItem } from "../definitions";
import { UserSettingsService } from "./user-settings-service";

import { NGXLogger } from "ngx-logger";
import { HttpClient, HttpParams } from "@angular/common/http";

export interface IDbStoreResursaInterna {
    getAvailableResursaInterna(): Promise<Array<IItem>>;
    getResursaInternaFromStorage(
        idResursa: number): Promise<{ id: number, nume: string, descriere: string, type: string, defaultAccess: boolean }>;
    setAddResursaInternaToStorage(
        resursa: IAccessResursa): Promise<boolean>;
    setUpdateResursaInternaToStorage(
        resursa: IAccessResursa): Promise<boolean>;
    setDeleteResursaInternaToStorage(
        resursa: { id: number, nume: string }): Promise<boolean>;
}

export class DbStoreResursaInterna implements IDbStoreResursaInterna {
    constructor(
        private $http: HttpClient,
        private $log: NGXLogger,
        private settingsService: UserSettingsService) { }


    public getAvailableResursaInterna(): Promise<Array<IItem>> {
        return this.$http.get(
            AppSettings.serverPath + 'data/all-resursa-interna',
            { observe: "response" }
        )
            .toPromise()
            .then((response) => {
                let layerList: Array<any> = <Array<any>>response.body;
                let layers: Array<IItem> = [];
                for (var i = 0; i < layerList.length; i++) {
                    let layer: IItem = { id: layerList[i].id, text: layerList[i].nume };
                    layers.push(layer);
                }
                return layers;
            });
    }

    public getResursaInternaFromStorage(idResursa: number)
        : Promise<{ id: number, nume: string, descriere: string, type: string, defaultAccess: boolean }> {
        return this.$http.get(
            AppSettings.serverPath + 'data/resursa-interna/' + idResursa,
            { observe: "response" }
        )
            .toPromise()
            .then((response) => {
                let role: { id: number, nume: string, descriere: string, type: string, defaultAccess: boolean }
                    = { id: null, nume: null, descriere: null, type: null, defaultAccess: null }
                try {
                    let resRole = response.body;
                    role.id = resRole['id'];
                    role.nume = resRole['nume'];
                    role.descriere = resRole['descriere'];
                    role.type = resRole['type'];
                    role.defaultAccess = resRole['defaultAccess'];
                } catch (e) {
                    throw new Error("erroare extragere resursa interna" + e.message);
                }
                return role;
            });
    }


    public setAddResursaInternaToStorage(
        resursa: IAccessResursa): Promise<boolean> {
        return this.$http.post(
            AppSettings.serverPath + 'data/add-resursa-interna',
            JSON.stringify(
                { nume: resursa.nume, descriere: resursa.descriere, type: resursa.type, defaultAccess: resursa.defaultAccess }),
            {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                },
                observe: "response"
            })
            .toPromise()
            .then((response) => {
                if ('id' in <any>response.body) {
                    return true;
                } else {
                    return false;
                }
            });
    }

    public setUpdateResursaInternaToStorage(
        resursa: IAccessResursa): Promise<boolean> {
        return this.$http.post(
            AppSettings.serverPath + 'data/update-resursa-interna',
            JSON.stringify(
                { id: resursa.id, nume: resursa.nume, descriere: resursa.descriere, type: resursa.type, defaultAccess: resursa.defaultAccess }),
            {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                },
                observe: "response"
            })
            .toPromise()
            .then((response) => {
                if ('success' in <any>response.body) {
                    return true;
                } else {
                    return false;
                }
            });
    }

    public setDeleteResursaInternaToStorage(
        resursa: { id: number, nume: string }): Promise<boolean> {
        return this.$http.post(
            AppSettings.serverPath + 'data/update-resursa-interna',
            JSON.stringify(
                { id: resursa.id, nume: resursa.nume }),
            {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                },
                observe: "response"
            })
            .toPromise()
            .then((response) => {
                if ('success' in <any>response.body) {
                    return true;
                } else {
                    return false;
                }
            });
    }
}
