//NG+
import { Component, Inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AppSettings } from '../../app-settings';
import {  authAs, authOpt, authType } from '../../definitions';
import { MapController } from '../map-controller';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'map-legend',
    providers: [],
    templateUrl: './app/map/map-components/map-legend.component.html',
    styleUrls: ['./app/map/map-components/map-legend.component.css']
})
export class MapLegendComponent implements OnInit, OnChanges {

    @Input("mapctrl") mapCtrl: MapController = null;
    //
    public constructor(@Inject(DomSanitizer) private sanitizer:DomSanitizer){
        //
    }
    //
    public ngOnInit(): void {
        //
    }
    //
    public ngOnChanges(changes: SimpleChanges) {
        //
    }
    //
    sanitize(url:string){
        return this.sanitizer.bypassSecurityTrustUrl(url);
    }

}