 
 
//
import { NGXLogger } from "ngx-logger";
import { authAs, authType, featureType } from "../definitions";
import { MapController } from "./map-controller";

    export class MapCtrlLegend {
        //
        public constructor(public mapCtrl: MapController, private $log : NGXLogger) {

        };
        //
        public doLegendDefaultOpen(){
            if (this.mapCtrl.userSettingsSrvs.isAuthForResource(authAs.legend_default_open, authType.object)) {
                this.mapCtrl.legendButtonStateOn = true;
            }
        }
        //
        public buildLegendStyleForLayerType(layerType: string, settings: any): object {
            let layerStyle: object = {};
            switch (layerType) {
                case featureType.icon:
                    layerStyle = this.buildLegendStyleForIcon(settings);
                    break;
                case featureType.point:
                    layerStyle = this.buildLegendStyleForPoint(settings);
                    break;
                case featureType.line:
                    layerStyle = this.buildLegendStyleForLine(settings);
                    break;
                case featureType.poly:
                    layerStyle = this.buildLegendStyleForPolygon(settings);
                    break;
                case featureType.polyText:
                case featureType.polyReport:
                    layerStyle = this.buildLegendStyleForPolygon(settings);
                    break;
                case featureType.pointText:
                    layerStyle = this.buildLegendStyleForPoint(settings);
                    break;
            }
            return layerStyle;
        }
        public buildDefaultLegendStyleForLayerType(layerType: string, color: string, layerSettings: any): object {
            let layerStyle: object = {};
            switch (layerType) {
                case featureType.tile:
                case featureType.image:
                case featureType.heatmap:
                case featureType.cluster:
                    layerStyle = null; //{ nostyle: 'nostyle' };
                    break;
                case featureType.icon:
                    layerStyle = this.buildLegendStyleForIcon({ image: { src: layerSettings.image.src || null } });
                    break;
                case featureType.point:
                    layerStyle = this.buildLegendStyleForPoint({ image: { fill: { color: '255,255,255,0.4' }, stroke: { color: color } } });
                    break;
                case featureType.line:
                    layerStyle = this.buildLegendStyleForLine({ stroke: { color: color } });
                    break;
                case featureType.poly:
                    layerStyle = this.buildLegendStyleForPolygon({ fill: { color: color }, stroke: { color: color }});
                    break;
                case featureType.polyReport:
                case featureType.polyText:
                    layerStyle = this.buildLegendStyleForPolygon({ fill: { color: color }, stroke: { color: color } });
                    break;
                case featureType.pointText:
                    layerStyle = this.buildLegendStyleForPoint({ image: { fill: { color: '255,255,255,0.4' }, stroke: { color: color } } });
                    break;
                

            }
            return layerStyle;
        }
        public buildLegendStyleForIcon(settings: any): object {
            return {
                "display": "inline-block",
                "height": "20px",
                "width": "20px",
                "border": "none",
                "background-image": `url(${settings.image.src})`,
                "background-color": "transparent",
                "background-repeat": "no-repeat",
                "background-size": "auto 100%"
            }
        }

        public buildLegendStyleForPoint(settings: any): object {
            return {
                "display": "inline-block",
                "height": "10px",
                "width": "10px",
                "margin-left": "5px",
                "margin-right": "5px",
                "background-color": `${settings.image.fill.color}`,
                "border-style": "solid",
                "border-color": `${settings.image.stroke.color}`,
                "border-width": "2px",
                "border-radius": "50%"}
        }
        //
        public buildLegendStyleForLine(settings: any): object {
            return {
                "display": "inline-block",
                "height": "2px",
                "width": "20px",
                "border-style": "solid",
                "border-color": `${settings.stroke.color}`,
                "border-width": "2px",
            }
        }
        //
        public buildLegendStyleForPolygon(settings: any): object {
            return {
                "display": "inline-block",
                "height": "10px",
                "width": "20px",
                "background-color": `${settings.fill.color}`,
                "border-style": "solid",
                "border-color": `${settings.stroke.color}`,
                "border-width": "2px",
            }
        }
    }
