import { Inject, Injectable, forwardRef } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { InternalMessageService } from './internal-message-service';
import { AuthDialogsServiceNg } from '../auth/auth-dialogs-service-ng';

@Injectable()
export class HttpNoAuthInterceptorService implements HttpInterceptor {

  constructor(
    @Inject(AuthDialogsServiceNg) private authDialogService: AuthDialogsServiceNg,
    @Inject(InternalMessageService) private internalMessageService: InternalMessageService
    ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let cloneReq = req.clone();
    return next.handle(cloneReq).pipe(
        // Catch any errors from the response
        catchError((error: HttpErrorResponse) => {
          // If the status code is 401 or 403, redirect to login page
          if (error.status === 401 || error.status === 403) {
            // 
            if (!this.authDialogService.isLoginDialogOpen()) {
                this.authDialogService.showLoginDialog()
                    .then((data) => {
                        if (data.value === true) {
                            this.internalMessageService.broadcast("userAuthChanged");
                        }
                    })
            }
          }
          // Return an observable with the error message
          return throwError(error.message);
        })
      );
  }
}