//NG+
import { AppSettings } from "../app-settings";
import { IItem } from "../definitions";
import { UserSettingsService } from "./user-settings-service";

import { NGXLogger } from "ngx-logger";
import { HttpClient, HttpParams } from "@angular/common/http";
import { IMapViewSettings } from "../roles/settings-components/settings-definitions";



export interface IDbStoreMapView {
    getAvailableMapViewSettings(): Promise<Array<IItem>>;
    getMapViewSettingsFromStorage(idMapViewConfig: number): Promise<IMapViewSettings>;
    setAddMapViewSettingsToStorage(mapViewConfig: IMapViewSettings): Promise<boolean>
    setUpdateMapViewSettingsToStorage(mapViewConfig: IMapViewSettings): Promise<boolean>
}
export class DbStoreMapView implements IDbStoreMapView {

    constructor(
        private $http: HttpClient,
        private $log: NGXLogger,
        private settingsService: UserSettingsService) { }


    public getAvailableMapViewSettings(): Promise<Array<IItem>> {
        return this.$http.get(
            AppSettings.serverPath + 'data/get-all-mapview-settings',
            { observe: "response" }
        )
            .toPromise()
            .then((response) => {
                let roleList: Array<any> = <Array<any>>response.body;
                let roles: Array<IItem> = [];
                for (var i = 0; i < roleList.length; i++) {
                    let role = { id: roleList[i].id,
                         text: roleList[i].version,
                         descriere: roleList[i].descriere
                        };
                    roles.push(role as any);
                }
                return roles;
            });
    }

    public getMapViewSettingsFromStorage(idMapViewConfig: number): Promise<IMapViewSettings> {
        return this.$http.get(
            AppSettings.serverPath + 'data/get-mapview-settings/' + idMapViewConfig,
            { observe: "response" }
        )
            .toPromise()
            .then((response) => {
                let mapView: IMapViewSettings = null;
                try {
                    let resMV = response.body;
                    let center = resMV['center'] || [0, 0];
                    mapView = {
                        id: resMV['id'],
                        version: resMV['version'],
                        projection: resMV['projection'],
                        zoom: resMV['zoom'],
                        minZoom: resMV['minZoom'],
                        maxZoom: resMV['maxZoom'],
                        centerLong: center[0] || 0,
                        centerLat: center[1] || 0,
                        basemap: resMV['basemap'],
                        basemapConfig: JSON.stringify(resMV['basemapConfig'])
                    }
                } catch (e) {
                    throw new Error("erroare extragere map-view settings" + e.message);
                }
                return mapView;
            });
    }

    public setAddMapViewSettingsToStorage(mapViewConfig: IMapViewSettings): Promise<boolean> {
        let mapView = {
            projection: mapViewConfig.projection,
            zoom: mapViewConfig.zoom,
            minZoom: mapViewConfig.minZoom,
            maxZoom: mapViewConfig.maxZoom,
            center: [mapViewConfig.centerLong, mapViewConfig.centerLat],
            basemap: mapViewConfig.basemap,
            basemapConfig: mapViewConfig.basemapConfig
        }
        return this.$http.post(
            AppSettings.serverPath + 'data/add-mapview-settings',
            JSON.stringify(mapView),
            {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                },
                observe: "response"
            })
            .toPromise()
            .then((response) => {
                if ('id' in <any>response.body) {
                    return true;
                } else {
                    return false;
                }
            });
    }

    public setUpdateMapViewSettingsToStorage(mapViewConfig: IMapViewSettings): Promise<boolean> {
        let mapView = {
            id: mapViewConfig.id,
            version: mapViewConfig.version,
            projection: mapViewConfig.projection,
            zoom: mapViewConfig.zoom,
            minZoom: mapViewConfig.minZoom,
            maxZoom: mapViewConfig.maxZoom,
            center: [mapViewConfig.centerLong, mapViewConfig.centerLat],
            basemap: mapViewConfig.basemap,
            basemapConfig: mapViewConfig.basemapConfig
        }
        return this.$http.post(
            AppSettings.serverPath + 'data/save-mapview-settings',
            JSON.stringify(mapView),
            {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                },
                observe: "response"
            })
            .toPromise()
            .then((response) => {
                if ('success' in <any>response.body) {
                    return true;
                } else {
                    return false;
                }
            });
    }
}
