 
 
//

import { ILayerReportSettings } from "../definitions";
import { getExMessage } from "./map-utils";

import Feature from 'ol/Feature';

    export const reportLayerFormula = {
        count: "count",
        sum: "sum",
        average: "average",
        percent: "percent",
        precentall: "percentall"
    }

    export function reportFormulaCheckParams(reportInfo: ILayerReportSettings): boolean {
        let result = false;
        switch (reportInfo.reportFormula) {
            case reportLayerFormula.count:
                result = reportCountCheckParams(reportInfo);
                break;
            case reportLayerFormula.sum:
                result = reportSumCheckParams(reportInfo);
                break;
            case reportLayerFormula.average:
                result = reportAverageCheckParams(reportInfo);
                break;
            case reportLayerFormula.percent:
                result = reportPercentCheckParams(reportInfo);
                break;
            case reportLayerFormula.precentall:
                result = reportPercentallCheckParams(reportInfo);
                break;
            default:
                result = false;
        }
        return result;
    }

    export function reportFormulaProcessData(reportInfo: ILayerReportSettings, repFeature: Feature, dataFeatures: Array<Feature>) {
        switch (reportInfo.reportFormula) {
            case reportLayerFormula.count:
                reportCount(reportInfo, repFeature, dataFeatures);
                break;
            case reportLayerFormula.sum:
                reportSum(reportInfo, repFeature, dataFeatures);
                break;
            case reportLayerFormula.average:
                reportAverage(reportInfo, repFeature, dataFeatures);
                break;
            case reportLayerFormula.percent:
                reportPercent(reportInfo, repFeature, dataFeatures);
                break;
            case reportLayerFormula.precentall:
                reportPercentall(reportInfo, repFeature, dataFeatures);
                break;
            default:

        }
    }

    //
    export function reportCountCheckParams(reportInfo: ILayerReportSettings): boolean {
        return ("result" in reportInfo.reportColumns);
    }
    export function reportCount(reportInfo: ILayerReportSettings, repFeature: Feature, dataFeatures: Array<Feature>) {
        try {
            let result = 0;
            dataFeatures.forEach((sitem) => {
                if (sitem["searchFilterOut"] === 'false') {
                    result++;
                }
            });
            repFeature.set(reportInfo.reportColumns['result'], result)
        } catch (e) {
            throw new Error(`count ${repFeature.getId()} : ${e.message || ""}`);
        }
    }
    //

    //
    export function reportSumCheckParams(reportInfo: ILayerReportSettings): boolean {
        return ("result" in reportInfo.reportColumns) && ('input1' in reportInfo.dataColumns);
    }
    export function reportSum(reportInfo: ILayerReportSettings, repFeature: Feature, dataFeatures: Array<Feature>) {
        try {
            let result = 0;
            dataFeatures.forEach((sitem) => {
                if (sitem["searchFilterOut"] === 'false') {
                    let tmpVal = sitem.get(reportInfo.dataColumns['input1']);
                    if (tmpVal !== undefined && !isNaN(tmpVal)) {
                        result += tmpVal;
                    }
                }
            });
            let nbDecimals = getConstantNbOfDecimals(reportInfo);
            if (nbDecimals >= 0) {
                repFeature.set(reportInfo.reportColumns['result'], result.toFixed(nbDecimals));
            } else {
                repFeature.set(reportInfo.reportColumns['result'], result.toFixed(2));
            }

        } catch (e) {
            throw new Error(`sum ${repFeature.getId()} : ${e.message || ""}`);
        }
    }

    //
    export function reportAverageCheckParams(reportInfo: ILayerReportSettings): boolean {
        return ("result" in reportInfo.reportColumns) && ('input1' in reportInfo.dataColumns);
    }
    export function reportAverage(reportInfo: ILayerReportSettings, repFeature: Feature, dataFeatures: Array<Feature>) {
        try {
            let result = 0;
            let count = 0
            dataFeatures.forEach((sitem) => {
                if (sitem["searchFilterOut"] === 'false') {
                    let tmpVal = sitem.get(reportInfo.dataColumns['input1']);
                    if (tmpVal !== undefined && !isNaN(tmpVal)) {
                        result += tmpVal;
                        count++;
                    }
                }
            });
            if (count > 1) {
                result = result / count;
            }
            //get decimal constant
            let nbDecimals = getConstantNbOfDecimals(reportInfo);
            if (nbDecimals >= 0) {
                repFeature.set(reportInfo.reportColumns['result'], result.toFixed(nbDecimals));
            } else {
                repFeature.set(reportInfo.reportColumns['result'], result);
            }

        } catch (e) {
            throw new Error(`sum ${repFeature.getId()} : ${e.message || ""}`);
        }
    }

    //
    export function reportPercentCheckParams(reportInfo: ILayerReportSettings): boolean {
        return ("result" in reportInfo.reportColumns)
            && ('input1' in reportInfo.dataColumns)
            && ('input2' in reportInfo.reportColumns);
    }
    export function reportPercent(reportInfo: ILayerReportSettings, repFeature: Feature, dataFeatures: Array<Feature>) {
        try {
            let result = 0;
            let count = 0
            dataFeatures.forEach((sitem) => {
                if (sitem["searchFilterOut"] === 'false') {
                    count++;
                }
            });
            let refTotal = Number(repFeature.get(reportInfo.reportColumns['input2']));

            if (!isNaN(refTotal) && refTotal > 0 && count > 0) {
                result = (count * 100) / refTotal;
            }
            let nbDecimals = getConstantNbOfDecimals(reportInfo);
            if (nbDecimals >= 0) {
                repFeature.set(reportInfo.reportColumns['result'], result.toFixed(nbDecimals));
            } else {
                repFeature.set(reportInfo.reportColumns['result'], result.toFixed(2))
            }
        } catch (e) {
            throw new Error(`percent ${repFeature.getId()} : ${e.message || ""}`);
        }
    }

    //todo extend formula for other imputs
    export function reportPercentallCheckParams(reportInfo: ILayerReportSettings): boolean {
        return ("result" in reportInfo.reportColumns)
            && ('input1' in reportInfo.dataColumns)
            && ('input2' in reportInfo.dataColumns);
    }
    export function reportPercentall(reportInfo: ILayerReportSettings, repFeature: Feature, dataFeatures: Array<Feature>) {
        try {
            let result = 0;
            let sumValues = 0;
            let refValues = 0;
            if ('input1' in reportInfo.dataColumns) {
                dataFeatures.forEach((sitem) => {
                    if (sitem["searchFilterOut"] === 'false') {
                        let tmpVal = Number(sitem.get(reportInfo.dataColumns['input1']));
                        if (tmpVal !== undefined && !isNaN(tmpVal)) {
                            sumValues += tmpVal;
                        }
                    }
                });
            }
            if ('input2' in reportInfo.dataColumns) {
                dataFeatures.forEach((sitem) => {
                    if (sitem["searchFilterOut"] === 'false') {
                        let tmpVal = Number(sitem.get(reportInfo.dataColumns['input2']));
                        if (tmpVal !== undefined && !isNaN(tmpVal)) {
                            refValues += tmpVal;
                        }
                    }
                });
            }
            //let refValues = Number(repFeature.get(reportInfo.reportColumns['input2']));

            if (!isNaN(refValues) && refValues > 0 && !isNaN(sumValues)) {
                result = (sumValues * 100) / refValues;
            }
            let nbDecimals = getConstantNbOfDecimals(reportInfo);
            if (nbDecimals >= 0) {
                repFeature.set(reportInfo.reportColumns['result'], result.toFixed(nbDecimals));
            } else {
                repFeature.set(reportInfo.reportColumns['result'], result.toFixed(2))
            }
        } catch (e) {
            throw new Error(`percent ${repFeature.getId()} : ${e.message || ""}`);
        }
    }

    //return nb of decimals from constants value must be between 0 and 20
    // if not correct -1 is returned
    function getConstantNbOfDecimals(reportInfo: any,): number {
        let nbDecimals = -1;
        try {
            if ("nb_of_decimals" in reportInfo.constants) {
                let rawNbDecimals = reportInfo.constants["nb_of_decimals"];
                if (typeof rawNbDecimals === 'number'
                    && rawNbDecimals >= 0 && rawNbDecimals <= 20
                ) {
                    nbDecimals = Number(rawNbDecimals.toFixed(0));
                }
            }
        } catch (reason) {
            console.log("eroare extragere numar zecimale ", getExMessage(reason));
        }
        return nbDecimals;
    }
