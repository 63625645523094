//NG+
import { AppSettings } from "../app-settings";

import { Inject, Injectable } from "@angular/core";
import { HttpClient, HttpParams, HttpResponse } from "@angular/common/http";
import { IDescarcareDateSatelitareIL, IIndiceConex, IIndiceHotspot,
        IInfAlgoritmIC,
        IInfoDescarcareDateSatelitareIL, IInfoImpermeabilitate,
        IInfoPragHotspot, IInfoProcesareIndiceConex as IInfoProcesareIndiceConex,
        IInfoUnitateAdministrativaIL, IIntegrateHotspot, IProcesareIndiceConex,
        IProdusDescarcareIL, IUnitateAdministrativaDescarcareIL,
        IUnitateAdministrativaProcesare, IInfoProcesareDateSezoniere,
        IProcesareDateSezoniere,IInfSezonDate } from "../map/map-components/map-integrate.definitions";

import moment, { Moment } from "moment";


import Polygon from 'ol/geom/Polygon';
import WKTFormater from "ol/format/WKT";

// export const TaskActiune = {
//     start: "start",
//     stop: "stop"
//}

// export const TaskStatus = {
//     initiat: "initiat",
//     in_lucru: "in lucru",
//     finalizat: "finalizat"
// }




export interface IIntegrateLandsatDataService {
    //
    getProduseDisponibileDescarcarePentruZonaInteres(startPerioada: Moment, endPerioada: Moment, zoneInteres: Array<Polygon>, sursaDate: Array<string>): Promise<any>;
    getUnitatiAdministrativeActive(): Promise<Array<IInfoUnitateAdministrativaIL>>;
    getDescarcariDateSatelitare(status: string[]): Promise<Array<IInfoDescarcareDateSatelitareIL>>;
    getDescarcareDateSatelitare(id: number, detalii?: boolean): Promise<IDescarcareDateSatelitareIL>;
    setSalvareDescarcareDateSatelitare(descarcare: IDescarcareDateSatelitareIL): Promise<any>;
    setNotificareDescarcareDateSatelitare(idDescarcare: number, config: any): Promise<any>;
    //
    getProcesariIndiceConex(status: string[]): Promise<Array<IInfoProcesareIndiceConex>>;
    getProcesareIndiceConex(id: number, detalii: boolean): Promise<IProcesareIndiceConex>;
    setSalvareProcesareIndiciConex(procesare: IProcesareIndiceConex): Promise<Number>
    getIndiciConex(): Promise<Array<IIndiceConex>>;
    getProcesariDateSezoniere(status: string[]): Promise<Array<IInfoProcesareDateSezoniere>>;
    getProcesareDateSezoniere(id: number, detalii: boolean): Promise<IProcesareDateSezoniere>;
    
}

@Injectable({
    providedIn: 'root',
})
export class IntegrateLandsatDataService implements IIntegrateLandsatDataService {
    public constructor(
        @Inject(HttpClient) private $http: HttpClient,
        // private $log: ng.ILogService,
        //  private $q: ng.IQService
    ) {

    }

    //
    //descarcare
    //
    public getProduseDisponibileDescarcarePentruZonaInteres(startPerioada: Moment, endPerioada: Moment, zoneInteres: Array<Polygon>, sursaDate: Array<string>): Promise<any> {
        let wkt = new WKTFormater();

        let payload = {
            startPerioada: startPerioada.format("YYYY-MM-DD"),
            endPerioada: endPerioada.format("YYYY-MM-DD"),
            zoneInteres: [],
            sursaDate: sursaDate
        }
        zoneInteres.forEach((item) => {
            payload.zoneInteres.push(wkt.writeGeometry(item));
        })

        return this.$http.post(AppSettings.serverPath + 'integrate-landsat/produse-disponibile-descarcare',
            JSON.stringify(payload),
            {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                },
                observe: "response"
            })
            .toPromise()
            .then((response) => {

                let grSat = [];
                let arrRes = response.body as Array<IProdusDescarcareIL>;
                try {
                    for (const itgr of arrRes) {
                        let tmpgr: IProdusDescarcareIL = {
                            codGranula: itgr.codGranula,
                            sursaDate: itgr.sursaDate,
                            numeProdus: itgr.numeProdus,
                            dataProdus: itgr.dataProdus,
                            // previzualizareS: "integrate-landsat/imagine-previzualizare-date-satelitare/mica/"+ itgr.uuid + "?sursaDate="+itgr.sursaDate,
                            // previzualizareL: "integrate-landsat/imagine-previzualizare-date-satelitare/mare/"+ itgr.uuid + "?sursaDate="+itgr.sursaDate,
                            previzualizareS: itgr.previzualizareS,
                            previzualizareL: itgr.previzualizareL,
                            uuid: itgr.uuid,
                            geom: itgr.geom
                        }
                        grSat.push(tmpgr);
                    }
                } catch (e) {
                    throw new Error(" eroare date la interogare produse disponibile descarcare pentru zona interes" + e.message || "");
                }
                return grSat;
            });
    }
    //
    public getUnitatiAdministrativeActive(): Promise<Array<IInfoUnitateAdministrativaIL>> {
        return this.$http.get(
            AppSettings.serverPath + 'integrate-landsat/unitati-administrative-active',
            {
                headers: {
                    // 'Content-Type': 'application/json; charset=utf-8'
                },
                observe: "response"
            })
            .toPromise()
            .then((response) => {

                let grSat = [];
                let arrRes = response.body as Array<IInfoUnitateAdministrativaIL>;
                try {
                    for (const itgr of arrRes) {
                        let tmpgr: IInfoUnitateAdministrativaIL = {
                            id: itgr.id,
                            denumire: itgr.denumire,
                            geom: itgr.geom
                        }
                        grSat.push(tmpgr);
                    }
                } catch (e) {
                    throw new Error(" eroare date la interogare produse disponibile descarcare pentru zona interes" + e.message || "");
                }
                return grSat;
            });
    }
    //
    public getDescarcariDateSatelitare(status: string[]): Promise<Array<IInfoDescarcareDateSatelitareIL>> {
        let paylod = {
            status: status
        }
        return this.$http.post(
            AppSettings.serverPath + 'integrate-landsat/descarcari-date-satelitare',
            JSON.stringify(paylod),
            {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                },
                observe: "response"
            })
            .toPromise()
            .then((response) => {
                let returnInfoDes: Array<IInfoDescarcareDateSatelitareIL> = [];
                let arrInfoDes = response.body as Array<IInfoDescarcareDateSatelitareIL>;
                try {
                    for (const itgr of arrInfoDes) {
                        let tmpgr: IInfoDescarcareDateSatelitareIL = {
                            id: itgr.id,
                            denumire: itgr.denumire,
                            descriere: itgr.descriere,
                            sursaDate: itgr.sursaDate,
                            status: itgr.status,
                            momentCreare: itgr.momentCreare
                        }
                        returnInfoDes.push(tmpgr);
                    }
                } catch (e) {
                    throw new Error(" eroare date la interogare taskuri descarcari date satelitare" + e.message || "");
                }
                return returnInfoDes;
            });
    }
    //
    public getDescarcareDateSatelitare(id: number, detalii: boolean = true): Promise<IDescarcareDateSatelitareIL> {
        return this.$http.get(
            AppSettings.serverPath + 'integrate-landsat/descarcare-date-satelitare/' + id,
            {
                params: new HttpParams().set('detalii', detalii.toString()),
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                },
                observe: "response"
            })
            .toPromise()
            .then((response) => {
                let returnDes: IDescarcareDateSatelitareIL = {
                    id: null,
                    denumire: null,
                    descriere: null,
                    sursaDate: null,
                    geom: null,
                    startPerioada: null,
                    endPerioada: null,
                    momentCreare: null,
                    momentModificare: null,
                    status: null,
                    mesajStatus: null,
                    actiune: null,

                    produseDescarcare: [],
                    unitatiAdministrativeDescarcare: []
                };
                let dataDes = response.body as IDescarcareDateSatelitareIL;
                try {
                    returnDes.id = dataDes.id;
                    returnDes.denumire = dataDes.denumire;
                    returnDes.descriere = dataDes.descriere;
                    returnDes.sursaDate = dataDes.sursaDate;
                    returnDes.geom = dataDes.geom;
                    //returnDes.startPerioada = dataDes.startPerioada;
                    //returnDes.endPerioada = dataDes.endPerioada;
                    if(dataDes.startPerioada && dataDes.startPerioada.length > 0){
                        returnDes.startPerioada = moment(dataDes.startPerioada,"YYYY-MM-DD")
                    }
                    if(dataDes.endPerioada && dataDes.endPerioada.length > 0){
                        returnDes.endPerioada = moment(dataDes.endPerioada,"YYYY-MM-DD")
                    }
                    returnDes.momentCreare = dataDes.momentCreare;
                    returnDes.momentModificare = dataDes.momentModificare;
                    returnDes.status = dataDes.status;
                    returnDes.mesajStatus = dataDes.mesajStatus;
                    returnDes.actiune = dataDes.actiune;
                    if (detalii === true) {
                        //
                        for (const ituat of dataDes.unitatiAdministrativeDescarcare) {
                            let tmpuatdes: IUnitateAdministrativaDescarcareIL = {
                                idUnitateAdministrativa: ituat.idUnitateAdministrativa,
                                denumire: ituat.denumire,
                                geom: ituat.geom,
                            }
                            returnDes.unitatiAdministrativeDescarcare.push(tmpuatdes);
                        }
                        //
                        for (const itgr of dataDes.produseDescarcare) {
                            let tmpgrdes: IProdusDescarcareIL = {
                                codGranula: itgr.codGranula,
                                sursaDate: itgr.sursaDate,
                                numeProdus: itgr.numeProdus,
                                dataProdus: itgr.dataProdus,
                                //previzualizareS: "greentop/imagine-previzualizare-date-satelitare/mica/" + itgr.uuid + "?sursaDate="+itgr.sursaDate,
                                //previzualizareL: "greentop/imagine-previzualizare-date-satelitare/mare/" + itgr.uuid + "?sursaDate="+itgr.sursaDate,
                                previzualizareS: itgr.previzualizareS,
                                previzualizareL: itgr.previzualizareL,
                                uuid: itgr.uuid,
                                geom: itgr.geom,
                                status: itgr.status,
                                mesajStatus: itgr.mesajStatus
                            }
                            returnDes.produseDescarcare.push(tmpgrdes);
                        }
                    }
                } catch (e) {
                    throw new Error(" eroare date la interogare task descarcari date satelitare" + e.message || "");
                }
                return returnDes;
            });
    }
    //
    public setSalvareDescarcareDateSatelitare(descarcare: IDescarcareDateSatelitareIL): Promise<Number> {
        let payload = descarcare;
        return this.$http.post(
            AppSettings.serverPath + 'integrate-landsat/salvare-descarcare-date-satelitare',
            JSON.stringify(payload),
            {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                },
                observe: "response"
            })
            .toPromise()
            .then((response) => {
                let idDescarcare = null;
                try {
                    idDescarcare = response.body["idDescarcare"];
                } catch (e) {
                    throw new Error(" eroare date la salvare task descarcare date satelitare" + e.message || "");
                }
                return idDescarcare;
            });
    }
    //
    public setNotificareDescarcareDateSatelitare(idDescarcare: number, config: any): Promise<any> {
        let payload = config || {};
        return this.$http.post(
            AppSettings.serverPath + 'integrate-landsat/notificare-descarcare-date-satelitare/' + idDescarcare,
            JSON.stringify(payload),
            {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                },
                observe: "response"
            })
            .toPromise()
            .then((response) => {
                let resData = null;
                try {
                    resData = response.body;
                } catch (e) {
                    throw new Error(" eroare notificare descarcare date satelitare" + e.message || "");
                }
                return resData;
            });
    }

    //
    //hotspot detectie
    //
    public getReferintaTimestampHotspotActive(): Promise<Array<string>> {
        return this.$http.get(
            AppSettings.serverPath + 'integrate-landsat/hotspot-referinta-timestamp',
            {
                headers: {
                    // 'Content-Type': 'application/json; charset=utf-8'
                },
                observe: "response"
            })
            .toPromise()
            .then((response) => {

                let tmsHs = [];
                let arrRes = response.body as Array<string>;
                try {
                    for (const itgr of arrRes) {
                        tmsHs.push(itgr['timestamp']);
                    }
                } catch (e) {
                    throw new Error(" eroare date la interogare hotspot referinta timestamp" + e.message || "");
                }
                return tmsHs;
            });
    }
    //
    public getPraguriDetectieHotspot(): Promise<Array<IInfoPragHotspot>> {
        return this.$http.get(
            AppSettings.serverPath + 'integrate-landsat/hotspot-praguri-detectie',
            {
                headers: {
                    // 'Content-Type': 'application/json; charset=utf-8'
                },
                observe: "response"
            })
            .toPromise()
            .then((response) => {

                let prgDec = [];
                let arrRes = response.body as Array<any>;
                try {
                    for (const itgr of arrRes) {
                        let tmpgr: IInfoPragHotspot = {
                            id: itgr["id"],
                            denumire: itgr["denumire"],
                            descriere: itgr["descriere"],
                            vMin: itgr["vMin"],
                            mMin: itgr["mMin"]
                        }
                        prgDec.push(tmpgr);
                    }
                } catch (e) {
                    throw new Error(" eroare date la interogare hotspot praguri detectie" + e.message || "");
                }
                return prgDec;
            });
    }
    //
    public getImpermabilitatiDetectieHotspot(): Promise<Array<IInfoImpermeabilitate>> {
        return this.$http.get(
            AppSettings.serverPath + 'integrate-landsat/hotspot-impermeabilitati',
            {
                headers: {
                    // 'Content-Type': 'application/json; charset=utf-8'
                },
                observe: "response"
            })
            .toPromise()
            .then((response) => {

                let impDec = [];
                let arrRes = response.body as Array<any>;
                try {
                    for (const itgr of arrRes) {
                        let tmpgr: IInfoImpermeabilitate = {
                            id: itgr["id"],
                            denumire: itgr["denumire"],
                            descriere: itgr["descriere"],
                            an: itgr["an"]
                        }
                        impDec.push(tmpgr);
                    }
                } catch (e) {
                    throw new Error(" eroare date la interogare hotspot praguri detectie" + e.message || "");
                }
                return impDec;
            });
    }
    //
    public getIndiciHotspot(): Promise<Array<IIndiceHotspot>> {
        return this.$http.get(
            AppSettings.serverPath + 'integrate-landsat/hotspot-indici',
            {
                headers: {
                    // 'Content-Type': 'application/json; charset=utf-8'
                },
                observe: "response"
            })
            .toPromise()
            .then((response) => {

                let impDec = [];
                let arrRes = response.body as Array<any>;
                try {
                    for (const itgr of arrRes) {
                        let tmpgr: IIndiceHotspot = {
                            id: itgr["id"],
                            codIndice: itgr["codIndice"],
                            idStratSursaIndici: itgr["idStratSursaIndici"],
                            stratAsociat: null
                        }
                        impDec.push(tmpgr);
                    }
                } catch (e) {
                    throw new Error(" eroare date la interogare hotspot indici" + e.message || "");
                }
                return impDec;
            });
    }

    public setInterogareHotspot(hotspot: IIntegrateHotspot): Promise<any> {
        let payload = hotspot;
        return this.$http.post(
            AppSettings.serverPath + 'integrate-landsat/identificare-hotspoturi',
            JSON.stringify(payload),
            {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                },
                observe: "response"
            })
            .toPromise()
            .then((response) => {
                let idHotspot = null;
                try {
                    if(response.body["Status"] == null){
                        throw new Error("nu exista status");
                    }
                    if (response.body["Status"] == 'Error'){
                        throw new Error(response.body["Message"] || "eroare procesare server")
                    }
                    if (response.body["Status"] == 'OK')
                    {
                        idHotspot = response.body["Message"];
                    }
                } catch (e) {
                    throw new Error(" eroare identificare hotspoturi: " + e.message || "");
                }
                return idHotspot;
            });
    }

    //
    //procesare indice conex
    //
    public getProcesariIndiceConex(status: string[]): Promise<Array<IInfoProcesareIndiceConex>> {
        let paylod = {
            status: status
        }
        return this.$http.get(
            AppSettings.serverPath + 'integrate-landsat/procesari-indice-conex',
            {
                headers: {
                    //'Content-Type': 'application/json; charset=utf-8'
                },
                observe: "response"
            })
            .toPromise()
            .then((response) => {
                let returnInfoProc: Array<IInfoProcesareIndiceConex> = [];
                let arrInfoProc = response.body as Array<IInfoProcesareIndiceConex>;
                try {
                    for (const itgr of arrInfoProc) {
                        let tmpgr: IInfoProcesareIndiceConex = {
                            id: itgr.id,
                            denumire: itgr.denumire,
                            descriere: itgr.descriere,
                            status: itgr.status,
                            momentCreare: itgr.momentCreare
                        }
                        returnInfoProc.push(tmpgr);
                    }
                } catch (e) {
                    throw new Error(" eroare date la interogare taskuri procesare indici conex" + e.message || "");
                }
                return returnInfoProc;
            });
    }
    //
    public getProcesareIndiceConex(id: number, detalii: boolean = true): Promise<IProcesareIndiceConex> {
        return this.$http.get(
            AppSettings.serverPath + 'integrate-landsat/procesare-indice-conex/' + id,
            {
                params: new HttpParams().set('detalii', detalii.toString()),
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                },
                observe: "response"
            })
            .toPromise()
            .then((response) => {
                let returnProc: IProcesareIndiceConex = {
                    id: null,
                    denumire: null,
                    descriere: null,
                    dataIndiceBaza: null,
                    idIndiceBaza: null,
                    idIndiceRezultat: null,
                    dataIndiceReferinta: null,
                    idIndiceReferinta: null,
                    idIndiceConex: null,
                    status: null,
                    mesajStatus: null,
                    actiune: null,
                    progresStatus: null,
                    progresDetalii: null,
                    codIndiceBaza: null,
                    codIndiceConex: null,
                    codIndiceReferinta: null,
                    idAlgoritmIC: null,
                    denumireAlogritmIC: "",
                    unitatiAdministrativeProcesare: []
                };
                let dataDes = response.body as IProcesareIndiceConex;
                try {
                    returnProc.id = dataDes.id;
                    returnProc.denumire = dataDes.denumire;
                    returnProc.descriere = dataDes.descriere;
                    returnProc.dataIndiceBaza =dataDes.dataIndiceBaza;
                    returnProc.dataIndiceReferinta = dataDes.dataIndiceReferinta;
                    // if(dataDes.dataIndiceBaza && (dataDes.dataIndiceBaza as string).length > 0){
                    //     returnProc.dataIndiceBaza = moment(dataDes.dataIndiceBaza,"YYYY-MM-DDTHH:mm:ss.SSSZ")
                    // }
                    // if(dataDes.dataIndiceReferinta && (dataDes.dataIndiceReferinta as string).length > 0){
                    //     returnProc.dataIndiceReferinta = moment(dataDes.dataIndiceReferinta,"YYYY-MM-DDTHH:mm:ss.SSSZ")
                    // }
                    returnProc.idIndiceBaza = dataDes.idIndiceBaza;
                    returnProc.idIndiceRezultat = dataDes.idIndiceRezultat;
                    returnProc.idIndiceReferinta = dataDes.idIndiceReferinta;
                    returnProc.idIndiceConex = dataDes.idIndiceConex;
                    returnProc.status = dataDes.status;
                    returnProc.mesajStatus = dataDes.mesajStatus;
                    returnProc.actiune = dataDes.actiune;
                    returnProc.codIndiceBaza = dataDes.codIndiceBaza;
                    returnProc.codIndiceConex = dataDes.codIndiceConex;
                    returnProc.codIndiceReferinta = dataDes.codIndiceReferinta;
                    returnProc.idAlgoritmIC = dataDes.idAlgoritmIC;
                    returnProc.denumireAlogritmIC = dataDes.denumireAlogritmIC;
                    if (detalii === true) {
                        //
                        for (const ituat of dataDes.unitatiAdministrativeProcesare) {
                            let tmpuatdes: IUnitateAdministrativaProcesare = {
                                idUnitateAdministrativa: ituat.idUnitateAdministrativa,
                                denumire: ituat.denumire,
                                status: ituat.status,
                                geom: ituat.geom,
                            }
                            returnProc.unitatiAdministrativeProcesare.push(tmpuatdes);
                        }
                    }
                } catch (e) {
                    throw new Error(" eroare date la interogare task procesare indice conex" + e.message || "");
                }
                return returnProc;
            });
    }
    //
    public setSalvareProcesareIndiciConex(procesare: IProcesareIndiceConex): Promise<Number> {
        let payload = procesare;
        return this.$http.post(
            AppSettings.serverPath + 'integrate-landsat/salvare-procesare-indice-conex',
            JSON.stringify(payload),
            {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                },
                observe: "response"
            })
            .toPromise()
            .then((response) => {
                let idProcesare = null;
                try {
                    idProcesare = response.body["idProcesare"];
                } catch (e) {
                    throw new Error(" eroare date la salvare task procesare indice conex" + e.message || "");
                }
                return idProcesare;
            });
    }
    //
    public getIndiciConex(): Promise<Array<IIndiceConex>> {
        return this.$http.get(
            AppSettings.serverPath + 'integrate-landsat/indici-conex-procesare',
            {
                headers: {
                    // 
                },
                observe: "response"
            })
            .toPromise()
            .then((response) => {

                let impDec = [];
                let arrRes = response.body as Array<any>;
                try {
                    for (const itgr of arrRes) {
                        let tmpgr: IIndiceConex = {
                            id: itgr["id"],
                            codIndice: itgr["codIndice"],
                            sursaDate: itgr["sursaDate"],
                            stratAsociat: null
                        }
                        impDec.push(tmpgr);
                    }
                } catch (e) {
                    throw new Error(" eroare date la interogare indici conex" + e.message || "");
                }
                return impDec;
            });
    }
    //
    public getAlgoritmiIndiceConex(): Promise<Array<IInfAlgoritmIC>> {
        return this.$http.get(
            AppSettings.serverPath + 'integrate-landsat/algoritmi-indice-conex',
            {
                headers: {
                    // 'Content-Type': 'application/json; charset=utf-8'
                },
                observe: "response"
            })
            .toPromise()
            .then((response) => {

                let impDec = [];
                let arrRes = response.body as Array<any>;
                try {
                    for (const italg of arrRes) {
                        let tmpgr: IInfAlgoritmIC = {
                            id: italg["id"],
                            nume: italg["nume"],
                            descriere: italg["descriere"],
                            default: italg["default"]
                        }
                        impDec.push(tmpgr);
                    }
                } catch (e) {
                    throw new Error(" eroare date la interogare algoritmi indice conex" + e.message || "");
                }
                return impDec;
            });
    }



    //
    //procesare indice conex
    //
    public getProcesariDateSezoniere(status: string[]): Promise<Array<IInfoProcesareDateSezoniere>> {
        let paylod = {
            status: status
        }
        return this.$http.get(
            AppSettings.serverPath + 'integrate-landsat/procesari-date-sezoniere',
            {
                headers: {
                    //'Content-Type': 'application/json; charset=utf-8'
                },
                observe: "response"
            })
            .toPromise()
            .then((response) => {
                let returnInfoProc: Array<IInfoProcesareDateSezoniere> = [];
                let arrInfoProc = response.body as Array<IInfoProcesareDateSezoniere>;
                try {
                    for (const itgr of arrInfoProc) {
                        let tmpgr: IInfoProcesareDateSezoniere = {
                            id: itgr.id,
                            denumire: itgr.denumire,
                            descriere: itgr.descriere,
                            status: itgr.status,
                            momentCreare: itgr.momentCreare
                        }
                        returnInfoProc.push(tmpgr);
                    }
                } catch (e) {
                    throw new Error(" eroare date la interogare taskuri procesare dae sezoniere" + e.message || "");
                }
                return returnInfoProc;
            });
    }
    //
    public getProcesareDateSezoniere(id: number, detalii: boolean = true): Promise<IProcesareDateSezoniere> {
        return this.$http.get(
            AppSettings.serverPath + 'integrate-landsat/procesare-date-sezoniere/' + id,
            {
                params: new HttpParams().set('detalii', detalii.toString()),
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                },
                observe: "response"
            })
            .toPromise()
            .then((response) => {
                let returnProc: IProcesareDateSezoniere = {
                    id: null,
                    denumire: null,
                    descriere: null,
                    an: null,
                    sezon: null,
                    status: null,
                    mesajStatus: null,
                    actiune: null,
                    unitatiAdministrativeProcesare: []
                };
                let dataDes = response.body as IProcesareDateSezoniere;
                try {
                    returnProc.id = dataDes.id;
                    returnProc.denumire = dataDes.denumire;
                    returnProc.descriere = dataDes.descriere;
                    returnProc.an = dataDes.an;
                    returnProc.sezon = dataDes.sezon;
                    returnProc.denumireSezon = dataDes.denumireSezon;
                    returnProc.status = dataDes.status;
                    returnProc.mesajStatus = dataDes.mesajStatus;
                    returnProc.actiune = dataDes.actiune;
                    if (detalii === true) {
                        //
                        for (const ituat of dataDes.unitatiAdministrativeProcesare) {
                            let tmpuatdes: IUnitateAdministrativaProcesare = {
                                idUnitateAdministrativa: ituat.idUnitateAdministrativa,
                                denumire: ituat.denumire,
                                status: ituat.status,
                                geom: ituat.geom,
                            }
                            returnProc.unitatiAdministrativeProcesare.push(tmpuatdes);
                        }
                    }
                } catch (e) {
                    throw new Error(" eroare date la interogare task procesare date sezoniere" + e.message || "");
                }
                return returnProc;
            });
    }

    public getSezoaneDate(): Promise<Array<IInfSezonDate>> {
        return this.$http.get(
            AppSettings.serverPath + 'integrate-landsat/sezoane-date',
            {
                headers: {
                    // 'Content-Type': 'application/json; charset=utf-8'
                },
                observe: "response"
            })
            .toPromise()
            .then((response) => {

                let impDec = [];
                let arrRes = response.body as Array<any>;
                try {
                    for (const italg of arrRes) {
                        let tmpgr: IInfSezonDate = {
                            id: italg["id"],
                            denumire: italg["denumire"],
                            descriere: italg["descriere"],
                            default: italg["default"]
                        }
                        impDec.push(tmpgr);
                    }
                } catch (e) {
                    throw new Error(" eroare date la interogare tip sezon date" + e.message || "");
                }
                return impDec;
            });
    }

    //
    public setSalvareProcesareDateSezoniere(procesare: IProcesareDateSezoniere): Promise<Number> {
        let payload = procesare;
        return this.$http.post(
            AppSettings.serverPath + 'integrate-landsat/salvare-procesare-date-sezoniere',
            JSON.stringify(payload),
            {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                },
                observe: "response"
            })
            .toPromise()
            .then((response) => {
                let idProcesare = null;
                try {
                    idProcesare = response.body["idProcesare"];
                } catch (e) {
                    throw new Error(" eroare date la salvare task procesare date sezoniere" + e.message || "");
                }
                return idProcesare;
            });
    }
}
