//NG+
import { AppSettings } from "../app-settings";
import { UserSettingsService } from "./user-settings-service";


import { NGXLogger } from "ngx-logger";
import { HttpClient, HttpParams } from "@angular/common/http";


export interface IDbStoreUserInfo {
    //
    getUserInfoFromStorage(userId: number): Promise<{ id: number, name: string, email: string, phone: string, mapConfigVersion: number }>;
    setUserInfoToStorage(id: number, name: string, email: string, phone: string, mapConfigVersion: number): Promise<boolean>;

}
export class DbStoreUserInfo implements IDbStoreUserInfo {
    constructor(
        private $http: HttpClient,
        private $log: NGXLogger,
        private settingsService: UserSettingsService) { }

    //
    public getUserInfoFromStorage(userId: number): Promise<{ id: number, name: string, email: string, phone: string, mapConfigVersion: number }> {
        return this.$http.get(
            AppSettings.serverPath + 'data/user-info/' + userId,
            { observe: "response" }
        )
            .toPromise()
            .then((response) => {
                let authData = response.body;
                let authUser = null;
                try {
                    authUser = {
                        id: authData["id"] as number,
                        name: authData["name"] as string,
                        email: authData["email"] as string,
                        phone: authData["phone"] as string,
                        mapConfigVersion: authData["mapConfigVersion"] as number
                    };
                    return authUser;
                } catch (e) {
                    throw new Error("datele de identificare lipsesc din raspuns");
                }
            });
    };
    //
    public setUserInfoToStorage(id: number, name: string, email: string, phone: string, mapConfigVersion: number): Promise<boolean> {
        return this.$http.post(
            AppSettings.serverPath + 'data/save-user-info',
            { id: id, name: name, email: email, phone: phone, mapConfigVersion: mapConfigVersion },
            {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                },
                observe: "response"
            })
            .toPromise()
            .then((response) => {
                return (response.status === 200);
            });
    }

}
