//NG+
import { Component, Inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AppSettings } from '../../app-settings';
import { ILayer, authAs, authOpt, authType, featureType } from '../../definitions';
import { MapController } from '../map-controller';
import { listaSursaDateClasificare } from "./map-greentop-definitions";
import { IDescarcareDateSatelitareIL, IUnitateAdministrativaDescarcareIL, IIntegrateLandsatDownloadData, IIntegrateLandsatProcesareIndiceConexData, IProcesareIndiceConex, IIntegrateLandsatProcesareDateSezoniereData, IProcesareDateSezoniere } from "./map-integrate.definitions";
import { IProdusDescarcare, TaskActiune, TaskStatus } from '../../services/greentop-data-service';
import { I18n, NgbCustomDatepickerI18n } from '../../shared-components/ngb-datepicker-i18n';
import { NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateMomentAdapter, NgbDateMomentParserFormatter } from '../../shared-components/ngb-moment-adapter-formater';
import moment, { Moment } from "moment";
import { createGeomFromWKT, delay, findDatesStratReferintaForLayer, findStratAsociatToIndice, findStratReferintaForLayer, getExMessage, setMomentOnTileLayer } from '../map-utils';
import { NGXLogger } from 'ngx-logger';

import VectorLayer from 'ol/layer/Vector';
import Feature from 'ol/Feature';
import Polygon from 'ol/geom/Polygon';
import VectorSource from "ol/source/Vector";
import OlStyle from "ol/style/Style";
import StrokeStyle from "ol/style/Stroke";
import FillStyle from "ol/style/Fill";
import TextStyle from "ol/style/Text";
import * as olColor from 'ol/color';

@Component({
    selector: 'integrate-landsat-procesare-date-sezoniere',
    providers: [
        I18n,
        { provide: NgbDatepickerI18n, 
             useFactory: () => { return new NgbCustomDatepickerI18n({language : 'ro'})} },
        { provide: NgbDateParserFormatter, 
            useFactory: () => { return new NgbDateMomentParserFormatter("DD-MM-YYYY") } },
        { provide: NgbDateAdapter, useClass: NgbDateMomentAdapter},
    ],
    templateUrl: './app/map/map-components/map-integrate-landsat-procesare-date-sezoniere.component.html',
    styleUrls: ['./app/map/map-components/map-integrate-landsat-procesare-date-sezoniere.component.css']
})
export class IntegrateLandsatProcesareDateSezoniereComponent implements OnInit, OnChanges {
    @Input("mapctrl") mapCtrl: MapController = null;
    //
    private integrateLandsatProcDateSezoniereData : IIntegrateLandsatProcesareDateSezoniereData = null;
    private zoneStyle = [
        new OlStyle({
            fill: new FillStyle({
                color: olColor.asArray('rgba(0,255,0,0)')
            }),
            stroke: new StrokeStyle({
                color: 'rgba(0,255,0,1)',
                width: 4,
            }),

        }),
        new OlStyle({
            fill: new FillStyle({
                color: olColor.asArray('rgba(0,255,0,0)')
            }),
            stroke: new StrokeStyle({
                color: 'rgba(255,0,0,1)',
                width: 4,
                lineDash: [4, 8],
            }),

        }),
    
];
    private zoneText(text: string) {
        return new OlStyle({
            text: new TextStyle({
                text: text,
                scale: 1,
                offsetX: 0,
                offsetY: 0,
                fill: new FillStyle({
                    color: 'rgba(0, 0, 0, 1)'
                })
            })
        })
    }

    constructor(
        @Inject(NGXLogger) private $log: NGXLogger,
    ) {
        //
    }

    public ngOnInit(): void {
        //
        if (this.mapCtrl && this.mapCtrl.integrateLandsatProcDateSezoniereData) {
            this.integrateLandsatProcDateSezoniereData = this.mapCtrl.integrateLandsatProcDateSezoniereData;
            this.mapCtrl.integrateLandsatProcDateSezoniereData.funcInit = this.initIntegrateLandsatProcDateSezoniereTool;
            this.mapCtrl.integrateLandsatProcDateSezoniereData.funcClear = this.clearIntegrateLandsatProcDateSezoniereTool;
            this.mapCtrl.integrateLandsatProcDateSezoniereData.ctrlSearchIsCollapsed = false
        }
    }

    public ngOnChanges(changes: SimpleChanges) {
        //
    }
    public changeNewExistsTab(index){
        if (index === "0"){
            if (this.integrateLandsatProcDateSezoniereData.activeNewTab0){
               // this.integrateLandsatProcDateSezoniereData.activeNewTab0 = false
            } else {
                this.integrateLandsatProcDateSezoniereData.activeExistsTab1 = false;
                this.integrateLandsatProcDateSezoniereData.activeNewTab0 = true;
                this.integrateLandsatProcDateSezoniereData.activeTab = 0;
                this.initNewProcDateSezoniereData();
            }
            
        } else if (index === "1"){
            if (this.integrateLandsatProcDateSezoniereData.activeExistsTab1){
               // this.integrateLandsatDownloadData.activeExistsTab1 = false
            } else {
                
                this.integrateLandsatProcDateSezoniereData.activeExistsTab1 = true;
                this.integrateLandsatProcDateSezoniereData.activeNewTab0 = false;
                this.integrateLandsatProcDateSezoniereData.activeTab = 1
                this.initExistingProcDateSezoniereDate(null);
            }
        }
    }
    public initIntegrateLandsatProcDateSezoniereTool = () => {
        this.addSelectLayer();
        this.integrateLandsatProcDateSezoniereData.activeExistsTab1 = true;
        this.integrateLandsatProcDateSezoniereData.activeNewTab0 = false;
        this.integrateLandsatProcDateSezoniereData.activeTab = 1;
        this.initExistingProcDateSezoniereDate(null);
    }

    public clearIntegrateLandsatProcDateSezoniereTool = () => {
        this.removeSelectLayer();
    }

    private initNewProcDateSezoniereData(){
        this.integrateLandsatProcDateSezoniereData.sezonierAnual = true;
        this.integrateLandsatProcDateSezoniereData.currentProcesare = {
            id: null,
            denumire: "",
            descriere: "",
            an: new Date().getFullYear(),
            sezon: 1,
            status: "init",
            mesajStatus: "",
            actiune: null,
            
            unitatiAdministrativeProcesare: []
        }
        this.integrateLandsatProcDateSezoniereData.selectLayer.getSource().clear();
        //
        this.integrateLandsatProcDateSezoniereData.errorMessage = "";
        this.integrateLandsatProcDateSezoniereData.loadingActive = true;
        this.mapCtrl.integrateLandsatDataService.getUnitatiAdministrativeActive()
            .then((data)=>{
                this.integrateLandsatProcDateSezoniereData.availableUnitatiAdministrative = data;
                return true;
            }).catch((reason)=>{
                this.integrateLandsatProcDateSezoniereData.errorMessage = "Eroare interogare unitati administrative";
                this.$log.error("Eroare interogare unitati administrative: ", getExMessage(reason));
            })
            .then((data)=>{
                return this.mapCtrl.integrateLandsatDataService.getSezoaneDate();
            })
            .then((data)=>{
                this.integrateLandsatProcDateSezoniereData.availableSezonDate = data;
            })
            .catch((reason)=>{
                this.integrateLandsatProcDateSezoniereData.errorMessage = "Eroare interogare sezoane";
                this.$log.error("Eroare interogare info sezoane: ", getExMessage(reason));
            })
            .then((data)=>{
                return delay(200).then(()=>{
                    //
                })
            })
            .catch((reason)=>{
                this.integrateLandsatProcDateSezoniereData.errorMessage = "Eroare interogare date";
                this.$log.error("Eroare interogare date: ", getExMessage(reason));
            })
            .finally(()=>{
                this.integrateLandsatProcDateSezoniereData.loadingActive = false;
            })
    }

    private initExistingProcDateSezoniereDate(idPreselected: any){
        this.mapCtrl.integrateLandsatProcDateSezoniereData.ctrlSearchIsCollapsed = false;
        this.integrateLandsatProcDateSezoniereData.currentProcesare = {
            id: null,
            denumire: "",
            descriere: "",
            an: 2000,
            sezon: 1,
            status: "init",
            mesajStatus: "",
            actiune: null,
            
            unitatiAdministrativeProcesare: []
        }
        this.integrateLandsatProcDateSezoniereData.selectedProcesare = null;
        //
        this.integrateLandsatProcDateSezoniereData.errorMessage = "";
        this.integrateLandsatProcDateSezoniereData.loadingActive = true;
        this.mapCtrl.integrateLandsatDataService.getUnitatiAdministrativeActive()
            .then((data)=>{
                this.integrateLandsatProcDateSezoniereData.availableUnitatiAdministrative = data;
                return true;
            }).catch((reason)=>{
                this.integrateLandsatProcDateSezoniereData.errorMessage = "Eroare interogare unitati administrative";
                this.$log.error("Eroare interogare unitati administrative: ", getExMessage(reason));
            })
            .then((data)=>{
                return this.mapCtrl.integrateLandsatDataService.getProcesariDateSezoniere(null);
            })
            .then((data)=>{
                this.integrateLandsatProcDateSezoniereData.availableProcesari = data;
                return true;
            })
            .catch((reason)=>{
                this.integrateLandsatProcDateSezoniereData.errorMessage = "Eroare interogare procesari existente";
                this.$log.error("Eroare interogare procesari existente: ", getExMessage(reason));
            })
            .then((state) => {
                if (state as any === true && typeof idPreselected === "number") {
                    let tmpPres = this.integrateLandsatProcDateSezoniereData.availableProcesari.filter((item) => item.id === idPreselected);
                    if (tmpPres && tmpPres.length > 0) {
                        this.integrateLandsatProcDateSezoniereData.selectedProcesare = tmpPres[0];
                        this.onChangeProcesare();
                    }
                }
            })
            .finally(()=>{
                this.integrateLandsatProcDateSezoniereData.loadingActive = false;
            })
    }

    public onChangeProcesare(){
        if (this.integrateLandsatProcDateSezoniereData.selectedProcesare == null) {
            return;
        }
        this.integrateLandsatProcDateSezoniereData.selectLayer.getSource().clear();
        this.integrateLandsatProcDateSezoniereData.errorMessage = "";
        this.integrateLandsatProcDateSezoniereData.loadingActive = true;
        this.mapCtrl.integrateLandsatDataService.getProcesareDateSezoniere(this.integrateLandsatProcDateSezoniereData.selectedProcesare.id, true)
            .then((data) => {//
                this.integrateLandsatProcDateSezoniereData.currentProcesare = data;
                this.integrateLandsatProcDateSezoniereData.currentProcesare.unitatiAdministrativeProcesare.forEach((uitem)=>{
                    this.createZoneFeature(uitem);
                    this.integrateLandsatProcDateSezoniereData.selectLayer.getSource().addFeature(uitem.feature);
                })
                //
            }).catch((reason) => {
                this.integrateLandsatProcDateSezoniereData.errorMessage = "Eroare interogare procesare";
                this.$log.error("Eroare interogare Procesare: ", getExMessage(reason));
            }).finally(() => {
                this.integrateLandsatProcDateSezoniereData.loadingActive = false;
            })
    }

    public onChangeSelectedUnitateAdministrativa(){
        try {
            if (this.integrateLandsatProcDateSezoniereData.selectedUnitateAdministrativa) {
                this.integrateLandsatProcDateSezoniereData.currentProcesare.unitatiAdministrativeProcesare.length = 0;
                this.integrateLandsatProcDateSezoniereData.selectLayer.getSource().clear();
                let tmpUat: IUnitateAdministrativaDescarcareIL = {
                    idUnitateAdministrativa: this.integrateLandsatProcDateSezoniereData.selectedUnitateAdministrativa.id,
                    denumire: this.integrateLandsatProcDateSezoniereData.selectedUnitateAdministrativa.denumire,
                    geom: this.integrateLandsatProcDateSezoniereData.selectedUnitateAdministrativa.geom
                }
                //
                this.createZoneFeature(tmpUat);
                this.integrateLandsatProcDateSezoniereData.selectLayer.getSource().addFeature(tmpUat.feature);
                //
                this.integrateLandsatProcDateSezoniereData.currentProcesare.unitatiAdministrativeProcesare.push(tmpUat);
            }
        } catch (error) {
            this.$log.error("eroare la adaugare unitate administrativa");
        }
    }

    public onStartProcesare(integrateProcForm){
        this.integrateLandsatProcDateSezoniereData.errorMessage = "";
        try {
            
            if (this.integrateLandsatProcDateSezoniereData.selectedUnitateAdministrativa == null) {
                throw Error("Nu este selectat unitate administrativa");
            }
           
            if (this.integrateLandsatProcDateSezoniereData.currentProcesare.an == null) {
                throw Error("Nu este selectat an");
            }
            
            if (this.integrateLandsatProcDateSezoniereData.sezonierAnual 
                && this.integrateLandsatProcDateSezoniereData.selectedSezonDate == null) {
                throw Error("Nu este selectat sezon");
            }
            //set name from fields
            if (this.integrateLandsatProcDateSezoniereData.currentProcesare.denumire == null
                || this.integrateLandsatProcDateSezoniereData.currentProcesare.denumire.length === 0){
                    this.integrateLandsatProcDateSezoniereData.currentProcesare.denumire =
                    this.integrateLandsatProcDateSezoniereData.selectedUnitateAdministrativa.denumire
                    + " " +this.integrateLandsatProcDateSezoniereData.currentProcesare.an;
                    if (this.integrateLandsatProcDateSezoniereData.sezonierAnual){
                        this.integrateLandsatProcDateSezoniereData.currentProcesare.denumire +=
                        this.integrateLandsatProcDateSezoniereData.selectedSezonDate.denumire;
                    }
            }
            if (this.integrateLandsatProcDateSezoniereData.sezonierAnual === false){
                this.integrateLandsatProcDateSezoniereData.selectedSezonDate = null;
                this.integrateLandsatProcDateSezoniereData.currentProcesare.sezon = null;
            }
                
            
            let tmpProcesare: IProcesareDateSezoniere = {
                id: null,
                denumire: this.integrateLandsatProcDateSezoniereData.currentProcesare.denumire,
                descriere: this.integrateLandsatProcDateSezoniereData.currentProcesare.descriere,
                an: this.integrateLandsatProcDateSezoniereData.currentProcesare.an,
                sezon: this.integrateLandsatProcDateSezoniereData.currentProcesare.sezon,
                
                status: this.integrateLandsatProcDateSezoniereData.currentProcesare.status,
                mesajStatus: "",
                actiune: null,
                idUnitateAdministrativa: this.integrateLandsatProcDateSezoniereData.selectedUnitateAdministrativa.id
            }
            //
            this.mapCtrl.integrateLandsatDataService.setSalvareProcesareDateSezoniere(tmpProcesare)
            .then((result) => {
                if (typeof result === "number") {
                    return result;
                }
                return null;
            }).catch((reason) => {
                this.integrateLandsatProcDateSezoniereData.errorMessage = "Eroare salvare procesare date sezoniere";
                this.$log.error("Erare salvare procesare: ", getExMessage(reason));
            }).finally(() => {
                //
                //clear
            }).then((idProcesare) => {
                if (typeof idProcesare === "number") {
                    this.integrateLandsatProcDateSezoniereData.activeNewTab0 = false;
                    this.integrateLandsatProcDateSezoniereData.activeExistsTab1 = true;
                    this.integrateLandsatProcDateSezoniereData.activeTab = 1;
                    this.initExistingProcDateSezoniereDate(idProcesare as any);
                }
            }).catch((reason) => {
                this.$log.error("Eroare la schimbare tab spre existente: ", getExMessage(reason));
            })
            //
        } catch (error) {
            this.$log.error("Eroare procesare date sezoniere:", getExMessage(error));
            this.integrateLandsatProcDateSezoniereData.errorMessage = "Eroare procesare date sezoniere: " + error.message || "";
            this.integrateLandsatProcDateSezoniereData.loadingActive = false;
            return;
        }
    }



    //
    public addSelectLayer(){
        this.integrateLandsatProcDateSezoniereData.selectLayer = new VectorLayer<any>({
            source: new VectorSource({ wrapX: false })
        });
        this.mapCtrl.map.addLayer(this.integrateLandsatProcDateSezoniereData.selectLayer);
    }

    public removeSelectLayer(){
        if (this.integrateLandsatProcDateSezoniereData.selectLayer) {
            this.mapCtrl.map.removeLayer(this.integrateLandsatProcDateSezoniereData.selectLayer);
            this.integrateLandsatProcDateSezoniereData.selectLayer = null;
        }
    }

    private createZoneFeature(zone: any) {
        try {
            let tmpgeom: string | Polygon = zone.geom;
            if (typeof tmpgeom === "string") {
                tmpgeom = createGeomFromWKT(tmpgeom, "EPSG:4326", this.mapCtrl.mapConfig.projection);
            }
            zone.feature = new Feature(tmpgeom as any);
            zone.feature.set("utilizare", zone.idUnitateAdministrativa);
            zone.feature.setStyle([
                this.zoneStyle[0],
                this.zoneStyle[1],
                this.zoneText(zone.denumire)
            ])
        } catch (error) {
            this.$log.error("eroare creare locatie zona");
        }
    }
    //
    public canRefreshProcesare() {
        return true;
    }
    //
    public onRefreshProcesare() {
        this.onChangeProcesare();
    }
    //
    public onClickClose() {
        this.clearIntegrateLandsatProcDateSezoniereTool();
        this.mapCtrl.integrateLandsatProcDateSezoniereButtonStateOn = false;
    }
}