
import { ILayer, IFeatureInfo } from "../../definitions";
import { IAntrenamentModelClasificare, IClasificareDateSatelitare, IDescarcareDateSatelitare,
     IIndiceDescarcare, IInfoAlgoritmClasificare,
      IInfoAntrenamentModelClasificare, IInfoClasificareDateSatelitare, IInfoDescarcareDateSatelitare,
       IInfoIndice, IInfoStil, IInfoStructuraDateAlgoritm,
        IInfoUtilizareTeren, IZonaAntrenament } from "../../services/greentop-data-service";

import VectorLayer from 'ol/layer/Vector';
import Draw from 'ol/interaction/Draw';
import Modify from 'ol/interaction/Modify';

export interface IGreentopDownloadData {
    ctrlSearchIsCollapsed: boolean;
    ctrlProductsIsCollapsed: boolean;
    ctrlDownloadDetailsIsColapsed: boolean;
    ctrlExistingDetalisIsColapsed: boolean;
    activeTab: number;
    activeNewTab0:boolean;
    activeExistsTab1: boolean;
    selectedDescarcare: IInfoDescarcareDateSatelitare;
    availableDescarcari: Array<IInfoDescarcareDateSatelitare>;
    optFormatDataPerioada: string;
    currentDescarcare: IDescarcareDateSatelitare|null;
    //
    selectedSursaDate: {tip: string, denumire: string};
    availableSursaDate: Array<{tip: string, denumire: string}>;
    //
    selectednewIndice: IInfoIndice;
    availableIndici: Array<IInfoIndice>;
    filteredIndici: Array<IInfoIndice>;
    //
    productsLayer: VectorLayer<any>;
    selectLayer: VectorLayer<any>;
    selectDrawInteraction: Draw;
    selectButtonStateOn: boolean;
    loadingSearchProductsActive: boolean;
    errorMessage: string;
    //
    funcInit: any;
    funcClear: any;

}

export const tipSursaDateClasificare = {
    sentinel_1: "sentinel-1",
    sentinel_2: "sentinel-2",
    planetscope: "planetscope",
    skysat: "skysat"
};

export const listaSursaDateClasificare: Array<{tip: string, denumire: string}> = [
    {tip: "sentinel-1", denumire: "Sentinel 1"},
    {tip: "sentinel-2", denumire: "Sentinel 2"},
    {tip: "planetscope", denumire: "Planetscope"},
    {tip: "skysat", denumire: "Skysat"}
];

export interface IGreentopTrainingData {
    ctrlSearchIsCollapsed: boolean,

    activeTab: number;
    activeNewTab0: boolean;
    activeExistsTab1: boolean;

    activeDataTab: number;

    loadingActive: boolean;

    selectedAntrenament: IInfoAntrenamentModelClasificare;
    availableAntrenamente: Array<IInfoAntrenamentModelClasificare>;

    currentAntrenament: IAntrenamentModelClasificare;

    selectedDescarcare: IInfoDescarcareDateSatelitare;
    availableDescarcari: Array<IInfoDescarcareDateSatelitare>;
    interestAreaLayer: VectorLayer<any>;

    selectedAlgoritm: IInfoAlgoritmClasificare;
    availableAlgoritmi: Array<IInfoAlgoritmClasificare>;

    selectedStructuraDate: IInfoStructuraDateAlgoritm;
    availableStructuriDateAlgoritmi: Array<IInfoStructuraDateAlgoritm>;

    trainingZonesLayer: VectorLayer<any>;
    importZonesLayer: VectorLayer<any>;

    selectLayer: VectorLayer<any>;
    selectDrawInteraction: Draw;
    selectEditInteraction: Modify;
    selectButtonStateOn: boolean;

    istoolActive_neweditTrainingZone: boolean;
    istoolActive_importTrainingZones: boolean;

    neweditZona: IZonaAntrenament;
    selectedZonaAntrenament: IZonaAntrenament;


    selectedneweditUtilizareTeren: IInfoUtilizareTeren;
    availableUtilizariTeren: Array<IInfoUtilizareTeren>;
    availableImportLayers: Array<ILayer>;
    selectedImportLayer: ILayer;

    availableImportFields: Array<IFeatureInfo>;
    selectedImportUtilizare: IFeatureInfo;
    selectedImportAn: IFeatureInfo;
    selectedImportStartPerioada: IFeatureInfo;
    selectedImportEndPerioada: IFeatureInfo;
    selectedImportDescriere: IFeatureInfo;
    importPeriodFromDefault: boolean;
    importSettings: IGrImportSettings;


    selectednewIndice: IIndiceDescarcare;
    //availableIndici: Array<IInfoIndice>;
    filteredIndici: Array<IIndiceDescarcare>;

    optFormatDataPerioada: string;

    errorMessage: string;
    errorMessageZona: string;
    errorMessageImport: string;
    resultMessageImport: string;

    resIntervalRef: any;
    autorefreshInterval: number;
    autorefreshTimeout: number;
    //
    funcInit: any;
    funcClear: any;
}

export interface IGrImportSettings{
    campSursaAn: string,
    campSursaUtilizare: string,
    campSursaStartPerioadaUtilizare: string,
    campSursaEndPerioadaUtilizare: string,
    campSursaDescriere: string,
    periodaDinValoriDefault: boolean
}

export interface IGreentopClassifyData {
    ctrlSearchIsCollapsed: boolean,

    activeTab: number;
    activeNewTab0: boolean;
    activeExistsTab1: boolean;

    activeDataTab: number;

    loadingActive: boolean;

    selectedClasificare: IInfoClasificareDateSatelitare;
    availableClasificari: Array<IInfoClasificareDateSatelitare>;

    currentClasificare: IClasificareDateSatelitare;

    selectedDescarcare: IInfoDescarcareDateSatelitare;
    availableDescarcari: Array<IInfoDescarcareDateSatelitare>;
    downloadAreaLayer: VectorLayer<any>;

    selectedAntrenament: IInfoAntrenamentModelClasificare;
    availableAntrenamente: Array<IInfoAntrenamentModelClasificare>;

    availableStiluri: Array<IInfoStil>;
    selectedStil: IInfoStil;

    selectedAnPerioada: number;
    availableAniPerioada: Array<number>;

    selectedMomentDateSatelitare: string;
    availableMomenteDateSatelitare: Array<string>;

    resultTileLayer: ILayer;
    resultLayer: VectorLayer<any>;
    selectLayer: VectorLayer<any>;
    selectDrawInteraction: Draw;
    selectButtonStateOn: boolean;

    legendaClasificareUrl?: any;

    optFormatDataPerioada: string;
    errorMessage: string;

    resIntervalRef: any;
    autorefreshInterval: number;
    autorefreshTimeout: number;
    //
    funcInit: any;
    funcClear: any;
}
