//NG+
import { NGXLogger } from "ngx-logger";
import { ILayer, windowMessageType, MapClickMessageMode, authOpt, authType, authItemAccess, WFSTActionType } from "../definitions";
import { getExMessage, isArray, isDefined, isObject } from "../map/map-utils";
import { InternalMessageService } from "./internal-message-service";
import { RouteMessageService, RouteGenStatus } from "./route-message-service";
import { CQLFilterService } from "./user-filter-service";
import { UserSettingsService } from "./user-settings-service";
//
import { Inject, Injectable } from "@angular/core";


import Feature from 'ol/Feature';
import Collection from "ol/Collection";

export interface IWindowMessageService {
    sendWindowMessage(message: any);
    getFeatureExtraInfoByMessage(feature: Feature, layer: ILayer, type: string): Promise<any>;
    setFeatureExtraInfoFromMessage(feature: Feature, layer: ILayer, message: any): void;
    getFeatureListInfoByMessage(featureList: Array<Feature>, layer: ILayer, type: string): Promise<any>;
    setFeatureListExtraInfoFromMessage(featureList: Collection<Feature>, message: any): void;
    sendWindowMessageFeatureChanged(layerName: string, actionType: string, wfsResponse: any, feature: Feature)
}

export class DeferredMessage {
    public promise: Promise<any>;
    public reject;
    public resolve;
    public msgId: number;
    public timeout: number;
    constructor(
        //private $q: ng.IQService,
        private $window: Window,
        public type: string,
        public data: any) {
        this.timeout = 5000;
        this.msgId = (new Date).getTime()
        this.promise = new Promise((resolve, reject) => {
            let message = this.buildWindowMessage();
            this.sendWindowMessage(message);
            this.reject = reject;
            this.resolve = resolve;
        });
    }
    //
    public sendWindowMessage(message: any) {
        if (this.$window.parent !== this.$window) {
            this.$window.parent.postMessage(message, '*');
        }
    }
    //
    public buildWindowMessage(): string {
        return JSON.stringify({ msgId: this.msgId, type: this.type, data: this.data });
    }
}

@Injectable({
    providedIn: 'root',
})
export class WindowMessageService implements IWindowMessageService {
    //
    public externalMessages: Array<DeferredMessage> = [];
    public constructor(
        @Inject(InternalMessageService) private internalMessageService: InternalMessageService,
        @Inject('Window') private $window: any,
        @Inject(NGXLogger) private $log: NGXLogger,
        @Inject(UserSettingsService) private userSettingsSrvs: UserSettingsService,
        @Inject(CQLFilterService) private userFilterServ: CQLFilterService,
        @Inject(RouteMessageService) private routeMessageServ: RouteMessageService
    ) {
        this.initWindowMessage();
    };

    private initWindowMessage() {
        //interval timeout
        setInterval(() => {
            for (var i = this.externalMessages.length; i > 0; i--) {
                let msgItem = this.externalMessages[i - 1];
                //
                msgItem.timeout -= 1000;
                if (msgItem.timeout <= 0) {
                    msgItem.resolve(null);
                    this.externalMessages.splice(i - 1, 1);
                }
            }

        }, 1000);
        //message listener
        this.$window.addEventListener('message', (event) => {
            if (typeof (event.data) != undefined) {
                this.$log.log(event.data);
                try {
                    let msgData = JSON.parse(event.data);
                    if ('type' in msgData) {
                        switch (msgData['type']) {
                            case windowMessageType.cqlFilter:
                                this.doMessageCqlFilter(msgData);
                                break;
                            case windowMessageType.featureListExtraInfo:
                            case windowMessageType.featureExtraInfo:
                                this.doMessageFeatureExtraInfo(msgData);
                                break;
                            case windowMessageType.sendMapClick:
                                this.doMessageSendMapClick(msgData);
                                break;
                            case windowMessageType.generateRoute:
                                this.doMessageGenerateRoute(msgData);
                                break;
                            case windowMessageType.animateRoute:
                                this.doMessageAnimateRoute(msgData);
                                break;
                            case windowMessageType.sendMapView:
                                this.doMessageSendMapview(msgData);
                                break;
                            case windowMessageType.sendLayerMenuAction:
                                this.doLayerMenuAction(msgData);
                                break;
                            case windowMessageType.sendFeatureMenuAction:
                                this.doFeatureMenuAction(msgData);
                                break;
                            case windowMessageType.sendLayerViews:
                                this.doLayerViews(msgData);
                                break
                            default:
                                throw new Error(" tip mesaj nu corespunde ")
                        }
                    } else {
                        this.$log.error("lipseste tip mesaj ")
                    }
                } catch (e) {
                    this.$log.error("eroare mesaj: " + e.message)
                }
            }
        });
    }
    //
    private doMessageCqlFilter(msgData: any) {
        if ('viewparams' in msgData) {
            this.userFilterServ.parseViewParamsString(msgData['viewparams']);
        }
        if ('userfilter' in msgData) {
            this.userFilterServ.parseUserFilterString(msgData['userfilter']);
        }
        if ('viewparams' in msgData || 'userfilter' in msgData) {
            this.internalMessageService.broadcast("cqlFilterChanged");
        }

    }
    private doMessageFeatureExtraInfo(msgData: any) {
        this.$log.log(msgData);
        let msgId = msgData['msgId']
        if (msgId && msgId > 0) {
            for (var i = 0; i < this.externalMessages.length; i++) {
                let msgItem = this.externalMessages[i];
                //
                if (msgItem.msgId === msgId) {
                    msgItem.resolve(msgData);
                    this.externalMessages.splice(i, 1);
                    return;
                }
            }
        }
    }
    private doMessageSendMapClick(msgData: any) {
        let mode = MapClickMessageMode.coordinates_propperties;
        if (msgData['mode'] &&
            (msgData['mode'] === MapClickMessageMode.coordinates
                || msgData['mode'] === MapClickMessageMode.properties
                || msgData['mode'] === MapClickMessageMode.coordinates_propperties
            )) {
            mode = msgData['mode'];
        }

        //if (msgData['coordinates'] && msgData['layer'] && msgData['properties']) {
        this.internalMessageService.broadcast("sendMapClick", { mode: mode, layer: msgData['layer'] || null, coordinates: msgData['coordinates'] || null, crsCoordinates: msgData['crsCoordinates'] || null, properties: msgData['properties'] || null });
        //}
    }
    private doMessageGenerateRoute(msgData: any) {
        if (msgData['routeData']) {
            let msgObj = this.routeMessageServ.parseWindowMessage(msgData['routeData']);
            if (msgObj) {
                this.routeMessageServ.generateRoute(msgObj)
                    .then((result) => {
                        if (result && result.status === RouteGenStatus.finish) {
                            this.sendWindowMessage(JSON.stringify({ type: "generateRouteFinish", routeId: result.routeNr, messageId: result.message.messageId, routeInfo: result.routeInfo }));
                            //send back message?
                        }
                    })
                    .catch((error) => {
                        this.$log.error("eroare mesaj generare rute", getExMessage(error));
                    })
            }
        }
    }
    private doMessageAnimateRoute(msgData: any) {
        if (msgData['layer'] && msgData['properties']) {
            this.internalMessageService.broadcast("sendAnimateRoute",
                {
                    layer: msgData['layer'],
                    properties: msgData['properties'],
                    startPointIndex: msgData['startPointIndex'] || 0,
                    startAnimation: isDefined(msgData['startAnimation']) ? msgData['startAnimation'] : true
                });
        }
    }
    private doMessageSendMapview(msgData: any) {
        if (msgData['center'] || msgData['zoom']) {
            this.internalMessageService.broadcast("sendMapView", {
                center: msgData['center'] || null,
                crsCenter: msgData['crsCenter'] || null,
                zoom: msgData['zoom'] || null,
                centerByFeature: msgData['centerByFeature'] || null
            });
        }
    }
    //
    private doLayerMenuAction(msgData: any) {
        if (msgData['layer'] && msgData['action']) {
            this.internalMessageService.broadcast("sendLayerMenuAction", {
                layer: msgData['layer'],
                action: msgData['action'],
                data: msgData['data'] || {}
            });
        }
    }
    //
    private doFeatureMenuAction(msgData: any) {
        if (msgData['layer'] && msgData['action'] && msgData['properties']) {
            this.internalMessageService.broadcast("sendFeatureMenuAction", {
                layer: msgData['layer'],
                action: msgData['action'],
                properties: msgData['properties'] || {}
            });
        }
    }
    private doLayerViews(msgData: any) {
        if (msgData['data']) {
            let parseData = this.parseLayerViewsString(msgData['data']);
            if (parseData && parseData.length > 0) {
                this.internalMessageService.broadcast("sendLayerViews", parseData);
            }
        }
    }
    //
    public sendWindowMessage(message: any) {
        this.$log.log(message);
        if (this.$window.parent !== this.$window) {
            this.$window.parent.postMessage(message, '*');
            if (this.$window.frames[0]) {
                this.$window.frames[0].postMessage(message, '*');
            }
            if (this.$window.frames[1]) {
                this.$window.frames[1].postMessage(message, '*');
            }
        }
    }

    public buildMessageObjectForFeature(feature: Feature, layer: ILayer): object {
        let objData: object = null;
        if (this.userSettingsSrvs.isAuthForOption(authOpt.msg_layer_data, layer.name, authType.layer)) {
            let msgData = [];
            layer.infoColumns.forEach((iitem) => {
                let authItem = this.userSettingsSrvs.isAuthForItemOption(authOpt.in_msg_data, layer.name, iitem.name, authType.layer)
                if (authItem && authItem === authItemAccess.true) {
                    msgData.push({ item: iitem.name, value: (feature.get(iitem.name) === undefined ? '' : feature.get(iitem.name)) });
                }
            });
            objData = {
                featureId: feature.getId(),
                layerId: layer.id,
                info: msgData
            }
        }
        return objData;
    }

    public getFeatureExtraInfoByMessage(feature: Feature, layer: ILayer, type: string): Promise<any> {
        let data = this.buildMessageObjectForFeature(feature, layer);
        if (!data) {
            return Promise.resolve(true).then(() => {
                return null;
            });
        } else {
            let msgObj = new DeferredMessage(
                //this.$q,
                this.$window,
                type, data);
            this.externalMessages.push(msgObj);
            return msgObj.promise.then((data) => {
                return data;
            });
        }
    }

    public getFeatureListInfoByMessage(featureList: Array<Feature>, layer: ILayer, type: string): Promise<any> {
        if (this.userSettingsSrvs.isAuthForOption(authOpt.msg_layer_data, layer.name, authType.layer)) {
            //
            let items = [];
            for (var i = 0; i < featureList.length; i++) {
                let tmpFeatureMes = this.buildMessageObjectForFeature(featureList[i], layer);
                items.push(tmpFeatureMes);
            }
            if (!items || items.length == 0) {
                return Promise.resolve(true).then(() => {
                    return null;
                });
            } else {
                let msgObj = new DeferredMessage(
                    // this.$q,
                    this.$window,
                    type, { layer: layer.id, items: items });
                this.externalMessages.push(msgObj);
                return msgObj.promise.then((data) => {
                    return data;
                })
            }
        } else {
            return null;
        }
    }

    public setFeatureExtraInfoFromMessage(feature: Feature, layer: ILayer, message: any): void {
        if (message && isObject(message) && message['data'] && message['data']['info']) {
            (message['data']['info'] as Array<any>).forEach((iitem) => {
                feature.set(iitem['item'], iitem['value']);
            })
        }
    }

    public setFeatureListExtraInfoFromMessage(featureList: Collection<Feature>, message: any): void {
        if (message && isObject(message) && message['data'] && message['data']['items'] && message['data']['items'].length > 0) {
            let messageArray: Array<any> = message['data']['items'];
            messageArray.forEach((msgItem) => {
                try {
                    if (msgItem.featureId) {
                        for (var i = 0; i < featureList.getLength(); i++) {
                            if (featureList.item(i).getId() == msgItem.featureId) {
                                (msgItem['info'] as Array<any>).forEach((iitem) => {
                                    featureList.item(i).set(iitem['item'], iitem['value']);
                                });
                                break;
                            }
                        }
                    }
                } catch (e) {
                    this.$log.error('eroare extragere date din mesaj');
                }
            })
        }
    }

    // send message for selected features
    public sendSelectedFeatureListInfoByMessage(featureList: Array<Feature>, layer: ILayer, type: string, authTypeIn: string): void {
        if (authTypeIn === authOpt.message_selected_features_info || authTypeIn === authOpt.message_selected_byclick_features_info) {
            if (this.userSettingsSrvs.isAuthForOption(authTypeIn, layer.name, authType.layer)) {
                //
                let items = [];
                for (var i = 0; i < featureList.length; i++) {
                    let tmpFeatureMes = this.buildMessageObjectForInfoFeature(featureList[i], layer, authTypeIn);
                    items.push(tmpFeatureMes);
                }
                if (items || items.length > 0) {
                    this.sendWindowMessage(JSON.stringify({ msgId: (new Date).getTime(), type: type, data: { layer: layer.id, items: items } }));
                }
            }
        }
    }

    public buildMessageObjectForInfoFeature(feature: Feature, layer: ILayer, authTypeIn: string): object {
        let objData: object = null;
        let msgData = [];
        let inAuthType = authOpt.in_message_selected_features_info;
        if (authTypeIn === authOpt.message_selected_byclick_features_info) {
            inAuthType = authOpt.in_message_selected_byclick_features_info;
        }
        layer.infoColumns.forEach((iitem) => {
            let authItem = this.userSettingsSrvs.isAuthForItemOption(inAuthType, layer.name, iitem.name, authType.layer)
            if (authItem && authItem === authItemAccess.true) {
                msgData.push({ item: iitem.name, value: (feature.get(iitem.name) === undefined ? '' : feature.get(iitem.name)) });
            }
        });
        objData = {
            featureId: feature.getId(),
            // layerId: layer.id,
            info: msgData
        }
        return objData;
    }

    public sendWindowMessageFeatureChanged(layerName: string, actionType: string, wfsResponse: any, feature: Feature) {
        try {
            if (actionType == WFSTActionType.insertFeature
                && "insertIds" in wfsResponse
                && (wfsResponse["insertIds"] as Array<string>).length > 0) {
                this.sendWindowMessage(JSON.stringify({
                    type: "insertedFeature",
                    layer: layerName,
                    id: (wfsResponse["insertIds"] as Array<string>)[0]
                }));
            } else if ((actionType == WFSTActionType.updateInfo
                || actionType == WFSTActionType.updateLocation)
            ) {
                this.sendWindowMessage(JSON.stringify({
                    type: "updatedFeature",
                    layer: layerName,
                    id: feature.getId()
                }));
            }
        } catch (error) {
            this.$log.error("Eroare trimitere messaj peste fereastra: ", getExMessage(error));
        }
    }

    public parseLayerViewsString(layerViews: Array<any>): Array<{ layer: string, visible: boolean, opacity: number }> {
        //
        let layerViewSettings: Array<{ layer: string, visible: boolean, opacity: number }> = [];
        if (layerViews) {
            try {
                if (isArray(layerViews)) {
                    layerViews.forEach((fitem) => {
                        let tmpLayerView: { layer: string, visible: boolean, opacity: number }
                            = { layer: null, visible: null, opacity: null };
                        try {
                            if (!("layer" in fitem) || !(typeof fitem.layer === "string") || fitem.layer == "") {
                                throw new Error("layer lipseste din item sau nu este string");
                            }
                            tmpLayerView.layer = fitem.layer;
                            if (!("visible" in fitem) || !(typeof fitem.visible === "boolean")) {
                                throw new Error("visislbe lipseste sau nu este boolean");
                            }
                            tmpLayerView.visible = fitem.visible;
                            if (("opacity" in fitem)) {
                                if (typeof fitem.opacity !== "number") {
                                    throw new Error("opacity este specificat dar nu este numar");
                                }
                                tmpLayerView.opacity = fitem.opacity;
                            } else {
                                tmpLayerView.opacity = null;
                            }
                            layerViewSettings.push(tmpLayerView);
                        } catch (e) {
                            this.$log.error(" exceptie parsare item visibilitate strat")
                        }
                    });
                } else {
                    this.$log.error(" eroare parsare lista visibilitate straturi");
                }
            } catch (e) {
                this.$log.error(" exceptie parsare lista visibilitate straturi");
            }
        }
        return layerViewSettings;
    }
}