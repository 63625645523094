 //NG+
import { AppSettings } from "../app-settings";
import { IDayTaskState, IItemNT, IRoutePointDist, ITripPlan, RoutingType, LocationType, RouteType, ILayer } from "../definitions";
import { IStationPoint } from "../routes/route-data-service";

import { Inject, Injectable } from "@angular/core";
import { HttpClient, HttpParams, HttpResponse } from "@angular/common/http";
import { IRoutePoint } from "../routes/route-components/add-edit-route.component";
import { getExMessage, isArray, isObject } from "../map/map-utils";
import { NGXLogger } from "ngx-logger";


import VectorLayer from 'ol/layer/Vector';
import * as olCoordinate from 'ol/coordinate';

    export interface ITransportDataService {
        getDayRouteTaskState(taskDate: string): Promise<IDayTaskState>;
        setAddDayRoutesTask(taskDate: string): Promise<IDayTaskState>;
        setResumeDayRoutesTask(taskDate: string): Promise<IDayTaskState>;
        setDeleteDayRouteTask(taskDate: string): Promise<boolean>;
        //
        getAvailableRestrictWaysTypes(): Promise<Array<IItemNT>>;
        getAddhocRoutePointsDists(idRoute): Promise<Array<IRoutePointDist>>;
        setAddAdhocRoute(
            points: Array<olCoordinate.Coordinate> | Array<string> | Array<number>,
            optimizeOrderTSP?: boolean,
            restrictByClientArea?: boolean,
            restrictType?: string,
            routingType?: string,
            locationType?: string,
            routeType?: string,
            name?: string
        ): Promise<{ id: number, length: number, points: Array<any> }>;

        setChangeAdhocRoute(
            routeId: number,
            points: Array<olCoordinate.Coordinate> | Array<string> | Array<number>,
            optimizeOrderTSP: boolean,
            restrictByClientArea: boolean,
            restrictType: string,
            routingType: string,
            locationType: string,
            routeType: string,
            name: string
        ): Promise<{ id: number, length: number, points: Array<any> }>
        //
        getTranzitPlan(locatiePlecare: [number,number], locatieSosire: [number,number],
            date: string, time: string, maxWalkDistance): Promise<ITripPlan>;
    }

    @Injectable({
        providedIn: 'root',
    })
    export class TransportDataService implements ITransportDataService {
        //
        public constructor(
            @Inject(HttpClient) private $http: HttpClient,
            @Inject(NGXLogger) private $log: NGXLogger,
            ) {
           
        }

        //day routes task
        //
        public getDayRouteTaskState(taskDate: string): Promise<IDayTaskState> {
            return this.$http.post(
                AppSettings.serverPath + 'devices/status-devices-day-routes',
                JSON.stringify({ "data": taskDate }),
                {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                },
                observe: "response"
            })
            .toPromise()
            .then((response) => {
                let taskState: IDayTaskState = null;
                try {
                    taskState = {
                        id: response.body["id"],
                        name: response.body["name"],
                        status: response.body["status"],
                        time: response.body["time"],
                        type: response.body["type"],
                        routes: response.body["routes"],
                        points: response.body["points"]
                    }

                } catch (e) {
                    throw new Error(" eroare date la interogare statut task rute zilnice" + e.message);
                }
                return taskState;
            });
        }

        public setAddDayRoutesTask(taskDate: string): Promise<IDayTaskState> {
            return this.$http.post(
                AppSettings.serverPath + 'devices/generate-devices-day-routes',
                JSON.stringify({ "data": taskDate }),
                {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                },
                observe: "response"
            })
            .toPromise()
            .then((response) => {
                let taskState: IDayTaskState = null;
                if (response.status !== 200) {
                    throw new Error(" eroare returnare date la adaugare task rute zilnice")
                }
                try {
                    taskState = {
                        id: response.body["id"],
                        name: response.body["name"],
                        status: response.body["status"],
                        time: response.body["time"],
                        type: response.body["type"],
                        routes: response.body["routes"],
                        points: response.body["points"]
                    }

                } catch (e) {
                    throw new Error(" eroare la  adaugare task rute zilnice" + e.message);
                }
                return taskState;
            });
        }

        public setResumeDayRoutesTask(taskDate: string): Promise<IDayTaskState> {
            return this.$http.post(
                AppSettings.serverPath + 'devices/resume-devices-day-routes',
                JSON.stringify({ "data": taskDate }),
                {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                },
                observe: "response"
            })
            .toPromise()
            .then((response) => {
                let taskState: IDayTaskState = null;
                if (response.status !== 200) {
                    throw new Error(" eroare la repornire task rute zilnice")
                }
                try {
                    taskState = {
                        id: response.body["id"],
                        name: response.body["name"],
                        status: response.body["status"],
                        time: response.body["time"],
                        type: response.body["type"],
                        routes: response.body["routes"],
                        points: response.body["points"]
                    }

                } catch (e) {
                    throw new Error(" eroare la repornire task rute zilnice" + e.message);
                }
                return taskState;
            });
        }

        public setDeleteDayRouteTask(taskDate: string): Promise<boolean> {
            return this.$http.post(
                AppSettings.serverPath + 'devices/delete-devices-day-routes',
                JSON.stringify({ "data": taskDate }),
                {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                },
                observe: "response"
            })
            .toPromise()
            .then((response) => {
                return response.status === 200;
            });
        }

        //ad-hoc route
        public setAddAdhocRoute(
            points: Array<olCoordinate.Coordinate> | Array<string> | Array<number> | Array<{ id: number, coordinates: number[] }>,
            optimizeOrderTSP: boolean = true,
            restrictByClientArea: boolean = true,
            restrictType: string = null,
            routingType: string = RoutingType.foot,
            locationType: string = LocationType.point,
            routeType: string = RouteType.ad_hoc,
            name: string = '',
            response: string = 'default'
        ): Promise<{ id: number, length: number, points: Array<any> }> {
            return this.$http.post(
                AppSettings.serverPath + 'devices/generate-adhoc-route',
                JSON.stringify(
                    {
                        "coordinates": points,
                        "optimizeOrderTSP": optimizeOrderTSP,
                        "restrictByClientArea": restrictByClientArea,
                        "restrictType": restrictType,
                        "routingType": routingType,
                        "locationType": locationType,
                        "routeType": routeType,
                        "name": name,
                        "response": response
                    }),
                    {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                },
                observe: "response"
            })
            .toPromise()
            .then((response) => {
                let infoRuta: any = null;
                if (response.status !== 200) {
                    throw new Error(" eroare returnare date la adaugare ruta adhoc")
                }
                try {
                    infoRuta = response.body as any;
                } catch (e) {
                    throw new Error(" eroare la  adaugare ruta adhoc" + e.message);
                }
                return infoRuta;
            });
        }

        public setChangeAdhocRoute(
            routeId: number,
            points: Array<olCoordinate.Coordinate> | Array<string> | Array<number> | Array<{ id: number, coordinates: number[] }>,
            optimizeOrderTSP: boolean = true,
            restrictByClientArea: boolean = true,
            restrictType: string = null,
            routingType: string = RoutingType.foot,
            locationType: string = LocationType.point,
            routeType: string = RouteType.ad_hoc,
            name: string = '',
            response: string = 'default'
        ): Promise<{ id: number, length: number, points: Array<any> }> {
            return this.$http.post(
                AppSettings.serverPath + 'devices/change-adhoc-route',
                JSON.stringify(
                    {
                        "routeId" : routeId,
                        "coordinates": points,
                        "optimizeOrderTSP": optimizeOrderTSP,
                        "restrictByClientArea": restrictByClientArea,
                        "restrictType": restrictType,
                        "routingType": routingType,
                        "locationType": locationType,
                        "routeType": routeType,
                        "name": name,
                        "response": response
                    }),
                    {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                },
                observe: "response"
            })
            .toPromise()
            .then((response) => {
                let infoRuta: any = null;
                if (response.status !== 200) {
                    throw new Error(" eroare returnare date la modificare ruta adhoc")
                }
                try {
                    infoRuta = response.body as any;
                } catch (e) {
                    throw new Error(" eroare la  modificare ruta adhoc" + e.message);
                }
                return infoRuta;
            });
        }

        //get-restrict-ways-types
        public getAvailableRestrictWaysTypes(): Promise<Array<IItemNT>> {
            return this.$http.get(
                AppSettings.serverPath + 'devices/get-restrict-ways-types',
                {
                    observe: "response"
                }
            )
            .toPromise()
            .then((response) => {
                let restrictTypeList: Array<any> = <Array<any>>response.body;
                let resTypes: Array<IItemNT> = [];
                for (var i = 0; i < restrictTypeList.length; i++) {
                    let restrict: IItemNT = { name: restrictTypeList[i].nume, text: restrictTypeList[i].descriere };
                    resTypes.push(restrict);
                }
                return resTypes;
            });
        }
        
        public getAddhocRoutePointsDists(idRoute): Promise<Array<IRoutePointDist>> {
            return this.$http.get(
                 AppSettings.serverPath + 'devices/get-route-points-dist/'+idRoute,
                 {
                     observe: "response"
                 })
            .toPromise()
            .then((response) => {
                let pointDistList: Array<any> = <Array<any>>response.body;
                let pointDitsts: Array<IRoutePointDist> = [];
                for (var i = 0; i < pointDistList.length; i++) {
                    let pointDist: IRoutePointDist = {
                        idRoute: pointDistList[i].idRoute,
                        subrouteId: pointDistList[i].subrouteId,
                        dist: pointDistList[i].dist,
                        distAgg: pointDistList[i].distAgg,
                        sfDist: pointDistList[i].sfDist,
                        sfDistAgg: pointDistList[i].sfDistAgg
                    };
                    pointDitsts.push(pointDist);
                }
                return pointDitsts;
            });
        }

        //
        //
        //
        public deleteRouteFromStorage(routeId: number): Promise<boolean> {
            return this.$http.post(
                AppSettings.serverPath + "remove-route",
                { "routeId": routeId },
                {
                headers: {
                    'Content-Type': 'application/json'
                },
                observe: "response"
            })
            .toPromise()
            .then((response) => {
                return response.status === 200;
            });
        }
        //
        public setRegenerateRoutesForType(routeTypeId: number): Promise<boolean> {
            return this.$http.post(
                AppSettings.serverPath + 'regenerate-routes-for-type',
                { "routeTypeId": routeTypeId },
                {
                headers: {
                    'Content-Type': 'application/json'
                },
                observe: "response"
            })
            .toPromise()
            .then((response) => {
                return (response.status === 200);
            });
        }
        //
        public getRoutes(routeType: string): Promise<Array<IItemNT>> {
            let routes: Array<IItemNT> = [];
            return this.$http.post(AppSettings.serverPath + 'transport/routes-for-type',
                { "routeType": routeType },
                {
                headers: {
                    'Content-Type': 'application/json'
                },
                observe: "response"
            })
            .toPromise()
            .then((response) => {
                let resData = response.body;
                if (resData && isArray(resData)) {
                    (resData as Array<any>).forEach((ritem) => {
                        let route: IItemNT = {
                            name: ritem.name || '',
                            text: ritem.id || ''
                        };
                        if (route.name != '' && route.text != '') {
                            routes.push(route);
                        }
                    })
                }
                return routes;
            })
        }
        //
        public getRoutePoints(routeId: number): Promise<Array<IRoutePoint>> {
            let stations: Array<IStationPoint> = [];
            return this.$http.post(
                AppSettings.serverPath + 'transport/points-for-route',
                { "routeId": routeId },
                {
                headers: {
                    'Content-Type': 'application/json'
                },
                observe: "response"
            })
            .toPromise()
            .then((response) => {
                let resData = response.body;
                if (resData && isArray(resData)) {
                    (resData as Array<any>).forEach((ritem) => {
                        try {
                            let station: IRoutePoint = {};
                            station.seq = ritem.seq;
                            station.id = ritem.idSource;
                            station.name = ritem.name;
                            station.long = ritem.long;
                            station.lat = ritem.lat;
                            station.source_id = ritem.strSource;

                            stations.push(station);
                        } catch (e) {
                            this.$log.error('eroare extragere statii ruta')
                        }
                    })
                }
                return stations;
            })
        }
        //
        public getStations(): Promise<Array<IStationPoint>> {
            let stations: Array<IStationPoint> = [];
            return this.$http.get(
                 AppSettings.serverPath + 'transport/get-transport-stations',
                {
                    observe: "response"
            })
            .toPromise()
            .then((response) => {
                let resData = response.body;
                if (resData && isArray(resData)) {
                    //todo extract features
                    (resData as Array<any>).forEach((sitem) => {
                        try {
                            stations.push({
                                id: sitem['id'],
                                type_id: sitem['type_id'],
                                name: sitem['name'],
                                lat: sitem['latitudine'],
                                long: sitem['longitudine'],
                                inactiv: sitem['inactiv']
                            });
                        } catch (e) {
                            this.$log.error("eroare extragere statie ", sitem);
                        }
                    });
                } else {
                    this.$log.error('eroare returnare statii');
                }
                return stations;
            })
        }
        //
        public setNewStationToStorage(station: IStationPoint): Promise<number> {
            let id: number = -1;
            return this.$http.post(
                AppSettings.serverPath + 'create-station-point',
                { "station": station },
                {
                headers: {
                    'Content-Type': 'application/json'
                },
                observe: "response"
            })
            .toPromise()
            .then((response) => {
                let resData: any = response.body;
                if (resData
                    && resData.stationId) {
                    return resData.stationId;
                } else {
                    this.$log.error('eroare la inserare statie', response);
                    return -1;
                }
            });
        }

        public setUpdateStationToStorage(station: IStationPoint): Promise<boolean> {
            let id: number = -1;
            return this.$http.post(
                AppSettings.serverPath + 'update-station-point',
                { "station": station },
                {
                headers: {
                    'Content-Type': 'application/json'
                },
                observe: "response"
            })
            .toPromise()
            .then((response) => {
                let resData: any = response.body;
                if (response.status === 200) {
                    return true;
                } else {
                    this.$log.error('eroare la modificare statie', response);
                    return false;
                }
            });
        }
        //
        private buildNewRoutePointsListForSet(routePoints: Array<IRoutePoint>): any {
            let setRoutePoints: Array<any> = [];
            routePoints.forEach((pitem, pindex) => {
                setRoutePoints.push({
                    seq: pindex + 1,
                    name: pitem.name,
                    lat: pitem.lat,
                    long: pitem.long,
                    source_id: pitem.id
                });
            });
            return setRoutePoints;
        }
        //
        public regenerateRoutesForType(layer: ILayer): any {
            let stypeId = -1;
            if (layer.menuLayerItems && layer.menuLayerItems.length > 0) {
                let maction = layer.menuLayerItems.filter((aitem) => { return aitem.action === "regenerateRoutes"; });
                if (maction) {
                    try {
                        stypeId = maction[0].data["type_id"] || "";
                    } catch (e) {
                        this.$log.error("nu poate fi identificat tipul statiei");
                        return;
                    }
                }
            }
            if (stypeId && stypeId >= 0) {
                this.setRegenerateRoutesForType(stypeId)
                    .then((success) => {
                        if (success) {
                            this.$log.log("rutele au fost regenerate");
                            (layer.internalLayer as VectorLayer<any>).getSource().refresh();
                        } else {
                            this.$log.error("eroare la regenerare rute");
                        }
                    })
                    .catch((err) => {
                        this.$log.error("eroare la regenerare rute");
                    })
            }
        }
        private canceler: any = null;
        //
        public getTranzitPlan(locatiePlecare: [number,number], locatieSosire: [number,number],
            date: string, time: string, maxWalkDistance): Promise<ITripPlan> {
            let plan: ITripPlan = null;

            //todo cancel previous upgrade
            //if (this.canceler){
                //cancel previous request
            //    this.$log.log("cancel prev req trip plan");
            //    this.canceler.resolve();
            //}
            //this.canceler = this.$q.defer();

            return this.$http.get(
                 AppSettings.serverPath + '/optitransit/plan/' + locatiePlecare[1] + ','+ locatiePlecare[0]
                + '/' + locatieSosire[1] + ',' + locatieSosire[0] + '/' + time + '/' + date +'/' + maxWalkDistance,
                {
                //timeout: this.canceler.promise
                observe: "response",
            })
            .toPromise()
            .then((response) => {
                let resData = response.body as any;
                if (resData && isObject(resData) && "plan" in resData) {
                    //todo extract data
                    try {
                        return resData["plan"] as ITripPlan;
                    } catch (reason) {
                        this.$log.error("eroare extragere plan tranzit ");
                    }

                } else {
                    this.$log.error('eroare returnare plan tranzit');
                }
                return null;
            }).finally(()=>{
                this.canceler = null;
            })
        }
    }

