//NG+
import { AppSettings } from "../app-settings";
import { authType, geocodingLocation, IAccessResursa, ICategory, IItem, ILayer, ILayerReportSettings, ILayerStyleSettings, IOptiuneRes, ISridProjection, IStatus, IUserSettings, WFSTActionType } from "../definitions";
import { IClient } from "../roles/settings-components/settings-definitions";
import { DbStoreCAdastru } from "./dbstore-cadastru";
import { DbStoreCategory } from "./dbstore-category";
import { DbStoreClient } from "./dbstore-client";
import { DbStoreGrupText } from "./dbstore-gruptext";
import { DbStoreLayer } from "./dbstore-layer";
import { DbStoreLayerStyle } from "./dbstore-layerstyle";
import { DbStoreLayerFiles } from "./dbstore-leyerfiles";
import { DbStoreMapView } from "./dbstore-mapview";
import { DbStoreOptResRol } from "./dbstore-optresrol";
import { DbStoreResursaInterna } from "./dbstore-resursainterna";
import { DbStoreRole } from "./dbstore-role";
import { DbStoreUserInfo } from "./dbstore-userinfo";

import { NGXLogger } from "ngx-logger";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { getExMessage, isObject } from "../map/map-utils";


import Feature from 'ol/Feature';
import GMLFormater from "ol/format/GML";
import WFSFormater from "ol/format/WFS";
import { DbStoreUrbanism } from "./dbstore-urbanism";

    export interface IUserSettingsService {
        resursaInterna: DbStoreResursaInterna;
        client: DbStoreClient;
        layerStyle: DbStoreLayerStyle;
        mapView: DbStoreMapView;
        category: DbStoreCategory;
        role: DbStoreRole;
        layer: DbStoreLayer;
        optResRol: DbStoreOptResRol;
        userInfo: DbStoreUserInfo;
        grupText: DbStoreGrupText;
        cadastru: DbStoreCAdastru;
        layerFiles: DbStoreLayerFiles;
        //
        getCurrentUser(): IUserSettings;
        getCurrentUserIdFromStorage(): Promise<IUserSettings>;
        getCurrentUserInfoFromStorage(): Promise<{ id: number, name: string, email: string, phone: string, mapConfigVersion: number }>;
        setCurrentUserInfoToStorage(name: string, email: string, phone: string, mapConfigVersion: number): Promise<boolean>;
        setCurrentUserToAuthUserAndUpdateAllFromStorage(authUser: IUserSettings): Promise<boolean>;
        //
        updateCurrentUserAllFromStorage(): Promise<boolean>
        updateCurrentClientCategoriesFromStorage(): Promise<boolean>;
        updateCurrentUserRolesFromStorage(): Promise<boolean>;
        updateCurrentUserResourceAccessFromStorage(): Promise<boolean>
        updateCurrentUserLayersFromStorage(): Promise<boolean>;
        updateCurrentUserLayerStylesFromStorage(): Promise<boolean>;
        updateCurrentUserClientInfoFromStorage(): Promise<boolean>;
        //
        isUserDefined(userName: string): Promise<number>;//?
        authenticateUser(userName: string, password: string): Promise<IUserSettings>;
        registerUser(userName: string, password: string, client?: string, name?: string, email?: string, phone?: string, mapConfigVersion?: number ): Promise<IUserSettings>;
        changeUserPassword(userName: string, password: string): Promise<IUserSettings>;
        changeCurrentUserPassword(oldPassword: string, newPassword: string): Promise<IUserSettings>;
        //
        isAuthForResource(resourceName: string, type?: string): boolean;
        isAuthForItemOptionsAllInfo(optionName: string, resourceName: string, type: string): IOptiuneRes[] 
        isAuthForOptionFullInfo(optionName: string, resourceName: string, type?: string ): IOptiuneRes;
        isAuthForOption(optionName: string, resourceName: string, type?: string): boolean;
        isAuthForItemOption(optionName: string, resourceName: string, itemName: string, type: string): string;
        isAuthForItemOption_Name_FullInfo(optionName: string, resourceName: string, itemName: string, type: string): IOptiuneRes; 
        isAuthForItemOption_Id_FullInfo(optionName: string, resourceName: string, itemId: number, type: string): IOptiuneRes;
        //isMaxAuth(): string;
        //
        getAvailableUsers(): Promise<Array<IItem>>;
        getAvailableResurse(): Promise<Array<IAccessResursa>>;
        //getAvailableRoles(): Promise<Array<IItem>>;
        //
        getAssignedRolesForUser(userId: number): Promise<Array<IItem>>;
        
        getAssignedLayers(userId: number): Promise<Array<ILayer>>;
        setAssignedRolesForUserToStorage(userId: number, roles: Array<IItem>): Promise<boolean>;
        
        //
        setFeatureToGisServerWFST(layer: ILayer, feature: Feature, actionType: string): Promise<boolean>; 
        //
        getIreportImage(name: string): Promise<{imagename: string, imageurl: string}>;
         //
        getSridProjection(srid: number): Promise<string>;
        getMapProjections(): Promise<ISridProjection[]>;

        getLocationByGeocoding(locationText: string): Promise<Array<geocodingLocation>>;
        
    }

    @Injectable({
        providedIn: 'root',
    })
    export class UserSettingsService implements IUserSettingsService {
        //
        private currentUser: IUserSettings;
        //
        public resursaInterna: DbStoreResursaInterna;
        public client: DbStoreClient;
        public layerStyle: DbStoreLayerStyle;
        public mapView: DbStoreMapView;
        public category: DbStoreCategory;
        public role: DbStoreRole;
        public layer: DbStoreLayer;
        public optResRol: DbStoreOptResRol;
        public userInfo: DbStoreUserInfo;
        public grupText: DbStoreGrupText;
        public cadastru: DbStoreCAdastru;
        public urbanism: DbStoreUrbanism;
        public layerFiles: DbStoreLayerFiles;
        //
        public constructor(
            @Inject(HttpClient) private $http: HttpClient,
            @Inject(NGXLogger) private $log: NGXLogger,
            ) {
            //
            this.currentUser = { name: { id: -1, text: '' }, accessResurse: [], roles: [], layers: [], token: '', reports: [] };
            //
            this.resursaInterna = new DbStoreResursaInterna(this.$http, this.$log, this);
            this.client = new DbStoreClient(this.$http, this.$log, this);
            this.layerStyle = new DbStoreLayerStyle(this.$http, this.$log, this);
            this.mapView = new DbStoreMapView(this.$http, this.$log, this);
            this.category = new DbStoreCategory(this.$http, this.$log, this);
            this.role = new DbStoreRole(this.$http, this.$log, this);
            this.layer = new DbStoreLayer(this.$http, this.$log, this);
            this.optResRol = new DbStoreOptResRol(this.$http, this.$log, this);
            this.userInfo = new DbStoreUserInfo(this.$http, this.$log, this);
            this.grupText = new DbStoreGrupText(this.$http, this.$log, this);
            this.cadastru = new DbStoreCAdastru(this.$http, this.$log, this);
            this.layerFiles = new DbStoreLayerFiles(this.$http, this.$log, this);
            this.urbanism = new DbStoreUrbanism(this.$http, this.$log, this);
            //
        }

        //
        //auth
        //
        public authenticateUser(userName: string, password: string): Promise<IUserSettings> {
            return this.userAuth('/auth/local-login', userName, password);
        }

        public registerUser(userName: string, password: string, client?: string, name?: string, email?: string, phone?: string, mapConfigVersion?: number ): Promise<IUserSettings> {
            return this.userAuth('/auth/local-register', userName, password, client, name, email, phone, mapConfigVersion);
        }

        private userAuth(actionType: string, userName: string, password: string, client?: string, name?: string, email?: string, phone?: string, mapConfigVersion?: number): Promise<IUserSettings> {
            let authData = { "username": userName, "password": password, "client": client }
            if (name) { authData['name'] = name; }
            if (email) { authData['email'] = email; }
            if (phone) { authData['phone'] = phone; }
            if (mapConfigVersion) { authData['mapConfigVersion'] = mapConfigVersion; }
            return this.$http.post(
                AppSettings.serverPath + actionType,
                authData,
                {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                },
                observe: "response"
            })
            .toPromise()
            .then((response) => {
                let authData = response.body as any;
                if (("token" in authData) && ("client" in authData) && ("id" in authData)/* && ("name" in authData)*/) {
                    let authUser: IUserSettings = {
                        name: { id: authData["id"], text: authData["name"]||'' },
                        token: authData["token"],
                        idClient: authData["client"]
                    }
                    return authUser;
                }
                else {
                    throw new Error("datele de autentificare lipsesc din raspuns");
                }
            }).catch((reason)=>{
                this.$log.error("erroare autentificare ", getExMessage(reason));
                throw new Error("eroare de autentificare");
            })
        }

        public changeUserPassword(userName: string, password: string): Promise<IUserSettings> {
            return this.$http.post(
                AppSettings.serverPath + '/auth/change-password',
                { "username": userName, "password": password },
                {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                },
                observe: "response"
            })
            .toPromise()
            .then((response) => {
                let authData = response.body as any;
                if (("username" in authData) && userName === authData["username"]) {
                    let authUser: IUserSettings = {
                        name: { id: -1, text: userName },
                        token: "",
                        idClient: ""
                    }
                    return authUser;
                }
                else {
                    throw new Error("datele de autentificare lipsesc din raspuns");
                }
            })
        }

        public changeCurrentUserPassword(oldPassword: string, newPassword: string): Promise<IUserSettings> {
            return this.$http.post(
                AppSettings.serverPath + '/auth/change-current-password',
                { "oldpassword": oldPassword, "newpassword": newPassword },
                {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                },
                observe: "response"
            })
            .toPromise()
            .then((response) => {
                let authData = response.body as any;
                if ("username" in authData) {
                    let authUser: IUserSettings = {
                        name: { id: -1, text: authData['username'] },
                        token: "",
                        idClient: ""
                    }
                    return authUser;
                }
                else {
                    throw new Error("datele de autentificare lipsesc din raspuns");
                }
            })
        }

        //
        // public isMaxAuth(): string {
        //     if (this.currentUser && this.currentUser.token) {
        //         var tokenPayload = this.jwtHelper.decodeToken(this.currentUser.token);
        //         return tokenPayload.auth;
        //     } else {
        //         return '0';
        //     }
        // }

        public isAuthForResource(resourceName: string, type: string = 'object'): boolean {
            let bResult = false;
            try {
                if (this.currentUser.accessResurse) {
                    let access = this.currentUser.accessResurse.filter((res) => { return res.nume === resourceName && res.type === type; })
                    if (access && access.length > 0) {
                        bResult = true;
                    }
                }
            } catch (e) {
                this.$log.error("eroare obtinere acces resursa");
            }
            return bResult;
        };

        public isAuthForOptionsFull(optionFilter: (item: IOptiuneRes) => boolean, resourceName: string, type: string = authType.layer): IOptiuneRes[] {
            let result: IOptiuneRes[] = null;
            try {
                if (this.currentUser.accessResurse) {
                    let access = this.currentUser.accessResurse.filter((res) => { return res.nume === resourceName && res.type === type; })
                    if (access && access.length > 0 && access[0]) {
                        let accessRes = access[0].optiuni;
                        if (accessRes && accessRes.length > 0) {
                            let optiuni = accessRes.filter(optionFilter);
                            if (optiuni && optiuni.length > 0) {
                                result = [];
                                (optiuni as Array<IOptiuneRes>).forEach((opItem) => {
                                    result.push(opItem)
                                });
                            }
                        }
                    }
                }
            } catch (e) {
                this.$log.error("eroare obtinere acces optiune pe element");
            }
            return result;
        }
        //
        public isAuthForItemOptionsAllInfo(optionName: string, resourceName: string, type: string = authType.layer): IOptiuneRes[] {
            let result: IOptiuneRes[] = null;
            try {
                if (this.currentUser.accessResurse) {
                    let access = this.currentUser.accessResurse.filter((res) => { return res.nume === resourceName && res.type === type; })
                    if (access && access.length > 0 && access[0]) {
                        let accessRes = access[0].optiuni;
                        if (accessRes && accessRes.length > 0) {
                            let optiuni = accessRes.filter((res) => { return res['nume'] === optionName; });
                            if (optiuni && optiuni.length > 0) {
                                result = [];
                                (optiuni as Array<IOptiuneRes>).forEach((opItem) => {
                                    result.push(opItem)
                                });
                            }
                        }
                    }
                }
            } catch (e) {
                this.$log.error("eroare obtinere acces optiune pe element");
            }
            return result;
        }

        public isAuthForItemOption_Name_FullInfo(optionName: string, resourceName: string, itemName: string = null, type: string = authType.layer): IOptiuneRes {
            let result: IOptiuneRes = null;
            try {
                if (this.currentUser.accessResurse) {
                    let access = this.currentUser.accessResurse.filter((res) => { return res.nume === resourceName && res.type === type; })
                    if (access && access.length > 0 && access[0]) {
                        let accessRes = access[0].optiuni;
                        if (accessRes && accessRes.length > 0) {
                            let optiune = accessRes.filter((res) => { return res['nume'] === optionName && res['descriere'] === itemName; });
                            if (optiune && optiune.length > 0) {
                                result = optiune[0] as IOptiuneRes;
                            }
                        }
                    }
                }
            } catch (e) {
                this.$log.error("eroare obtinere acces optiune pe element");
            }
            return result;
        }

        public isAuthForItemOption_Id_FullInfo(optionName: string, resourceName: string, itemId: number = -1, type: string = authType.layer): IOptiuneRes {
            let result: IOptiuneRes = null;
            try {
                if (this.currentUser.accessResurse) {
                    let access = this.currentUser.accessResurse.filter((res) => { return res.nume === resourceName && res.type === type; })
                    if (access && access.length > 0 && access[0]) {
                        let accessRes = access[0].optiuni;
                        if (accessRes && accessRes.length > 0) {
                            let optiune = accessRes.filter((res) => { return res['nume'] === optionName && res['idItem'] === itemId; });
                            if (optiune && optiune.length > 0) {
                                result = optiune[0] as IOptiuneRes;
                            }
                        }
                    }
                }
            } catch (e) {
                this.$log.error("eroare obtinere acces optiune pe element");
            }
            return result;
        }

        public isAuthForItemOption(optionName: string, resourceName: string, itemName: string = null, type: string = authType.layer): string {
            let bResult = null;
            try {
                if (this.currentUser.accessResurse) {
                    let access = this.currentUser.accessResurse.filter((res) => { return res.nume === resourceName && res.type === type; })
                    if (access && access.length > 0 && access[0]) {
                        let accessRes = access[0].optiuni;
                        if (accessRes && accessRes.length > 0) {
                            let optiune = accessRes.filter((res) => { return res['nume'] === optionName && res['descriere'] === itemName; });
                            if (optiune && optiune.length > 0) {
                                bResult = optiune[0]['customAccess'];
                            }
                        }
                    }
                }
            } catch (e) {
                this.$log.error("eroare obtinere acces optiune pe element");
            }
            return bResult;
        }

        public isAuthForOptionFullInfo(optionName: string, resourceName: string, type: string = authType.layer): IOptiuneRes {
            let result = null;
            try {
                if (this.currentUser.accessResurse) {
                    let access = this.currentUser.accessResurse.filter((res) => { return res.nume === resourceName && res.type === type; })
                    if (access && access.length > 0 && access[0]) {
                        let accessRes = access[0].optiuni;
                        if (accessRes && accessRes.length > 0) {
                            let optiune = accessRes.filter((res) => { return res['nume'] === optionName; });
                            if (optiune && optiune.length > 0) {
                                result = optiune[0] as IOptiuneRes;
                            }
                        }
                    }
                }
            } catch (e) {
                this.$log.error("eroare obtinere acces optiune");
            }
            return result;
        }

        public isAuthForOption(optionName: string, resourceName: string, type: string = authType.layer): boolean {
            let bResult = false;
            let optInfo = this.isAuthForOptionFullInfo(optionName, resourceName, type);
            if (optInfo) {
                bResult = true;
            }
            return bResult;
        }
        //end auth

        //
        public getCurrentUser(): IUserSettings {
            if (this.currentUser) {
                return this.currentUser;
            }
            else {
                this.$log.warn('utilizatorul curent nu este configurat');
                return null;
            }
        }
        //
        public getCurrentUserIdFromStorage(): Promise<IUserSettings> {
            return this.$http.get(
                AppSettings.serverPath + 'data/current-user',
                {observe: "response"}
            )
            .toPromise()
            .then((response) => {
                let authData = response.body as any;
                if (("token" in authData) && ("client" in authData) && ("id" in authData) && ("name" in authData)) {
                    let authUser: IUserSettings = {
                        name: { id: authData["id"], text: authData["name"] },
                        token: authData["token"],
                        idClient: authData["client"]
                    }
                    return authUser;
                }
                else {
                    throw new Error("datele de identificare lipsesc din raspuns");
                }
            });
        };
        //
        public getCurrentUserInfoFromStorage(): Promise<{ id: number, name: string, email: string, phone: string, mapConfigVersion: number }> {
            return this.$http.get(
                AppSettings.serverPath + 'data/current-user-info',
                {observe: "response"}
            )
            .toPromise()
            .then((response) => {
                let authData = response.body as any;
                if (("id" in authData) && ("name" in authData) && ("email" in authData) && ("phone" in authData)) {
                    let authUser = {
                        id: authData["id"] as number,
                        name: authData["name"] as string,
                        email: authData["email"] as string,
                        phone: authData["phone"] as string,
                        mapConfigVersion: authData["mapConfigVersion"] as number
                    };
                    return authUser;
                } else {
                    throw new Error("datele de identificare lipsesc din raspuns");
                }
            });
        };
        //
        public setCurrentUserInfoToStorage(name: string, email: string, phone: string, mapConfigVersion: number): Promise<boolean> {
            return this.$http.post(
                AppSettings.serverPath + 'data/save-current-user-info',
                { name: name, email: email, phone: phone, mapConfigVersion: mapConfigVersion },
                {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                },
                observe: "response"
            })
            .toPromise()
            .then((response) => {
                return (response.status === 200);
            });
        }
        //
        public isUserDefined(userName: string): Promise<number> {
            return this.$http.get(
                 AppSettings.serverPath + '/auth/username-is-defined/' + userName,
                 {observe: "response"}
            )
            .toPromise()
            .then((response) => {
                return <number>response.body;
            });
        }
        //
        public setCurrentUserToAuthUserAndUpdateAllFromStorage(authUser: IUserSettings): Promise<boolean> {
            this.currentUser.name.text = authUser.name.text;
            this.currentUser.name.id = authUser.name.id;
            this.currentUser.token = authUser.token;
            this.currentUser.idClient = authUser.idClient;
            //
            return this.updateCurrentUserAllFromStorage();
        }

        //
        //
        //
        public updateCurrentUserAllFromStorage(): Promise<boolean> {
            return this.updateCurrentUserRolesFromStorage()
                .then((result) => {
                    return result;
                })
                .then((success: boolean) => {
                    if (!success) { return false; }
                    return this.updateCurrentUserResourceAccessFromStorage()
                        .then((result) => {
                            return result;
                        });
                })
                .then((success: boolean) => {
                    if (!success) { return false; }
                    return this.updateCurrentClientMapConfigFromStorage()
                        .then((result) => {
                            return result;
                        });
                })
                .then((success: boolean) => {
                    if (!success) { return false; }
                    return this.updateCurrentClientCategoriesFromStorage()
                        .then((result) => {
                            return result;
                        });
                })
                .then((success: boolean) => {
                    if (!success) { return false; }
                    return this.updateCurrentUserLayersFromStorage()
                        .then((result) => {
                            return result;
                        });
                })
                .then((success: boolean) => {
                    if (!success) { return false; }
                    return this.updateCurrentUserLayerStylesFromStorage()
                        .then((result) => {
                            return result;
                        })
                })
                .then((success: boolean) => {
                    if (!success) { return false; }
                    return this.updateCurrentUserLayerReportsFromStorage()
                        .then((result) => {
                            return result;
                        })
                })
                .then((success: boolean) => {
                    if (!success) { return false; }
                    return this.updateCurrentUserClientInfoFromStorage()
                        .then((result) => {
                            return result;
                        })
                })
                .then((success: boolean) => {
                    if (!success) { return false; }
                    return this.updateCurrentUserMapProjectionsFromStorage()
                        .then((result) => {
                            return result;
                        })
                })
            //
        }
        //
        public updateCurrentUserRolesFromStorage(): Promise<boolean> {
           return this.getAssignedRolesForCurrentUser()
               .then((roles: Array<IItem>) => {
                    this.currentUser.roles = roles;
                    return true;
                });
        }
        //
        public updateCurrentUserResourceAccessFromStorage(): Promise<boolean> {
            return this.getAssignedResourceAccessForCurrentUser()
                .then((accessResurse: Array<IAccessResursa>) => {
                    this.currentUser.accessResurse = accessResurse;
                    return true;
                });
        }
        //
        public updateCurrentClientMapConfigFromStorage(): Promise<boolean> {
            return this.getMapConfigForCurrentUser()
                .then((mapConfig: any) => {
                    this.currentUser.mapConfig = mapConfig;
                    return true;
                });
        }
        //
        public updateCurrentClientCategoriesFromStorage(): Promise<boolean> {
            return this.getAssignedCategoriesForCurrentClient()
                .then((categories: Array<ICategory>) => {
                    this.currentUser.categories = categories;
                    return true;
                });
        }
        //
        public updateCurrentUserLayersFromStorage(): Promise<boolean> {
            return this.getAssignedLayersForCurrentUser()
                .then((layers: Array<ILayer>) => {
                    this.currentUser.layers = layers;
                    return true;
                });
        }
        //
        public updateCurrentUserLayerStylesFromStorage(): Promise<boolean> {
            return this.getAssignedLayerStylesForCurrentUser()
                .then((styles: Array<ILayerStyleSettings>)=> {
                    this.currentUser.styles = styles;
                    return true;
                });
        }
        //
        public updateCurrentUserLayerReportsFromStorage(): Promise<boolean> {
            return this.getAssignedLayerReportForCurrentUser()
                .then((reports: Array<ILayerReportSettings>) => {
                    this.currentUser.reports = reports;
                    return true;
                });
        }
        //
        public updateCurrentUserClientInfoFromStorage(): Promise<boolean> {
            return this.getAssignedClientInfoForCurrentUser()
                .then((client: IClient) => {
                    this.currentUser.client = client;
                    return true;
                });
        }
        //
        public updateCurrentUserMapProjectionsFromStorage(): Promise<boolean> {
            return this.getMapProjections()
                .then((projections) => {
                    this.currentUser.mapProjections = projections;
                    return true;
                })
        }
        //
        public getAssignedClientInfoForCurrentUser(): Promise<IClient> {
            return this.$http.get(
                AppSettings.serverPath + 'data/current-user-client-info',
                {observe: "response"}
            )
            .toPromise()
            .then((response) => {
                try {
                    let rezClient: IClient = {
                        id: response.body["id"],
                        tipClient: null,
                        nume: response.body["nume"],
                        descriere: response.body["descriere"],
                        numarPostal: response.body["numarPostal"],
                        idStrada: response.body["idStrada"],
                        idLocalitate: response.body["idLocalitate"],
                        idJudet: response.body["idJudet"],
                        url: response.body["url"],
                        judet: response.body["denumireJudet"],
                        localitate: response.body["denumireLocalitate"],
                        //
                        formatDateTime: response.body["formatDateTime"],
                        formatDate: response.body["formatDate"],
                        formatTime: response.body["formatTime"],
                        //
                        username: null,
                        password: null,
                        judetSelectat: null,
                        localitateSelectata: null,
                    };

                    return rezClient;
                } catch (e) {
                    throw new Error("Eroare parsare info client: " + e.message)
                }
            });
        }


        //get all users form storage
        public getAvailableUsers(): Promise<Array<IItem>> {
            return this.$http.get(
                AppSettings.serverPath + 'data/users',
                {observe: "response"}
            )
            .toPromise()
            .then((response) => {
                let usersList: Array<any> = <Array<any>>response.body;
                let users: Array<IItem> = [];
                for (var i = 0; i < usersList.length; i++) {
                    let tmpUser: IItem = { id: usersList[i].id, text: usersList[i].username}
                    users.push(tmpUser);
                }
                return users;
            });
        }

        //user id is taken form authentification data
        public getAssignedResourceAccessForCurrentUser() {
            return this.$http.get(
                AppSettings.serverPath + '/data/current-user-resource-access',
                {observe: "response"}
            )
            .toPromise()
            .then((response) => {
                let roles: Array<IAccessResursa> = [];
                let roleList: Array<any> = <Array<any>>response.body;
                for (var i = 0; i < roleList.length; i++) {
                    try {
                        let resursa: IAccessResursa = {
                            id: roleList[i].id,
                            nume: roleList[i].nume,
                            descriere: roleList[i].descriere,
                            type: roleList[i].type,
                            access: roleList[i].accessPermis,
                            optiuni: roleList[i].options
                        };
                        roles.push(resursa);
                    } catch (reason) {
                        this.$log.error("eroare resursa access ", i , getExMessage(reason));
                    }
                }
                return roles;
            });
        };
        //get from storage
        public getAssignedResourceAccessForUser(userId: number) {
            return this.$http.get(
                AppSettings.serverPath + '/data/user-resource-access/' + userId,
                {observe: "response"}
            )
            .toPromise()
            .then((response) => {
                let roles: Array<IAccessResursa> = [];
                let roleList: Array<any> = <Array<any>>response.body;
                for (var i = 0; i < roleList.length; i++) {
                    try {
                        let resursa: IAccessResursa = {
                            id: roleList[i].id,
                            nume: roleList[i].nume,
                            descriere: roleList[i].descriere,
                            type: roleList[i].type,
                            access: roleList[i].accessPermis,
                            optiuni: roleList[i].options
                        };
                        roles.push(resursa);
                    } catch (reason) {
                        this.$log.error("eroare resursa access ", i , getExMessage(reason));
                    }
                }
                return roles;
            });
        };

        //current user from sesion
        public getAssignedRolesForCurrentUser(): Promise<Array<IItem>> {
            return this.$http.get(
                AppSettings.serverPath + 'data/current-user-roles',
                {observe: "response"} 
            )
            .toPromise()
            .then((response) => {
                let roles: Array<IItem> = [];
                let roleList: Array<any> = <Array<any>>response.body;
                for (var i = 0; i < roleList.length; i++) {
                    let role: IItem = { id: roleList[i].id, text: roleList[i].nume };
                    roles.push(role);
                }
                return roles;
            })

        }
        //get from storage
        public getAssignedRolesForUser(userId: number): Promise<Array<IItem>> {
            return this.$http.get(
                AppSettings.serverPath + 'data/user-roles/' + userId,
                {observe: "response"}
            )
            .toPromise()
            .then((response) => {
                let roles: Array<IItem> = [];
                let roleList: Array<any> = <Array<any>>response.body;
                for (var i = 0; i < roleList.length; i++) {
                    let role: IItem = { id: roleList[i].id, text: roleList[i].nume };
                    roles.push(role);
                }
                return roles;
            })
            
        }
        //
        public setAssignedRolesForUserToStorage(userId: number, roles: Array<IItem>):Promise<boolean> {
            return this.$http.post(
                AppSettings.serverPath + 'data/save-user-roles/' + userId,
                JSON.stringify(roles),
                {
                //withCredentials: true,
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                },
                observe: "response"
            })
            .toPromise()
            .then((response) => {
                if ('success' in (response.body as any)) {
                    return (<IStatus>response.body).success;
                }
                else {
                    return false;
                }
            });
        }

        
        //
        public getAssignedLayersForCurrentUser(): Promise<Array<ILayer>> {
            return this.$http.get(
                AppSettings.serverPath + 'data/current-user-layers',
                {observe: "response"} 
            )
            .toPromise()
            .then((response) => {
                let layerList: Array<any> = <Array<any>>response.body;
                let layers: Array<ILayer> = [];
                for (var i = 0; i < layerList.length; i++) {
                    let layer: ILayer = {
                        id: layerList[i].id,
                        name: layerList[i].nume,
                        description: layerList[i].descriere,
                        category: layerList[i].categorie,
                        url: '',//url not visible
                        projection: layerList[i].proiectie,
                        featureType: layerList[i].layerType,
                        color: layerList[i].culoare,
                        asset: layerList[i].icon,
                        auth: layerList[i].auth,
                        styleType: layerList[i].styleType,
                        styleKeyColumn: layerList[i].styleKeyColumn,
                        textKeyColumn: layerList[i].textKeyColumn,
                        visible: false,
                        fileName: null,
                        internalLayer: null,
                        manualRefresh: false
                    };
                    layers.push(layer);
                }
                return layers;
            });
        }

        //todo change fields as required
        public getAssignedLayers(userId: number): Promise<Array<ILayer>> {
            return this.$http.get(
                AppSettings.serverPath + '/user-layers/' + userId,
                {observe: "response"}
            )
            .toPromise()
            .then((response) => {
                let layerList: Array<any> = <Array<any>>response.body;
                let layers: Array<ILayer> = [];
                for (var i = 0; i < layerList.length; i++) {
                    let layer: ILayer = {
                        id: layerList[i].id,
                        name: layerList[i].name,
                        description: layerList[i].descriere,
                        category: layerList[i].category,
                        url: layerList[i].url,//url not visible
                        projection: layerList[i].proiectie,
                        featureType: layerList[i].featureType,
                        color: layerList[i].color,
                        asset: layerList[i].asset,
                        auth: layerList[i].auth,
                        visible: false,
                        fileName: null,
                        internalLayer: null
                    };
                    layers.push(layer);
                }
                return layers;
            });
        }
        //
        public getAssignedLayerStylesForCurrentUser(): Promise<Array<ILayerStyleSettings>> {
            return this.$http.get(
                AppSettings.serverPath + 'data/current-user-layer-styles',
                {observe: "response"}
            )
            .toPromise()
            .then((response) => {
                let styleList: Array<ILayerStyleSettings> = <Array<ILayerStyleSettings>>response.body;
                let styles: Array<ILayerStyleSettings> = [];
                for (let i = 0; i < styleList.length; i++) {
                    let style: ILayerStyleSettings = {
                        id: styleList[i].id,
                        idResursa: styleList[i].idResursa,
                        styleKey: styleList[i].styleKey,
                        styleName: styleList[i].styleName,
                        descriere: styleList[i].descriere,
                        layerType: styleList[i].layerType,
                        style: styleList[i].style,
                        styleOnSelect: styleList[i].styleOnSelect,
                        icon: styleList[i].icon,
                        legenda: styleList[i].legenda
                    };
                    if (style) {
                        styles.push(style);
                    }
                }
                return styles;
            })
        }

        //
        public getAssignedLayerReportForCurrentUser(): Promise<Array<ILayerReportSettings>> {
            return this.$http.get(
                AppSettings.serverPath + 'data/current-user-layer-report',
                {observe: "response"}
            )
            .toPromise()
            .then((response) => {
                let repInfList: Array<ILayerReportSettings> = <Array<ILayerReportSettings>>response.body;
                let repInfos: Array<ILayerReportSettings> = [];
                for (let i = 0; i < repInfList.length; i++) {
                    let repinf: ILayerReportSettings = {
                        id: repInfList[i].id,
                        nume: repInfList[i].nume,
                        idResReport: repInfList[i].idResReport,
                        reportColumns: repInfList[i].reportColumns,
                        nameResData: repInfList[i].nameResData,
                        dataColumns: repInfList[i].dataColumns,
                        constants: repInfList[i].constants,
                        reportFormula: repInfList[i].reportFormula,
                        description: repInfList[i].description
                    };
                    if (repinf) {
                        repInfos.push(repinf);
                    }
                }
                return repInfos;
            })
        }

        //clientId form session
        public getAssignedCategoriesForCurrentClient(): Promise<Array<ICategory>> {
            return this.$http.get(
                AppSettings.serverPath + 'data/current-client-categories',
                {observe: "response"}
            )
            .toPromise()
            .then((response) => {
                let categoryList: Array<any> = <Array<any>>response.body;
                let categories: Array<ICategory> = [];
                for (var i = 0; i < categoryList.length; i++) {
                    let category: ICategory = {
                        id: categoryList[i].id,
                        code: categoryList[i].nume,
                        name: categoryList[i].descriere,
                        parentCode: categoryList[i].parentCode || null,
                        layers: [],
                        layersColapsed: true
                    };
                    categories.push(category);
                }
                return categories;
            });
        }
        //
        public getMapConfigForCurrentUser(): Promise<any> {
            return this.$http.get(
                AppSettings.serverPath + 'data/current-user-map-config',
                {observe: "response"}
            )
            .toPromise()
            .then((response) => {
                let config = response.body as any;
                if (isObject(config) && 'id' in config && 'version' in config ) {
                    return config;
                } else {
                   throw new Error("configurarea harti nu este disponibila");
                }
            })
        };


        //gis-server
        public setFeatureToGisServerWFST(layer:ILayer, feature: Feature, actionType: string): Promise<any> {
            //
           //feature.setGeometry( feature.getGeometry().transform(this.currentUser.mapConfig['projection'],layer.projection))
            //
            let serXML = new XMLSerializer();
            let formatGML = new GMLFormater({
                featureNS: layer.targetNamespace,
                featureType: layer.typeName,
                //srsName: 'urn:ogc:def:crs:EPSG::4326'
                srsName: layer.projection || this.currentUser.mapConfig['projection'] // 
            });
            let formatWFS = new WFSFormater();

            // srsName: 'EPSG:4326'
            // this.$log.error('feature',JSON.stringify( feature));
            let node;
            if (actionType === WFSTActionType.insertFeature) {
                node = formatWFS.writeTransaction([feature], null, null, formatGML as any);
            } else {
                node = formatWFS.writeTransaction(null, [feature], null, formatGML as any);
            }
            //to update only the info, remove the node with geometry
            if (actionType === WFSTActionType.updateInfo) {
                let geoName = feature.getGeometryName();
                $(node).find('Update > Property').each((index, element) => {
                    let tmpname = $(element).find('Name').first();
                    if (tmpname && tmpname.text() === geoName) {
                        $(element).remove();
                    }
                })
            }
            //
            let payload = serXML.serializeToString(node);

            if (actionType === WFSTActionType.insertFeature) {
                payload = payload.replace('<geometry>', '<' + feature.getGeometryName() + '>').replace('</geometry>', '</' + feature.getGeometryName() + '>');
            }

            return this.$http.post(
                AppSettings.serverPath + 'layer/save-feature/' + layer.id,
                payload,
                {
                headers: {
                    'Data-Type': 'xml',
                    'Content-Type': 'text/xml'
                },
                observe: "response",
                responseType: 'text'
            })
            .toPromise()
            .then((response) => {
                let wfsResponse = formatWFS.readTransactionResponse(response.body) as any;
                if (("transactionSummary" in wfsResponse)
                    && (("totalUpdated" in wfsResponse["transactionSummary"]) 
                        || ("totalInserted" in wfsResponse["transactionSummary"]))) {
                    //
                    return wfsResponse;
                }  else {
                    this.$log.error(response.body);
                    return false;
                }
            }).catch((reason) => {
                this.$log.error("Error", getExMessage(reason));
                return false;
            });

        }

        
        getIreportImage(name: string): Promise<{imagename: string, imageurl: string}>{
            return this.$http.get(
                AppSettings.serverPath + '/iReport/getImage',
                {
                params: {image: name},
                headers: {
                    'Accept': 'image/webp,image/apng,image/*,*/*;q=0.8'
                },
                responseType: 'arraybuffer',
                observe: "response"
            })
            .toPromise()
            .then((response) => {
                if (response.status == 200 &&
                    (response.headers.get('content-type') === 'image/jpeg'
                        || response.headers.get('content-type') === 'image/*'
                    )
                ) {
                    let arrayBufferView = Uint8Array.from(response.body as any);
                    let blob = new Blob([response.body as any], { type: 'image/jpeg' });
                    let urlCreator = window.URL || window['webkitURL'];
                    let resurl = urlCreator.createObjectURL(blob);
                    this.$log.log(' ireport image url ' + resurl);
                    return {imagename: name, imageurl: resurl};
                } else {
                    if (response.body == Object(response.body)) {
                        this.$log.error("Eroare de incarcare imagine iReport ", JSON.stringify(response.body));
                    }
                    throw new Error("eroare de incarcare imagine iReport");
                }
            }).catch((reason)=>{
                this.$log.error("erroare la incaracre imagine iRaport ", getExMessage(reason));
                return null;
            });
        }
        //end gis-server

        //layer report settings
        public getLayerReportSettings(layerId): Promise<Array<ILayerReportSettings>> {
            return this.$http.get(
                AppSettings.serverPath + '/data/layer-report-settings/' + layerId,
                {observe: "response"}
            )
            .toPromise()
            .then((response) => {

                let resSettings = response.body as Array<ILayerReportSettings>;
                let result: Array<ILayerReportSettings> = [];
                if (response.body && isObject(response.body)) {
                    resSettings.forEach((lsItem) => {
                        let setting: ILayerReportSettings = null;
                        try {
                            setting = {
                                id: lsItem['id'],
                                nume: lsItem['nume'],
                                idResReport: lsItem['idResReport'],
                                reportColumns: JSON.stringify(lsItem['reportColumns']),
                                nameResData: lsItem['nameResData'],
                                dataColumns: JSON.stringify(lsItem['dataColumns']),
                                constants: JSON.stringify(lsItem['constants']),
                                reportFormula: lsItem['reportFormula'],
                                description: lsItem['description']
                            }
                        } catch (e) {
                            this.$log.error("eroare in extragere date strat");
                        }
                        if (setting) {
                            result.push(setting);
                        }
                    })
                }
                return result;
            });
        }

        // resursa 
        public getAvailableResurse(): Promise<Array<IAccessResursa>> {
            return this.$http.get(
                AppSettings.serverPath + 'data/resurse',
                {observe: "response"}
            )
            .toPromise()
            .then((response) => {
                let layerList: Array<any> = <Array<any>>response.body;
                let layers: Array<IAccessResursa> = [];
                for (var i = 0; i < layerList.length; i++) {
                    let layer = {
                        id: layerList[i].id,
                        nume: layerList[i].nume,
                        descriere: layerList[i].descriere,
                        type: layerList[i].type,
                        access: layerList[i].access
                    };
                    layers.push(layer);
                }
                return layers;
            });
        }


       
        //
        public getSridProjection(srid: number): Promise<string> {
            return this.$http.get(
                AppSettings.serverPath + 'data/get-projection/' + srid,
                {observe: "response"}
            )
            .toPromise()
            .then((response) => {
                let projectionData = response.body;
                try {
                    return projectionData['projection'];
                } catch (e) {
                    throw new Error("eroare proiectie in raspuns " + e.message);
                }
            });
        };

        public getMapProjections(): Promise<ISridProjection[]> {
            return this.$http.get(
                AppSettings.serverPath + 'data/get-map-projections',
                {observe: "response"}
            )
            .toPromise()
            .then((response) => {
                let projectionList: Array<ISridProjection> = <Array<ISridProjection>>response.body;
                let projections: Array<any> = [];
                try {
                    //proiectie: string, srid: string, proj4text: string
                    for (let i = 0; i < projectionList.length; i++) {
                        let projection: ISridProjection = {
                            proiectie: projectionList[i].proiectie,
                            srid: projectionList[i].srid,
                            proj4text: projectionList[i].proj4text
                        };
                        if (projection) {
                            projections.push(projection);
                        }
                    }
                } catch (e) {
                    throw new Error('Eroare proiectie straturi ' + e.message);
                }
                return projections;
            });
        }

        getLocationByGeocoding(locationText: string): Promise<Array<geocodingLocation>>
        {
            return this.$http.get(
                AppSettings.serverPath + '/geocoding/search',
                {
                params: {q: locationText, polygon_geojson:'1'},
                observe: "response"
            })
            .toPromise()
            .then((response) => {
                let locations: Array<geocodingLocation> = [];
                let locationsList: Array<geocodingLocation> = <Array<geocodingLocation>>response.body;
                
                try {
                    //
                    for (let i = 0; i < locationsList.length; i++) {
                        let location: geocodingLocation = {
                            place_id: locationsList[i].place_id,
                            licence: locationsList[i].licence,
                            osm_type: locationsList[i].osm_type,
                            osm_id: locationsList[i].osm_id,
                            boundingbox: locationsList[i].boundingbox,
                            lat: locationsList[i].lat,
                            lon: locationsList[i].lon,
                            display_name: locationsList[i].display_name,
                            class: locationsList[i].class,
                            type: locationsList[i].type,
                            importance: locationsList[i].importance,
                            geojson: locationsList[i].geojson
                        };
                        if (location) {
                            locations.push(location);
                        }
                    }
                } catch (e) {
                    throw new Error('Eroare locatie din geocoding ' + e.message);
                }
                return locations;
            });
        }

    }
    //