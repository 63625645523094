//NG+
import { Component, Inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AppSettings } from '../../app-settings';
import { ILayer, authAs, authOpt, authType, featureType } from '../../definitions';
import { MapController } from '../map-controller';
import { listaSursaDateClasificare } from "./map-greentop-definitions";
import { IDescarcareDateSatelitareIL, IUnitateAdministrativaDescarcareIL, IIntegrateLandsatDownloadData, IIntegrateLandsatProcesareIndiceConexData, IProcesareIndiceConex } from "./map-integrate.definitions";
import { IProdusDescarcare, TaskActiune, TaskStatus } from '../../services/greentop-data-service';
import { I18n, NgbCustomDatepickerI18n } from '../../shared-components/ngb-datepicker-i18n';
import { NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateMomentAdapter, NgbDateMomentParserFormatter } from '../../shared-components/ngb-moment-adapter-formater';
import moment, { Moment } from "moment";
import { createGeomFromWKT, delay, findDatesStratReferintaForLayer, findStratAsociatToIndice, findStratReferintaForLayer, getExMessage, setMomentOnTileLayer } from '../map-utils';
import { NGXLogger } from 'ngx-logger';

import VectorLayer from 'ol/layer/Vector';
import Feature from 'ol/Feature';
import Polygon from 'ol/geom/Polygon';
import VectorSource from "ol/source/Vector";
import OlStyle from "ol/style/Style";
import StrokeStyle from "ol/style/Stroke";
import FillStyle from "ol/style/Fill";
import TextStyle from "ol/style/Text";
import * as olColor from 'ol/color';

@Component({
    selector: 'integrate-landsat-procesare-indice-conex',
    providers: [
        I18n,
        { provide: NgbDatepickerI18n, 
             useFactory: () => { return new NgbCustomDatepickerI18n({language : 'ro'})} },
        { provide: NgbDateParserFormatter, 
            useFactory: () => { return new NgbDateMomentParserFormatter("DD-MM-YYYY") } },
        { provide: NgbDateAdapter, useClass: NgbDateMomentAdapter},
    ],
    templateUrl: './app/map/map-components/map-integrate-landsat-procesare-indice-conex.component.html',
    styleUrls: ['./app/map/map-components/map-integrate-landsat-procesare-indice-conex.component.css']
})
export class IntegrateLandsatProcesareIndiceConexComponent implements OnInit, OnChanges {
    @Input("mapctrl") mapCtrl: MapController = null;
    //
    public infoSearch = "test info procesare LST"
    public infoProducts = "test info products download"
    private integrateLandsatProcIndConexData : IIntegrateLandsatProcesareIndiceConexData = null;
    //
    private zoneStyle = [
        new OlStyle({
            fill: new FillStyle({
                color: olColor.asArray('rgba(0,255,0,0)')
            }),
            stroke: new StrokeStyle({
                color: 'rgba(0,255,0,1)',
                width: 4,
            }),

        }),
        new OlStyle({
            fill: new FillStyle({
                color: olColor.asArray('rgba(0,255,0,0)')
            }),
            stroke: new StrokeStyle({
                color: 'rgba(255,0,0,1)',
                width: 4,
                lineDash: [4, 8],
            }),

        }),
    
];
    private zoneText(text: string) {
        return new OlStyle({
            text: new TextStyle({
                text: text,
                scale: 1,
                offsetX: 0,
                offsetY: 0,
                fill: new FillStyle({
                    color: 'rgba(0, 0, 0, 1)'
                })
            })
        })
    }

    constructor(
        @Inject(NGXLogger) private $log: NGXLogger,
    ) {
        //
    }

    public ngOnInit(): void {
        //
        if (this.mapCtrl && this.mapCtrl.integrateLandsatProcIndConexData) {
            this.integrateLandsatProcIndConexData = this.mapCtrl.integrateLandsatProcIndConexData;
            this.mapCtrl.integrateLandsatProcIndConexData.funcInit = this.initIntegrateLandsatProcIndConexTool;
            this.mapCtrl.integrateLandsatProcIndConexData.funcClear = this.clearIntegrateLandsatProcIndConexTool;

        }
    }

    public ngOnChanges(changes: SimpleChanges) {
        //
    }

    public changeNewExistsTab(index){
        if (index === "0"){
            if (this.integrateLandsatProcIndConexData.activeNewTab0){
               // this.integrateLandsatProcIndConexData.activeNewTab0 = false
            } else {
                this.integrateLandsatProcIndConexData.activeExistsTab1 = false;
                this.integrateLandsatProcIndConexData.activeNewTab0 = true;
                this.integrateLandsatProcIndConexData.activeTab = 0;
                this.initNewProcIndConexData();
            }
            
        } else if (index === "1"){
            if (this.integrateLandsatProcIndConexData.activeExistsTab1){
               // this.integrateLandsatDownloadData.activeExistsTab1 = false
            } else {
                
                this.integrateLandsatProcIndConexData.activeExistsTab1 = true;
                this.integrateLandsatProcIndConexData.activeNewTab0 = false;
                this.integrateLandsatProcIndConexData.activeTab = 1
                this.initExistingProcIndConexDate(null);
            }
        }
    }

    public initIntegrateLandsatProcIndConexTool = () => {
        this.addSelectLayer();
        this.integrateLandsatProcIndConexData.activeExistsTab1 = true;
        this.integrateLandsatProcIndConexData.activeNewTab0 = false;
        this.integrateLandsatProcIndConexData.activeTab = 1;
        this.initExistingProcIndConexDate(null);
    }

    public clearIntegrateLandsatProcIndConexTool = () => {
        this.removeSelectLayer();
    }

    private initNewProcIndConexData(){
        this.integrateLandsatProcIndConexData.currentProcesare = {
            id: null,
            denumire: "",
            descriere: "",
            dataIndiceBaza: null,
            idIndiceBaza: 1,
            idIndiceRezultat: 4,
            dataIndiceReferinta: null,
            idIndiceReferinta: 3,
            idIndiceConex: 2,
            status: "init",
            mesajStatus: "",
            actiune: null,
            progresStatus: 0,
            progresDetalii: "",
            codIndiceBaza: "",
            codIndiceConex: "",
            codIndiceReferinta: "",
            unitatiAdministrativeProcesare: []
        }
        this.integrateLandsatProcIndConexData.selectLayer.getSource().clear();
        //
        this.integrateLandsatProcIndConexData.errorMessage = "";
        this.integrateLandsatProcIndConexData.loadingSearchProductsActive = true;
        this.mapCtrl.integrateLandsatDataService.getUnitatiAdministrativeActive()
            .then((data)=>{
                this.integrateLandsatProcIndConexData.availableUnitatiAdministrative = data;
                return true;
            }).catch((reason)=>{
                this.integrateLandsatProcIndConexData.errorMessage = "Eroare interogare unitati administrative";
                this.$log.error("Eroare interogare unitati administrative: ", getExMessage(reason));
            })
            .then((data)=>{
                return this.mapCtrl.integrateLandsatDataService.getIndiciConex();
            })
            .then((data)=>{
                this.integrateLandsatProcIndConexData.availableIndiciConex = data;
                this.asignareInfoIndici();
                this.activeazaStratReferinta(this.integrateLandsatProcIndConexData.indiceBaza.stratAsociat);
                this.activeazaStratReferinta(this.integrateLandsatProcIndConexData.indiceReferinta.stratAsociat);
       
            })
            .catch((reason)=>{
                this.integrateLandsatProcIndConexData.errorMessage = "Eroare interogare info indici conex";
                this.$log.error("Eroare interogare info indici: ", getExMessage(reason));
            })
            .then((data)=>{
                return this.mapCtrl.integrateLandsatDataService.getAlgoritmiIndiceConex();
            })
            .then((data)=>{
                this.integrateLandsatProcIndConexData.availableAlgoritmiIC = data;
                return true;
            }).catch((reason)=>{
                this.integrateLandsatProcIndConexData.errorMessage = "Eroare interogare algoritmi indice conex";
                this.$log.error("Eroare interogare ualgoritmi indice conex: ", getExMessage(reason));
            })
            .then((data)=>{
                return delay(2000).then(()=>{
                    this.integrateLandsatProcIndConexData.availableMomenteDSBaza 
                    = findDatesStratReferintaForLayer(this.integrateLandsatProcIndConexData.indiceBaza.stratAsociat, this.mapCtrl)
                    this.integrateLandsatProcIndConexData.availableMomenteDSReferinta 
                    = findDatesStratReferintaForLayer(this.integrateLandsatProcIndConexData.indiceReferinta.stratAsociat, this.mapCtrl)
                    this.dezactiveazaStratReferinta(this.integrateLandsatProcIndConexData.indiceBaza.stratAsociat);
                    this.dezactiveazaStratReferinta(this.integrateLandsatProcIndConexData.indiceReferinta.stratAsociat);
           
                })
            })
            .catch((reason)=>{
                this.integrateLandsatProcIndConexData.errorMessage = "Eroare interogare date";
                this.$log.error("Eroare interogare date: ", getExMessage(reason));
            })
            .finally(()=>{
                this.integrateLandsatProcIndConexData.loadingSearchProductsActive = false;
            })
    }

    private asignareInfoIndici(){
        //indice de baza
        let rezInd = this.integrateLandsatProcIndConexData.availableIndiciConex
        .filter((item)=>item.id === this.integrateLandsatProcIndConexData.currentProcesare.idIndiceBaza)
        if (rezInd && rezInd.length > 0){
            this.integrateLandsatProcIndConexData.indiceBaza = {
                id: rezInd[0].id,
                codIndice: rezInd[0].codIndice,
                sursaDate: rezInd[0].sursaDate,
                stratAsociat: null
            }
        }
        findStratAsociatToIndice(this.integrateLandsatProcIndConexData.indiceBaza as any, this.mapCtrl, "integrateIndConex")
        //indice rezultat
        let rezIndr = this.integrateLandsatProcIndConexData.availableIndiciConex
        .filter((item)=>item.id === this.integrateLandsatProcIndConexData.currentProcesare.idIndiceRezultat)
        if (rezIndr && rezIndr.length > 0){
            this.integrateLandsatProcIndConexData.indiceRezultat = {
                id: rezIndr[0].id,
                codIndice: rezIndr[0].codIndice,
                sursaDate: rezIndr[0].sursaDate,
                stratAsociat: null
            }
        }
        
        findStratAsociatToIndice(this.integrateLandsatProcIndConexData.indiceRezultat as any, this.mapCtrl, "integrateIndConex");
        //indice referinta
        let rezInde = this.integrateLandsatProcIndConexData.availableIndiciConex
        .filter((item)=>item.id === this.integrateLandsatProcIndConexData.currentProcesare.idIndiceReferinta)
        if (rezInde && rezInde.length > 0){
            this.integrateLandsatProcIndConexData.indiceReferinta = {
                id: rezInde[0].id,
                codIndice: rezInde[0].codIndice,
                sursaDate: rezInde[0].sursaDate,
                stratAsociat: null
            }
        }
        findStratAsociatToIndice(this.integrateLandsatProcIndConexData.indiceReferinta as any, this.mapCtrl, "integrateIndConex")
        //indice conex
        let rezIndc = this.integrateLandsatProcIndConexData.availableIndiciConex
        .filter((item)=>item.id === this.integrateLandsatProcIndConexData.currentProcesare.idIndiceConex)
        if (rezIndc && rezIndc.length > 0){
            this.integrateLandsatProcIndConexData.indiceConex = {
                id: rezIndc[0].id,
                codIndice: rezIndc[0].codIndice,
                sursaDate: rezIndc[0].sursaDate,
                stratAsociat: null
            }
        }
        findStratAsociatToIndice(this.integrateLandsatProcIndConexData.indiceConex as any, this.mapCtrl, "integrateIndConex");
    }

    private activeazaStratReferinta(layer: ILayer) {
        let strRef = findStratReferintaForLayer(layer, this.mapCtrl);
        if (strRef && strRef.visible === false) {
            strRef.visible = true;
            strRef.internalLayer.setVisible(strRef.visible);
            strRef.internalLayer.changed();
        }
    }
    private dezactiveazaStratReferinta(layer: ILayer) {
        let strRef = findStratReferintaForLayer(layer, this.mapCtrl);
        if (strRef && strRef.visible === true) {
            strRef.visible = false;
            strRef.internalLayer.setVisible(strRef.visible);
           // strRef.internalLayer.changed();
        }
    }

    private initExistingProcIndConexDate(idPreselected: any){
        this.integrateLandsatProcIndConexData.currentProcesare = {
            id: null,
            denumire: "",
            descriere: "",
            dataIndiceBaza: null,
            idIndiceBaza: 1,
            idIndiceRezultat: 4,
            dataIndiceReferinta: null,
            idIndiceReferinta: 3,
            idIndiceConex: 2,
            status: "init",
            mesajStatus: "",
            actiune: null,
            progresStatus: 0,
            progresDetalii: "",
            codIndiceBaza: "",
            codIndiceConex: "",
            codIndiceReferinta: "",
            unitatiAdministrativeProcesare: []
        }
        this.integrateLandsatProcIndConexData.selectedProcesare = null;
        //
        this.integrateLandsatProcIndConexData.errorMessage = "";
        this.integrateLandsatProcIndConexData.loadingSearchProductsActive = true;
        this.mapCtrl.integrateLandsatDataService.getUnitatiAdministrativeActive()
            .then((data)=>{
                this.integrateLandsatProcIndConexData.availableUnitatiAdministrative = data;
                return true;
            }).catch((reason)=>{
                this.integrateLandsatProcIndConexData.errorMessage = "Eroare interogare unitati administrative";
                this.$log.error("Eroare interogare unitati administrative: ", getExMessage(reason));
            })
            .then((data)=>{
                return this.mapCtrl.integrateLandsatDataService.getIndiciConex();
            })
            .then((data)=>{
                this.integrateLandsatProcIndConexData.availableIndiciConex = data;
            })
            .catch((reason)=>{
                this.integrateLandsatProcIndConexData.errorMessage = "Eroare interogare info indici conex";
                this.$log.error("Eroare interogare info indici: ", getExMessage(reason));
            })
            .then((data)=>{
                return this.mapCtrl.integrateLandsatDataService.getProcesariIndiceConex(null);
            })
            .then((data)=>{
                this.integrateLandsatProcIndConexData.availableProcesari = data;
                return true;
            })
            .catch((reason)=>{
                this.integrateLandsatProcIndConexData.errorMessage = "Eroare interogare procesari existente";
                this.$log.error("Eroare interogare procesari existente: ", getExMessage(reason));
            })
            .then((state) => {
                if (state as any === true && typeof idPreselected === "number") {
                    let tmpPres = this.integrateLandsatProcIndConexData.availableProcesari.filter((item) => item.id === idPreselected);
                    if (tmpPres && tmpPres.length > 0) {
                        this.integrateLandsatProcIndConexData.selectedProcesare = tmpPres[0];
                        this.onChangeProcesare();
                    }
                }
            })
            .finally(()=>{
                this.integrateLandsatProcIndConexData.loadingSearchProductsActive = false;
            })
    }

    public onChangeProcesare(){
        if (this.integrateLandsatProcIndConexData.selectedProcesare == null) {
            return;
        }
        this.integrateLandsatProcIndConexData.currentProcesare.unitatiAdministrativeProcesare.length = 0;
        this.integrateLandsatProcIndConexData.selectLayer.getSource().clear();
        this.integrateLandsatProcIndConexData.errorMessage = "";
        this.integrateLandsatProcIndConexData.loadingActive = true;
        this.mapCtrl.integrateLandsatDataService.getProcesareIndiceConex(this.integrateLandsatProcIndConexData.selectedProcesare.id, true)
            .then((data) => {
                this.integrateLandsatProcIndConexData.currentProcesare = data;
                //
                this.asignareInfoIndici();
                //
                setMomentOnTileLayer(this.integrateLandsatProcIndConexData.indiceRezultat.stratAsociat, this.integrateLandsatProcIndConexData.currentProcesare.dataIndiceBaza as string);
                setMomentOnTileLayer(this.integrateLandsatProcIndConexData.indiceBaza.stratAsociat, this.integrateLandsatProcIndConexData.currentProcesare.dataIndiceBaza as string);
                setMomentOnTileLayer(this.integrateLandsatProcIndConexData.indiceConex.stratAsociat, this.integrateLandsatProcIndConexData.currentProcesare.dataIndiceReferinta as string);
                //
                this.integrateLandsatProcIndConexData.currentProcesare.unitatiAdministrativeProcesare.forEach((uitem)=>{
                    this.createZoneFeature(uitem);
                    this.integrateLandsatProcIndConexData.selectLayer.getSource().addFeature(uitem.feature);
                })
                //
            }).catch((reason) => {
                this.integrateLandsatProcIndConexData.errorMessage = "Eroare interogare procesare";
                this.$log.error("Eroare interogare Procesare: ", getExMessage(reason));
            }).finally(() => {
                this.integrateLandsatProcIndConexData.loadingActive = false;
            })
    }

    public onChangeMomentDSBaza() {
        try {
            let tmpMoment = "1000-01-01";// no data
        if (this.integrateLandsatProcIndConexData.selectedMomentDSBaza && this.integrateLandsatProcIndConexData.selectedMomentDSBaza.length > 0) {
            tmpMoment = this.integrateLandsatProcIndConexData.selectedMomentDSBaza;
        }
        if (this.integrateLandsatProcIndConexData.indiceBaza.stratAsociat && this.integrateLandsatProcIndConexData.indiceBaza.stratAsociat.internalLayer) {
            setMomentOnTileLayer(this.integrateLandsatProcIndConexData.indiceBaza.stratAsociat, tmpMoment);
        }
        } catch (reason) {
            this.$log.error("Eroare setare timestamp: ", getExMessage(reason))
        }
        
    }
    public onClickPrevMomentDSBaza(){
        if (this.integrateLandsatProcIndConexData.selectedMomentDSBaza == null 
            && this.integrateLandsatProcIndConexData.availableMomenteDSBaza.length > 0){
                this.integrateLandsatProcIndConexData.selectedMomentDSBaza =   this.integrateLandsatProcIndConexData.availableMomenteDSBaza[this.integrateLandsatProcIndConexData.availableMomenteDSBaza.length - 1];
                this.onChangeMomentDSBaza();
                return;
        } else if (this.integrateLandsatProcIndConexData.selectedMomentDSBaza.length > 0 
            && this.integrateLandsatProcIndConexData.availableMomenteDSBaza.length > 0){
                let indCurrent = this.integrateLandsatProcIndConexData.availableMomenteDSBaza.indexOf(this.integrateLandsatProcIndConexData.selectedMomentDSBaza);
                if (indCurrent > 0 && indCurrent <= this.integrateLandsatProcIndConexData.availableMomenteDSBaza.length){
                    this.integrateLandsatProcIndConexData.selectedMomentDSBaza = this.integrateLandsatProcIndConexData.availableMomenteDSBaza[indCurrent - 1];
                    this.onChangeMomentDSBaza();
                }
            }
    }
    public onClickNextMomentDSBaza(){
        if (this.integrateLandsatProcIndConexData.selectedMomentDSBaza == null 
            && this.integrateLandsatProcIndConexData.availableMomenteDSBaza.length > 0){
                this.integrateLandsatProcIndConexData.selectedMomentDSBaza =  this.integrateLandsatProcIndConexData.availableMomenteDSBaza[0];
                this.onChangeMomentDSBaza();
                return;
        } else if (this.integrateLandsatProcIndConexData.selectedMomentDSBaza.length > 0 
            && this.integrateLandsatProcIndConexData.availableMomenteDSBaza.length > 0){
                let indCurrent = this.integrateLandsatProcIndConexData.availableMomenteDSBaza.indexOf(this.integrateLandsatProcIndConexData.selectedMomentDSBaza);
                if (indCurrent >= 0 && indCurrent < this.integrateLandsatProcIndConexData.availableMomenteDSBaza.length - 1){
                    this.integrateLandsatProcIndConexData.selectedMomentDSBaza = this.integrateLandsatProcIndConexData.availableMomenteDSBaza[indCurrent + 1];
                    this.onChangeMomentDSBaza();
                }
            }
    }

    public onChangeMomentDSReferinta(){
        try {
            let tmpMoment = "1000-01-01";// no data
        if (this.integrateLandsatProcIndConexData.selectedMomentDSReferinta && this.integrateLandsatProcIndConexData.selectedMomentDSReferinta.length > 0) {
            tmpMoment = this.integrateLandsatProcIndConexData.selectedMomentDSReferinta;
        }
        if (this.integrateLandsatProcIndConexData.indiceReferinta.stratAsociat && this.integrateLandsatProcIndConexData.indiceReferinta.stratAsociat.internalLayer) {
            setMomentOnTileLayer(this.integrateLandsatProcIndConexData.indiceReferinta.stratAsociat, tmpMoment);
        }
        } catch (reason) {
            this.$log.error("Eroare setare timestamp: ", getExMessage(reason))
        }
    }
    public onClickPrevMomentDSReferinta(){
        if (this.integrateLandsatProcIndConexData.selectedMomentDSReferinta == null 
            && this.integrateLandsatProcIndConexData.availableMomenteDSReferinta.length > 0){
                this.integrateLandsatProcIndConexData.selectedMomentDSReferinta =   this.integrateLandsatProcIndConexData.availableMomenteDSReferinta[this.integrateLandsatProcIndConexData.availableMomenteDSReferinta.length - 1];
                this.onChangeMomentDSReferinta();
                return;
        } else if (this.integrateLandsatProcIndConexData.selectedMomentDSReferinta.length > 0 
            && this.integrateLandsatProcIndConexData.availableMomenteDSReferinta.length > 0){
                let indCurrent = this.integrateLandsatProcIndConexData.availableMomenteDSReferinta.indexOf(this.integrateLandsatProcIndConexData.selectedMomentDSReferinta);
                if (indCurrent > 0 && indCurrent <= this.integrateLandsatProcIndConexData.availableMomenteDSReferinta.length){
                    this.integrateLandsatProcIndConexData.selectedMomentDSReferinta = this.integrateLandsatProcIndConexData.availableMomenteDSReferinta[indCurrent - 1];
                    this.onChangeMomentDSReferinta();
                }
            }
    }
    public onClickNextMomentDSReferinta(){
        if (this.integrateLandsatProcIndConexData.selectedMomentDSReferinta == null 
            && this.integrateLandsatProcIndConexData.availableMomenteDSReferinta.length > 0){
                this.integrateLandsatProcIndConexData.selectedMomentDSReferinta =  this.integrateLandsatProcIndConexData.availableMomenteDSReferinta[0];
                this.onChangeMomentDSReferinta();
                return;
        } else if (this.integrateLandsatProcIndConexData.selectedMomentDSReferinta.length > 0 
            && this.integrateLandsatProcIndConexData.availableMomenteDSReferinta.length > 0){
                let indCurrent = this.integrateLandsatProcIndConexData.availableMomenteDSReferinta.indexOf(this.integrateLandsatProcIndConexData.selectedMomentDSReferinta);
                if (indCurrent >= 0 && indCurrent < this.integrateLandsatProcIndConexData.availableMomenteDSReferinta.length - 1){
                    this.integrateLandsatProcIndConexData.selectedMomentDSReferinta = this.integrateLandsatProcIndConexData.availableMomenteDSReferinta[indCurrent + 1];
                    this.onChangeMomentDSReferinta();
                }
            }
    }
    public onClickClose() {
        this.clearIntegrateLandsatProcIndConexTool();
        this.mapCtrl.integrateLandsatProcIndConexButtonStateOn = false;
    }

    public onAddUnitateAdministrativa(){
        try {
            if (this.integrateLandsatProcIndConexData.selectedUnitateAdministrativa) {
                //check if exists
                let resultExists = this.integrateLandsatProcIndConexData.currentProcesare.unitatiAdministrativeProcesare
                    .filter(fitem => fitem.idUnitateAdministrativa === this.integrateLandsatProcIndConexData.selectedUnitateAdministrativa.id);
                if (resultExists && resultExists.length > 0) {
                    this.$log.error("unitatea administrativa exista deja in lista");
                    return;
                }
                let tmpUat: IUnitateAdministrativaDescarcareIL = {
                    idUnitateAdministrativa: this.integrateLandsatProcIndConexData.selectedUnitateAdministrativa.id,
                    denumire: this.integrateLandsatProcIndConexData.selectedUnitateAdministrativa.denumire,
                    geom: this.integrateLandsatProcIndConexData.selectedUnitateAdministrativa.geom
                }
                //
                this.createZoneFeature(tmpUat);
                this.integrateLandsatProcIndConexData.selectLayer.getSource().addFeature(tmpUat.feature);
                //
                this.integrateLandsatProcIndConexData.currentProcesare.unitatiAdministrativeProcesare.push(tmpUat);
            }
        } catch (error) {
            this.$log.error("eroare la adaugare unitate administrativa");
        }
    }

    public onAddAllUnitateAdministrativa(){
        try {
            
            this.integrateLandsatProcIndConexData.currentProcesare.unitatiAdministrativeProcesare.length = 0;
            this.integrateLandsatProcIndConexData.selectLayer.getSource().clear();
            //
            this.integrateLandsatProcIndConexData.availableUnitatiAdministrative.forEach((itemunit)=>{
                let tmpUat: IUnitateAdministrativaDescarcareIL = {
                    idUnitateAdministrativa: itemunit.id,
                    denumire: itemunit.denumire,
                    geom: itemunit.geom
                }
                //
                this.createZoneFeature(tmpUat);
                this.integrateLandsatProcIndConexData.selectLayer.getSource().addFeature(tmpUat.feature);
                //
                this.integrateLandsatProcIndConexData.currentProcesare.unitatiAdministrativeProcesare.push(tmpUat);
            })
        } catch (error) {
            this.$log.error("eroare la adaugare unitate administrativa");
        }
    }

    public onRemoveUnitateAdministrativa(uat: IUnitateAdministrativaDescarcareIL) {
        try {
            if (uat) {
                
                let iduat = this.integrateLandsatProcIndConexData.currentProcesare.unitatiAdministrativeProcesare
                    .indexOf(uat);
                if (iduat >= 0) {
                    this.integrateLandsatProcIndConexData.selectLayer.getSource().removeFeature(uat.feature);
                    this.integrateLandsatProcIndConexData.currentProcesare.unitatiAdministrativeProcesare.splice(iduat, 1);

                }
            }
        } catch (error) {
            this.$log.error("eroare la stergere zona uat");
        }
    }
    private createZoneFeature(zone: IUnitateAdministrativaDescarcareIL) {
        try {
            let tmpgeom: string | Polygon = zone.geom;
            if (typeof tmpgeom === "string") {
                tmpgeom = createGeomFromWKT(tmpgeom, "EPSG:4326", this.mapCtrl.mapConfig.projection);
            }
            zone.feature = new Feature(tmpgeom as any);
            zone.feature.set("utilizare", zone.idUnitateAdministrativa);
            zone.feature.setStyle([
                this.zoneStyle[0],
                this.zoneStyle[1],
                this.zoneText(zone.denumire)
            ])
        } catch (error) {
            this.$log.error("eroare creare locatie zona");
        }
    }


    //
    public addSelectLayer(){
        this.integrateLandsatProcIndConexData.selectLayer = new VectorLayer<any>({
            source: new VectorSource({ wrapX: false })
        });
        this.mapCtrl.map.addLayer(this.integrateLandsatProcIndConexData.selectLayer);
    }

    public removeSelectLayer(){
        if (this.integrateLandsatProcIndConexData.selectLayer) {
            this.mapCtrl.map.removeLayer(this.integrateLandsatProcIndConexData.selectLayer);
            this.integrateLandsatProcIndConexData.selectLayer = null;
        }
    }

    public showHideIndiceLayer(indice){
        this.mapCtrl.mapMenuLayers.showHideLayer(null, indice.stratAsociat)
    }

    public canContinueProcesare(){

    }

    public onStartProcesare(integrateProcForm){
        this.integrateLandsatProcIndConexData.errorMessage = "";
        try {
            if (this.integrateLandsatProcIndConexData.currentProcesare.denumire == null
                || this.integrateLandsatProcIndConexData.currentProcesare.denumire.length === 0){
                    throw Error("Nu este specificată denumirea")
                }
            if (this.integrateLandsatProcIndConexData.selectedMomentDSBaza == null) {
                throw Error("Nu este selectat Moment date satelitare baza");
            }
            this.integrateLandsatProcIndConexData.currentProcesare.dataIndiceBaza =
                this.integrateLandsatProcIndConexData.selectedMomentDSBaza;
            if (this.integrateLandsatProcIndConexData.selectedMomentDSReferinta == null) {
                throw Error("Nu este selectat Moment date satelitare referinta");
            }
            this.integrateLandsatProcIndConexData.currentProcesare.dataIndiceReferinta =
                this.integrateLandsatProcIndConexData.selectedMomentDSReferinta;
            if (this.integrateLandsatProcIndConexData.currentProcesare.unitatiAdministrativeProcesare == null
            || this.integrateLandsatProcIndConexData.currentProcesare.unitatiAdministrativeProcesare.length === 0){
                throw Error("Nu sunt selectate unitati administrative");
            }
            if (this.integrateLandsatProcIndConexData.selectedAlgoritmIC == null) {
                throw Error("Nu este selectat Algoritm procesare");
            }
            this.integrateLandsatProcIndConexData.currentProcesare.idAlgoritmIC = this.integrateLandsatProcIndConexData.selectedAlgoritmIC.id;
            let tmpProcesare: IProcesareIndiceConex = {
                id: null,
                denumire: this.integrateLandsatProcIndConexData.currentProcesare.denumire,
                descriere: this.integrateLandsatProcIndConexData.currentProcesare.descriere,
                dataIndiceBaza: this.integrateLandsatProcIndConexData.currentProcesare.dataIndiceBaza,
                idIndiceBaza: this.integrateLandsatProcIndConexData.currentProcesare.idIndiceBaza,
                idIndiceRezultat: this.integrateLandsatProcIndConexData.currentProcesare.idIndiceRezultat,
                dataIndiceReferinta: this.integrateLandsatProcIndConexData.currentProcesare.dataIndiceReferinta,
                idIndiceReferinta: this.integrateLandsatProcIndConexData.currentProcesare.idIndiceReferinta,
                idIndiceConex: this.integrateLandsatProcIndConexData.currentProcesare.idIndiceConex,
                idAlgoritmIC: this.integrateLandsatProcIndConexData.currentProcesare.idAlgoritmIC,
                status: this.integrateLandsatProcIndConexData.currentProcesare.status,
                mesajStatus: "",
                actiune: null,
                progresStatus: 0,
                progresDetalii: "",
                unitatiAdministrativeProcesare: []
            }
            this.integrateLandsatProcIndConexData.currentProcesare.unitatiAdministrativeProcesare.forEach((item)=>{
                let tmpItem: any = {idUnitateAdministrativa: item.idUnitateAdministrativa}
                tmpProcesare.unitatiAdministrativeProcesare.push(tmpItem);
            })
            //
            this.mapCtrl.integrateLandsatDataService.setSalvareProcesareIndiciConex(tmpProcesare)
            .then((result) => {
                if (typeof result === "number") {
                    return result;
                }
                return null;
            }).catch((reason) => {
                this.integrateLandsatProcIndConexData.errorMessage = "Eroare salvare procesare indice conex";
                this.$log.error("Erare salvare procesare: ", getExMessage(reason));
            }).finally(() => {
                //
                //clear
            }).then((idProcesare) => {
                if (typeof idProcesare === "number") {
                    this.integrateLandsatProcIndConexData.activeNewTab0 = false;
                    this.integrateLandsatProcIndConexData.activeExistsTab1 = true;
                    this.integrateLandsatProcIndConexData.activeTab = 1;
                    this.initExistingProcIndConexDate(idProcesare as any);
                }
            }).catch((reason) => {
                this.$log.error("Eroare la schimbare tab spre existente: ", getExMessage(reason));
            })



            } catch (reason) {
                this.$log.error("Eroare procesare indice:", getExMessage(reason));
                this.integrateLandsatProcIndConexData.errorMessage = "Eroare procesare indice: ", getExMessage(reason);
                this.integrateLandsatProcIndConexData.loadingActive = false;
                return;
            }
    }

    public onDownloadRaportIndiceInfo(){
        try {
            window.open("/integrate-landsat/indice-conex-raport/" + this.integrateLandsatProcIndConexData.currentProcesare.id, '_blank')
        } catch (error) {
            this.$log.error("Eroare descarcare raport indice conex: " + error.message || "");
        }
    }

    public canStopProcesare(){
        return false;
    }

    public onStopProcesare(){
        
    }
    public canRefreshProcesare(){
        return true;
    }
    public onRefreshProcesare(){
        this.onChangeProcesare();
   }
   public onChangeSelectedUnitateAdministrativa(){
    //
   }
   public onChangeAlgoritm(){
    // 
   }
}

