
import { ILayer } from "../../definitions";
import moment, { Moment } from "moment";

import VectorLayer from 'ol/layer/Vector';
import Draw from 'ol/interaction/Draw';
import Feature from 'ol/Feature';
import Polygon from 'ol/geom/Polygon';

export interface IIntegrateLandsatHotspotData {
    ctrlSearchIsCollapsed: boolean,

    loadingActive: boolean;
    doneActive: boolean;

    currentHotspot: IIntegrateHotspot;

    downloadAreaLayer: VectorLayer<any>;

    selectedMomentDateSatelitare: string;
    availableMomenteDateSatelitare: Array<string>;

    selectedImpermeabilitate: IInfoImpermeabilitate;
    availableImpermeabilitate: Array<IInfoImpermeabilitate>

    selectedPrag: IInfoPragHotspot;
    availablePraguri: Array<IInfoPragHotspot>;

    numarHotspoturi: number;
    selectedGeom: any;
    
    resultTileLayer: ILayer;
    resultTileLayer2: ILayer;
    resultLayer: VectorLayer<any>;
    selectLayer: VectorLayer<any>;
    selectDrawInteraction: Draw;
    selectButtonStateOn: boolean;

    optFormatDataPerioada: string;
    errorMessage: string;

    resIntervalRef: any;
    autorefreshInterval: number;
    autorefreshTimeout: number;
    //
    funcInit: any;
    funcClear: any;
    //
    indiciHotspot: Array<IIndiceHotspot>;

}

export interface IIntegrateHotspot{
    momentDateSatelitare: string; 
    impermeabilitate: any;
    pragvMin: number;
    pragmMin: number
    numarHotspoturi: number;
    geom?: any;
    idResult: any;
}

export interface IInfoImpermeabilitate {
    id: number;
    denumire: string;
    descriere: string;
    an: number;
}

export interface IInfoPragHotspot {
    id: number;
    denumire: string;
    descriere?: string;
    vMin: number;
    mMin: number;
}

export interface IDescarcareDateSatelitareIL {
    id?: number,
    denumire: string,
    descriere: string,
    sursaDate: string,
    geom: string | Polygon,
    startPerioada:  Moment | any,
    endPerioada:  Moment | any,
    momentCreare?: string,
    momentModificare?: string,
    status: string,
    mesajStatus: string,
    actiune?: string,
    produseDescarcare?: Array<IProdusDescarcareIL>,
    unitatiAdministrativeDescarcare?: Array<IUnitateAdministrativaDescarcareIL>
}

export interface IUnitateAdministrativaDescarcareIL {
    id?: number,
    idUnitateAdministrativa: number,
    denumire: string,
    geom: string,
    feature?: Feature
}

export interface IInfoUnitateAdministrativaIL {
    id?: number,
    denumire: string,
    geom: string
}

export interface IProdusDescarcareIL {
    id?: number,
    codGranula: string,
    sursaDate: string,
    numeProdus: string,
    dataProdus: string,
    uuid: string,
    geom: string,
    previzualizareS: string,
    previzualizareL: string,
    status?: string,
    mesajStatus?: string,
    feature?: Feature,
    nr?: number,
    selected?: boolean
}

export interface IInfoDescarcareDateSatelitareIL {
    id?: number,
    denumire: string,
    descriere: string,
    sursaDate: string,
    momentCreare?: string,
    status: string,
}
export interface IIndiceHotspot {
    id?: number,
    codIndice: string,
    idStratSursaIndici: number,
    stratAsociat?: ILayer
}

export interface IIntegrateLandsatDownloadData {
    ctrlSearchIsCollapsed: boolean;
    ctrlProductsIsCollapsed: boolean;
    ctrlDownloadDetailsIsColapsed: boolean;
    ctrlDownloadUnitatiAdministrativeIsColapsed: boolean;
    ctrlExistingDetalisIsColapsed: boolean;
    activeTab: number;
    activeNewTab0:boolean;
    activeExistsTab1: boolean;
    selectedDescarcare: IInfoDescarcareDateSatelitareIL;
    availableDescarcari: Array<IInfoDescarcareDateSatelitareIL>;
    optFormatDataPerioada: string;
    currentDescarcare: IDescarcareDateSatelitareIL|null;
    //
    selectedSursaDate: {tip: string, denumire: string};
    availableSursaDate: Array<{tip: string, denumire: string}>;
    //
    selectedUnitateAdministrativa: IInfoUnitateAdministrativaIL;
    availableUnitatiAdministrative: Array<IInfoUnitateAdministrativaIL>;
    //
    productsLayer: VectorLayer<any>;
    selectLayer: VectorLayer<any>;
    selectDrawInteraction: Draw;
    selectButtonStateOn: boolean;
    loadingSearchProductsActive: boolean;
    errorMessage: string;
    //
    funcInit: any;
    funcClear: any;

}

export const tipSursaDateClasificareIL = {
    sentinel_1: "landsat-5",
    sentinel_2: "landsat-8"
};

export const listaSursaDateClasificareIL: Array<{tip: string, denumire: string}> = [
    {tip: "landsat-5", denumire: "Landsat 5"},
    {tip: "landsat-8", denumire: "Landsat 8"}
];

export interface IIntegrateLandsatProcesareIndiceConexData{
    ctrlSearchIsCollapsed: boolean,
    ctrlUnitatiAdministrativeIsColapsed: boolean,

    activeTab: number;
    activeNewTab0: boolean;
    activeExistsTab1: boolean;

    activeDataTab: number;

    loadingActive: boolean;

    selectedProcesare: IInfoProcesareIndiceConex;
    availableProcesari: Array<IInfoProcesareIndiceConex>;

    currentProcesare: IProcesareIndiceConex;

    availableMomenteDSBaza: Array<any>;
    selectedMomentDSBaza: any;
    indiceBaza: IIndiceConex;
    indiceRezultat: IIndiceConex;

    availableMomenteDSReferinta: Array<any>;
    selectedMomentDSReferinta: any;
    indiceReferinta: IIndiceConex;
    indiceConex: IIndiceConex;

    selectedUnitateAdministrativa: IInfoUnitateAdministrativaIL;
    availableUnitatiAdministrative: Array<IInfoUnitateAdministrativaIL>;
    //
    availableIndiciConex : Array<IIndiceConex>;
    //
    selectedAlgoritmIC: IInfAlgoritmIC;
    availableAlgoritmiIC: Array<IInfAlgoritmIC>;
    //
    productsLayer: VectorLayer<any>;
    selectLayer: VectorLayer<any>;
    selectDrawInteraction: Draw;
    selectButtonStateOn: boolean;
    loadingSearchProductsActive: boolean;
    errorMessage: string;
    //
    funcInit: any;
    funcClear: any;

}

export interface IProcesareIndiceConex {
    id: 1,
    denumire: string,
    descriere: string,
    dataIndiceBaza: Moment | string,
    idIndiceBaza: number,
    idIndiceRezultat: number,
    dataIndiceReferinta: Moment | string,
    idIndiceReferinta: number,
    idIndiceConex: number,
    status: string,
    mesajStatus: string,
    actiune: string,
    progresStatus: number,
    progresDetalii: string,
    codIndiceBaza?: string,
    codIndiceConex?: string,
    codIndiceReferinta?: string,
    idAlgoritmIC?: number,
    denumireAlogritmIC?:string, 
    unitatiAdministrativeProcesare: Array<any>
}

export interface IUnitateAdministrativaProcesare {
    id?: number,
    idUnitateAdministrativa: number,
    denumire: string,
    geom: string,
    feature?: Feature
    status?: string,
    mesajStatus?: string,
}


export interface IInfoProcesareIndiceConex {
    id?: number,
    denumire: string,
    descriere: string,
    momentCreare?: string,
    status: string,
}

export interface IIndiceConex {
    id?: number,
    codIndice: string,
    sursaDate: string,
    idStratSursaIsundici?: number,
    stratAsociat?: ILayer
}

export interface IInfAlgoritmIC {
    id: number,
    nume: string,
    descriere: string,
    default: boolean
}

export interface IIntegrateLandsatProcesareDateSezoniereData{
    ctrlSearchIsCollapsed: boolean,
    ctrlUnitatiAdministrativeIsColapsed: boolean,

    activeTab: number;
    activeNewTab0: boolean;
    activeExistsTab1: boolean;

    activeDataTab: number;

    loadingActive: boolean;

    selectedProcesare: IInfoProcesareIndiceConex;
    availableProcesari: Array<IInfoProcesareIndiceConex>;

    currentProcesare: IProcesareDateSezoniere;
    sezonierAnual: boolean;
    selectedUnitateAdministrativa: IInfoUnitateAdministrativaIL;
    availableUnitatiAdministrative: Array<IInfoUnitateAdministrativaIL>;

    selectedSezonDate: IInfSezonDate;
    availableSezonDate: Array<IInfSezonDate>;
    //
    productsLayer: VectorLayer<any>;
    selectLayer: VectorLayer<any>;
    selectDrawInteraction: Draw;
    selectButtonStateOn: boolean;
    //loadingSearchProductsActive: boolean;
    errorMessage: string;
    //
    funcInit: any;
    funcClear: any;

}

export interface IProcesareDateSezoniere {
    id: 1,
    denumire: string,
    descriere: string,
    an: number,
    sezon: number,
    denumireSezon?: string,
    status: string,
    mesajStatus: string,
    actiune: string,
    idUnitateAdministrativa?: number,
    unitatiAdministrativeProcesare?: Array<any>
}



export interface IInfoProcesareDateSezoniere {
    id?: number,
    denumire: string,
    descriere: string,
    momentCreare?: string,
    status: string,
}

export interface IInfSezonDate {
    id: number,
    denumire: string,
    descriere: string,
    default?: boolean
}