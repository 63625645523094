//NG+
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Compiler, ComponentFactoryResolver, Inject, Injectable, Injector } from "@angular/core";
import { LoginComponent } from "./auth-components/login.component";
import { UserMainComponent } from "./auth-components/user-main.component";

export interface IAuthDialogsServiceNg {
    showLoginDialog(): any;
    showRegisterDialog(): any;
    isLoginDialogOpen(): boolean;
}

@Injectable({
    providedIn: 'root',
  })
export class AuthDialogsServiceNg implements IAuthDialogsServiceNg {
    public LoginDialog: any = null;
    //
    public constructor (
    @Inject(NgbModal) private modalService,
    @Inject(ComponentFactoryResolver) private componentFactoryResolver: ComponentFactoryResolver,
    @Inject(Compiler) private compiler: Compiler,
    @Inject(Injector) private injector: Injector) {
        //
    }
    //
    public showLoginDialog(){
        const modalOpt = {backdrop: false }
        this.LoginDialog = this.modalService.open(LoginComponent, modalOpt);
        return this.LoginDialog.result;
    }
    //
    public showRegisterDialog(){

        const modalOpt = { backdrop: false, windowClass: 'modalUserMain', fullscreen: true }
        let hasFactory = true
        try { this.componentFactoryResolver.resolveComponentFactory(UserMainComponent); }
        catch (error) { hasFactory = false; }
        //
        return Promise.resolve(true)
        .then((result)=>{
            if (hasFactory === false){
                return import('./auth-components/user-main.module')
                .then((esiModule)=>{
                    return this.loadModuleComponent(this.compiler, this.injector, this.componentFactoryResolver, esiModule.UserMainModule, UserMainComponent);
                })
            } else {
                return Promise.resolve(true);
            }
        }).then((result)=>{
            const modalRef = this.modalService.open(UserMainComponent, modalOpt);
            return modalRef.result;
        })
    }
    //
    public isLoginDialogOpen(): boolean {
        let isOpen = false;
        if (this.LoginDialog) {
            isOpen = true;
        }
        return isOpen;
    }
    //
    loadModuleComponent(compiler, injector,  componentFactoryResolver, moduleClass, componentClass):Promise<any>{
        return compiler.compileModuleAsync(moduleClass)
           .then((moduleFactory)=>{
               const moduleRef = moduleFactory.create(injector);
               const componentFactory = moduleRef.componentFactoryResolver.resolveComponentFactory(componentClass);
               //const compRef = componentFactory.create(this.injector);
               // create component factory for DynamicComponent
               (componentFactoryResolver as any)._factories.set(componentClass, componentFactory);
               return Promise.resolve(true);
           })
   }
}