 
 
//
import { NGXLogger } from "ngx-logger";
import { authAs, authType, ISelectedFeatures, isFeatureTypeForCluster, authOpt, ILayer, IFeatureInfo, ISelectFeatureConnected } from "../definitions";
import { MapController } from "./map-controller";
import { getExMessage } from "./map-utils";


import BaseLayer from 'ol/layer/Base';
import Control from 'ol/control/Control';
import VectorLayer from 'ol/layer/Vector';
import Draw from 'ol/interaction/Draw';
import DragBox from 'ol/interaction/DragBox';
import ExtentInter from 'ol/interaction/Extent';
import Feature from 'ol/Feature';
import Collection from "ol/Collection";
import Polygon from 'ol/geom/Polygon';
import  MultiPolygon  from "ol/geom/MultiPolygon";
import HeatmapLayer from "ol/layer/Heatmap";
import * as olExtent from 'ol/extent';
import VectorSource from "ol/source/Vector";
import ClusterSource from 'ol/source/Cluster';
import GeoJSONFormater from "ol/format/GeoJSON";
import { platformModifierKeyOnly } from "ol/events/condition";

    export class MapCtrlSelectBox {
        public constructor(public mapCtrl: MapController, private $log : NGXLogger) {

        };

        public addBoxSelection() {

            this.mapCtrl.dragBox = new DragBox({
                condition: platformModifierKeyOnly
                //condition: ol.events.condition.always
            });

            this.mapCtrl.map.addInteraction(this.mapCtrl.dragBox);

            this.mapCtrl.dragBox.on('boxstart', this.onDragBoxStart);
            this.mapCtrl.dragBox.on('boxend', this.onDragBoxEnd);
        }

        public onDragBoxStart = (event) => {
            if (this.mapCtrl.selectButtonStateOn) {
                return false;
            }
            if (this.mapCtrl.selectionExtent) {
                this.mapCtrl.map.removeInteraction(this.mapCtrl.selectionExtent);
            }
            (this.mapCtrl.infoOverlay as any).hide();
            this.mapCtrl.selectedFeaturesOnLayers.clear();
        };

        public onDragBoxEnd = (event) => {
            if (this.mapCtrl.selectButtonStateOn) {
                return false;
            }
            let extent = this.mapCtrl.dragBox.getGeometry().getExtent();
            this.mapCtrl.selectionExtent = new ExtentInter({
                extent: extent
            });
            this.mapCtrl.map.addInteraction(this.mapCtrl.selectionExtent);
            this.mapCtrl.selectionExtent.setActive(false);
            this.getFeaturesForExtent(this.mapCtrl.selectionExtent.getExtent());
            //show list as popup
            if (this.mapCtrl.selectedFeaturesOnLayers.getLength() > 0) {
                this.sendMessageSelectedFeatures();
                if (!this.mapCtrl.userSettingsSrvs.isAuthForResource(authAs.info_multi_feature_hide, authType.object)) {
                    this.showPopupSelectedFeatures(event.coordinate);
                }
            }
        };

        public addSelectButton() {
            this.mapCtrl.selectButton = document.createElement('button');
            this.mapCtrl.selectButton.innerHTML = '<i class="fas fa-object-ungroup"></i>';
            this.mapCtrl.selectButton.title = 'activează/dezactivează desenare zonă dreptunghiulară pentru selecție';
            $(this.mapCtrl.selectButton).tooltip({placement: "right"});
            let element = document.createElement('div');
            element.className = 'select-button ol-unselectable ol-control';
            element.appendChild(this.mapCtrl.selectButton);

            this.mapCtrl.selectButtonCtrl = new Control({
                element: element
            });

            this.mapCtrl.selectButton.addEventListener('click', this.onClickSelectButton);
            this.mapCtrl.map.addControl(this.mapCtrl.selectButtonCtrl);
        }

        public onClickSelectButton = (event) => {
            if (!this.mapCtrl.selectButtonStateOn) {
                $(this.mapCtrl.selectButtonCtrl["element"]).addClass('select-button-on');
                this.addSelectLayerInteraction();
                this.mapCtrl.selectButtonStateOn = true;
                //remove dragbox and close popover
                if (this.mapCtrl.selectionExtent) {
                    this.mapCtrl.map.removeInteraction(this.mapCtrl.selectionExtent);
                }
                (this.mapCtrl.infoOverlay as any).hide();
            }
            else {
                if (this.mapCtrl.selectDrawInteraction.getActive()) {
                    this.mapCtrl.selectDrawInteraction.finishDrawing();
                }
                else {
                    $(this.mapCtrl.selectButtonCtrl["element"]).removeClass('select-button-on');
                    this.removeSelectLayerInteraction();
                    this.mapCtrl.selectButtonStateOn = false;
                }
            }

            //todo
        }

        public addSelectLayerInteraction() {
            this.mapCtrl.selectLayer = new VectorLayer<any>({
                source: new VectorSource({ wrapX: false })
            });
            this.mapCtrl.map.addLayer(this.mapCtrl.selectLayer);
            this.mapCtrl.selectDrawInteraction = new Draw({
                source: this.mapCtrl.selectLayer.getSource(),
                type: 'Polygon',// 'Circle',//'Polygon',
                //geometryFunction: Draw.createBox()
            });
            this.mapCtrl.map.addInteraction(this.mapCtrl.selectDrawInteraction);
            this.mapCtrl.selectDrawInteraction.on('drawend', this.onDrawSelectBoxEnd);
            this.mapCtrl.selectDrawInteraction.on('drawstart', this.onDrawSelectBoxStart);
        }

        public removeSelectLayerInteraction() {
            if (this.mapCtrl.selectLayer) {
                this.mapCtrl.map.removeLayer(this.mapCtrl.selectLayer);
                this.mapCtrl.selectLayer = null;
                (this.mapCtrl.infoOverlay as any).hide();
            }
            if (this.mapCtrl.selectDrawInteraction) {
                this.mapCtrl.map.removeInteraction(this.mapCtrl.selectDrawInteraction);
                this.mapCtrl.selectDrawInteraction = null;
            }
        }

        public onDrawSelectBoxStart = (event) => {
            this.mapCtrl.selectLayer.getSource().clear();
            this.mapCtrl.selectedFeaturesOnLayers.clear();
            (this.mapCtrl.infoOverlay as any).hide();
        }

        public onDrawSelectBoxEnd = (event) => {
            let extentFeature = event.feature as Feature;
            this.mapCtrl.selectDrawInteraction.setActive(false);
            // to do get extent list details
            if (extentFeature) {
                let extentPolygon = extentFeature.getGeometry() as Polygon;
                this.getFeaturesForPolygon(extentPolygon);
                //show list as popup
                if (this.mapCtrl.selectedFeaturesOnLayers.getLength() > 0) {
                    this.sendMessageSelectedFeatures();
                    if (!this.mapCtrl.userSettingsSrvs.isAuthForResource(authAs.info_multi_feature_hide, authType.object)) {
                        this.showPopupSelectedFeatures([extentPolygon.getExtent()[2], extentPolygon.getExtent()[3]]);
                    }

                }
            }
        }

        public getFeaturesForExtent(extent: olExtent.Extent) {
            this.mapCtrl.map.getLayers().forEach((litem: BaseLayer, index: number) => {
                if (litem instanceof VectorLayer && litem.getVisible()) {
                    let tmpSelectFeatureOnLayer: ISelectedFeatures =
                        { layer: litem, features: new Collection<Feature>() };
                    let vectorSource = litem.getSource() as VectorSource;
                    let appLayer = litem['appLayer'];
                    if (appLayer == null){
                        return null;
                    }
                    //for cluster
                    if (isFeatureTypeForCluster(appLayer.featureType)) {
                        vectorSource = (vectorSource as ClusterSource<any>).getSource();
                    }
                    vectorSource.forEachFeatureIntersectingExtent(extent, (feature: Feature) => {
                        if (!(MapController.searchFilterOut in feature)
                            || ((MapController.searchFilterOut in feature) && feature[MapController.searchFilterOut] === "false")) {
                            let st = feature.getStyleFunction();
                            tmpSelectFeatureOnLayer.features.push(feature);
                        }
                    });
                    if (tmpSelectFeatureOnLayer.features.getLength() > 0) {
                        this.mapCtrl.selectedFeaturesOnLayers.push(tmpSelectFeatureOnLayer);
                    }
                }
            });
        }

        public getFeaturesForPolygon(geometry: Polygon) {
            var geomWGS = geometry.clone().transform(this.mapCtrl.mapConfig.projection, 'EPSG:4326')
            var geojsonFormat = new GeoJSONFormater();
            var polySelectionGeoJson = geojsonFormat.writeGeometryObject(geomWGS);
            //var polyBuffer = turf.buffer(polySelectionGeoJson as any, 2, { units: 'miles' });
            //var geometryNew = geojsonFormat.readGeometry(polyBuffer);

            this.mapCtrl.map.getLayers().forEach((litem: BaseLayer, index: number) => {
                if ((litem instanceof VectorLayer || litem instanceof HeatmapLayer) && litem.getVisible()) {
                    let tmpSelectFeatureOnLayer: ISelectedFeatures = this.mapCtrl.mapOlFeatures.getLayerFeaturesForPolygonGeoJson(polySelectionGeoJson, litem);
                    //
                    if (tmpSelectFeatureOnLayer && tmpSelectFeatureOnLayer.features.getLength() > 0) {
                        this.mapCtrl.selectedFeaturesOnLayers.push(tmpSelectFeatureOnLayer);
                    }
                }
            });
        }
        //
       
        public sendMessageSelectedFeatures() {
            let hasMessageLayers = false;
            for (var i = 0; i < this.mapCtrl.selectedFeaturesOnLayers.getLength(); i++) {
                if (this.mapCtrl.userSettingsSrvs.isAuthForOption(authOpt.message_selected_features_info, this.mapCtrl.selectedFeaturesOnLayers.item(i).layer['appLayer']['name'], authType.layer)) {
                    hasMessageLayers = true;
                    this.mapCtrl.windowMessageService.sendSelectedFeatureListInfoByMessage(this.mapCtrl.selectedFeaturesOnLayers.item(i).features.getArray(),
                     this.mapCtrl.selectedFeaturesOnLayers.item(i).layer[MapController.appLayer] as any, "msg-send-selected-info", authOpt.message_selected_features_info);
                }
            }
            //todo 
        }

        public showPopupSelectedFeatures(coordinate: [number, number]) {

            if (this.mapCtrl.selectedFeaturesOnLayers.getLength() > 0) {
                let selectedInfo: string = this.buildGroupFeatureInfo(this.mapCtrl.selectedFeaturesOnLayers);
                if (selectedInfo == undefined) { selectedInfo = 'fara informatii' }
                let buttons = $("<div class='btn-group btn-group-justified'></div>")
                let details = $("<div class='btn btn-light ' id='infBtnDetailsFeatures' > Detalii </div>");
                let print = $("<div class='btn btn-light ' id='infBtnDetailsFeaturesPrint' > Raport </div>");
                if (this.mapCtrl.userSettingsSrvs.isAuthForResource(authAs.menu_multi_feature_details, authType.object)) {
                    $(buttons).append([details]);
                }
                if (this.mapCtrl.userSettingsSrvs.isAuthForResource(authAs.menu_multi_feature_report, authType.object)) {
                    $(buttons).append([print]);
                }
                let printSpatiuVerde = $("<div class='btn btn-light ' id='infBtnDetailsSpatiuVerdeFeatures' > Raport S.V.</div>");
                //
                for (var i = 0; i < this.mapCtrl.selectedFeaturesOnLayers.getLength(); i++) {
                    if (this.mapCtrl.userSettingsSrvs.isAuthForOption(authOpt.report_layer_feature_spatiuverde, this.mapCtrl.selectedFeaturesOnLayers.item(i).layer['appLayer']['name'], authType.layer)) {
                        $(buttons).append(printSpatiuVerde);
                        break;
                    }
                }

                //
                if (this.mapCtrl.infoOverlay != null) {
                    this.mapCtrl.infoOverlay.setPosition(coordinate);
                    (this.mapCtrl.infoOverlay as any).hide();
                    //
                    let title = $('<div style="min-width:300px"><span> Informatii </span></div>');
                    let closeBtn = $('<span id="infoPopupCloseBtn"  class="float-right" > X </span>');
                    title.append(closeBtn);
                    //
                    let content = $('<div> </div>');
                    let card = $('<div class="card info-popover"> </div>');
                    let header = $('<h5 class="card-header"></h5>');
                    header.append(title);
                    let body = $(`<div class="card-body p-2"> <div> ${selectedInfo} </div></div>`);
                    body.append(buttons);
                    card.append(header);
                    card.append(body);
                    content.append(card);
                    //
                    (this.mapCtrl.infoOverlay as any).show(coordinate, content.html());
                    //
                    let selectedFeaturesOnLayers = this.mapCtrl.selectedFeaturesOnLayers;
                    $('#infBtnDetailsFeatures').on("click", { selectedFeaturesOnLayers }, this.onClickDetailsSelectedFeatures);
                    $('#infoPopupCloseBtn').on("click", () => { 
                        (this.mapCtrl.infoOverlay as any).hide();
                     });
                    $('#infBtnDetailsFeaturesPrint').on("click", { selectedFeaturesOnLayers }, this.onClickPrintSelectedFeatures);
                    $('#infBtnDetailsSpatiuVerdeFeatures').on('click', { selectedFeaturesOnLayers, coordinate }, this.onClickPrintSelectedFeatureSpatiuVerde);
                    
                }
            }
        }

        public buildGroupFeatureInfo(featuresOnLayers: Collection<ISelectedFeatures>): string {
            var infoContent = "<div class='popupFeaturesListInfo'>";
            featuresOnLayers.forEach((lfItem) => {
                if (MapController.appLayer in lfItem.layer) {
                    let layerSettings = (lfItem.layer[MapController.appLayer] as ILayer);
                    let props: Array<IFeatureInfo> = layerSettings.infoColumns;
                    let tmpContent = "<div><p>Strat: " + layerSettings.name + " [" + lfItem.features.getLength() + "]</p>";
                    tmpContent += "<ul> ";
                    let displayInfo = false;
                    //get info for feature instead of id 
                    let sourceColumnName = this.mapCtrl.userSettingsSrvs.isAuthForOptionFullInfo(authOpt.selectbox_infopopup_display_propvalue, layerSettings.name, authType.layer);
                    if (sourceColumnName && "descriere" in sourceColumnName
                        && sourceColumnName.descriere !== null 
                        && sourceColumnName.descriere.length > 0) {
                        displayInfo = true;
                    } 
                    
                    lfItem.features.forEach((fitem) => {
                        if (displayInfo === true){
                            //display feature info instead of id
                            let info = fitem.get(sourceColumnName.descriere);
                            if(info == null || info === ""){
                                info = "-";
                            }
                            tmpContent += "<li> " + info + "</li>"
                        } else {
                            tmpContent += "<li> Id: " + fitem.getId() + "</li>"
                        }
                        
                    })
                    tmpContent += "</ul></div>";
                    infoContent += tmpContent;
                }
            });
            return infoContent + "</div>";
        }

        public onClickDetailsSelectedFeatures = (object: JQueryEventObject) => {
            this.mapCtrl.mapDialogs.showDetailsFeaturesInfoDialog(object.data["selectedFeaturesOnLayers"]);
        }

        public onClickPrintSelectedFeatures = (object: JQueryEventObject) => {
            this.mapCtrl.map.once('postrender', (event) => {
                //if coordinate found, set center of map for printing
                if (object.data["coordinate"]) {
                    this.mapCtrl.map.getView().setCenter(object.data["coordinate"] as any);
                    this.mapCtrl.map.renderSync();
                }
                //
                if (this.mapCtrl.mapImgUrl) {
                    URL.revokeObjectURL(this.mapCtrl.mapImgUrl);
                    this.mapCtrl.mapImgUrl = '';
                }
                
                var canvas = this.mapCanvasForImage();

                if ("toBlob" in canvas) {
                    canvas.toBlob((blob) => {
                        this.mapCtrl.mapImgUrl = URL.createObjectURL(blob);//canvas.msToBlob()
                        this.mapCtrl.mapDialogs.showPrintFeaturesInfoDialog(object.data["selectedFeaturesOnLayers"], this.mapCtrl.mapImgUrl);
                    });
                }
                else if ("msToBlob" in canvas) {
                    var blob =  (canvas as any).msToBlob()
                    this.mapCtrl.mapImgUrl = URL.createObjectURL(blob);//canvas.msToBlob()
                    this.mapCtrl.mapDialogs.showPrintFeaturesInfoDialog(object.data["selectedFeaturesOnLayers"], this.mapCtrl.mapImgUrl);

                }

            });
            this.mapCtrl.map.renderSync();

        }

        //Spatiu verde
        public onClickPrintSelectedFeatureSpatiuVerde = (object: JQueryEventObject) => {
            try {
                //extrage date
                let selectedFeaturesOnLayers: Array<ISelectedFeatures> = object.data['selectedFeaturesOnLayers'];
                var selectedSvFeaturesOnLayers: Array<ISelectedFeatures> = [];
                var selectedFeaturesConnectedOnLayers: Array<ISelectFeatureConnected> = [];
                selectedFeaturesOnLayers.forEach((layItem) => {
                    if (this.mapCtrl.userSettingsSrvs.isAuthForOption(authOpt.report_layer_feature_spatiuverde, layItem.layer['appLayer']['name'], authType.layer)) {
                        selectedSvFeaturesOnLayers.push(layItem);
                    }
                })

                //pentru fiecare strat cu spatii verzi care are locatii selectate
                selectedSvFeaturesOnLayers.forEach((selItem) => {
                    //pentru fiecare locatie selectata din spatii verzi
                    selItem.features.forEach((selFeature) => {
                        try {
                            let tmpFeatureConn: ISelectFeatureConnected = { layer: selItem.layer, feature: selFeature, connectedConstructii: [], connectedVegetatie: [] };

                            let geometry = tmpFeatureConn.feature.getGeometry();
                            if (geometry.getType() === "MultiPolygon") {
                                geometry = (geometry as MultiPolygon).getPolygon(0);
                            }
                            if (geometry.getType() !== "Polygon") {
                                throw new Error("geometria trebuie sa fie de tip poligon");
                            }
                            this.mapCtrl.map.getLayers().forEach((litem: BaseLayer, index: number) => {
                                try {
                                    if ((litem instanceof VectorLayer || litem instanceof HeatmapLayer) && litem.getVisible()) {
                                        //doar straturile marcate pentru a putea face parte din raport
                                        let sourceTypeConstructii = this.mapCtrl.userSettingsSrvs.isAuthForOption(authOpt.report_spatiuverde_source_layer_constructii, litem['appLayer']['name'], authType.layer);
                                        let sourceTypeVegetatie = this.mapCtrl.userSettingsSrvs.isAuthForOption(authOpt.report_spatiuverde_source_layer_vegetatie, litem['appLayer']['name'], authType.layer);
                                        if (sourceTypeConstructii && sourceTypeVegetatie) {
                                            this.$log.error("eroare stratul nu poate fi si vegetatie si constructii", litem['appLayer']['name']);
                                        } else if (sourceTypeConstructii || sourceTypeVegetatie) {
                                            let tmpSelectFeatureOnLayer: ISelectedFeatures = this.mapCtrl.mapOlFeatures.getLayerFeaturesForPolygon(geometry as any, litem);
                                            //
                                            if (tmpSelectFeatureOnLayer && tmpSelectFeatureOnLayer.features.getLength() > 0) {
                                                if (sourceTypeConstructii) {
                                                    tmpFeatureConn.connectedConstructii.push(tmpSelectFeatureOnLayer);
                                                } else if (sourceTypeVegetatie) {
                                                    tmpFeatureConn.connectedVegetatie.push(tmpSelectFeatureOnLayer);
                                                }
                                            }
                                        }
                                    }
                                } catch (reason) {
                                    this.$log.error("eroare selectie feature ", getExMessage(reason));
                                }
                            });
                            selectedFeaturesConnectedOnLayers.push(tmpFeatureConn);
                        } catch (reason) {
                            this.$log.error("eroare selectie layer feature ", getExMessage(reason));
                        }
                    });
                    //
                });
                //
                this.mapCtrl.map.once('postrender', (event) => {
                    //
                    //if coordinate found, set center of map for printing
                    if (object.data["coordinate"]) {
                        this.mapCtrl.map.getView().setCenter(object.data["coordinate"] as any);
                        this.mapCtrl.map.renderSync();
                    }
                    //
                    if (this.mapCtrl.mapImgUrl) {
                        URL.revokeObjectURL(this.mapCtrl.mapImgUrl);
                        this.mapCtrl.mapImgUrl = '';
                    }
                    var canvas = this.mapCanvasForImage();
                    //
                    var funcFromBlob = (blob) => {
                        this.mapCtrl.mapImgUrl = URL.createObjectURL(blob);//canvas.msToBlob()
                        //this.mapCtrl.mapDialogs.showPrintFeaturesInfoDialog(object.data["selectedFeaturesOnLayers"], this.mapCtrl.mapImgUrl);
                        this.mapCtrl.mapDialogs.showFisaSpatiuluiVerdeDialog(selectedFeaturesConnectedOnLayers, this.mapCtrl.mapImgUrl);
                    }
                    if ("toBlob" in canvas) {
                        canvas.toBlob(funcFromBlob);
                    }
                    else if ("msToBlob" in canvas) {
                        var blob = (canvas as any).msToBlob();
                        funcFromBlob(blob);
                    }

                });
                this.mapCtrl.map.renderSync();
            } catch (reason) {
                this.$log.error('eroare date raport spatiu verde', getExMessage(reason));
                return;
            }
        }

        mapCanvasForImage() {
            const mapCanvas = document.createElement('canvas');
            const size = this.mapCtrl.map.getSize();
            mapCanvas.width = size[0];
            mapCanvas.height = size[1];
            const mapContext = mapCanvas.getContext('2d');
            Array.prototype.forEach.call(
                this.mapCtrl.map.getViewport().querySelectorAll('.ol-layer canvas, canvas.ol-layer'),
                function (canvas) {
                    if (canvas.width > 0) {
                        const opacity =
                            canvas.parentNode.style.opacity || canvas.style.opacity;
                        mapContext.globalAlpha = opacity === '' ? 1 : Number(opacity);
                        let matrix;
                        const transform = canvas.style.transform;
                        if (transform) {
                            // Get the transform parameters from the style's transform matrix
                            matrix = transform
                                .match(/^matrix\(([^\(]*)\)$/)[1]
                                .split(',')
                                .map(Number);
                        } else {
                            matrix = [
                                parseFloat(canvas.style.width) / canvas.width,
                                0,
                                0,
                                parseFloat(canvas.style.height) / canvas.height,
                                0,
                                0,
                            ];
                        }
                        // Apply the transform to the export map context
                        CanvasRenderingContext2D.prototype.setTransform.apply(
                            mapContext,
                            matrix,
                        );
                        const backgroundColor = canvas.parentNode.style.backgroundColor;
                        if (backgroundColor) {
                            mapContext.fillStyle = backgroundColor;
                            mapContext.fillRect(0, 0, canvas.width, canvas.height);
                        }
                        mapContext.drawImage(canvas, 0, 0);
                    }
                },
            );
            //
            mapContext.globalAlpha = 1;
            mapContext.setTransform(1, 0, 0, 1, 0, 0);

            return mapCanvas;//.toDataURL();
           
        }

    }
