//NG+
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ILayer, } from "../definitions";
import { Inject, Injectable } from "@angular/core";
import { AddEditLayerComponent } from "./layer-components/add-edit-layer.component";



export interface ILayerDialogsServiceNg {
    showAddEditLayerDialog(category: string, layer: ILayer, isAddElseEdit: boolean): any;
}

@Injectable({
    providedIn: 'root',
  })
export class LayerDialogsServiceNg implements ILayerDialogsServiceNg {

    constructor(@Inject(NgbModal) private modalService) {

    }

    public showAddEditLayerDialog(category: string, layer: ILayer, isAddElseEdit: boolean){
        const modalOpt = {backdrop: false }
        const modalRef = this.modalService.open(AddEditLayerComponent, modalOpt);
		modalRef.componentInstance.data = {category:category, layer, isAddElseEdit: isAddElseEdit };
        return modalRef.result;
    }
}