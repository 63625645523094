//NG+
import { AppSettings } from "../app-settings";
import { UserSettingsService } from "./user-settings-service";

import { NGXLogger } from "ngx-logger";
import { HttpClient, HttpParams, HttpResponse } from "@angular/common/http";
import { layerTypeName } from "../definitions";
import { taskFileCF, uploadFileCF } from "../roles/settings-components/settings-definitions";
import { getExMessage, isArray, isObject } from "../map/map-utils";


export interface IDbStoreCadastru {
    setUploadCarteFunciara(loadFile: uploadFileCF): Promise<any>;
    getIstoricTaskCarteFunciara(pagina: number): Promise<{ maxPages: number, tasks: Array<taskFileCF> }>;
    getDocCarteFunciara(path: string): Promise<Blob>;
}

export class DbStoreCAdastru implements IDbStoreCadastru {

    constructor(
        private $http: HttpClient,
         private $log: NGXLogger, 
         private settingsService: UserSettingsService) { }

    public setUploadCarteFunciara(loadFile: uploadFileCF): Promise<taskFileCF> {
        let result: taskFileCF =
        {
            id: -1, landId: '', fisier: '', stare: '',
            status: '', time: '', fisier_pdf: '', fisier_xml: ''

        };
        //
        let formData = new FormData();
        formData.append("numarCadastral", loadFile.files.idCF);
        formData.append("cgxml", loadFile.files.fileXML);
        formData.append("pdf", loadFile.files.filePDF);
        //
        return this.$http.post(
            AppSettings.serverPath + '/cadastru/load-carte-funciara',
            formData,
            {
                headers: { /*'Content-Type': 'multipart/form-data'*/ },
                observe: "response"
            })
            .toPromise()
            .then((response) => {
                if (response.body && isObject(response.body)) {
                    let restsk = response.body as taskFileCF;
                    try {
                        result.id = restsk.id;
                        result.landId = restsk.landId;
                        result.fisier = restsk.fisier;
                        result.stare = restsk.stare;
                        result.status = restsk.status;
                        result.fisier_pdf = restsk.fisier_pdf;
                        result.fisier_xml = restsk.fisier_xml;
                    } catch (error) {
                        result.status = 'eroare';
                        result.stare = 'eroare extragere date task';
                        this.$log.error("eroare extragere date task");
                    }
                } else {
                    this.$log.error("eroare salvare strat");
                    throw new Error("Eroare format obiect json");
                }
                return result;
            }).catch((reason) => {
                result.status = "eroare";
                result.stare = " eroare interogare cadastru";
                if (reason.status && reason.statusText) {
                    result.stare = " status code: " + reason.status + " , text: " + reason.statusText;
                }
                return result;
            })
    }

    public getIstoricTaskCarteFunciara(pagina: number): Promise<{ maxPages: number, tasks: Array<taskFileCF> }> {
        return this.$http.get(
            AppSettings.serverPath + '/cadastru/tasks-history/' + pagina,
            {
                observe: "response"
            })
            .toPromise()
            .then((response) => {
                let result: { maxPages: number, tasks: Array<taskFileCF> } = { maxPages: 0, tasks: [] };
                if (response.body && isObject(response.body)
                    && response.body.hasOwnProperty('nrMaxPagini')
                    && response.body.hasOwnProperty('date')
                    && isArray(response.body['date'])) {
                    result.maxPages = response.body['nrMaxPagini'] || 0;
                    let resArray = response.body['date'] as Array<any>;
                    for (let index = 0; index < resArray.length; index++) {
                        let resItem: taskFileCF =
                        {
                            id: -1, time: '', landId: null, fisier: '',
                            stare: '', status: '', fisier_pdf: '', fisier_xml: ''
                        };
                        try {
                            const restsk = resArray[index];
                            //
                            resItem.id = restsk.id;
                            resItem.time = (restsk.stamp as string).replace('T', " ");
                            resItem.landId = restsk.land_id;
                            resItem.fisier = restsk.fisier;
                            resItem.stare = restsk.stare;
                            resItem.status = restsk.status;
                            resItem.fisier_pdf = restsk.fisier_pdf;
                            resItem.fisier_xml = restsk.fisier_xml;
                        } catch (error) {
                            resItem.status = 'eroare';
                            resItem.stare = 'eroare extragere date task';
                            this.$log.error("eroare extragere date task");
                        }
                        result.tasks.push(resItem);
                    }
                }
                return result;
            });
    }

    public getDocCarteFunciara(path: string): Promise<Blob> {
        return this.$http.get(
            AppSettings.serverPath + '/cadastru/document',
            {
                params: { path: path },
                responseType: 'arraybuffer',
                observe: "response"
            })
            .toPromise()
            .then((response: any) => {
                return new Blob([response.body], { type: 'application/pdf' });
            }).catch((reason) => {
                this.$log.error("Eroare interogare document carte funciara ", getExMessage(reason));
                return null;
            })
    }
}
