 
 
//
import { NGXLogger } from "ngx-logger";
import { featureTypeForVector, authOpt, authType, ILayer, featureType } from "../definitions";
import { MapController } from "./map-controller";
import { getExMessage } from "./map-utils";
//

import Control from 'ol/control/Control';
import VectorLayer from 'ol/layer/Vector';

    export class MapAutorefreshLayers {
        public resInterval: any;
        public constructor(public mapCtrl: MapController, private $log: NGXLogger) {
            this.configAutorefreshLayers();
        };
        public addAutorefreshButton() {
            this.mapCtrl.autorefreshButton = document.createElement('button');
            this.mapCtrl.autorefreshButton.innerHTML = '<i class="fas fa-redo-alt"></i>';
            this.mapCtrl.autorefreshButton.title = 'activează/dezactivează autorefresh';
            $(this.mapCtrl.autorefreshButton).tooltip({placement: "right"});
            let element = document.createElement('div');
            element.className = 'autorefresh-button ol-unselectable ol-control select-button-on';
            element.appendChild(this.mapCtrl.autorefreshButton);

            this.mapCtrl.autorefreshButtonCtrl = new Control({
                element: element
            });

            this.mapCtrl.autorefreshButton.addEventListener('click', this.onClickAutorefreshButton);
            this.mapCtrl.map.addControl(this.mapCtrl.autorefreshButtonCtrl);
            
        }

        public onClickAutorefreshButton = (event) => {
            if (!this.mapCtrl.autorefreshButtonStateOn) {
                $(this.mapCtrl.autorefreshButtonCtrl["element"]).removeClass('select-button-off');
                $(this.mapCtrl.autorefreshButtonCtrl["element"]).addClass('select-button-on');
               
                this.mapCtrl.autorefreshButtonStateOn = true;
            } else {
                $(this.mapCtrl.autorefreshButtonCtrl["element"]).removeClass('select-button-on');
                $(this.mapCtrl.autorefreshButtonCtrl["element"]).addClass('select-button-off');
                this.mapCtrl.autorefreshButtonStateOn = false;
            }
        }

        private configAutorefreshLayers(): void {
            let autorefreshLastStateOn = this.mapCtrl.autorefreshButtonStateOn;
            this.resInterval = setInterval(
                () => {
                    try {
                        let hasLayers = false;
                        if (this.mapCtrl.categories) {
                            for (let cat of this.mapCtrl.categories) {
                                for (let layer of cat.layers) {
                                    //autorefresh
                                    if (layer.visible && featureTypeForVector(layer.featureType)) {

                                        if (layer.autorefreshInterval == null) {
                                            //get the interval from layer options
                                            layer.autorefreshInterval = -1;
                                            let indexOption = this.mapCtrl.userSettingsSrvs.isAuthForOptionFullInfo(authOpt.auto_refresh_interval, layer.name, authType.layer);
                                            if (indexOption && indexOption.idItem) {
                                                layer.autorefreshInterval = indexOption.idItem;
                                                if (layer.autorefreshInterval < 3) { layer.autorefreshInterval = 3; }
                                                if (layer.autorefreshInterval > 30) { layer.autorefreshInterval = 30; }
                                                layer.autorefreshTimeout = layer.autorefreshInterval;
                                            }

                                        }
                                        //autorefresh and delay refresh for report
                                        if (layer.autorefreshInterval > 0 || layer.autorefreshDelay > 0) {
                                            if (layer.autorefreshInterval > 0) {
                                                hasLayers = true;
                                            }
                                            //
                                            if (layer.autorefreshTimeout <= 0) {
                                                if (layer.autorefreshInterval > 0) {
                                                    layer.autorefreshTimeout = layer.autorefreshInterval;
                                                }
                                                if (layer.autorefreshDelay > 0) {
                                                    layer.manualRefresh = true;
                                                    layer.autorefreshTimeout = 100000000;
                                                }
                                                (layer.internalLayer as VectorLayer<any>).getSource().refresh();

                                            } else {
                                                if (this.mapCtrl.autorefreshButtonStateOn || layer.autorefreshDelay > 0) {
                                                    layer.autorefreshTimeout = layer.autorefreshTimeout - 1;
                                                }
                                                //for autorefresh turning on to do a fast refresh
                                                if (this.mapCtrl.autorefreshButtonStateOn && autorefreshLastStateOn == false) {
                                                    layer.autorefreshTimeout = 0;
                                                }
                                            }
                                        }
                                    }

                                }
                            }
                        }
                        //show hide autorefresh button
                        if (this.mapCtrl.autorefreshButtonCtrl) {
                            if (this.mapCtrl.autorefreshButtonStateVisible === false && hasLayers) {
                                $(this.mapCtrl.autorefreshButtonCtrl["element"]).removeClass('autorefres-button-hide');
                            } else if (this.mapCtrl.autorefreshButtonStateVisible === true && hasLayers === false) {
                                $(this.mapCtrl.autorefreshButtonCtrl["element"]).addClass('autorefres-button-hide');
                            }
                        }
                        //
                        this.mapCtrl.autorefreshButtonStateVisible = hasLayers;
                        autorefreshLastStateOn = this.mapCtrl.autorefreshButtonStateOn;
                    } catch (reason) {
                        this.$log.error("eroare autorefresh layer ", getExMessage(reason));
                    }
                }, 1000
            )
        }

        public setDelayedRefreshForRaport(sourcelayer: ILayer): boolean {
            let hasLayers = false;
            try {
                for (let cat of this.mapCtrl.categories) {
                    for (let layer of cat.layers) {
                        if (layer.visible && layer.featureType == featureType.polyReport) {
                            hasLayers = true;
                            //get the name of the refresh layer
                            let indexOption = this.mapCtrl.userSettingsSrvs.isAuthForOptionFullInfo(authOpt.report_refresh_by_layer, layer.name, authType.layer);
                            if (indexOption && indexOption.descriere && indexOption.descriere === sourcelayer.name) {
                                //get the interval from layer options
                                if (layer.autorefreshDelay == null) {
                                    layer.autorefreshDelay = 5;
                                    let indexOption = this.mapCtrl.userSettingsSrvs.isAuthForOptionFullInfo(authOpt.report_refresh_delay, layer.name, authType.layer);
                                    if (indexOption && indexOption.idItem) {
                                        layer.autorefreshDelay = indexOption.idItem;
                                        if (layer.autorefreshDelay < 1) { layer.autorefreshDelay = 1; }
                                        if (layer.autorefreshDelay > 30) { layer.autorefreshDelay = 30; }
                                    }
                                }
                                layer.autorefreshTimeout = layer.autorefreshDelay;
                                hasLayers = true;
                            }
                        }
                    }
                }
                //
            } catch (reason) {
                this.$log.error("eroare autorefresh layer ", getExMessage(reason));
            }
            //
            return hasLayers;
        }
    }
