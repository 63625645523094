//NG
import { Component, OnInit, Input, Inject } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { NGXLogger } from "ngx-logger";
import { UserSettingsService } from "../../services/user-settings-service";
import { WindowMessageService } from "../../services/window-message-service";
import { IFeatureInfo, ISelectedFeatures, authAs, authType, ILayer, authOpt, IOptiune, saveOptionType } from "../../definitions";
import { MapController } from "../../map/map-controller";

import { Grid, GridOptions, ColDef, GridReadyEvent } from "ag-grid-community";
import { AgGridAngular } from "ag-grid-angular";
import { downloadFile, getExMessage, readablePropertyName } from "../../map/map-utils";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";


import Feature from 'ol/Feature';
import Collection from "ol/Collection";

export interface ITable {
    idLayer?: number,
    name: string,
    columns: Array<IFeatureInfo>,
    data: Array<any>,
    gridOptions: any,
    gridApi: any
}

@Component({
    selector: 'print-features-info',
    providers: [],
    templateUrl: './app/features/features-components/print-features-info.component.html',
    styleUrls: ['./app/features/features-components/print-features-info.component.css']
})
export class PrintFeaturesInfoComponent implements OnInit {
    private selectedFeaturesOnLayers: Collection<ISelectedFeatures>;
    public featuresInTables: Array<ITable>;
    public mapImgUrl: string;
    table: ITable;
    isAdmin: boolean = false;

    public defaultColDef = {
        sortable: true,
        filter: true,
        resizable: true
    }

    @Input() data: any;

    constructor(
        @Inject(NgbActiveModal) private activeModal: NgbActiveModal,
        @Inject(NGXLogger) private $log: NGXLogger,
        @Inject(UserSettingsService) private userSettingsService: UserSettingsService,
        @Inject(WindowMessageService) private windowMessageService: WindowMessageService,
        @Inject(DomSanitizer) private sanitizer: DomSanitizer
    ) {
        //
    }

    public ngOnInit(): void {

        this.isAdmin = this.userSettingsService.isAuthForResource(authAs.data_save_resursa_optiuni, authType.route);

        if (this.data.selectedFeaturesOnLayers && this.data.mapImgUrl) {
            //this.selectedFeaturesOnLayers = data.selectedFeaturesOnLayers as Collection<ISelectedFeatures>;
            this.selectedFeaturesOnLayers = this.cloneFeaturesList(this.data.selectedFeaturesOnLayers as Collection<ISelectedFeatures>);
            this.mapImgUrl = this.sanitizer.bypassSecurityTrustUrl(this.data.mapImgUrl) as any;
            //
            this.featuresInTables = [];
            Promise.resolve(true)
                .then(() => {
                    return this.getFeaturesListsExtraInfo();
                })
                .catch((reason) => {
                    this.$log.error('eroare la extragere info', getExMessage(reason));
                })
                .then(() => {
                    this.createTableData();
                    //to do table full height
                    this.setTableWidthHeight();
                })
                .catch((reason) => {
                    this.$log.error('eroare la creare date tabele', getExMessage(reason));
                })
        }
        else {
            this.$log.warn("straturile cu date nu exista");
            this.activeModal.close(false);
        }
    }

    
    //
    private createTableData() {
        if (this.selectedFeaturesOnLayers) {
            this.selectedFeaturesOnLayers.forEach((ilayerSel) => {
                try {
                    let tmpTable: ITable = { idLayer: -1, name: '', columns: [], data: [], gridOptions: {}, gridApi: {} };
                    if (ilayerSel.layer["appLayer"] && ilayerSel.layer["appLayer"]["infoColumns"]) {
                        let layerSettings: ILayer = ilayerSel.layer["appLayer"] as ILayer;
                        let columnTypes: IFeatureInfo[] = layerSettings.infoColumns;
                        tmpTable.name = layerSettings.name;
                        tmpTable.columns = columnTypes;
                        tmpTable.idLayer = layerSettings.id;
                        ilayerSel.features.forEach((ifeature) => {
                            let newRow = {};
                            newRow["Id"] = ifeature.getId();
                            columnTypes.forEach((icolumn) => {
                                let tmpVal = ifeature.get(icolumn.name);
                                if (tmpVal == null) {
                                    tmpVal = "";
                                }
                                newRow[icolumn.name] = tmpVal;
                            })
                            tmpTable.data.push(newRow);
                        });

                        try {
                            //check if use readableProperyName
                            let displayReadablePropertyName = this.userSettingsService.isAuthForOption(authOpt.display_readable_propery_name, ilayerSel.layer['appLayer']['name'], authType.layer);
                            layerSettings.gridDefaultColumns = [];
                            Object.keys(tmpTable.data[0]).forEach(key => {
                                let headerName = displayReadablePropertyName ? readablePropertyName(key) : key;
                                layerSettings.gridDefaultColumns.push({ field: key, headerTooltip: key, headerName: headerName });
                                if (this.userSettingsService.isAuthForItemOption(authOpt.in_table_details, ilayerSel.layer['appLayer']['name'], key, authType.layer) === 'false') {
                                    layerSettings.gridDefaultColumns[layerSettings.gridDefaultColumns.length - 1].hide = true;
                                } else {
                                    layerSettings.gridDefaultColumns[layerSettings.gridDefaultColumns.length - 1].hide = false;
                                }
                            });
                        } catch (error) {
                            this.$log.error("Eroare", getExMessage(error));
                            layerSettings.gridDefaultColumns = null;
                        }

                        this.table = tmpTable;
                        this.setGridOptions(tmpTable, layerSettings.gridDefaultColumns);
                        this.featuresInTables.push(tmpTable);
                    }
                    else {
                        this.$log.error("setarile pentru strat lipsesc");
                    }
                } catch (e) {
                    this.$log.error("eroare in creare date tabel ");
                }
            })
        }
    }

    //
    private cloneFeaturesList(selectedFeatures: Collection<ISelectedFeatures>): Collection<ISelectedFeatures> {
        let returnList: Collection<ISelectedFeatures> = new Collection<ISelectedFeatures>();;
        selectedFeatures.forEach((selectFeature) => {
            let tmpSelectFeature: ISelectedFeatures = {
                layer: selectFeature.layer,
                features: new Collection<Feature>()
            }
            selectFeature.features.forEach((feature) => {
                let tmpFeature = feature.clone();
                tmpFeature.setId(feature.getId());
                tmpSelectFeature.features.push(tmpFeature);
            });
            returnList.push(tmpSelectFeature);
        });
        return returnList;
    }

    //
    private getFeaturesListsExtraInfo(): Promise<any> {
        let promises: any = [];
        this.selectedFeaturesOnLayers.forEach((layerSel) => {
            //
            let tmpPromise = this.windowMessageService.getFeatureListInfoByMessage(layerSel.features.getArray(), layerSel.layer[MapController.appLayer], 'featureListExtraInfo');
            if (tmpPromise){
                promises.push();
            }
        })
        if (promises.length == 0){
            return Promise.resolve(true);
        }
        //
        return Promise.all(promises).then((results) => {
            if (results && Array.isArray(results) && results.length > 0) {
                results.forEach((result) => {
                    for (var i = 0; i < this.selectedFeaturesOnLayers.getLength(); i++) {
                        if ((this.selectedFeaturesOnLayers.item(i).layer[MapController.appLayer] as ILayer).id === result['data']['layer']) {
                            //do each feature
                            this.windowMessageService.setFeatureListExtraInfoFromMessage(this.selectedFeaturesOnLayers.item(i).features, result)
                            break;
                        }
                    }
                });
            }
            return true;
        });
    }

    //
    private setGridOptions(table: ITable, defaluts: any) {
        let columnDefs = defaluts || [];
        table.gridOptions = {
            columnDefs: columnDefs,
            data: table.data,
            domLayout: 'print',//'autoHeight',//'print',
            enableSelectAll: true,
            columnSelection: false,
            
            onRegisterApi: function (gridApi) {
                table.gridApi = gridApi;
            },
            onGridReady: (event: GridReadyEvent) => {
                // use api from event
                table.gridApi = event.api;
             }
        };
    }

    //
    private saveDefaultOptions(featureTab: ITable) {
        let options: Array<IOptiune> = [];
        let colDef = this.table.gridOptions.columnDefs;
        for (let i = 0; i < colDef.length; i++) {
            let visible;
            if (colDef[i].hide === undefined) {
                visible = 'true';
            } else {
                visible = colDef[i].hide ? 'false' : 'true';
            }
            options.push(
                {
                    id: -1,
                    nume: authOpt.in_table_details,
                    idItem: i,
                    descriere: colDef[i].field,
                    defaultAccess: true,
                    customAccess: visible,
                    idItemMin: -1
                }
            );
        }
        this.userSettingsService.optResRol.setAssingendResursaOptiuniToStorage(featureTab.idLayer, options, saveOptionType.option, authOpt.in_table_details)
            .then(data => {
                this.$log.info('Configurarea a fost salvata!');
            })
            .catch(error => {
                this.$log.error("eroare in salvare", getExMessage(error));
            });
    }

    //panel hide show column action
    public gridColumnClick(event, grid: AgGridAngular, codDef) {
        codDef.hide = !codDef.hide
        grid.columnApi.setColumnVisible(codDef.field, !codDef.hide);
    }

    // 
    public setTableWidthHeight() {
        setTimeout(() => {
         //   $('.modal-content').width('290mm');
        }, 100)
    }
    //
    public cancel(): void {
        this.activeModal.close(false);
    }
}