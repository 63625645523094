//NG+
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Compiler, ComponentFactoryResolver, Inject, Injectable, Injector } from "@angular/core";
import { SettingsMainComponent } from "./settings-components/settings-main.component";

export interface ISettingsDialogsServiceNg {
    showSettingsDialog(data: any): any;
}

@Injectable({
    providedIn: 'root',
  })
export class SettingsDialogsServiceNg implements ISettingsDialogsServiceNg {
    public LoginDialog: any = null;
    //
    public constructor (
    @Inject(NgbModal) private modalService,
    @Inject(ComponentFactoryResolver) private componentFactoryResolver: ComponentFactoryResolver,
    @Inject(Compiler) private compiler: Compiler,
    @Inject(Injector) private injector: Injector) {
        //
    }
    //
    public showSettingsDialog(data:any){

        const modalOpt = { backdrop: false, windowClass: 'modalSettingsMain',fullscreen:true }
        let hasFactory = true
        try { this.componentFactoryResolver.resolveComponentFactory(SettingsMainComponent); }
        catch (error) { hasFactory = false; }
        //
        return Promise.resolve(true)
        .then((result)=>{
            if (hasFactory === false){
                return import('./settings-components/settings-main.module')
                .then((esiModule)=>{
                    return this.loadModuleComponent(this.compiler, this.injector, this.componentFactoryResolver, esiModule.SettingsMainModule, SettingsMainComponent);
                })
            } else {
                return Promise.resolve(true);
            }
        }).then((result)=>{
            const modalRef = this.modalService.open(SettingsMainComponent, modalOpt);
            modalRef.componentInstance.data = data;
            return modalRef.result;
        })
    }
    //
    //
    loadModuleComponent(compiler, injector,  componentFactoryResolver, moduleClass, componentClass):Promise<any>{
        return compiler.compileModuleAsync(moduleClass)
           .then((moduleFactory)=>{
               const moduleRef = moduleFactory.create(injector);
               const componentFactory = moduleRef.componentFactoryResolver.resolveComponentFactory(componentClass);
               //const compRef = componentFactory.create(this.injector);
               // create component factory for DynamicComponent
               (componentFactoryResolver as any)._factories.set(componentClass, componentFactory);
               return Promise.resolve(true);
           })
   }
}