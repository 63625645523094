//NG+

import * as THREE from "three"; 
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import Stats from 'three/examples/jsm/libs/stats.module';
import { ILayer } from "../../definitions";
import { AppSettings } from "../../app-settings";
import GeoTIFF, { fromUrl, fromUrls, fromArrayBuffer, fromBlob } from "geotiff";
import { GisDataService } from "../../services/gis-data-service";
import { Component, ElementRef, Inject, Input, OnInit, Renderer2, ViewChild } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { NGXLogger } from "ngx-logger";
import { getExMessage } from "../../map/map-utils";

@Component({
    selector: 'info-click-3d-graph',
    providers: [],
    templateUrl: './app/features/features-components/info-click-3d-graph.component.html',
    styleUrls: ['./app/features/features-components/info-click-3d-graph.component.css']
})
export class InfoClick3DGraphComponent implements OnInit {
    public loading: boolean = false;
    public camera: any;
    public scene: any;
    public renderer: any;
    public meshObject: any;
    public container: any;
    public controls: any;
    public pointer = new THREE.Vector2();
    public raycaster = new THREE.Raycaster();
    public helper: any;
    public stats: any;
    public width = null;
    public height = null;
    public backgroundColor = '#333333';
    public imagePng: any = null;
    public graphData: { layer: ILayer, bbox: [any], coordinate: any, time: string, width: number, offset: number, offsetOsm: number, scale:number, nullValue: number, data: any } = null;
    //
    @Input() data: any;
    @ViewChild('graphElement', { static: false }) element: ElementRef;
    //
    public constructor(
        @Inject(NgbActiveModal) private activeModal: NgbActiveModal,
        @Inject(NGXLogger) private $log: NGXLogger,
        @Inject(GisDataService) private gisDataService: GisDataService,
        @Inject(Renderer2) private renderer2:Renderer2
        ) {
       //
    }

    public ngOnInit(): void {
        //
        if ("graphData" in this.data) {
            this.graphData = this.data["graphData"];
            if ("layer" in this.graphData === false) {
                this.$log.error("stratul nu exista in date")
            }
        }
        //
        this.width = 700;//
        this.height = 800;
        //delayed init
        setTimeout(this.delayedInit, 100);
    }


    public delayedInit = () => {
        this.init();
        //this.$log.log("on init animate and build graph");
        this.animate();
        //
        this.buildGraph();
    }

    private buildGraph() {
        this.loading = true;
        //open street lower image
        this.customLoadImageOSMPngFromServer(this.graphData.bbox);
        //async no wait
        this.customLoadImagePng(this.graphData.layer, this.graphData.bbox, this.graphData.time)
        .then((status)=>{
            //this.customLoadImageFromWCSServer(this.graphData.layer, this.graphData.bbox, this.graphData.time);
            return this.customLoadImageFromWCSServer10(this.graphData.layer, this.graphData.bbox, this.graphData.time);
        })
        .catch((reason)=>{
            this.$log.error("eroare incarcare imagine")
        })
        .finally(()=>{
            this.loading = false;
        })
       
    }

    // Initialization function
    public init() {
        this.width = this.element.nativeElement.getBoundingClientRect().width - 25;
        //
        this.renderer = new THREE.WebGLRenderer();
        //
        this.container = this.renderer2.createElement('div');
        this.element.nativeElement.append(this.container);
        //
        this.camera = new THREE.PerspectiveCamera(50, this.width / this.height, 100, 100000);
        this.camera.position.x = this.graphData.width/10;//500;
        this.camera.position.y = this.graphData.width/10;
        this.camera.position.z = this.graphData.width/10;
        //
        this.controls = new OrbitControls(this.camera, this.renderer.domElement);
        this.controls.minDistance = 1;
        this.controls.maxDistance = 100000;
        this.controls.maxPolarAngle = Math.PI;
        //
        this.scene = new THREE.Scene();
        this.scene.background = new THREE.Color(0xf2f5f9);

        //this.addHelperObject();
        //this.addCenterObject();
        //this.addStatsTools();
        //
        this.container.addEventListener('pointermove', this.onPointerMove);
        //
        this.renderer.setSize(this.width, this.height);
        //renderer.setClearColor(scope.backgroundColor);

        this.container.appendChild(this.renderer.domElement);
    };

    private addHelperObject() {
        //
        const geometryHelper = new THREE.ConeGeometry(5, 10, 3);
        geometryHelper.translate(0, 50, 0);
        geometryHelper.rotateX(Math.PI / 2);
        let helper = new THREE.Mesh(geometryHelper, new THREE.MeshNormalMaterial());
        this.scene.add(helper);
    }

    private addCenterObject() {
        //helper geom in o.o.o
        let geometry = new THREE.BoxGeometry(1, 1, 1);
        let material = new THREE.MeshBasicMaterial({
            color: '#ffaa00'
        });

        let object = new THREE.Mesh(geometry, material);

        object.position.x = 0;
        object.position.y = 0;
        object.position.z = 0;

        this.scene.add(object);
    }

    private addStatsTools() {
        //
        this.stats = new Stats();
        this.container.appendChild(this.stats.dom);

        this.scene.add(new THREE.AxesHelper(5))
    }

    //auto rotate
    public renderRotate() {
        //
        this.camera.lookAt(this.scene.position);
        // Traverse the scene, rotate the Mesh object(s)
        this.scene.traverse((element) => {
            if (element instanceof THREE.Mesh) {
                element.rotation.x += 0.0065;
                element.rotation.y += 0.0065;
            }
            this.renderer.render(this.scene, this.camera);
        });
    };

    //
    public animate = () => {
        //
        requestAnimationFrame(this.animate);
        this.render();
        //
        if (this.stats) {
            this.stats.update();
        }
    };

    //
    public render() {
        //
        this.renderer.render(this.scene, this.camera);
    }

    //
    private onPointerMove = (event) => {
        //
        this.pointer.x = (event.clientX / this.renderer.domElement.clientWidth) * 2 - 1;
        this.pointer.y = - (event.clientY / this.renderer.domElement.clientHeight) * 2 + 1;
        this.raycaster.setFromCamera(this.pointer, this.camera);
        let intersects = [];
        // See if the ray from the camera into the world hits one of our meshes
        try {
            intersects = this.raycaster.intersectObject(this.meshObject);
        } catch(reason){
            //
        }
        
        // Toggle rotation bool for meshes that we clicked
        if (this.helper && intersects.length > 0) {
            this.helper.position.set(0, 0, 0);
            this.helper.lookAt(intersects[0].face.normal);
            this.helper.position.copy(intersects[0].point);
        }
    }


    //old
    public importTiff(arrayBuffer: any) {
        let tiff = null;
        let image = null;
        let data = null;
        fromArrayBuffer(arrayBuffer)
            .then((data) => {
                tiff = data;
                return true;
            }).then((status) => {
                return tiff.getImage()
                    .then((result) => {
                        image = result;
                        return true;
                    });
            }).then((status) => {
                return image.readRasters({ interleave: true })
                    .then((dataRet) => {
                        data = dataRet;

                        const width = image.getWidth();
                        const height = image.getHeight()
                        // Create a three.js texture from the data
                        // const texture = new THREE.DataTexture(data, width, height, THREE.DepthFormat);//LuminanceFormat RGBAFormat 
                        // texture.needsUpdate = true;
                        const texture = this.testTextureTr(data, width, height);
                        // Create a three.js plane geometry
                        const geometry = new THREE.PlaneGeometry(width, height);

                        // Create a three.js material with the texture
                        //const material = new THREE.MeshBasicMaterial({ map: texture });
                        const material = new THREE.MeshBasicMaterial({ color: '#FFFFFF', map: texture });
                        // Create a three.js mesh with the geometry and material
                        const mesh = new THREE.Mesh(geometry, material);

                        // Add the mesh to the scene
                        this.scene.add(mesh);
                    });
            }).catch((reason) => {
                this.$log.error("eroare la integrare imagine")
            });

    }

    //
    public customLoadImagePng(layer: ILayer, bbox: Array<number>, time: string): Promise<any> {
        return this.gisDataService.getImagePngFromServer(layer, bbox, time)
            .then((htmlImage) => {
                this.imagePng = htmlImage;
                this.importImagePng(htmlImage);
                return Promise.resolve(true);
            })
            .catch((reason) => {
                this.$log.error("Eroare interogare tile geoserver ", getExMessage(reason));
                return Promise.resolve(false);
            })
    }

    //
    public customLoadImageOSMPngFromServer(bbox: Array<number>) {
        //
        this.gisDataService.getImageOSMPngFromServer(bbox, "EPSG:3857")
            .then((httpImage) => {
                if (httpImage) {
                    this.importImagePng(httpImage, true);
                } else {
                    throw Error("eroare incarcare imagine")
                }
            }).catch((reason) => {
                this.$log.error("Eroare interogare tile geoserver ", getExMessage(reason));
            });
    }

    //
    public importImagePng(image: HTMLImageElement, isOSM = false) {
        const width = image.width;
        const height = image.height;
        // create texture
        let texture = new THREE.TextureLoader().load(image.src);
        //texture.rotation = Math.PI / 2;
        texture.center = new THREE.Vector2(0.5, 0.5);
        // create a three.js plane geometry
        let geometry = new THREE.PlaneGeometry(width, height);
        geometry.rotateX(- Math.PI / 2);
        //offset osm vs png layer
        let offsetH = -Math.trunc(this.graphData.width/20);
        if (isOSM) {
            offsetH = (-this.graphData.width/10) - this.graphData.offsetOsm;
        }
        geometry.translate(0, offsetH, 0);
        //create a three.js material with the texture
        let material = new THREE.MeshBasicMaterial({ color: '#FFFFFF', map: texture, transparent: true });
        if (isOSM) {
            material.transparent = false;
        }
        //create a three.js mesh with the geometry and material
        let mesh = new THREE.Mesh(geometry, material);

        // Add the mesh to the scene
        this.scene.add(mesh);

    }

    public importImage(arrayBuffer: any) {
        let tiff = null;
        let image = null;
        let data = null;
        fromArrayBuffer(arrayBuffer)
            .then((data) => {
                tiff = data;
                return true;
            }).then((status) => {
                return tiff.getImage()
                    .then((result) => {
                        image = result;
                        return true;
                    });
            }).then((status) => {
                return image.readRasters({ interleave: true })
                    .then((dataRet) => {
                        data = dataRet;

                        const width = image.getWidth();
                        const height = image.getHeight()
                        // Create a three.js texture from the data
                        // const texture = new THREE.DataTexture(data, width, height, THREE.DepthFormat);//LuminanceFormat RGBAFormat 
                        // texture.needsUpdate = true;
                        const texture = this.testTextureTr(data, width, height);
                        // Create a three.js plane geometry
                        const geometry = new THREE.PlaneGeometry(width, height);

                        // Create a three.js material with the texture
                        //const material = new THREE.MeshBasicMaterial({ map: texture });
                        const material = new THREE.MeshBasicMaterial({ color: '#FFFFFF', map: texture });
                        // Create a three.js mesh with the geometry and material
                        const mesh = new THREE.Mesh(geometry, material);

                        // Add the mesh to the scene
                        this.scene.add(mesh);
                    });
            }).catch((reason) => {
                this.$log.error("eroare la integrare imagine")
            });

    }

    //wcs 1.0.0
    public customLoadImageFromWCSServer10(layer: ILayer, bbox: Array<number>, time: string) {
        let tiff = null;
        let image = null;
        let data = null;
        let tmpDate = time.substring(0,10);
        return this.gisDataService.getImageXmlFromWCSServer10(layer, bbox, tmpDate)
            .then((data) => {
                return fromArrayBuffer(data as any)
                    .then((data) => {
                        return data;
                    })
            }).then((data) => {
                tiff = data;
                return true;
            }).then((status) => {
                return tiff.getImage()
                    .then((result) => {
                        image = result;
                        return true;
                    });
            }).then((status) => {
                return image.readRasters({ interleave: true })
                    .then((dataRet) => {
                        data = dataRet;

                        const width = image.getWidth();
                        const height = image.getHeight()

                        const newData = data;//this.changeXYforwgsData(data, width, height)
                        //flat image
                        //this.graph2DPlaneGeometry(data, width, height);

                        //add 3dplane
                        this.graph3DPlaneGeometry(newData, width, height);
                    });
            }).catch((reason) => {
                this.$log.error("eroare la integrare imagine", getExMessage(reason))
            });

    }
    //wcs 1.1.1
    public customLoadImageFromWCSServer(layer: ILayer, bbox: Array<number>, time: string) {
        //
        this.loading = true;
        this.gisDataService.getImageXmlFromWCSServer(layer, bbox, time)
            .then((data) => {
                this.importWCSTiff(data);
            }).catch((reason) => {
                this.$log.error("Eroare interogare tile geoserver ", getExMessage(reason));
            }).finally(()=>{
                this.loading = false;
            })

    }

    //wcs 1.1.1
    public changeXYforwgsData(data: Float32Array, width, height) {
        let retData = new Float32Array(data.length);
        let retData2 = new Float32Array(data.length);

        for (let i = 0; i < data.length; i++) {
            retData[data.length - i] = data[i];

        }
        for (let y = 0; y < height; y++) {

            for (let x = 0; x < width; x++) {
                const eldata = data[y * width + x];
                retData2[x * width + y] = eldata;
            }

        }
        return retData2;

    }
    //wcs 1.1.1
    public importWCSTiff(xmlResponse: any) {
        let tiff = null;
        let image = null;
        let data = null;
        let urlTif = this.getUrlFromWCSxmlRespone(xmlResponse);
        this.gisDataService.getWCSImage(urlTif)
            .then((data) => {
                return fromArrayBuffer(data as any)
                    .then((data) => {
                        return data;
                    })
            }).then((data) => {
                tiff = data;
                return true;
            }).then((status) => {
                return tiff.getImage()
                    .then((result) => {
                        image = result;
                        return true;
                    });
            }).then((status) => {
                return image.readRasters({ interleave: true })
                    .then((dataRet) => {
                        data = dataRet;

                        const width = image.getWidth();
                        const height = image.getHeight()

                        const newData = this.changeXYforwgsData(data, width, height)
                        //flat image
                        //this.graph2DPlaneGeometry(data, width, height);

                        //add 3dplane
                        this.graph3DPlaneGeometry(newData, width, height);
                    });
            }).catch((reason) => {
                this.$log.error("eroare la integrare imagine")
            });

    }

    //
    private getUrlFromWCSxmlRespone(xmlRes: string) {
        let url = "";
        if (xmlRes == null || xmlRes.length === 0) {
            return url;
        }
        try {
            let start = xmlRes.indexOf("xlink:href")
            let stText = xmlRes.indexOf("http", start);
            let endText = xmlRes.indexOf(".tif", start);
            if (stText > 0 && endText > stText) {
                url = xmlRes.substring(stText, endText + 4);


            }
        } catch (error) {
            this.$log.error("eroare extragere url tif ")
        }
        return url;
    }

   
    //to create image from tiff data
    private graph2DPlaneGeometry(data, width, height) {
        // Create a three.js texture from the data

        const texture = this.testTextureTr(data, width, height);
        // Create a three.js plane geometry
        const geometry = new THREE.PlaneGeometry(width, height);
        geometry.rotateX(- Math.PI / 2);
        geometry.translate(0, -100, 0);
        // Create a three.js material with the texture
        const material = new THREE.MeshBasicMaterial({ color: '#FFFFFF', map: texture });
        // Create a three.js mesh with the geometry and material
        const mesh = new THREE.Mesh(geometry, material);

        // Add the mesh to the scene
        this.scene.add(mesh);
    }

    //
    private testTextureTr(buffer: Uint8Array, width, height) {

        const size = width * height;
        const data = new Uint8Array(4 * size);
        const color = new THREE.Color(0x11ff55);

        const r = Math.floor(color.r * 255);
        const g = Math.floor(color.g * 255);
        const b = Math.floor(color.b * 255);

        for (let i = 0; i < size; i++) {

            const stride = i * 4;

            data[stride] = buffer[i] * 100;
            data[stride + 1] = buffer[i] * 100;
            data[stride + 2] = buffer[i] * 100;
            data[stride + 3] = 255;

        }

        // used the buffer to create a DataTexture

        const texture = new THREE.DataTexture(data, width, height);
        texture.needsUpdate = true;
        return texture;
    }

    //
    private graph3DPlaneGeometry(data, width, height) {
        let worldWidth = width;
        let worldDepth = height;
        this.meshObject = new THREE.PlaneGeometry(width, height, worldWidth - 1, worldDepth - 1);
        //rotate to horizontal plane
        this.meshObject.rotateX(- Math.PI / 2);
        //create 3d surface by vertices values
        const vertices = (this.meshObject as any).attributes.position.array;
        for (let i = 0, j = 0, l = vertices.length; i < l; i++, j += 3) {

            vertices[j + 1] = data[i] * this.graphData.scale;
            if (data[i] <= this.graphData.nullValue){
                vertices[j + 1] = null;
            }

        }
        this.meshObject.translate(0, -this.graphData.offset, 0);
        //this.meshObject.rotateY(Math.PI / 2);
        //
        let texture = null;
        if (this.imagePng) {
            // create texture
            texture = new THREE.TextureLoader().load(this.imagePng.src);
            //texture.rotation = Math.PI / 2;
            texture.center = new THREE.Vector2(0.5, 0.5);
        } else {

            //
            texture = new THREE.CanvasTexture(this.generate3DTexture(data, worldWidth, worldDepth));
            texture.wrapS = THREE.ClampToEdgeWrapping;
            texture.wrapT = THREE.ClampToEdgeWrapping;
            //texture.colorSpace = THREE.SRGBColorSpace;
        }


        let mesh = new THREE.Mesh(this.meshObject, new THREE.MeshBasicMaterial({ map: texture, transparent: true }));
        //let mesh = new THREE.Mesh( geometry, new THREE.MeshBasicMaterial( { color: 0x555555, wireframe: true } ) );
        this.scene.add(mesh);
    }

    //
    private generate3DTexture(data, width, height) {
        // bake lighting into texture

        let context, image, imageData, shade;

        const vector3 = new THREE.Vector3(0, 0, 0);

        const sun = new THREE.Vector3(1, 1, 1);
        sun.normalize();

        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;

        context = canvas.getContext('2d');
        context.fillStyle = '#000';
        context.fillRect(0, 0, width, height);

        image = context.getImageData(0, 0, canvas.width, canvas.height);
        imageData = image.data;

        for (let i = 0, j = 0, l = imageData.length; i < l; i += 4, j++) {

            vector3.x = data[j - 2] - data[j + 2];
            vector3.y = 2;
            vector3.z = data[j - width * 2] - data[j + width * 2];
            vector3.normalize();

            shade = vector3.dot(sun);
            imageData[i] = (96 + shade * 128) * (0.5 + data[j] * 1);
            imageData[i + 1] = (32 + shade * 96) * (0.5 + data[j] * 1);
            imageData[i + 2] = (shade * 96) * (0.5 + data[j] * 1);

            // imageData[ i ] = ( 96 + shade * 128 ) * ( 0.5 + data[ j ] * 0.007 );
            // imageData[ i + 1 ] = ( 32 + shade * 96 ) * ( 0.5 + data[ j ] * 0.007 );
            // imageData[ i + 2 ] = ( shade * 96 ) * ( 0.5 + data[ j ] * 0.007 );

        }

        context.putImageData(image, 0, 0);

        // Scaled 4x

        const canvasScaled = document.createElement('canvas');
        canvasScaled.width = width * 4;
        canvasScaled.height = height * 4;

        context = canvasScaled.getContext('2d');
        context.scale(4, 4);
        context.drawImage(canvas, 0, 0);

        image = context.getImageData(0, 0, canvasScaled.width, canvasScaled.height);
        imageData = image.data;
        //grained data for better visibility
        for (let i = 0, l = imageData.length; i < l; i += 4) {

            const v = ~ ~(Math.random() * 5);

            imageData[i] += v;
            imageData[i + 1] += v;
            imageData[i + 2] += v;

        }

        context.putImageData(image, 0, 0);

        return canvasScaled;

    }

    //
    public cancel(): void {
        this.activeModal.close(false);
    }
}
