//NG+
import { Component, Inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AppSettings } from '../../app-settings';
import { ILayer, authAs, authOpt, authType, featureType } from '../../definitions';
import { MapController } from '../map-controller';
import { IDescarcareDateSatelitareIL, IUnitateAdministrativaDescarcareIL, IIntegrateLandsatDownloadData } from "./map-integrate.definitions";
import { IDescarcareDateSatelitare, IIndiceAntrenament, IIndiceDescarcare, IProdusDescarcare, TaskActiune, TaskStatus } from '../../services/greentop-data-service';
import { I18n, NgbCustomDatepickerI18n } from '../../shared-components/ngb-datepicker-i18n';
import { NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateMomentAdapter, NgbDateMomentParserFormatter } from '../../shared-components/ngb-moment-adapter-formater';
import moment, { Moment } from "moment";
import { IGreentopDownloadData, listaSursaDateClasificare } from './map-greentop-definitions';
import { NGXLogger } from 'ngx-logger';
import { getExMessage } from '../map-utils';

import VectorLayer from 'ol/layer/Vector';
import Draw from 'ol/interaction/Draw';
import Feature from 'ol/Feature';
import Polygon from 'ol/geom/Polygon';
import VectorSource from "ol/source/Vector";
import WKTFormater from "ol/format/WKT";

@Component({
    selector: 'greentop-download',
    providers: [
        I18n,
        { provide: NgbDatepickerI18n, 
             useFactory: () => { return new NgbCustomDatepickerI18n({language : 'ro'})} },
        { provide: NgbDateParserFormatter, 
            useFactory: () => { return new NgbDateMomentParserFormatter("YYYY-MM-DD") } },
        { provide: NgbDateAdapter, useClass: NgbDateMomentAdapter},
    ],
    templateUrl: './app/map/map-components/map-greentop-download.component.html',
    styleUrls: ['./app/map/map-components/map-greentop-download.component.css']
})
export class GreentopDownloadComponent implements OnInit, OnChanges {
    @Input("mapctrl") mapCtrl: MapController = null;

    //
    public infoSearch = "info descarcare date satelitare";
    public infoProducts = "info detalii produse descarcare";
    private greentopDownloadData : IGreentopDownloadData;
    public constructor(
        @Inject(NGXLogger) private $log: NGXLogger,){
        //
    }

    public ngOnInit(): void {
        if (this.mapCtrl && this.mapCtrl.greentopDownloadData) {
            this.greentopDownloadData = this.mapCtrl.greentopDownloadData;
            this.mapCtrl.greentopDownloadData.funcInit = this.initGreentopDownloadTool;
            this.mapCtrl.greentopDownloadData.funcClear = this.clearGreentopDownloadTool;
        }
    }

    public ngOnChanges(changes: SimpleChanges) {
        //
    }

    

    public initGreentopDownloadTool = () => {
        this.addSelectLayer();
        this.addProductsLayer();
        this.greentopDownloadData.activeExistsTab1 = true;
        this.greentopDownloadData.activeNewTab0 = false;
        this.greentopDownloadData.activeTab = 1;
        //this.initNewDescarcareData();
        this.initExistingDescarcareDate(null);
    }

    

    public clearGreentopDownloadTool = () => {
        this.removeSelectLayerInteraction();
        this.removeSelectLayer();
        this.removeProducstLayer();
    }

    public changeNewExistsTab(index){
        if (index === "0"){
            if (this.greentopDownloadData.activeNewTab0){
               // this.greentopDownloadData.activeNewTab0 = false
            } else {
                this.greentopDownloadData.activeExistsTab1 = false;
                this.greentopDownloadData.activeNewTab0 = true;
                this.greentopDownloadData.activeTab = 0;
                this.initNewDescarcareData();
            }
            
        } else if (index === "1"){
            if (this.greentopDownloadData.activeExistsTab1){
               // this.greentopDownloadData.activeExistsTab1 = false
            } else {
                
                this.greentopDownloadData.activeExistsTab1 = true;
                this.greentopDownloadData.activeNewTab0 = false;
                this.greentopDownloadData.activeTab = 1
                this.initExistingDescarcareDate(null);
            }
        }
    }

    private initNewDescarcareData(){
        this.greentopDownloadData.productsLayer.getSource().clear();
        this.greentopDownloadData.selectLayer.getSource().clear();
        this.greentopDownloadData.selectButtonStateOn = false;
        this.greentopDownloadData.currentDescarcare = {
            denumire: "",
            descriere: "",
            sursaDate: null,
            startPerioada: moment(new Date()),
            endPerioada: moment(new Date()),
            geom: null,
            status: "initiat",
            mesajStatus: "",
            granuleSatelitare: [],
            produseDescarcare: [],
            indiciDescarcare: []
        }
        this.greentopDownloadData.availableIndici.length = 0;
        //
        this.greentopDownloadData.errorMessage = "";
        this.greentopDownloadData.loadingSearchProductsActive = true;
        this.mapCtrl.greentopDataService.getAvailableIndici()
        .then((data) => {
            this.greentopDownloadData.availableIndici = data;
            this.setFilteredIndici();
            return true;
        }).catch((reason)=>{
            this.greentopDownloadData.errorMessage = "Eroare interogare indici";
            this.$log.error("Eroare interogare descarcari: ", getExMessage(reason));
        }).finally(()=>{
            this.greentopDownloadData.loadingSearchProductsActive = false;
        })
    }
    
    //
    private initExistingDescarcareDate(idPreselected: number){
        this.greentopDownloadData.productsLayer.getSource().clear();
        this.greentopDownloadData.selectLayer.getSource().clear();
        this.removeSelectLayerInteraction();
        this.greentopDownloadData.selectedDescarcare = null;
        this.greentopDownloadData.selectButtonStateOn = false;
        this.greentopDownloadData.currentDescarcare = {
            denumire: "",
            descriere: "",
            sursaDate: null,
            startPerioada: "",
            endPerioada: "",
            geom: null,
            status: "",
            mesajStatus: "",
            granuleSatelitare: [],
            produseDescarcare: []
        }
        
        this.greentopDownloadData.errorMessage = "";
        this.greentopDownloadData.loadingSearchProductsActive = true;
        this.mapCtrl.greentopDataService.getDescarcariDateSatelitare(null)
            .then((data)=>{
                this.greentopDownloadData.availableDescarcari = data;
                return true;
            }).catch((reason)=>{
                this.greentopDownloadData.errorMessage = "Eroare interogare descarcari";
                this.$log.error("Eroare interogare descarcari: ", getExMessage(reason));
            }).finally(()=>{
                this.greentopDownloadData.loadingSearchProductsActive = false;
            }).then((state)=>{
                //load preselected
                if(state as any === true && typeof idPreselected === "number" ){
                  let tmpPres =  this.greentopDownloadData.availableDescarcari.filter((item)=>item.id === idPreselected);
                  if (tmpPres && tmpPres.length > 0){
                      this.greentopDownloadData.selectedDescarcare = tmpPres[0];
                      this.onChangeDescarcare();
                  }
                } 
            }).catch((reason)=>{
                this.$log.error("Eroare activare preselectie: ", getExMessage(reason))
            })
    }
    //
    public onClickClose(){
        this.clearGreentopDownloadTool();
        this.mapCtrl.greentopDwonloadButtonStateOn = false;
    }
    //
    public onChangeDescarcare = ()=>{
        if (this.greentopDownloadData.selectedDescarcare == null){
            return;
        } 
        this.greentopDownloadData.currentDescarcare.granuleSatelitare.length = 0;
        this.greentopDownloadData.currentDescarcare.produseDescarcare.length = 0;
        this.greentopDownloadData.errorMessage = "";
        this.greentopDownloadData.loadingSearchProductsActive = true;
        this.mapCtrl.greentopDataService.getDescarcareDateSatelitare(this.greentopDownloadData.selectedDescarcare.id)
            .then((data)=>{
                this.greentopDownloadData.currentDescarcare = data;
                this.greentopDownloadData.currentDescarcare.produseDescarcare.forEach((item,index)=>{
                    item.nr = index + 1;
                })
                //
                let wkt = new WKTFormater();
                let geomwgs = wkt.readGeometry(this.greentopDownloadData.currentDescarcare.geom) ;
                let geom = geomwgs.transform("EPSG:4326",this.mapCtrl.mapConfig.projection )as Polygon;
                 //
                let tmpFeature = new Feature(geom);
                this.greentopDownloadData.selectLayer.getSource().clear();
                this.greentopDownloadData.selectLayer.getSource().addFeature(tmpFeature);
                this.addcurrentProductsToLayer();
                this.greentopDownloadData.ctrlExistingDetalisIsColapsed = false;
            }).catch((reason)=>{
                this.greentopDownloadData.errorMessage = "Eroare interogare descarcare";
                this.$log.error("Eroare interogare descarcare: ", getExMessage(reason));
            }).finally(()=>{
                this.greentopDownloadData.loadingSearchProductsActive = false;
                this.greentopDownloadData.ctrlProductsIsCollapsed = false;
            })
    }
    public onClickReloadExisting(){
        this.onChangeDescarcare();
    }

    public onctrlDownloadDetailsIsColapsed(){
        this.greentopDownloadData.ctrlDownloadDetailsIsColapsed = ! this.greentopDownloadData.ctrlDownloadDetailsIsColapsed;
    }
    public onctrlExistingDetailsIsColapsed(){
        this.greentopDownloadData.ctrlExistingDetalisIsColapsed = !this.greentopDownloadData.ctrlExistingDetalisIsColapsed;
    }


    //
    public onSearchAvailableProductsClick(){
        this.greentopDownloadData.errorMessage = "";
        try {
            this.greentopDownloadData.currentDescarcare.produseDescarcare.length = 0;
            this.greentopDownloadData.currentDescarcare.granuleSatelitare.length = 0;
            this.checkSearchInputs();
            //
                this.greentopDownloadData.ctrlDownloadDetailsIsColapsed = true;
                let geomwgs = (this.greentopDownloadData.currentDescarcare.geom as Polygon).clone().transform(this.mapCtrl.mapConfig.projection, 'EPSG:4326') as any;
                this.greentopDownloadData.loadingSearchProductsActive = true;

                this.mapCtrl.greentopDataService.getProduseDisponibileDescarcarePentruZonaInteres(
                    this.greentopDownloadData.currentDescarcare.startPerioada.format("YYYY-MM-DD"),
                    this.greentopDownloadData.currentDescarcare.endPerioada.format("YYYY-MM-DD"),
                    geomwgs, [this.greentopDownloadData.selectedSursaDate.tip] // ["sentinel-2"]
                ).then((data)=>{
                    //this.$log.log(data.length);
                    this.greentopDownloadData.currentDescarcare.produseDescarcare = data;
                    this.greentopDownloadData.currentDescarcare.produseDescarcare.forEach((item,index)=>{
                        item.nr = index + 1;
                        item.selected = true;
                    })
                    this.addAvailableProductsToLayer();
                    if (data.length === 0){
                        this.greentopDownloadData.errorMessage = "Nu au fost gasite produse disponibile";
                    }
                    return true;
                })
                //granule sateliare nu sunt disponibile la toti satelitii, suspendam utilizarea
                /*.then((status)=>{
                    return this.mapCtrl.greentopDataService.getGranuleSatelitarePentruZonaInteres(geomwgs,["sentinel-2"]);
                }).then((data)=>{
                    //this.$log.log(data);
                    this.greentopDownloadData.currentDescarcare.granuleSatelitare = data;
                })*/
                .catch((reason)=>{
                    this.greentopDownloadData.errorMessage ="Eroare interogare server ";
                    this.$log.error("Eroare interogare server", getExMessage(reason));

                }).finally(()=>{
                    this.greentopDownloadData.loadingSearchProductsActive = false;
                })

                //
        } catch (error) {
            this.$log.error("Eroare cautare produse disponibile:", getExMessage(error));
            this.greentopDownloadData.errorMessage = "Eroare cautare produse disponibile: " + error.message ||"";
        }
    }
    private checkSearchInputs(){
        if (this.greentopDownloadData.selectedSursaDate == null){
            throw Error("Nu este selectata sursa date satelitare");
        }
        if (this.greentopDownloadData.currentDescarcare.geom == null) {
            throw Error("Nu exista zona de selectie");
        }
        if (this.greentopDownloadData.currentDescarcare.startPerioada == null
            || moment.isMoment(this.greentopDownloadData.currentDescarcare.startPerioada) == false) {
            throw Error("Inceput perioada nu este specificat");
        }
        if (this.greentopDownloadData.currentDescarcare.endPerioada == null
            || moment.isMoment(this.greentopDownloadData.currentDescarcare.endPerioada) == false) {
            throw Error("Sfarsit perioada nu este specificat");
        }
    }
    public clearSearchResults(){
        //
        if (this.greentopDownloadData.loadingSearchProductsActive === true){
            return;
        }
        if (this.greentopDownloadData.activeNewTab0 === true) {
            this.greentopDownloadData.currentDescarcare.produseDescarcare.length = 0;
            this.greentopDownloadData.productsLayer.getSource().clear();
        }
    }

    public onClickColapseProducts(){
        this.greentopDownloadData.ctrlProductsIsCollapsed = !this.greentopDownloadData.ctrlProductsIsCollapsed;
        if(this.greentopDownloadData.ctrlProductsIsCollapsed === true){
            this.greentopDownloadData.productsLayer.getSource().clear();
            this.greentopDownloadData.currentDescarcare.produseDescarcare.forEach((item)=>{
                if(item.feature){
                    this.greentopDownloadData.productsLayer.getSource().addFeature(item.feature);
                }
            })
        }
    }
    //
    public onClickProductsClose(){
        this.clearSearchResults();
    }
    //
    //
    //
    onClickSaveDownloadProducts(){
        //
        this.greentopDownloadData.errorMessage = "";
        try {
            this.checkSearchInputs();
            if (this.greentopDownloadData.currentDescarcare.produseDescarcare == null
                || this.greentopDownloadData.currentDescarcare.produseDescarcare.length == 0) {
                throw Error("Nu sunt produse pentru descarcare");
            }
            if (this.greentopDownloadData.currentDescarcare.denumire == null
                || this.greentopDownloadData.currentDescarcare.denumire.length == 0){
                    throw Error("Denumire descarcare este obligatoriu");
                }
                if (this.greentopDownloadData.currentDescarcare.indiciDescarcare == null
                    || this.greentopDownloadData.currentDescarcare.indiciDescarcare.length == 0) {
                    throw Error("Nu sunt indici selectati pentru descarcare");
                }
            //
            this.greentopDownloadData.loadingSearchProductsActive = true;
            let geomwgs = (this.greentopDownloadData.currentDescarcare.geom as Polygon).clone().transform(this.mapCtrl.mapConfig.projection, 'EPSG:4326') as any;
            let wkt = new WKTFormater();
            let sentData: IDescarcareDateSatelitare = {
                sursaDate: this.greentopDownloadData.selectedSursaDate.tip,
                denumire: this.greentopDownloadData.currentDescarcare.denumire,
                descriere: this.greentopDownloadData.currentDescarcare.descriere || "",
                geom: wkt.writeGeometry(geomwgs as Polygon),
                startPerioada: this.greentopDownloadData.currentDescarcare.startPerioada.format("YYYY-MM-DD"),
                endPerioada: this.greentopDownloadData.currentDescarcare.endPerioada.format("YYYY-MM-DD"),
                status: "initiat",
                mesajStatus: "",
                granuleSatelitare: [],
                produseDescarcare: [],
                indiciDescarcare: []
            }
            this.greentopDownloadData.currentDescarcare.granuleSatelitare.forEach((item)=>{
                sentData.granuleSatelitare.push({
                    codGranula: item.codGranula,
                    sursaDate: item.sursaDate,
                    geom: item.geom
                })
            })
            this.greentopDownloadData.currentDescarcare.produseDescarcare.forEach((item)=>{
                if (item.selected === true){
                    sentData.produseDescarcare.push({
                        codGranula: item.codGranula,
                        sursaDate: item.sursaDate,
                        numeProdus: item.numeProdus,
                        dataProdus: item.dataProdus,
                        geom: item.geom,
                        uuid: item.uuid,
                        previzualizareS: item.previzualizareS,
                        previzualizareL: item.previzualizareL,
                        status: "initiat",
                        mesajStatus: ""
                    })
                }
            })
            this.greentopDownloadData.currentDescarcare.indiciDescarcare.forEach((item)=>{
                sentData.indiciDescarcare.push({
                    codIndice: item.codIndice,
                    idIndice: item.idIndice,
                    sursaDate: item.sursaDate
                })
            })

            this.mapCtrl.greentopDataService.setSalvareDescarcareDateSatelitare(sentData)
            .then((result)=>{
                //to do switch to existente
                if (typeof result === "number"){
                    return result;
                }
                return null;
            }).catch((reason)=>{
                this.greentopDownloadData.errorMessage = "Eroare salvare planificare descarcare";
                this.$log.error("Erare salvare descarcare: ", getExMessage(reason));
            }).finally(()=>{
                this.greentopDownloadData.loadingSearchProductsActive = false;
            }).then((idDescarcare)=>{
                if (typeof idDescarcare === "number"){
                    this.greentopDownloadData.activeNewTab0 = false;
                    this.greentopDownloadData.activeExistsTab1 = true;
                    this.greentopDownloadData.activeTab = 1;
                    this.initExistingDescarcareDate(idDescarcare as any);
                }
            }).catch((reason)=>{
                this.$log.error("Eroare la schimbare tab spre existente: ", getExMessage(reason));
            })

        } catch (error) {
            let resErr = "Eroare salvare produse descarcare: " +  getExMessage(error);
            this.greentopDownloadData.errorMessage = resErr;
            this.$log.error(resErr);
            this.greentopDownloadData.loadingSearchProductsActive = false;
        }
    }

    //
    //
    //
    public onClickSelectButton = (event) => {
        try {
            if (!this.greentopDownloadData.selectButtonStateOn) {
                // $(this.mapCtrl.selectButtonCtrl["element"]).addClass('select-button-on');
                this.greentopDownloadData.selectLayer.getSource().clear();
                this.greentopDownloadData.currentDescarcare.geom = null;
                this.addSelectLayerInteraction();
                this.greentopDownloadData.selectButtonStateOn = true;
                this.clearSearchResults();
            } else {
                if (this.greentopDownloadData.selectDrawInteraction.getActive()) {
                    this.greentopDownloadData.selectDrawInteraction.finishDrawing();
                } else {
                    // $(this.greentopDownloadData.selectButtonCtrl["element"]).removeClass('select-button-on');
                    this.removeSelectLayerInteraction();
                    this.greentopDownloadData.selectButtonStateOn = false;
                }
            }
        } catch (error) {
            this.$log.error("eroare selectie zona");
        }
        //todo
    }
    //
    public onChangeSursaDate(){
        this.clearSearchResults();
        this.setFilteredIndici();
    }

    //indici
    private setFilteredIndici(setDefault: boolean = true) {
        if (this.greentopDownloadData.currentDescarcare == null) {
            this.greentopDownloadData.filteredIndici.length = 0;
        } else if (this.greentopDownloadData.currentDescarcare) {
            this.greentopDownloadData.filteredIndici.length = 0;
            this.greentopDownloadData.filteredIndici.length = 0;
            if (setDefault) {
                this.greentopDownloadData.currentDescarcare.indiciDescarcare.length = 0;
            }
            if (this.greentopDownloadData.selectedSursaDate == null ||
                this.greentopDownloadData.selectedSursaDate.tip == null){
                return;
            }
            const sursaDate = this.greentopDownloadData.selectedSursaDate.tip;
            //
            this.greentopDownloadData.availableIndici.forEach(item => {
                if (item.sursaDate === sursaDate) {
                    this.greentopDownloadData.filteredIndici.push(item);
                    if (item.implicit === true){
                        //to do add to list
                        let tmpIndDes: IIndiceDescarcare = {
                            idIndice: item.id,
                            codIndice: item.codIndice,
                            sursaDate: item.sursaDate
                        }
                        if(setDefault){
                            this.greentopDownloadData.currentDescarcare.indiciDescarcare.push(tmpIndDes);
                        }
                    }
                }
            });
            this.greentopDownloadData.selectednewIndice = null;
           
        }
    }
    //
    public onAddNewIndice() {
        try {
            if (this.greentopDownloadData.selectednewIndice) {
                let exist = this.greentopDownloadData.currentDescarcare
                    .indiciDescarcare.filter((item) => item.idIndice === this.greentopDownloadData.selectednewIndice.id)
                if (exist && exist.length > 0) {
                    return;
                }
                let tmpIndice: IIndiceAntrenament = {
                    codIndice: this.greentopDownloadData.selectednewIndice.codIndice,
                    idIndice: this.greentopDownloadData.selectednewIndice.id,
                    sursaDate: this.greentopDownloadData.selectednewIndice.sursaDate,
                    idStratSursaIndici: null
                }
                this.greentopDownloadData.currentDescarcare
                    .indiciDescarcare.push(tmpIndice);
            }
        } catch (error) {
            this.$log.error("eroare la adaugare indice descarcare");
        }

    }
    //
    public onRemoveIndice(indice: IIndiceAntrenament) {
        try {
            if (indice) {
                let ind = this.greentopDownloadData.currentDescarcare.indiciDescarcare.indexOf(indice);
                if (ind >= 0) {
                    this.greentopDownloadData.currentDescarcare.indiciDescarcare.splice(ind, 1);
                }
            }
        } catch (error) {
            this.$log.error("eroare la inlaturare indice descarcare")
        }

    }
    //
    public addSelectLayer(){
        this.greentopDownloadData.selectLayer = new VectorLayer<any>({
            source: new VectorSource({ wrapX: false })
        });
        this.mapCtrl.map.addLayer(this.greentopDownloadData.selectLayer);
    }
    public addProductsLayer(){
        this.greentopDownloadData.productsLayer = new VectorLayer<any>({
            source: new VectorSource({ wrapX: false })
        });
        this.mapCtrl.map.addLayer(this.greentopDownloadData.productsLayer);
    }
    public addSelectLayerInteraction() {
        if (this.greentopDownloadData.selectLayer == null){
            this.addSelectLayer;
        }
        this.greentopDownloadData.selectDrawInteraction = new Draw({
            source: this.greentopDownloadData.selectLayer.getSource(),
            type: 'Polygon',// 'Circle',//'Polygon',
            //geometryFunction: Draw.createBox()
        });
        this.mapCtrl.map.addInteraction(this.greentopDownloadData.selectDrawInteraction);
        this.greentopDownloadData.selectDrawInteraction.on('drawend', this.onDrawSelectBoxEnd);
        this.greentopDownloadData.selectDrawInteraction.on('drawstart', this.onDrawSelectBoxStart);
    }
    public removeSelectLayer(){
        if (this.greentopDownloadData.selectLayer) {
            this.mapCtrl.map.removeLayer(this.greentopDownloadData.selectLayer);
            this.greentopDownloadData.selectLayer = null;
        }
    }
    public removeProducstLayer(){
        if (this.greentopDownloadData.productsLayer) {
            this.mapCtrl.map.removeLayer(this.greentopDownloadData.productsLayer);
            this.greentopDownloadData.productsLayer = null;
        }
    }
    public removeSelectLayerInteraction() {
        if (this.greentopDownloadData.selectDrawInteraction) {
            this.mapCtrl.map.removeInteraction(this.greentopDownloadData.selectDrawInteraction);
            this.greentopDownloadData.selectDrawInteraction = null;
        }
    }
    //
    public addAvailableProductsToLayer(){
        this.greentopDownloadData.productsLayer.getSource().clear();
        if (this.greentopDownloadData.currentDescarcare.produseDescarcare){
            let wkt = new WKTFormater();
            this.greentopDownloadData.currentDescarcare.produseDescarcare.forEach((item)=>{
                let geomwgs = wkt.readGeometry(item.geom) as Polygon;
                let geom = geomwgs.transform("EPSG:4326",this.mapCtrl.mapConfig.projection ) as Polygon;
                item.feature = new Feature(geom);
                item.feature.set("uuid", item.uuid);
                this.greentopDownloadData.productsLayer.getSource().addFeature(item.feature);
            })
        }
    }
    //
    public addcurrentProductsToLayer(){
        this.greentopDownloadData.productsLayer.getSource().clear();
        if (this.greentopDownloadData.currentDescarcare.produseDescarcare){
            this.greentopDownloadData.currentDescarcare.produseDescarcare.forEach((item)=>{
                let wkt = new WKTFormater();
                let geomwgs = wkt.readGeometry(item.geom) as Polygon;
                let geom = geomwgs.transform("EPSG:4326",this.mapCtrl.mapConfig.projection ) as Polygon;
                item.feature = new Feature(geom);
                item.feature.set("uuid", item.uuid);
                this.greentopDownloadData.productsLayer.getSource().addFeature(item.feature);
            })
        }
    }

    public flipwktPolygonCoordinates(polygonWKT: string): {geom: Polygon, wkt: string}{
        let wkt = new WKTFormater();
        let geomwgs = wkt.readGeometry(polygonWKT) as Polygon;
        let coords = geomwgs.getCoordinates();
        if (coords.length === 1) {
            coords = coords[0] as any;
        }
        let revCoord = coords.map((point) => {
            return [point[1], point[0]]
        })
        let newPolygon = new Polygon([revCoord as any]);
        return {
            geom: newPolygon,
            wkt: wkt.writeGeometry(newPolygon)
        }
    }

    public onProducListItemClick(product: IProdusDescarcare){
        this.greentopDownloadData.productsLayer.getSource().clear();
        this.greentopDownloadData.productsLayer.getSource().addFeature(product.feature);
    }

    public onProductListImageClick(product: IProdusDescarcare){
        try {
            if (product.previzualizareL){
                let imgData = [{imagename: product.numeProdus, imageurl: product.previzualizareL}]
                this.mapCtrl.mapDialogs.showInfoFeatureImagesDialog(imgData);
            }
        }catch (e) {
            this.$log.error("Eroare afisare imagine", getExMessage(e));
        }
    }

    public onDrawSelectBoxStart = (event) => {
        this.greentopDownloadData.selectLayer.getSource().clear();
        this.greentopDownloadData.currentDescarcare.geom = null;
    }

    public onDrawSelectBoxEnd = (event) => {
        let extentFeature = event.feature as Feature;
        this.greentopDownloadData.currentDescarcare.geom = extentFeature.getGeometry() as any || null;
        this.greentopDownloadData.selectDrawInteraction.setActive(false);
        // to do get extent list details
        if (extentFeature) {
            let extentPolygon = extentFeature.getGeometry() as Polygon;
            
        }
    }

    public canRestartDownload() {
        if (this.greentopDownloadData.currentDescarcare.actiune
            && this.greentopDownloadData.currentDescarcare.actiune.toLowerCase() === TaskActiune.start) {
            return false;
        } else {
            return this.greentopDownloadData.currentDescarcare.status.toLowerCase() !== TaskStatus.in_lucru
               // && this.greentopDownloadData.currentDescarcare.status.toLowerCase() !== TaskStatus.finalizat;
        }
    }

    public onRestartDownload() {
        if (this.greentopDownloadData.currentDescarcare.status
            && this.greentopDownloadData.currentDescarcare.status.toLowerCase() === TaskStatus.finalizat) {
            if (confirm("Confirmă că dorești sa repornești descărcarea de la început") === false) {
                return;
            }
        } 
        //
        this.greentopDownloadData.errorMessage = "";
        this.greentopDownloadData.loadingSearchProductsActive = true;
            this.mapCtrl.greentopDataService.setNotificareDescarcareDateSatelitare(this.greentopDownloadData.currentDescarcare.id, null)
            .then((result)=>{
                //
                return true;
            }).catch((reason)=>{
                this.$log.error("Eroare restart descarcare");
            }).finally(()=>{
                this.onChangeDescarcare();
            });
            
    }

    public canStopDownload() {
        if (this.greentopDownloadData.currentDescarcare.actiune
            && this.greentopDownloadData.currentDescarcare.actiune.toLowerCase() === TaskActiune.stop) {
            return false;
        } else if (this.greentopDownloadData.currentDescarcare.actiune
            && this.greentopDownloadData.currentDescarcare.actiune.toLowerCase() === TaskActiune.start
            && this.greentopDownloadData.currentDescarcare.status.toLowerCase() === TaskStatus.initiat) {
                return true;
        } else {
            return this.greentopDownloadData.currentDescarcare.status.toLowerCase() === TaskStatus.in_lucru
        }
    }

    public onStopDownload() {
        this.greentopDownloadData.errorMessage = "";
        this.greentopDownloadData.loadingSearchProductsActive = true;
            this.mapCtrl.greentopDataService.setNotificareDescarcareDateSatelitare(this.greentopDownloadData.currentDescarcare.id, {isStop: true})
            .then((result)=>{
                //
                return true;
            }).catch((reason)=>{
                this.$log.error("Eroare stop descarcare");
            }).finally(()=>{
                this.onChangeDescarcare();
            });
            
    }

    public canContinueDownload() {
        if (this.greentopDownloadData.currentDescarcare.actiune
            && this.greentopDownloadData.currentDescarcare.actiune.toLowerCase() === TaskActiune.start) {
            return false;
        } else {
            return this.greentopDownloadData.currentDescarcare.status.toLowerCase() !== TaskStatus.in_lucru
                && this.greentopDownloadData.currentDescarcare.status.toLowerCase() !== TaskStatus.finalizat;
        }
    }

    public onContinueDownload() {
        this.greentopDownloadData.errorMessage = "";
        this.greentopDownloadData.loadingSearchProductsActive = true;
            this.mapCtrl.greentopDataService.setNotificareDescarcareDateSatelitare(this.greentopDownloadData.currentDescarcare.id, {isContinue: true})
            .then((result)=>{
                //
                return true;
            }).catch((reason)=>{
                this.$log.error("Eroare continuare descarcare");
            }).finally(()=>{
                this.onChangeDescarcare();
            });
            
    }

    public bindSursaDate(){
        if (this.greentopDownloadData.currentDescarcare.sursaDate){
            let rez = listaSursaDateClasificare.filter(item=> item.tip === this.greentopDownloadData.currentDescarcare.sursaDate);
            if (rez && rez.length > 0){
                return rez[0].denumire;
            } else {
                return this.greentopDownloadData.currentDescarcare.sursaDate;
            }
        }
        //
        return "";
    }

    //
    onInitNewFromExisting(){
        try {
            if (this.greentopDownloadData.currentDescarcare == null) {
                throw new Error("nu este selectata descarcare");
            }
            this.greentopDownloadData.activeExistsTab1 = false;
            this.greentopDownloadData.activeNewTab0 = true;
            this.greentopDownloadData.activeTab = 0;
            this.greentopDownloadData.ctrlDownloadDetailsIsColapsed = false;

            this.greentopDownloadData.currentDescarcare.denumire = "";
            this.greentopDownloadData.currentDescarcare.status = "initiat";
            this.greentopDownloadData.currentDescarcare.mesajStatus = "";
            this.greentopDownloadData.availableIndici.length = 0;
            let resultSursa = this.greentopDownloadData.availableSursaDate.filter((fitem)=>this.greentopDownloadData.currentDescarcare.sursaDate === fitem.tip);
            if (resultSursa && resultSursa.length > 0){
                this.greentopDownloadData.selectedSursaDate = resultSursa[0];
            }
            this.greentopDownloadData.currentDescarcare.produseDescarcare.forEach((item)=>{
                item.selected = true;
            })
            if (typeof this.greentopDownloadData.currentDescarcare.geom  === "string"){
                let wkt = new WKTFormater();
                let geomwgs = wkt.readGeometry(this.greentopDownloadData.currentDescarcare.geom) ;
                let geom = geomwgs.transform("EPSG:4326",this.mapCtrl.mapConfig.projection )as Polygon;
                this.greentopDownloadData.currentDescarcare.geom = geom;
            }
            //
            this.greentopDownloadData.errorMessage = "";
            this.greentopDownloadData.loadingSearchProductsActive = true;
            this.mapCtrl.greentopDataService.getAvailableIndici()
                .then((data) => {
                    this.greentopDownloadData.availableIndici = data;
                    this.setFilteredIndici(false);
                    return true;
                }).catch((reason) => {
                    this.greentopDownloadData.errorMessage = "Eroare interogare indici";
                    this.$log.error("Eroare interogare indici: ", getExMessage(reason));
                }).finally(() => {
                    this.greentopDownloadData.loadingSearchProductsActive = false;
                })
        } catch (error) {
            this.$log.error("Eroare initiere descarcare noua din existanta", getExMessage(error));
        }
        
    }
}