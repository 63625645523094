import { NgbDateAdapter, NgbDateStruct, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';
import * as moment from 'moment';

// Might need to polyfill depending on needed browser support...
const isInt = Number.isInteger;

@Injectable()
export class NgbDateMomentAdapter extends NgbDateAdapter<moment.Moment> {
    
  fromModel(date: moment.Moment): NgbDateStruct {
    if (!date || !moment.isMoment(date)) {
      return null;
    }

    return {
      year: date.year(),
      month: date.month() + 1,
      day: date.date(),
    };
  }

  toModel(date: NgbDateStruct): moment.Moment {
    if (!date || !isInt(date.day) || !isInt(date.day) || !isInt(date.day)) {
      return null;
    }

    return moment(`${date.year}-${date.month}-${date.day}`,  'YYYY-MM-DD');
  }
}

export class NgbDateMomentParserFormatter extends NgbDateParserFormatter {
    constructor(private momentFormat: string) {
        super();
    };
    format(date: NgbDateStruct): string {
        if (date === null) {
            return '';
        }
        let d = moment({ year: date.year, 
                         month: date.month - 1, 
                         date: date.day });
        return d.isValid() ? d.format(this.momentFormat) : '';
    }

    parse(value: string): NgbDateStruct {
        if (!value) {
            return null;
        }
        let d = moment(value, this.momentFormat);
        return d.isValid() ? { year: d.year(), 
                               month: d.month() + 1, 
                               day: d.date() } : null;
    }

    public setFormat(momentNewFormat: string){
      this.momentFormat = momentNewFormat;
    }
}