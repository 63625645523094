 
 
//
import { NGXLogger } from "ngx-logger";
import { AppSettings } from "../app-settings";
import { featureTypeAddEdit, ILayer, IMenuFeatureItem, WFSTActionType } from "../definitions";
import { MapController } from "./map-controller";


import VectorLayer from 'ol/layer/Vector';
import Modify from 'ol/interaction/Modify';
import Feature from 'ol/Feature';
import VectorSource from "ol/source/Vector";
import OlStyle from "ol/style/Style";
import StrokeStyle from "ol/style/Stroke";
import IconStyle from "ol/style/Icon";
import CircleStyle from "ol/style/Circle";
import FillStyle from "ol/style/Fill";

    export class MapCtrlEditFeature {
        public constructor(public mapCtrl: MapController, private $log : NGXLogger) {

        };
        public infoAdd = ' - Adăugare locație la click,\n - Mutare locație prin tragere (opțional), \n - Adăugarea informațiilor';
        public infoEdit = "Modificarea locației se face prin selectie si tragere";
        public onClickEditGeometry = (object: JQueryEventObject): void => {
            this.mapCtrl.editFeatureReferenceLayer = object.data['layer'];
            
            if (("data" in object) && ("feature" in object.data) && ("layer" in object.data)) {
                this.$log.log(object.data);

                let featureClone = object.data['feature'].clone();

                let feature: Feature = new Feature();
                feature.setId(object.data['feature'].getId());
                feature.setGeometry(featureClone.getGeometry());

                switch (feature.getGeometry().getType()) {
                    case featureTypeAddEdit.point:
                        this.mapCtrl.editLayerFeature = this.editLayerFeaturePoint();
                        this.mapCtrl.selectModifyInteraction = this.modifyInteractionPoint();
                        break;
                    case featureTypeAddEdit.line:
                        this.mapCtrl.editLayerFeature = this.editLayerFeatureLine();
                        this.mapCtrl.selectModifyInteraction = this.modifyInteractionLine();
                    case featureTypeAddEdit.polygon:
                        this.mapCtrl.editLayerFeature = this.editLayerFeaturePolygon();
                        this.mapCtrl.selectModifyInteraction = this.modifyInteractionPolygon();
                        break;
                    case featureTypeAddEdit.icon:
                        this.mapCtrl.editLayerFeature = this.editLayerFeatureIcon();
                        this.mapCtrl.selectModifyInteraction = this.modifyInteractionIcon();
                        break;
                    default:
                        return;
                }

                this.mapCtrl.editLayerFeature.getSource().addFeature(feature);
                this.mapCtrl.map.addInteraction(this.mapCtrl.selectModifyInteraction);
                this.mapCtrl.map.addLayer(this.mapCtrl.editLayerFeature);
                this.mapCtrl.editLayerFeature.setZIndex(10000);
                this.mapCtrl.showEditFeature = true;
            }

        }


        private editLayerFeaturePoint = () => {
            return new VectorLayer<any>({
                source: new VectorSource(),
                style: new OlStyle({
                    image: new IconStyle({
                        crossOrigin: 'anonymous',
                        src: AppSettings.serverPath + '/img/featureEdit.png',
                        anchor: [0.5, 0.0],
                        anchorOrigin: 'bottom-left'
                    })
                })
            });
        }

        private modifyInteractionPoint = () => {
            return new Modify({
                source: this.mapCtrl.editLayerFeature.getSource(),
                style: new OlStyle({
                    fill: new FillStyle({
                        color: 'rgba(255, 255, 255, 0.2)'
                    }),
                    stroke: new StrokeStyle({
                        color: '#ffcc33',
                        width: 2
                    }),
                    image: new CircleStyle({
                        radius: 7,
                        fill: new FillStyle({
                            color: '#ffcc33'
                        })
                    })
                })
            } as any);
        }

        private editLayerFeatureLine = () => {
            return new VectorLayer<any>({
                source: new VectorSource(),
                style: new OlStyle({
                    stroke: new StrokeStyle({
                        color: '#ffcc33',
                        width: 4
                    })
                })
            });
        }

        private modifyInteractionLine = () => {
            return new Modify({
                source: this.mapCtrl.editLayerFeature.getSource()
            } as any);
        }

        private editLayerFeaturePolygon = () => {
            return new VectorLayer<any>({
                source: new VectorSource(),
                style: new OlStyle({
                    stroke: new StrokeStyle({
                        color: 'rgba(123, 45, 25, 1)',
                        width: 4
                    }),
                    fill: new FillStyle({
                        color: 'rgba(28, 15, 125, 0.5)'
                    })
                })
            });
        }

        private modifyInteractionPolygon = () => {
            return new Modify({
                source: this.mapCtrl.editLayerFeature.getSource()
            } as any);
        }

        private editLayerFeatureIcon = () => {
            return new VectorLayer<any>({
                source: new VectorSource(),
                style: new OlStyle({
                    image: new IconStyle({
                        crossOrigin: 'anonymous',
                        src: AppSettings.serverPath + '/img/featureEdit.png',
                        anchor: [0.5, 0.0],
                        anchorOrigin: 'bottom-left'
                    })
                })
            });
        }

        private modifyInteractionIcon = () => {
            return new Modify({
                source: this.mapCtrl.editLayerFeature.getSource(),
                style: new OlStyle({
                    fill: new FillStyle({
                        color: 'rgba(255, 255, 255, 0.2)'
                    }),
                    stroke: new StrokeStyle({
                        color: '#ffcc33',
                        width: 2
                    }),
                    image: new CircleStyle({
                        radius: 7,
                        fill: new FillStyle({
                            color: '#ffcc33'
                        })
                    })
                })
            } as any);
        }

        public saveGeometry() {
            let features = this.mapCtrl.editLayerFeature.getSource().getFeatures();
            //
            if (features.length) {
                let feature = features[0];
                let layer = this.mapCtrl.editFeatureReferenceLayer['appLayer'] as ILayer;
                feature.setGeometry(feature.getGeometry().transform(this.mapCtrl.mapConfig.projection, layer.projection || this.mapCtrl.mapConfig.projection));
                //
                features[0].setGeometryName((layer.infoGeometry as any)[0].name);
                this.mapCtrl.userSettingsSrvs.setFeatureToGisServerWFST(layer, feature, WFSTActionType.updateLocation)
                    .then(result => {
                        if (result == false) {
                            alert("eroare salvare locatie")
                        } else {
                            //send window message
                            this.mapCtrl.windowMessageService.sendWindowMessageFeatureChanged(layer.name, WFSTActionType.updateLocation, null, feature)
                        }
                        (layer.internalLayer as VectorLayer<any>).getSource().refresh();
                    })
                    .catch((reason) => {
                        this.$log.error("eroare salvare locatie");
                    });
            } else {
                alert('nu sunt locatii');
            }

            this.cancelSaveGeometry();
        }

        public cancelSaveGeometry() {
            this.mapCtrl.map.removeInteraction(this.mapCtrl.selectModifyInteraction);
            this.mapCtrl.map.removeInteraction(this.mapCtrl.selectDrawInteraction);
            this.mapCtrl.map.removeLayer(this.mapCtrl.editLayerFeature);
            this.mapCtrl.showEditFeature = false;
            this.mapCtrl.showAddFeature = false;
            this.mapCtrl.showMainMenu = true;
        }

        public addEditFeatureInfo() {
            let layer = this.mapCtrl.editFeatureReferenceLayer;

            let specificEditAction: IMenuFeatureItem;
            if (layer.menuFeatureItems && layer.menuFeatureItems.length > 0) {
                let maction = layer.menuFeatureItems.filter((aitem) => { return aitem.action === "editFeature"; });
                if (maction && maction.length > 0) {
                    specificEditAction = maction[0];
                }
            }
            //specific or standard edit dialog
            if (specificEditAction) {
                //this.featureMenuAction(layer, object.data["feature"], specificEditAction);
            } else {
                this.mapCtrl.mapDialogs.showEditInfoDialog(this.mapCtrl.newFeature, layer.internalLayer as any, false, this.mapCtrl.addFeatureData);
            }

            (this.mapCtrl.infoOverlay as any).hide();
            this.mapCtrl.mapCtrlEditFeature.cancelSaveGeometry();
        }

    }
