
import { featureType, authOpt, authType, ILayer } from "../definitions";
import { IIndiceAntrenament } from "../services/greentop-data-service";
import { MapController } from "./map-controller";

import VectorLayer from 'ol/layer/Vector';
import Polygon from 'ol/geom/Polygon';
import TileLayer from 'ol/layer/Tile';
import  TileWMSSource  from "ol/source/TileWMS";
import WKTFormater from "ol/format/WKT";

export function createGeomFromWKT(wktString: string, epsgSource: string, epsgDest: string): Polygon {
    let geom = null;
    try {
        let wkt = new WKTFormater();
        let geomwgs = wkt.readGeometry(wktString) as Polygon;
        geom = geomwgs.transform(epsgSource,epsgDest) as Polygon;
        //geom = geomwgs.transform("EPSG:4326", this.mapCtrl.mapConfig.projection) as Polygon;
    } catch (error) {
        console.log("eroare conversie geom");
    }
    //
    return geom;
}
 ////
 export function createWKTFromGeom(geom: Polygon, epsgSource: string, epsgDest: string) {
    let wktgeom = null
    try {
        let wkt = new WKTFormater();
        let geomwgs = geom.transform(epsgSource, epsgDest) as Polygon;
        //let geomwgs = geom.transform(this.mapCtrl.mapConfig.projection, "EPSG:4326") as Polygon;
        wktgeom = wkt.writeGeometry(geomwgs);

    } catch (error) {
        console.log("eroare conversie geom");
    }
    return wktgeom;
}
//
export function convertDataDecadaToString(datadec: { luna: number, decada: number }) {
    if (datadec.luna == null || datadec.decada == null) {
        return null;
    }
    let strLuna = datadec.luna < 10 ? `0${datadec.luna}` : `${datadec.luna}`
    return `${strLuna}-${datadec.decada}`
}
//
export function convertDataStringToDecada(datastr: string): { luna: number, decada: number } {
    let res = { luna: null, decada: null };
    try {
        if (datastr == null || datastr === "" || datastr.length < 3) {
            return res;
        }
        let rezSplit = datastr.split("-");
        if (rezSplit.length !== 2) {
            return res;
        }
        res.luna = Number(rezSplit[0]);
        res.decada = Number(rezSplit[1]);
    } catch (error) {
        console.log("eroare la extragere luna decada din string");
    }
    return res;
}

export function findStratAsociatToIndice(ind: IIndiceAntrenament, mapCtrl: MapController, type: string = "greentop"){
    ind.stratAsociat = null;
    try {
        if (ind.codIndice == null || ind.codIndice.length < 2){
            throw Error("codIndice nu e specificat");
        }
        let authSursa = authOpt.greentop_sursa_referinta;
        let authIndice = authOpt.greentop_indice_referinta
        if (type === "integrateIndConex"){
            authSursa = authOpt.integrate_indconex_sursa_referinta;
            authIndice = authOpt.integrate_indconex_indice_referinta;
        }
        mapCtrl.categories.forEach((catItem) => {
            if (ind.stratAsociat == null){
                catItem.layers.forEach((layItem) => {
                    if (ind.stratAsociat == null) {
                        if (layItem.featureType === featureType.tile) {
                            //to do check if config is for import
                            let sursaDate = "";//"sentinel-2";//default value
                            let infSursa =  mapCtrl.userSettingsSrvs.isAuthForOptionFullInfo(authSursa, layItem.name, authType.layer)
                            if (infSursa && infSursa.descriere){
                                sursaDate = infSursa.descriere;
                            }
                           let infauth =  mapCtrl.userSettingsSrvs.isAuthForOptionFullInfo(authIndice, layItem.name, authType.layer)
                            if (infauth && infauth.descriere && infauth.descriere.length > 0
                                && sursaDate && sursaDate.length > 0
                                && infauth.descriere.toLowerCase() === ind.codIndice.toLowerCase()
                                && sursaDate.toLowerCase() === ind.sursaDate.toLowerCase()
                                ) {
                                    ind.stratAsociat = layItem;
                            }
                        }
                    }
                })
            }
        });
    } catch (reason) {
        console.log("Eroare asociere strat indice", getExMessage(reason));
    }
    
}

export function findDatesStratReferintaForLayer(layer: ILayer, mapCtrl: MapController ): Array<string>{
    let sourceLayerName = mapCtrl.userSettingsSrvs.isAuthForOptionFullInfo(authOpt.animate_time_raster_source, layer.name, authType.layer);
    if (sourceLayerName === undefined || sourceLayerName.descriere === undefined || sourceLayerName.descriere === '') {
        throw new Error("lipseste nume strat connex");
    }
    // cautam stratul din harta
    let sourceLayer =mapCtrl.mapOlFeatures.searchForVectorLayer(sourceLayerName.descriere);
    if (sourceLayer === null) {
        throw new Error("lipseste stratul connex");
    }
    //extract source column animate_time_raster_column
    let sourceColumnName = mapCtrl.userSettingsSrvs.isAuthForOptionFullInfo(authOpt.animate_time_raster_column, layer.name, authType.layer);
    if (sourceColumnName === undefined || sourceColumnName.descriere === undefined || sourceColumnName.descriere === '') {
        throw new Error("lipseste nume strat connex");
    }
    let sourceVectorColumn = sourceColumnName.descriere;
    //get the steps from connected layer
    let features = (sourceLayer.internalLayer as VectorLayer<any>).getSource().getFeatures();
    let steps = [];
    features.forEach((fitem) => {
        let strDate = fitem.get(sourceVectorColumn);
        //
        if (strDate) {
            let exists = steps.filter((item) => item === strDate);
            if (exists == null || exists.length == 0) {
                steps.push(strDate);
            }
        }
    });
    steps.sort();
    return steps;
}

export function findStratReferintaForLayer(layer: ILayer, mapCtrl: MapController ): ILayer{
    let sourceLayerName = mapCtrl.userSettingsSrvs.isAuthForOptionFullInfo(authOpt.animate_time_raster_source, layer.name, authType.layer);
    if (sourceLayerName === undefined || sourceLayerName.descriere === undefined || sourceLayerName.descriere === '') {
        return null;
    }
    // cautam stratul din harta
    let sourceLayer = mapCtrl.mapOlFeatures.searchForVectorLayer(sourceLayerName.descriere);
    //
    return sourceLayer;
}

export function setMomentOnTileLayer(layer: ILayer, moment: string){
    let source = (layer.internalLayer as TileLayer<any>).getSource() as TileWMSSource;
        let params = source.getParams();
        params['time'] = moment;//time=2009-11-01
        source.updateParams(params);
        source.changed();
}

export function delay(ms: number) : Promise<any>{
    return new Promise(resolve => setTimeout(resolve, ms));
}

//camelcase underscore dash and . to space
export function readablePropertyName(propName) {
    if (!propName || propName == null) {
        return null;
    }
    var rex = /([A-Z])([A-Z])([a-z])|([a-z])([A-Z])/g;
    var words = propName.replace(rex, '$1$4 $2$3$5')
    .replace('_', ' ').replace('-', ' ').replace('.', ' ').split(' ');
    return words.map(function (word) { return word.substring(0, 1).toUpperCase() + ((word.length > 1) ? word.substring(1, word.length) : ''); }).join(' ');
}

export function downloadFile(fileName, csvContent, columnSeparator, exporterOlderExcelCompatibility, exporterIsExcelCompatible) {
    var D = document,
      a = D.createElement('a'),
      strMimeType = 'application/octet-stream;charset=utf-8',
      rawFile,
      ieVersion = isIE();

    if (exporterIsExcelCompatible) {
        csvContent = 'sep=' + columnSeparator + '\r\n' + csvContent;
    }

    // IE10+
    if ((navigator as any).msSaveBlob) {
      return (navigator as any).msSaveOrOpenBlob(
        new Blob(
          [exporterOlderExcelCompatibility ? "\uFEFF" : '', csvContent],
          { type: strMimeType } ),
        fileName
      );
    }

    if (ieVersion) {
      var frame = D.createElement('iframe');

      document.body.appendChild(frame);

      frame.contentWindow.document.open('text/html', 'replace');
      frame.contentWindow.document.write(csvContent);
      frame.contentWindow.document.close();
      frame.contentWindow.focus();
      frame.contentWindow.document.execCommand('SaveAs', true, fileName);

      document.body.removeChild(frame);
      return true;
    }

    // html5 A[download]
    if ('download' in a) {
      var blob = new Blob(
        [exporterOlderExcelCompatibility ? "\uFEFF" : '', csvContent],
        { type: strMimeType }
      );
      rawFile = URL.createObjectURL(blob);
      a.setAttribute('download', fileName);
    } else {
      rawFile = 'data: ' + strMimeType + ',' + encodeURIComponent(csvContent);
      (a as any).setAttribute('target', '_blank');
    }

    a.href = rawFile;
    a.setAttribute('style', 'display:none;');
    D.body.appendChild(a);
    setTimeout(function() {
      if (a.click) {
        a.click();
        // Workaround for Safari 5
      } else if (document.createEvent) {
        var eventObj = document.createEvent('MouseEvents');
        eventObj.initEvent('click', true, true);
        a.dispatchEvent(eventObj);
      }
      D.body.removeChild(a);

    }, 100);
  }

  export function isIE() {
    var match = navigator.userAgent.search(/(?:Edge|MSIE|Trident\/.*; rv:)/);
    var isIE = false;

    if (match !== -1) {
      isIE = true;
    }

    return isIE;
  }
  //
  export function getExMessage(exception: any): string{
     let message = "";
      if (isString(exception)){
        message = exception;
      } else if (isBoolean(exception)){
        message = exception.toString();
      } else if (isNumber(exception)){
        message = exception.toString();
      } else if(isObject(exception)){
        if ("message" in exception && isString(exception.measure)){
          message = exception.message;
        }
      }
     return message;
  }

//Angular legacy
export function isBoolean(value) { return typeof value === 'boolean'; }

export function isArray(arr) { return Array.isArray(arr) || arr instanceof Array; }

export function isNumber(value) { return typeof value === 'number'; }

export function isString(value) { return typeof value === 'string'; }

// http://jsperf.com/isobject4
export function isObject(value) { return value !== null && typeof value === 'object'; }

export function isDefined(value) { return typeof value !== 'undefined'; }

export function isUndefined(value) { return typeof value === 'undefined'; }

export function isFunction(value) { return typeof value === 'function'; }

export function isRegExp(value) { return toString.call(value) === '[object RegExp]'; }

export function isWindow(obj) { return obj && obj.window === obj; }

export function isScope(obj) { return obj && obj.$evalAsync && obj.$watch; }

export function isDate(value) { return toString.call(value) === '[object Date]';}

export function toJson(obj, pretty = null) {
    if (isUndefined(obj)) return undefined;
    if (!isNumber(pretty)) {
        pretty = pretty ? 2 : null;
    }
    return JSON.stringify(obj, toJsonReplacer, pretty);
}
export function fromJson(json) {
    return isString(json)
        ? JSON.parse(json)
        : json;
}

function toJsonReplacer(key, value) {
    var val = value;

    if (typeof key === 'string' && key.charAt(0) === '$' && key.charAt(1) === '$') {
        val = undefined;
    } else if (isWindow(value)) {
        val = '$WINDOW';
    } else if (value && window.document === value) {
        val = '$DOCUMENT';
    } else if (isScope(value)) {
        val = '$SCOPE';
    }

    return val;
}

export function simpleCompare(a, b) { return a === b || (a !== a && b !== b); }

export function equals(o1, o2) {
    if (o1 === o2) return true;
    if (o1 === null || o2 === null) return false;
    // eslint-disable-next-line no-self-compare
    if (o1 !== o1 && o2 !== o2) return true; // NaN === NaN
    var t1 = typeof o1, t2 = typeof o2, length, key, keySet;
    if (t1 === t2 && t1 === 'object') {
      if (isArray(o1)) {
        if (!isArray(o2)) return false;
        if ((length = o1.length) === o2.length) {
          for (key = 0; key < length; key++) {
            if (!equals(o1[key], o2[key])) return false;
          }
          return true;
        }
      } else if (isDate(o1)) {
        if (!isDate(o2)) return false;
        return simpleCompare(o1.getTime(), o2.getTime());
      } else if (isRegExp(o1)) {
        if (!isRegExp(o2)) return false;
        return o1.toString() === o2.toString();
      } else {
        if (isScope(o1) || isScope(o2) || isWindow(o1) || isWindow(o2) ||
          isArray(o2) || isDate(o2) || isRegExp(o2)) return false;
        keySet = createMap();
        for (key in o1) {
          if (key.charAt(0) === '$' || isFunction(o1[key])) continue;
          if (!equals(o1[key], o2[key])) return false;
          keySet[key] = true;
        }
        for (key in o2) {
          if (!(key in keySet) &&
              key.charAt(0) !== '$' &&
              isDefined(o2[key]) &&
              !isFunction(o2[key])) return false;
        }
        return true;
      }
    }
    return false;
  }
  
  function createMap() {
    return Object.create(null);
  }