declare var GeoStylerSLDParser: any;
declare var _: any;


declare type ContrastEnhancement = any;
declare type Style = any;
declare type UnsupportedProperties = any;



  export class GeoserverSldStyleParser extends GeoStylerSLDParser.SldStyleParser {



    /**
       * Get the GeoStyler-Style ContrastEnhancement from a SLD ContrastEnhancement.
       *
       * @param {object} sldContrastEnhancement The SLD ContrastEnhancement
       */
    getContrastEnhancementFromSldContrastEnhancement(sldContrastEnhancement: any): ContrastEnhancement {
      const contrastEnhancement: ContrastEnhancement = {};

      // parse enhancementType
      const hasHistogram = typeof sldContrastEnhancement.Histogram !== 'undefined';
      const hasNormalize = typeof sldContrastEnhancement.Normalize !== 'undefined';
      if (hasHistogram && hasNormalize) {
        throw new Error(`Cannot parse ContrastEnhancement. Histogram and Normalize
    are mutually exclusive.`);
      } else if (hasHistogram) {
        contrastEnhancement.enhancementType = 'histogram';
        contrastEnhancement["histogram"] = _.get(sldContrastEnhancement, 'Histogram[0]');
      } else if (hasNormalize) {
        contrastEnhancement.enhancementType = 'normalize';
        contrastEnhancement["normalize"] = _.get(sldContrastEnhancement, 'Normalize[0]');
      }
      // parse gammavalue
      let gammaValue = _.get(sldContrastEnhancement, 'GammaValue[0]._');
      if (gammaValue) {
        gammaValue = parseFloat(gammaValue);
      }
      contrastEnhancement.gammaValue = gammaValue;

      return contrastEnhancement;
    }

    /**
       * Get the SLD Object (readable with xml2js) from an GeoStyler-Style ContrastEnhancement.
       *
       * @param {ContrastEnhancement} contrastEnhancement A GeoStyler-Style ContrastEnhancement.
       * @return {object} The object representation of a SLD ContrastEnhancement (readable with xml2js)
       */
    getSldContrastEnhancementFromContrastEnhancement(contrastEnhancement: ContrastEnhancement): any {
      const sldContrastEnhancement: any = [{}];
      const enhancementType = _.get(contrastEnhancement, 'enhancementType');
      if (enhancementType === 'normalize') {
        // parse normalize
        if (contrastEnhancement.normalize == null) {
          sldContrastEnhancement[0].Normalize = [''];
        } else {
          sldContrastEnhancement[0].Normalize = [contrastEnhancement.normalize];
          this.removeMeta(sldContrastEnhancement[0].Normalize);
        }

      } else if (enhancementType === 'histogram') {
        // parse histogram
        if (contrastEnhancement.histogram == null) {
          sldContrastEnhancement[0].Histogram = [''];
        } else {
          sldContrastEnhancement[0].Histogram = [contrastEnhancement.histogram];
          this.removeMeta(sldContrastEnhancement[0].Histogram);
        }

      }
      // parse gammaValue
      if (typeof contrastEnhancement.gammaValue !== 'undefined') {
        sldContrastEnhancement[0].GammaValue = [contrastEnhancement.gammaValue.toString()];
      }
      return sldContrastEnhancement;
    }

    removeMeta(obj: any) {
      for (let prop in obj) {
        if (prop === '$$')
          delete obj[prop];
        else if (typeof obj[prop] === 'object')
          this.removeMeta(obj[prop]);
      }
    }


    getStyleNameFromSldObject(sldObject: any): string {
      let test = sldObject.StyledLayerDescriptor.NamedLayer[0].Name[0]._;
      return test;
      //const userStyleTitle = _get(sldObject, 'StyledLayerDescriptor.NamedLayer[0].UserStyle[0].Title[0]._');
      // const namedLayerName = _get(sldObject, 'StyledLayerDescriptor.NamedLayer[0].Name[0]._');

      //return userStyleTitle ? userStyleTitle
      //   : namedLayerName ? namedLayerName : '';
    }
    geoStylerStyleToSldObject(geoStylerStyle: any): any {
      const rules: any[] = this.getSldRulesFromRules(geoStylerStyle.rules);
      // add the ogc namespace to the filter element, if a filter is present
      rules.forEach(rule => {
        if (rule.Filter && !rule.Filter.$) {
          rule.Filter.$ = { 'xmlns': 'http://www.opengis.net/ogc' };
        }
      });

      //console.log("sld version ", this.sldVersion);
      // if (this.sldVersion !== '1.0.0') {
      //   return SymbologyEncoder.getSymbologyEncoding(
      //     geoStylerStyle, rules, this.symbolizerUnits);
      // }
      let scriptString = geoStylerStyle['jiffleScript'] || "";
      let jiffleTransformation = {
        'ogc:Function': {
          '$': { 'name': 'ras:Jiffle' },
          'ogc:Function': [
            {
              '$': { 'name': 'parameter' },
              'ogc:Literal': 'coverage'
            },
            {
              '$': { 'name': 'parameter' },
              'ogc:Literal': ['script', scriptString]
            }
          ]
        }
      }
      if (scriptString == null || scriptString == "") {
        jiffleTransformation = null;
      }
      //
      return {
        StyledLayerDescriptor: {
          '$': {
            'version': '1.0.0',
            'xsi:schemaLocation': 'http://www.opengis.net/sld StyledLayerDescriptor.xsd',
            'xmlns': 'http://www.opengis.net/sld',
            'xmlns:ogc': 'http://www.opengis.net/ogc',
            'xmlns:xlink': 'http://www.w3.org/1999/xlink',
            'xmlns:xsi': 'http://www.w3.org/2001/XMLSchema-instance'
          },
          'NamedLayer': [{
            'Name': [geoStylerStyle.name || ''],
            'UserStyle': [{
              'Name': [geoStylerStyle.name || ''],
              'Title': [geoStylerStyle.name || ''],
              'FeatureTypeStyle': [{
                'Transformation': [jiffleTransformation],
                'Rule': rules
              }]
            }]
          }]
        }
      };
    }
  }
