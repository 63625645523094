//NG+
import { AppSettings } from "../app-settings";
import { featureType, IItem, ILayerStyleSettings } from "../definitions";
import { UserSettingsService } from "./user-settings-service";

import { NGXLogger } from "ngx-logger";
import { HttpClient, HttpParams } from "@angular/common/http";
import { toJson } from "../map/map-utils";


export interface IDbStoreLayerStyle {
    getAvailableStylesDescriptionsFromStorage(): Promise<Array<IItem>>;
    getStyleDescriptionFromStorage(id: number): Promise<ILayerStyleSettings>;
    setAddStyleSettingsToStorage(styleSett: ILayerStyleSettings, file: any): Promise<boolean>;
    setUpdateStyleSettingsToStorage(styleSett: ILayerStyleSettings, file: any): Promise<boolean>;
    setDeleteStyleSettingsToStorage(styleSettId: number): Promise<boolean>;
    getAssignedStylesForLayer(layerId: number): Promise<Array<ILayerStyleSettings>>;
    getAvailableStylesForLayerType(layerType: string): Promise<Array<IItem>>;
}

export class DbStoreLayerStyle implements IDbStoreLayerStyle {

    constructor(private $http: HttpClient, private $log: NGXLogger, private settingsService: UserSettingsService) { }


    //
    public getAvailableStylesDescriptionsFromStorage(): Promise<Array<IItem>> {
        return this.$http.get(
            AppSettings.serverPath + 'data/styles-descriptions',
            {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                },
                observe: "response"
            })
            .toPromise()
            .then((response) => {
                let styleDescList: Array<any> = <Array<any>>response.body;;
                let stylesDesc: Array<IItem> = [];
                for (var i = 0; i < styleDescList.length; i++) {
                    let styleD: IItem = {
                        text: styleDescList[i].nume,
                        id: styleDescList[i].id,
                        //descriere  : styleDescList[i].descriere,
                        //layerType :  styleDescList[i].layerType,
                        //styleKey : null,
                        //idResursa : null,
                        //style : null
                    }
                    stylesDesc.push(styleD);
                }
                return stylesDesc;
            })
    }
    //
    public getStyleDescriptionFromStorage(id: number): Promise<ILayerStyleSettings> {
        return this.$http.get(
            AppSettings.serverPath + 'data/style-settings/' + id,
            {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                },
                observe: "response"
            })
            .toPromise()
            .then((response) => {
                let styleSetting: any = response.body;
                let styleD: ILayerStyleSettings = {
                    id: styleSetting.id,
                    styleName: styleSetting.nume,
                    descriere: styleSetting.descriere,
                    layerType: styleSetting.layerType,
                    styleKey: null,
                    idResursa: null,
                    icon: styleSetting.icon,
                    style: styleSetting.style,
                    styleOnSelect: styleSetting['styleOnSelect']
                };
                return styleD;
            })
    }
    //
    public setAddStyleSettingsToStorage(styleSett: ILayerStyleSettings, image: any): Promise<boolean> {
        let tmpStyleSett = {
            nume: styleSett.styleName,
            descriere: styleSett.descriere,
            layerType: styleSett.layerType,
            style: styleSett.style,
            styleOnSelect: styleSett.styleOnSelect
        }
        let formData = new FormData();
        formData.append("settings", toJson(tmpStyleSett));
        formData.append("asset", image);

        return this.$http.post(
            AppSettings.serverPath + 'data/add-style-settings',
            formData,
            {
                headers: { /*'Content-Type': 'multipart/form-data'*/ },
                observe: "response"
            })
            .toPromise()
            .then((response) => {
                if ('id' in <any>response.body) {
                    return true;
                } else {
                    return false;
                }
            })
    }
    //
    public setUpdateStyleSettingsToStorage(styleSett: ILayerStyleSettings, image: any): Promise<boolean> {
        let tmpStyleSett = {
            id: styleSett.id,
            nume: styleSett.styleName,
            descriere: styleSett.descriere,
            layerType: styleSett.layerType,
            style: styleSett.style,
            styleOnSelect: styleSett.styleOnSelect
        }
        let formData = new FormData();
        formData.append("settings", toJson(tmpStyleSett));
        formData.append("asset", image);
        //
        return this.$http.post(
            AppSettings.serverPath + 'data/save-style-settings',
            formData,
            {
                headers: { /*'Content-Type': 'multipart/form-data'*/ },
                observe: "response"
            })
            .toPromise()
            .then((response) => {
                if ('success' in <any>response.body) {
                    return true;
                } else {
                    return false;
                }
            })
    }
    //
    public setDeleteStyleSettingsToStorage(styleSettId: number): Promise<boolean> {
        return this.$http.post(
            AppSettings.serverPath + 'data/delete-style-settings',
            JSON.stringify({ id: styleSettId }),
            {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                },
                observe: "response"
            })
            .toPromise()
            .then((response) => {
                if ('success' in <any>response.body) {
                    return true;
                } else {
                    return false;
                }
            })
    }
    //
    public getAssignedStylesForLayer(layerId: number): Promise<Array<ILayerStyleSettings>> {
        return this.$http.get(
            AppSettings.serverPath + 'data/asigned-layer-styles/' + layerId,
            { observe: "response" }
        )
            .toPromise()
            .then((response) => {
                let styleList: Array<ILayerStyleSettings> = <Array<ILayerStyleSettings>>response.body;
                let styles: Array<ILayerStyleSettings> = [];
                for (let i = 0; i < styleList.length; i++) {
                    let style: ILayerStyleSettings = {
                        id: styleList[i].id,
                        idResursa: styleList[i].idResursa,
                        styleKey: styleList[i].styleKey,
                        styleName: styleList[i].styleName,
                        descriere: styleList[i].descriere,
                        layerType: styleList[i].layerType,
                        style: null,
                        styleOnSelect: null,
                        icon: null,
                        legenda: styleList[i].legenda
                    };
                    if (style) {
                        styles.push(style);
                    }
                }
                return styles;
            })
    }

    public getAvailableStylesForLayerType(layerType: string): Promise<Array<IItem>> {
        let tmplayerType = layerType;
        if (layerType === featureType.polyReport) {
            //tmplayerType = Gis.featureType.poly;
        }
        return this.$http.get(
            AppSettings.serverPath + 'data/available-styles-for-layer-type/' + tmplayerType,
            { observe: "response" }
        )
            .toPromise()
            .then((response) => {
                let styleList: Array<any> = <Array<any>>response.body;
                let styles: Array<IItem> = [];
                for (let i = 0; i < styleList.length; i++) {
                    let style: IItem = {
                        id: styleList[i].id,
                        text: styleList[i].nume
                    };
                    if (style) {
                        styles.push(style);
                    }
                }
                return styles;
            })
    }

}
