//NG+
import { Component, OnInit, Input, Inject } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { NGXLogger } from "ngx-logger";

import { ILayer, IItemNT, RouteType, RoutingType } from "../../definitions";
import { TransportDataService } from "../../services/transport-data-service";
import { WindowMessageService } from "../../services/window-message-service";
import { RouteDataService } from "../route-data-service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { isDefined, isObject } from "../../map/map-utils";


import VectorLayer from 'ol/layer/Vector';

export interface IRoutePoint {
    id?: number;
    seq?: number;
    name?: string;
    long?: number;
    lat?: number;
    geom?: any;
    source_layer?: string;
    source_id?: string;
    routeId?: number;
    inactiv?: boolean;
}

export const routeDialogReturn = {
    closeAdd: "closeAdd",
    closeEdit: "closeEdit",
    editOnMap: "editOnMap"
}

@Component({
    selector: 'add-edit-route',
    providers: [],
    templateUrl: './app/routes/route-components/add-edit-route.component.html',
    styleUrls: ['./app/routes/route-components/add-edit-route.component.css']
})
export class AddEditRouteComponent implements OnInit {
    //strat referinta
    refLayer: ILayer;
    //strat sursa puncte
    sourceLayer: ILayer;
    //
    routeList: Array<IItemNT>;
    newRoute: IItemNT;
    //lista puncte
    pointList: Array<IRoutePoint>;
    newPoint: IRoutePoint;
    sourceList: Array<IRoutePoint>;
    selectList: Array<IRoutePoint>;
    name: string;
    type: string;
    public disableInput: boolean = false;
    public errorResponse: string = '';
    public isAddElseEdit: boolean = false;
    public routeIdFromEdit: number = null;

    @Input() data: any;

    public constructor(
        @Inject(NgbActiveModal) private activeModal: NgbActiveModal,
        @Inject(NGXLogger) private $log: NGXLogger,
        @Inject(RouteDataService) private routeDataService: RouteDataService,
        @Inject(TransportDataService) private transportDataService: TransportDataService,
        @Inject(WindowMessageService) private windowMessageService: WindowMessageService
    ) {
        //
    };

    public ngOnInit() {

        if (isObject(this.data) && 'routeDialogReturn' in this.data && this.data['routeDialogReturn'] === routeDialogReturn.editOnMap) {
            let srvcData = this.routeDataService.getRouteData();
            this.isAddElseEdit = srvcData.isAddElseEdit;
            this.sourceLayer = srvcData.sourceLayer;
            this.refLayer = srvcData.refLayer;
            this.pointList = srvcData.pointList;
            this.sourceList = srvcData.sourceList;
            this.selectList = srvcData.sourceList.filter((item) => item.inactiv == null || item.inactiv === false);
            this.routeList = srvcData.routeList;
            this.type = srvcData.type;
            this.name = srvcData.name;
            this.newRoute = srvcData.newRoute;

        } else if (isObject(this.data) && 'source' in this.data && 'layer' in this.data && 'isAddElseEdit' in this.data) {
            this.isAddElseEdit = this.data['isAddElseEdit'];
            this.sourceLayer = this.data['source'];
            this.refLayer = this.data['layer'];
            this.routeIdFromEdit = this.data['routeId'];
            this.pointList = [];
            this.sourceList = [];
            this.selectList = [];
            this.routeList = [];
            this.type = "transport";//this.getRouteTypeId();
            //this.loadSourceItems(this.sourceLayer);
            this.loadSourceItemsFromStorage();
        } else {
            this.$log.warn("straturile pentru rutare nu exista");
            this.activeModal.close(false);
        }
    }

    private loadSourceItems(source: ILayer) {
        let featureList = (source.internalLayer as VectorLayer<any>).getSource().getFeatures();

        featureList.forEach((fitem, findex) => {
            let tmpPoint: IRoutePoint = {};
            tmpPoint.id = Number(fitem.get("id")) || 0;
            tmpPoint.name = fitem.get("name") || tmpPoint.id;
            tmpPoint.lat = Number(fitem.get("latitudine")) || 0.0;
            tmpPoint.long = Number(fitem.get("longitudine")) || 0.0;

            tmpPoint.source_id = fitem.getId() as string || '';
            tmpPoint.source_layer = source.name || '';
            tmpPoint.geom = null;
            this.sourceList.push(tmpPoint);
        })
    }

    private loadSourceItemsFromStorage() {
        this.disableInput = true;
        this.errorResponse = "Incarcare statii";
        let statusOk = true
        this.transportDataService.getStations()
            .then((stations) => {
                if (stations) {
                    stations.forEach((istation) => {
                        try {
                            let tmpPoint: IRoutePoint = {};
                            tmpPoint.id = istation.id || 0;
                            tmpPoint.name = istation.name || tmpPoint.id.toString();
                            tmpPoint.lat = istation.lat;
                            tmpPoint.long = istation.long;
                            tmpPoint.source_id = '';
                            tmpPoint.source_layer = '';
                            tmpPoint.geom = null;
                            tmpPoint.inactiv = istation.inactiv;
                            this.sourceList.push(tmpPoint);
                        } catch (e) {
                            this.$log.error("eroare conversie statie")
                        }
                    })
                    this.selectList = this.sourceList.filter((item) => item.inactiv == null || item.inactiv === false);
                }
            })
            .then(() => {
                if (!this.isAddElseEdit) {
                    return this.transportDataService.getRoutes(this.type)
                        .then((routes) => {
                            routes.forEach((ritem) => {
                                try {
                                    let tmpRoute: IItemNT = {
                                        name: ritem.name,
                                        text: ritem.text,
                                    }
                                    this.routeList.push(tmpRoute);
                                } catch (e) {
                                    this.$log.error("eroare conversie ruta");
                                }
                            })
                            //select route if an routeId is specified
                            if (this.routeIdFromEdit != null) {
                                const rezSelRoutes = this.routeList.filter((fitem) => fitem.text === this.routeIdFromEdit as any);
                                if (rezSelRoutes && rezSelRoutes.length > 0) {
                                    this.newRoute = rezSelRoutes[0];
                                    this.onChangeRoute(this.newRoute);
                                }
                            }
                        });
                }
            })
            .then(() => {
                this.errorResponse = '';
            })
            .catch((reason) => {
                this.$log.error("eroare la incarcare statii");
                this.activeModal.close(false);
            })
            .finally(() => {
                this.disableInput = false;
            })
    }

    public onChangePoint(event: any): void {

    }

    public onChangeRoute(event: any): void {
        if (!this.isAddElseEdit && event.text) {
            this.loadRoutePoints(Number(event.text));
        }
    }

    public addRoutePoint(): void {
        if (this.newPoint) {
            let tmpPoint: IRoutePoint = {
                id: this.newPoint.id,
                name: this.newPoint.name,
                long: this.newPoint.long,
                lat: this.newPoint.lat,
            }
            tmpPoint.seq = this.pointList.length;
            this.pointList.push(tmpPoint);
        } else {
            this.$log.error("eroare lipsa punct pentru ruta");
        }
    }

    public removeRoutePoint(rtPoint): void {
        if (rtPoint) {
            let indexof = this.pointList.indexOf(rtPoint);
            if (indexof >= 0) {
                this.pointList.splice(indexof, 1);
                this.pointList.forEach((pitem, pindex) => {
                    pitem.seq = pindex;
                });
            } else {
                this.$log.error("eroare eliminare punct din ruta");
            }
        }
    }

    public moveUpRoutePoint(rtPoint): void {
        let indexof = this.pointList.indexOf(rtPoint);

        if (this.pointList.length > 1 && indexof > 0) {
            this.pointList.splice(indexof, 1);
            this.pointList.splice(indexof - 1, 0, rtPoint);
        }
    }

    public moveDownRoutePoint(rtPoint): void {
        let indexof = this.pointList.indexOf(rtPoint);
        if (this.pointList.length > 1 && indexof < this.pointList.length - 1) {
            this.pointList.splice(indexof, 1);
            this.pointList.splice(indexof + 1, 0, rtPoint);
        }
    }

    public enableSaveButton(): boolean {
        if (this.isAddElseEdit) {
            return this.pointList.length > 1 && this.name.length > 0;
        } else {
            return this.pointList.length > 1 && isDefined(this.newRoute);
        }
    }

    public enableEditLocatii(): boolean {
        if (this.isAddElseEdit) {
            return this.pointList.length > 1 && this.name.length > 0;
        } else {
            return this.pointList.length > 1 && isDefined(this.newRoute);
        }
    }
    private getRouteTypeId(): number {
        let rtype = -1;
        if (this.refLayer.menuLayerItems && this.refLayer.menuLayerItems.length > 0) {
            let maction = this.refLayer.menuLayerItems.filter((aitem) => { return aitem.action === "addRoute"; });
            if (maction) {
                try {
                    rtype = Number(maction[0].data["type_id"]);
                } catch (e) {
                    this.$log.error("nu poate fi identificat tipul traseului")
                }
            }
        }
        return rtype;
    }

    private getRouteId(): number {
        if (this.newRoute && this.newRoute.text && Number(this.newRoute.text) >= 0) {
            return Number(this.newRoute.text);
        } else {
            return -1;
        }
    }

    public loadRoutePoints(routeId: number) {
        this.disableInput = true;
        this.errorResponse = "Încărcare stații rută";
        this.transportDataService.getRoutePoints(routeId)
            .then((rpoints) => {
                this.pointList = rpoints;
                //this.pointList.push(rpoints);
                this.errorResponse = '';
                this.checkRoutePoints();
            })
            .catch((reason) => {
                this.$log.error("eroare la incarcare statii ruta");
                this.errorResponse = "eroare la încărcare stații rută";
            })
            .finally(() => {
                this.disableInput = false;
            })
    }

    public checkRoutePoints() {
        if (this.pointList.length > 0) {
            if (this.sourceList.length > 0) {
                this.pointList.forEach((pitem) => {
                    if (pitem.id >= 0) {
                        let sourceItem = this.sourceList.filter((sitem) => { return sitem.id === pitem.id; })
                        if (sourceItem && sourceItem.length > 0) {
                            pitem.lat = sourceItem[0].lat;
                            pitem.long = sourceItem[0].long;
                            pitem.geom = sourceItem[0].geom;
                            pitem.name = sourceItem[0].name;
                            pitem.inactiv = sourceItem[0].inactiv === true ? true : false;
                        } else {
                            this.$log.error("eroare verificare statie " + pitem.name + " nu exista sursa");
                            this.errorResponse += "eroare verificare stație " + pitem.name + " nu există sursă";
                        }
                    } else {
                        pitem.name = 'locatie intermediara';
                    }
                });
            } else {
                this.$log.error("eroare verificare statii nu exista sursa");
                this.errorResponse = "eroare verificare stații nu există sursă";
            }
        }
    }

    public checkRouteLocationSequence(): boolean {
        let res = true;
        if (this.pointList.length < 2) {
            alert("Nu sunt suficiente stații");
            res = false;
        }
        if (this.pointList[0].id < 0) {
            alert("Punctul de start nu este stație");
            res = false;
        }
        if (this.pointList[this.pointList.length - 1].id < 0) {
            alert("Punctul de stop nu este stație");
            res = false;
        }
        return res;
    }

    public delete(): void {
        let routeId = this.getRouteId();
        if (routeId < 0) {
            this.$log.error("selecteaza o ruta");
            this.errorResponse = "selectează o rută"
            return;
        }
        this.disableInput = true;
        this.errorResponse = "Ștergere rută";
        this.transportDataService.deleteRouteFromStorage(routeId)
            .then((success) => {
                if (success) {
                    this.$log.info("ruta" + this.name + " a fost ștearsă");
                    this.activeModal.close(true);
                } else {
                    this.$log.error("eroare ștergere rută");
                    this.errorResponse = "eroare la ștergere rută"
                }
            })
            .catch((reason) => {
                this.$log.error("eroare la stergere ruta");
                this.errorResponse = "eroare la ștergere rută";
            })
            .finally(() => {
                this.disableInput = false;
            })
    }

    public save(): void {
        if (!this.checkRouteLocationSequence()) { return; }
        this.disableInput = true;
        this.errorResponse = "Salvare rută";
        let pointArr: Array<{ id: number, coordinates: number[] }> = [];
        //
        this.pointList.forEach((pitem) => {
            pointArr.push({ id: pitem.id, coordinates: [pitem.long, pitem.lat] });
        })
        if (this.isAddElseEdit) {
            //
            this.transportDataService.setAddAdhocRoute(pointArr, false, true, null, RoutingType.foot, 'statie_transport', RouteType.transport, this.name)

                //ruta noua
                //this.routeDataService.setNewRouteToStorage(this.name, this.typeId, this.pointList)
                .then((routeInfo) => {

                    if (routeInfo.id >= 0) {
                        this.$log.info("ruta " + this.name + " a fost creata cu id " + routeInfo.id);
                        this.windowMessageService.sendWindowMessage(JSON.stringify({ type: "transportRouteDone" }));
                        this.activeModal.close(true);
                    } else {
                        this.$log.error("eroare creare ruta");
                        this.errorResponse = "eroare la adaugarea rută";
                    }
                })
                .catch((reason) => {
                    this.$log.error("eroare in adaugarea ruta");
                    this.errorResponse = "eroare in adaugarea rută";
                })
                .finally(() => {
                    this.disableInput = false;
                })
        } else {
            //modificare ruta
            this.transportDataService.setChangeAdhocRoute(Number(this.newRoute.text), pointArr, false, true, null, RoutingType.foot, 'statie_transport', RouteType.transport, this.newRoute.name)
                .then((success) => {
                    if (success) {
                        this.$log.info("ruta " + this.name + " a fost modificata cu id " + this.newRoute.text);
                        this.windowMessageService.sendWindowMessage(JSON.stringify({ type: "transportRouteDone" }));
                        this.activeModal.close(true);
                    } else {
                        this.$log.error("eroare modificare ruta");
                        this.errorResponse = "eroare la modificare rută";
                    }
                })
                .catch((reason) => {
                    this.$log.error("eroare la modificare ruta");
                    this.errorResponse = "eroare la modificare rută";
                })
                .finally(() => {
                    this.disableInput = false;
                })
        }
    }

    public editOnMap() {
        if (!this.checkRouteLocationSequence()) { return; }
        this.routeDataService.setRouteData(this.isAddElseEdit, this.refLayer, this.sourceLayer,
            this.routeList, this.newRoute, this.pointList, this.newPoint, this.sourceList, this.name, this.type);
        this.activeModal.close(routeDialogReturn.editOnMap);
    }

    public cancel(): void {
        this.activeModal.close(this.isAddElseEdit ? routeDialogReturn.closeAdd : routeDialogReturn.closeEdit);
    }
}
