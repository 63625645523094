//NG+
import { NGXLogger } from "ngx-logger";
import { LocationType, RoutingType } from "../definitions";
import { getExMessage, isArray, isNumber, isString, isUndefined } from "../map/map-utils";
import { InternalMessageService } from "./internal-message-service";
import { TransportDataService } from "./transport-data-service";

import { Inject, Injectable } from "@angular/core";

export interface IRouteMessageService {
    getStatus(): string;
    parseWindowMessage(message: string): IRouteMessage;
    generateRoute(message: IRouteMessage): Promise<IRouteResult>;
}

export interface IRouteMessage {
    messageId: number;
    routingType?: string;
    optimizeOrderTSP: boolean;
    layerName: string;
    coordinates: Array<[number, number]>;
    restrictByClientArea?: boolean;
    restrictType?: string;
    filterRoute?: boolean;
    locationType?: string;
}

export interface IRouteResult {
    status: string;
    routeNr: number;
    routeInfo: any;
    message: IRouteMessage;
}

export const RouteGenStatus = {
    free: "free",
    busy: "busy",

    finish: "finish",
    error: "error"
}


@Injectable({
    providedIn: 'root',
})
export class RouteMessageService implements IRouteMessageService {
    private message: IRouteMessage;
    private status: string;

    public constructor(
        @Inject(InternalMessageService) private internalMessageService: InternalMessageService,
        @Inject(NGXLogger) private $log: NGXLogger,
        @Inject(TransportDataService) private transportDataService: TransportDataService
    ) {

    }
    //
    public getStatus() {
        return this.status;
    }

    //
    public parseWindowMessage(message: any): IRouteMessage {
        let rMessage: IRouteMessage = null;
        //
        try {
            let objMessage = message;
            if (isString(message)) {
                objMessage = JSON.parse(message)
            }
            if ('messageId' in objMessage
                && 'layerName' in objMessage
                && 'optimizeOrderTSP' in objMessage
                && 'coordinates' in objMessage
            ) {
                rMessage = {
                    messageId: objMessage["messageId"],
                    layerName: objMessage["layerName"],
                    optimizeOrderTSP: objMessage["optimizeOrderTSP"],
                    coordinates: objMessage["coordinates"],
                    restrictByClientArea: isUndefined(objMessage["restrictByClientArea"]) ? false : objMessage["restrictByClientArea"],
                    restrictType: objMessage["restrictType"] || null,
                    filterRoute: isUndefined(objMessage["filterRoute"]) ? true : objMessage["filterRoute"],
                    locationType: isUndefined(objMessage["locationType"]) ? LocationType.point : objMessage["locationType"],
                    routingType: isUndefined(objMessage["routingType"]) ? RoutingType.foot : objMessage["routingType"]
                };
                if (!isNumber(rMessage.messageId)) {
                    throw new Error("id messaj nu este numar");
                }
                if (rMessage.layerName.length === 0) {
                    throw new Error("lipseste nume strat");
                }
                if (rMessage.optimizeOrderTSP !== true && rMessage.optimizeOrderTSP !== false) {
                    throw new Error("optimizare ordine puncte ruta TSP nu este specificata");
                }
                if (rMessage.routingType != RoutingType.car && rMessage.routingType != RoutingType.foot) {
                    throw new Error("tip rutare nu exista");
                }
                if (rMessage.locationType != LocationType.point && rMessage.locationType != LocationType.uuid_address) {
                    throw new Error("tip locatie nu exista");
                }
                if (!isArray(rMessage.coordinates) || rMessage.coordinates.length < 2) {
                    throw new Error("coordonatele lipsesc sau sunt insuficiente")
                } else {
                    if (rMessage.locationType === LocationType.point) {
                        rMessage.coordinates.forEach((icoord) => {
                            if (!isArray(icoord) || icoord.length !== 2) {
                                throw new Error('coordonatele nu sunt matrice de doua elemente');
                            } else {
                                if (!isNumber(icoord[0] || !isNumber(icoord[1]))) {
                                    throw new Error('coordonatele nu sunt numere');
                                }
                            }
                        })
                    } else if (rMessage.locationType === LocationType.uuid_address) {
                        rMessage.coordinates.forEach((icoord) => {
                            if (!isString(icoord) || icoord.length < 30) {
                                throw new Error('id locatie nu este string sau este prea scurt');
                            }
                        })
                    }
                }
            } else {
                throw new Error(" eroare extragere elemente ruta din mesaj")
            }
            if (rMessage.restrictByClientArea === false) {
                if (rMessage.restrictType && rMessage.restrictType.length > 0) {
                    throw new Error('Setare tip restrictie posibila doar daca este selectat restrictByClientArea');
                }
            }
        }
        catch (reason) {
            this.$log.error("eroare mesaj ruta" , getExMessage(reason));
            rMessage = null;
        }
        //
        return rMessage;
    }

    public generateRoute(message: IRouteMessage): Promise<IRouteResult> {

        if (this.status === RouteGenStatus.busy) {
            return Promise.resolve(true)
                .then(() => {
                    this.$log.log(" generate route is busy");
                    return { status: RouteGenStatus.busy, routeNr: null, routeInfo: null, message }
                }) as any
        }
        this.status = RouteGenStatus.busy;
        let routeResult: IRouteResult = {
            status: RouteGenStatus.busy,
            routeNr: null,
            routeInfo: null,
            message: message
        }
        return this.transportDataService.setAddAdhocRoute(
            message.coordinates,
            message.optimizeOrderTSP,
            message.restrictByClientArea,
            message.restrictType,
            message.routingType,
            message.locationType
        )
            .then((routeInfo) => {
                routeResult.routeNr = routeInfo.id;
                routeResult.routeInfo = routeInfo;
                return true;
            })
            .then(() => {
                routeResult.status = RouteGenStatus.finish;
                if (routeResult.message.filterRoute === true) {
                    this.internalMessageService.broadcast("routeGenMessage", routeResult);
                }
                this.$log.log("a fost generata ruta cu id",routeResult.routeNr);
                return routeResult;
            })
            .catch((reason) => {
                this.$log.error("eroare generare ruta ", getExMessage(reason));
                routeResult.status = RouteGenStatus.error;
                return routeResult;
            })
            .finally(() => {
                this.status = RouteGenStatus.free;
            });

    }
}
