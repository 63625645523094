import { Injectable } from "@angular/core";
import { OwlDateTimeFormats } from "@danielmoncada/angular-datetime-picker";
import { IClient } from "../roles/settings-components/settings-definitions";

export const DATE_TIME_PICKER_MOMENT_FORMATS = {
    parseInput: 'DD/MM/YYYY HH:mm:ss',//'l LT',
    fullPickerInput: 'DD/MM/YYYY HH:mm:ss',//'l LT',
    datePickerInput: 'DD/MM/YYYY',//'l',
    timePickerInput: 'HH:mm:ss',//'LT',
    monthYearLabel: 'MMM YYYY',//'MMM YYYY',
    dateA11yLabel: 'LL',//'LL',
    monthYearA11yLabel: 'MMMM YYYY',//'MMMM YYYY',
};

@Injectable()
export class DateTimePickerFormatsProvider {
  private currentFormats: OwlDateTimeFormats;

  constructor() {
    this.currentFormats = DATE_TIME_PICKER_MOMENT_FORMATS;
  }

  setFormats(formats: OwlDateTimeFormats) {
    this.currentFormats = formats;
  }

  getFormats(): OwlDateTimeFormats {
    return this.currentFormats;
  }
  
  setFormatsFromClient(client: IClient){
    if (client && client.formatDateTime && client.formatDateTime !== "") {
        this.currentFormats.parseInput = client.formatDateTime;
        this.currentFormats.fullPickerInput = client.formatDateTime;
    }
    if (client && client.formatDate && client.formatDate !== "") {
        this.currentFormats.datePickerInput = client.formatDate;
    }
    if (client && client.formatTime && client.formatTime !== "") {
        this.currentFormats.timePickerInput = client.formatTime;
    }
  }
}