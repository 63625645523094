//NG+
import { AppSettings } from "../app-settings";
import { IOptiune, IRoleOptiuni, IStatus, optionGroupType } from "../definitions";
import { UserSettingsService } from "./user-settings-service";

import { NGXLogger } from "ngx-logger";
import { HttpClient, HttpParams } from "@angular/common/http";

export interface IDbStoreOptResRol {
    getAssignedRolesForResursa(layerId: number): Promise<Array<IRoleOptiuni>>;
    setAssignedRolesForResursaToStorage(layerId: number, roles: Array<IRoleOptiuni>): Promise<boolean>;
    getAvailableResursaTipDefaultAccessFromStorage(): Promise<Array<{ id: number, nume: string, descriere: string, group: string }>>;
    getAvailableResursaTipOptiuniFromStorage(): Promise<Array<IOptiune>>;
    getAssingendResursaOptiuniFromStorage(idResursa: number): Promise<Array<IOptiune>>;
    setAssingendResursaOptiuniToStorage(idResursa: number, optiuni: Array<IOptiune>, type: string, option?: string): Promise<boolean>;

    // resource type
    getResourceType(): Promise<any>;

    // resource role
    getAvailableResourceRole(idRole: number, resType: string): Promise<any>;

    // assigned resource role
    getAssignedResourceRole(idRole: number, resType: string): Promise<any>;
}

export class DbStoreOptResRol implements IDbStoreOptResRol {
    constructor(
        private $http: HttpClient,
        private $log: NGXLogger,
        private settingsService: UserSettingsService) { }

    //resources
    public getAssignedRolesForResursa(idResursa: number): Promise<Array<IRoleOptiuni>> {
        return this.$http.get(
            AppSettings.serverPath + 'data/resursa-rol-optiuni/' + idResursa,
            { observe: "response" }
        )
            .toPromise()
            .then((response) => {
                let roles: Array<IRoleOptiuni> = [];
                let roleList: Array<any> = <Array<any>>response.body;
                for (var i = 0; i < roleList.length; i++) {
                    let role: IRoleOptiuni = { id: roleList[i].id, nume: roleList[i].nume, optiuni: [] };
                    if (roleList[i].optiuni && roleList[i].optiuni.length > 0) {
                        roleList[i].optiuni.forEach((itm) => {
                            role.optiuni.push(itm);
                        })
                    }
                    roles.push(role);
                }
                return roles;
            });

    }
    //
    public setAssignedRolesForResursaToStorage(idResursa: number, roles: Array<IRoleOptiuni>): Promise<boolean> {
        return this.$http.post(
            AppSettings.serverPath + '/data/save-resursa-roles-optiuni',
            { id: idResursa, roles: roles },
            //withCredentials: true,
            {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                },
                observe: "response"
            })
            .toPromise()
            .then((response) => {
                if ('success' in <any>response.body) {
                    return (<IStatus>response.body).success;
                }
                else {
                    return false;
                }
            });
    }
    //
    public getAvailableResursaTipDefaultAccessFromStorage(): Promise<Array<{ id: number, nume: string, descriere: string, group: string }>> {
        return this.$http.get(
            AppSettings.serverPath + '/data/optiuni-tip-custom-access',
            { observe: "response" }
        )
            .toPromise()
            .then((response) => {
                let optiuni: Array<{ id: number, nume: string, descriere: string, group: string }> = [];
                let optList: Array<any> = <Array<any>>response.body;
                for (var i = 0; i < optList.length; i++) {
                    let option: any = {
                        id: optList[i].id, nume: optList[i].nume, descriere: optList[i].descriere, group: optList[i].group
                    };
                    optiuni.push(option);
                }
                return optiuni;
            });
    }
    //
    public getAvailableResursaTipOptiuniFromStorage(): Promise<Array<IOptiune>> {
        return this.$http.get(
            AppSettings.serverPath + 'data/resurse-optiuni',
            { observe: "response" }
        )
            .toPromise()
            .then((response) => {
                let optiuni: Array<IOptiune> = [];
                let optList: Array<any> = <Array<any>>response.body;
                for (var i = 0; i < optList.length; i++) {
                    let option: IOptiune = {
                        id: optList[i].id, nume: optList[i].nume, idItem: optList[i].idItem, descriere: optList[i].descriere,
                        defaultAccess: optList[i].defaultAccess, customAccess: optList[i].customAccess, group: optList[i].group,
                        idItemMin: optList[i].group === optionGroupType.index ? -9999999 : -1
                    };
                    optiuni.push(option);
                }
                return optiuni;
            });
    }
    //
    public getAssingendResursaOptiuniFromStorage(idResursa: number): Promise<Array<IOptiune>> {
        return this.$http.get(
            AppSettings.serverPath + 'data/resursa-optiuni/' + idResursa,
            { observe: "response" }
        )
            .toPromise()
            .then((response) => {
                let optiuni: Array<IOptiune> = [];
                let optList: Array<any> = <Array<any>>response.body;
                for (var i = 0; i < optList.length; i++) {
                    let option: IOptiune = {
                        id: optList[i].id, nume: optList[i].nume, idItem: optList[i].idItem, descriere: optList[i].descriere,
                        defaultAccess: optList[i].defaultAccess, customAccess: optList[i].customAccess, group: optList[i].group,
                        idItemMin: optList[i].group === optionGroupType.index ? -9999999 : -1
                    };
                    optiuni.push(option);
                }
                return optiuni;
            });
    }
    //
    public setAssingendResursaOptiuniToStorage(idResursa: number, optiuni: Array<IOptiune>, type: string, optiune: string = null): Promise<boolean> {
        return this.$http.post(
            AppSettings.serverPath + '/data/save-resursa-optiuni',
            { id: idResursa, optiuni: optiuni, type: type, optiune: optiune },
            //withCredentials: true,
            {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                },
                observe: "response"
            })
            .toPromise()
            .then((response) => {
                if ('success' in <any>response.body) {
                    return true;
                } else {
                    return false;
                }
            });
    }



    public getResourceType(): Promise<any> {
        return this.$http.get(
            AppSettings.serverPath + '/data/get-resource-type',
            { observe: "response" }
        )
            .toPromise()
            .then(res => res)
            .catch(err => err);
    }

    public getAvailableResourceRole(idRole: number, resType: string): Promise<any> {
        return this.$http.post(AppSettings.serverPath + '/data/get-available-resource-role',
            { idRole, resType },
            { observe: "response" }
        )
            .toPromise()
            .then(res => res)
            .catch(err => err);
    }

    public getAssignedResourceRole(idRole: number, resType: string): Promise<any> {
        return this.$http.post(AppSettings.serverPath + '/data/get-res-opt-rol',
            { idRole, resType },
            { observe: "response" }
        )
            .toPromise()
            .then(res => res)
            .catch(err => err);
    }
}
