import { Component, OnInit, Input, Inject } from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { AppSettings } from "../../app-settings";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { UserSettingsService } from "../../services/user-settings-service";
import { getExMessage } from "../../map/map-utils";


@Component({
    selector: 'login',
    providers: [],
    templateUrl: './app/auth/auth-components/login.component.html',
    styleUrls: ['./app/auth/auth-components/login.component.css']
})
    export class LoginComponent implements OnInit {
        public username: string;
        public password: string;
        public errorResponse: string;
        public disableInput: boolean = false;
        //
        public constructor(
            @Inject(NgbActiveModal) private activeModal: NgbActiveModal,
            @Inject(NGXLogger) private $log: NGXLogger,
            @Inject(UserSettingsService) private userSettingsSrvs: UserSettingsService
            ) {
                //
        }
        //
        public ngOnInit(): void {
            //
        }
        //
        public authenticateAnonymous() {
            this.username = AppSettings.anonimUser;
            this.password = AppSettings.anonimPass;
            this.authenticate();
        }
        //
        public authenticate(): void {
            //check if user in database
            this.errorResponse = '';
            this.disableInput = true;
            //
            this.userSettingsSrvs.authenticateUser(this.username, this.password)
                .then((authUser) => {
                    return this.userSettingsSrvs.setCurrentUserToAuthUserAndUpdateAllFromStorage(authUser);
                })
                .then((success) => {
                    this.errorResponse = success ? '' : 'Eroare verificare utilizator';
                    if (success) { 
                        this.activeModal.close(true); 
                    }
                })
                .catch((reason) => {
                    this.$log.error("Eroare la verificarea utilizatorului ", getExMessage(reason));
                    this.errorResponse = "Eroare la verificarea utilizatorului";
                })
                .finally(() => {
                    this.disableInput = false;
                });
        }
        //
        public onEnterKeyPress(form){
            if (form.valid === true){
                this.authenticate();
            }
        }
        
        //
        public cancel(): void {
            this.activeModal.close(false);
        }
    }
