//NG+
import { UserSettingsService } from "./user-settings-service";

import { NGXLogger } from "ngx-logger";
import { HttpClient, HttpParams } from "@angular/common/http";

export interface IDbStoreCategory {
    getCategories(): Promise<any>;
    addCategory(category: { nume: string, descriere: string, parentCode: string }): Promise<any>;
    updateCategory(category: { id: number, nume: string, descriere: string, parentCode: string }): Promise<any>;
    getAssignedCategories(): Promise<any>;
    setAssignedCategories(categories: any): Promise<any>;
}
export class DbStoreCategory implements IDbStoreCategory {
    constructor(private $http: HttpClient, private $log: NGXLogger, private settingsService: UserSettingsService) { }

    public getCategories = (): Promise<any> => {
        return this.$http.get(
            '/data/get-categories',
            { observe: "response" }
        )
            .toPromise()
            .then(res => res)
    }

    public addCategory = ({ nume, descriere, parentCode }): Promise<any> => {
        return this.$http.post(
            '/data/add-category',
            { nume, descriere, parentCode },
            { observe: "response" })
            .toPromise()
            .then(res => res)
    }

    public updateCategory = ({ id, nume, descriere, parentCode }): Promise<any> => {
        return this.$http.post(
            '/data/update-category',
            { id, nume, descriere, parentCode },
            { observe: "response" }
        )
            .toPromise()
            .then(res => res)
    }

    public getAssignedCategories = (): Promise<any> => {
        return this.$http.get(
            '/data/get-assigned-categories',
            { observe: "response" }
        )
            .toPromise()
            .then(res => res)
    }

    public setAssignedCategories = (categories: any): Promise<any> => {
        return this.$http.post(
            '/data/set-assigned-categories',
            { categories },
            { observe: "response" }
        )
            .toPromise()
            .then(res => res)
    }
}
