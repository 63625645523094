import { Component, OnInit, Input, Inject } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { NGXLogger } from "ngx-logger";
import { UserSettingsService } from "../../services/user-settings-service";
import { WindowMessageService } from "../../services/window-message-service";

import { AppSettings } from "../../app-settings";
import { ILayer, IItemNT, layerTypeName, styleTypeList, IItem, ILayerStyleSettings, layerAttributeType, authAs, authType, sourceTypeList, destUploadActionList, sourceType, featureType, styleType, ILayerReportSettings } from "../../definitions";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { getExMessage, isObject } from "../../map/map-utils";

@Component({
    selector: 'add-edit-layer',
    providers: [],
    templateUrl: './app/layer/layer-components/add-edit-layer.component.html',
    styleUrls: ['./app/layer/layer-components/add-edit-layer.component.css']
})
    export class AddEditLayerComponent implements OnInit
    {
        public refLayer: ILayer;
        public layer: ILayer;
        public defaultStyle: { color: Array<Number> };
        public errorResponse: string = '';
        public isAddElseEdit: boolean = false;
        //public serverUrl: string;
        public disableInput: boolean = false;

        public selectedType: IItemNT;
        public availableTypes: Array<IItemNT> = layerTypeName;

        public selectedStyleType: IItemNT;
        public availableStyleType: Array<IItemNT> = styleTypeList;

        public selectedSourceType: IItemNT;
        public availableSourceType: Array<IItemNT> =[]; 
        public selectedDestAction: IItemNT;
        public availableDestinationActions: Array<IItemNT> = [];

        public selectedLStyle: IItem;
        public availableLStyles: Array<IItem> = [];
        public asignedLStyles: Array<ILayerStyleSettings>;
        //public remainingLStyles: Array<IItem> = [];
        private regExpNumber = /[-+]?[0-9]*\.?[0-9]+/g;
        public file: any;
        public files: Array<any>;

        public categories: any;
        public categoryModel:any;
        
        public connectedLayer: ILayer;

        public providedCategory: string;

        public attributeTypeList = [
              {nume: "Numar intreg", type: layerAttributeType.integer},
              {nume: "Numar zecimal", type: layerAttributeType.float},
              {nume: "Numar zecimal precizie dubla", type: layerAttributeType.double},
              {nume: "Text", type: layerAttributeType.string},
              {nume: "Timp", type: layerAttributeType.timestamp},
              {nume: "Data", type: layerAttributeType.date},
              {nume: "Boolean", type: layerAttributeType.boolean}
        ]
        public columnNameFilter = '[0-9a-zA-Z\-_]+';

        public selectedAttributeType: any;
        public selectedAttributeTypeCL: any;

        public canChangeAccess =  false;

        @Input() data: any;

        constructor(
            @Inject(NgbActiveModal) private activeModal: NgbActiveModal,
            @Inject(NGXLogger) private $log: NGXLogger,
            @Inject(UserSettingsService) private userSettingsService: UserSettingsService,
            @Inject(WindowMessageService) private windowMessageService: WindowMessageService,
            @Inject(DomSanitizer) private sanitizer: DomSanitizer
            
            ){
            //
            }

            public ngOnInit(): void {
                this.defaultStyle = { color: [255, 255, 255, 1] };
                //let data = $scope["ngDialogData"];
                if (isObject(this.data) && 'isAddElseEdit' in this.data) {
                    this.isAddElseEdit = this.data["isAddElseEdit"];
                } else {
                    this.$log.warn("datele pentru strat nu exista");
                    this.activeModal.close(false);
                }
                if (this.isAddElseEdit) {
                    if (!this.userSettingsService.isAuthForResource(authAs.data_add_layer, authType.route)) {
                        this.$log.warn('utilizatorul nu este autorizat pentru adaugare layere');
                        this.activeModal.close(false);
                        return;
                    }
                    this.availableSourceType = sourceTypeList.map(item => item);
                    if ("category" in this.data) {
                        this.providedCategory = this.data["category"]
                        this.layer = {
                            id: null,
                            name: null,
                            description: null,
                            url: null,
                            featureType: "point",
                            projection: null,
                            color: null,
                            fileName: null,
                            asset: null,
                            visible: false,
                            internalLayer: null,
                            category: this.data["category"],
                            reports: [],
                            attributes: []
                        }
                        //
                    } else {
                        this.$log.warn("datele pentru strat nu exista");
                        this.activeModal.close(false);
                    }
                    //
                    this.availableDestinationActions.push(destUploadActionList[0]);
                    this.selectedDestAction = this.availableDestinationActions[0];
                } else {
                    if (!this.userSettingsService.isAuthForResource(authAs.data_update_layer, authType.route)) {
                        this.$log.warn('utilizatorul nu este autorizat pentru modificare layere');
                        this.activeModal.close(false);
                        return;
                    }
                    this.canChangeAccess = this.userSettingsService.isAuthForResource(authAs.data_change_layer_client_access_public_pivate, authType.route)
                    this.availableSourceType = sourceTypeList.filter((item)=>item.name !== sourceType.table);
                    //url este selectatr implicit pentru editare
                    this.availableDestinationActions.push(destUploadActionList[1]);
                    this.availableDestinationActions.push(destUploadActionList[2]);
                    this.selectedDestAction = this.availableDestinationActions[0];
                    //
                    let resFilter = this.availableSourceType.filter((type) => type.name === sourceType.url);
                    if (resFilter && resFilter.length > 0) {
                        this.selectedSourceType = resFilter[0];
                    }
                    //
                    if ("layer" in this.data) {
                        this.refLayer = this.data['layer'];
                        //asinc
                        this.loadLayerDataFromStorage(this.refLayer.id);
                        //
                    } else {
                        this.$log.warn("datele pentru strat nu exista");
                        this.activeModal.close(false);
                    }
            }

            
           

            //tocheck
            //this.serverUrl = $sce.trustAsResourceUrl(AppSettings.serverPath + "/layer-save");
        }

        public fileSelected = (event, labelElement) => {
            try {
                this.file = event.target.files[0] || null;
                let displayText = "Nu este imagine selectată"
                if (event.target.files.length > 0){
                    displayText = event.target.files[0].name;
                }
                labelElement.innerText = displayText;
            } catch (error) {
                this.$log.error("eroare selectie fisier")
            }
            
        }

        public multiFileSelected = (event, labelElement) => {
            try {
                this.files = event.target.files;
                let displayText = "Nu sunt fișiere selectate";
                let titleText = "";
                if (event.target.files.length > 0){
                    titleText = Array.from(event.target.files)
                                    .map(f => f["name"])
                                    .join(', ');
                    displayText = `Sunt ${event.target.files.length} fisiere selectate`; 
                }
                labelElement.innerText = displayText;
                labelElement.title = titleText;
            } catch (error) {
                this.$log.error("eroare selectie fisiere")
            }
        }

        public onTipLayerChanged = () => {
            this.layer.featureType = this.selectedType.name;
            //
            if(this.layer.featureType == featureType.polyReportCP){
                this.initLayerConsultarePublica();
                
               
            }
            //
            this.loadAvailableStylesFromStorage();
        }

        public onTipStyleChanged = () => {
            this.layer.styleType = this.selectedStyleType.name;
            this.asignedLStyles.length = 0;
        }

        public onTipSourceChanged = () => {
            this.layer.sourceType = this.selectedSourceType.name;
        }

        //
        public onRemoveOptionStyle = (style: any) => {
           let idStyle = this.asignedLStyles.indexOf(style);
           this.asignedLStyles.splice(idStyle,1);
        }
        public onAddStyleToLayer = () => {
            if (this.selectedLStyle) {
                let tmpStyle: ILayerStyleSettings = {
                    styleName: this.selectedLStyle.text,
                    idResursa: this.layer.id,
                    id: -1,
                    styleKey: "",
                    descriere: null,
                    icon: null,
                    layerType: null,
                    style: null,
                    styleOnSelect: null
                }
                this.asignedLStyles.push(tmpStyle);
                this.selectedLStyle = null;
            }
        }

        private loadAvailableStylesFromStorage() {
            this.disableInput = true;
            this.errorResponse = "Incarcare date strat";
            this.asignedLStyles = [];
            return this.userSettingsService.layerStyle.getAvailableStylesForLayerType(this.layer.featureType)
                .then((styles) => {
                    this.availableLStyles = styles;
                }).catch((reason) => {
                    this.$log.error("eroare la incarcare date stiluri");
                    this.errorResponse = "eroare la incarcare date stiluri";
                    this.activeModal.close(false);
                }).finally(() => {
                    this.errorResponse = '';
                    this.disableInput = false;
                });
        }
        //
        private loadLayerDataFromStorage(layerId: any) {
            this.disableInput = true;
            this.errorResponse = "Incarcare date strat";
            this.userSettingsService.layer.getLayerFromStorage(this.refLayer.id)
                .then((layerdata) => {
                    if (layerdata) {
                        this.layer = layerdata;
                        if (this.layer.category) {
                            this.userSettingsService.category.getAssignedCategories().then(res => {
                                this.categories = res.body.assignedCategories

                                this.categories.forEach(e => {
                                    if (e.nume === this.layer.category) {
                                        this.categoryModel = e;
                                        // {
                                        //     id: e.id,
                                        //     nume: e.nume,
                                        //     descriere: e.descriere
                                        // }
                                    }
                                })
                            });
                            
                        }
                        if (this.layer.featureType) {
                            let results = this.availableTypes.filter((item) => item.name === this.layer.featureType);
                            if (results && results.length > 0) {
                                this.selectedType = results[0];
                            }
                        }
                        if (this.layer.styleType) {
                            let results = this.availableStyleType.filter((item) => item.name === this.layer.styleType);
                            if (results && results.length > 0) {
                                this.selectedStyleType = results[0];
                            }
                        }
                        if (this.layer.color) {
                            if (this.layer.color.indexOf("#") === 0) {
                                this.defaultStyle.color = this.convertToRGB(this.layer.color);
                            } else {
                                this.defaultStyle.color = this.layer.color.match(this.regExpNumber).map(Number)
                            }
                        }
                        //if (this.layer.featureType === 'icon' && this.layer.asset && this.layer.asset != "") {
                        //    this.layer.featureType = 'point-icon'
                        //}
                    } else {
                        this.$log.error("eroare la incarcare date strat");
                        this.errorResponse = "eroare la incarcare date strat";
                        this.activeModal.close(false);
                    }
                })
                .then(() => {
                    //cluster style test
                    let tmpFeatureType = this.layer.featureType;
                    if (this.layer.featureType === featureType.cluster){
                        tmpFeatureType = featureType.point;
                    } else if (this.layer.featureType === featureType.clusterIcon){
                        tmpFeatureType = featureType.icon;
                    } else if (this.layer.featureType === featureType.clusterText){
                        tmpFeatureType = featureType.pointText;
                    }
                    return this.userSettingsService.layerStyle.getAvailableStylesForLayerType(tmpFeatureType)
                        .then((styles) => {
                            this.availableLStyles = styles;
                        })
                })
                .then(() => {
                    return this.userSettingsService.layerStyle.getAssignedStylesForLayer(this.layer.id)
                        .then((styles) => {
                            this.asignedLStyles = styles;
                        });
                })
                .then(() => {
                    return this.userSettingsService.getLayerReportSettings(this.layer.id)
                        .then((settings) => {
                            this.layer.reports = settings;
                        })
                })
                .catch((reason) => {
                    this.$log.error("eroare la incarcare date strat");
                    this.errorResponse = "eroare la incarcare date strat";
                    this.activeModal.close(false);
                })
                .finally(() => {
                    this.errorResponse = '';
                    this.disableInput = false;
                });
        }
        //
        private loadAvailableStyles(styles: Array<IItem>) {
            if (!this.availableLStyles) {
                this.availableLStyles = styles;
            } else {
                while (this.availableLStyles.length) {
                    this.availableLStyles.pop();
                }
                for (var i = 0; i < styles.length; i++) {
                    this.availableLStyles.push(styles[i]);
                }
            }
        }
        //
        private checkDupicateStyleKey():string {
            let resultstr = "";
            if (this.layer.styleType === styleType.multiStyle) {
                this.asignedLStyles.forEach((sitem) => {
                    if (sitem.styleKey === "") {
                        resultstr += " cheia nu este definita pentru stil :" + sitem.styleName + " \n";
                    } else {
                        let results = this.asignedLStyles.filter((fitem) => fitem.styleName === sitem.styleName && fitem.styleKey === sitem.styleKey);
                        if (results && results.length > 1) {
                            resultstr += " duplicat pentru stil: " + sitem.styleName + ", cheie: " + sitem.styleKey + " \n";
                        }
                    }
                    
                });
            }
            return resultstr;
        }

        //
        public save(form: any): void {
            this.errorResponse = '';
            //
            if (form.valid === false){
                form.submitted =  true;
                this.errorResponse = 'eroare validare';
                return;
            }
            //check fisier imagine
            if ((this.layer.featureType == 'icon' || this.layer.featureType == 'clusterIcon')
                && this.file == null){
                this.errorResponse = "Lipseste fisier imagine";
                return;
            }
            //check fisiere shapefile
            if (this.selectedSourceType && this.selectedSourceType.name === 'upload'
                && (this.files == null || this.files.length == 0)){
                this.errorResponse = "Lipseste fisiere shapefile";
                return;
            }
            //check duplicate style
            let resDuplicateStyle = this.checkDupicateStyleKey();
            if (resDuplicateStyle != "") {
                this.errorResponse = resDuplicateStyle;
                return;
            }
            //check duplicate text label
            let resDuplicateText = this.checkDupicateTextKey();
            if (resDuplicateText != ""){
                this.errorResponse = resDuplicateText;
                return;
            }
            //
            this.layer.color = `rgba(${this.defaultStyle.color[0]}, ${this.defaultStyle.color[1]}, ${this.defaultStyle.color[2]}, ${this.defaultStyle.color[3]})`
            //
            let tmpStyles = (this.layer.styleType === styleType.multiStyle || this.layer.styleType === styleType.singleStyle) ? this.asignedLStyles : null;
            //report layer
            var reportsSettingsStatus: { status: boolean, result: Array<ILayerReportSettings> } = { status: false, result: null };
            if (this.layer.featureType === featureType.polyReport) {
                reportsSettingsStatus = this.checkReportLayerJson(this.layer.reports)
                if (reportsSettingsStatus.status === false) {
                    this.errorResponse = 'Eroare parametri raport';
                    return;
                };
            }
            //
            this.disableInput = true;
            this.errorResponse = "Salvare strat";
            //
            if (this.isAddElseEdit) {
                //strat nou
                this.userSettingsService.layer.setAddLayerToStorage(this.layer, this.selectedSourceType.name, this.selectedDestAction.name, this.file, this.files, this.asignedLStyles, reportsSettingsStatus.result, this.connectedLayer)
                    .then((layerId) => {
                        if (layerId >= 0) {
                            this.$log.info("stratul" + this.layer.name + "a fost creat cu id " + layerId);
                            this.activeModal.close(true);
                        } else {
                            this.$log.error("eroare creare strat");
                            this.errorResponse = "eroare la create strat";
                        }
                    })
                    .catch((reason) => {
                        this.$log.error("eroare in adaugarea strat");
                        this.errorResponse = "eroare in adaugarea strat";
                    })
                    .finally(() => {
                        this.disableInput = false;
                    })
            } else {
                this.layer.category = this.categoryModel.nume;
                this.userSettingsService.layer.setUpdateLayerToStorage(this.layer, this.selectedSourceType.name, this.selectedDestAction.name, this.file, this.files, tmpStyles, reportsSettingsStatus.result)
                    .then((success) => {
                        if (success) {
                            this.$log.info("stratul" + this.layer.name + "a fost modificat");
                            this.activeModal.close(true);
                        } else {
                            this.$log.error("eroare modificare strat");
                            this.errorResponse = "eroare la modificare strat";
                        }
                    })
                    .catch((reason) => {
                        this.$log.error("eroare in modificare strat");
                        this.errorResponse = "eroare in modificare strat";
                    })
                    .finally(() => {
                        this.disableInput = false;
                    })
            }
        }
        //
        public cancel() {
            this.activeModal.close(false);
        }
        //
        private convertToRGB(hex: string) {
            let hexStr = hex.substr(0, 7);
            let r = hexStr.match(/^#([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})$/i);
            if (r) {
                let rez: Array<Number> = r.slice(1, 4).map(x => parseInt(x, 16));
                rez.push(1);
                return rez;
            }
            return null;
        }

        private checkReportLayerJson(reportSettings: Array<ILayerReportSettings>): { status: boolean, result: Array<ILayerReportSettings> }{
            let tmp: { status: boolean, result: Array<ILayerReportSettings> } = { status: false, result: [] };
            try {
                reportSettings.forEach((rItem) => {
                    if (rItem.reportFormula == '' || rItem.nameResData == '') {
                        throw new Error("formula sau stratul nu pot fi nedefinite");
                    }
                    let tmpRSeting: ILayerReportSettings = {
                        reportFormula: rItem.reportFormula,
                        idResReport: rItem.idResReport,
                        reportColumns: JSON.parse(rItem.reportColumns),
                        nameResData: rItem.nameResData,
                        dataColumns: JSON.parse(rItem.dataColumns),
                        constants: JSON.parse(rItem.constants),
                        id: rItem.id || -1,
                        description: rItem.description
                    };
                    tmp.status = true;
                    tmp.result.push(tmpRSeting);
                })
            } catch (e) {
                tmp.status = false;
                tmp.result = null;
                this.$log.error('eroare parsare configurare raport ', getExMessage(e));
            }
            //
            return tmp;
        }

        public onAddAttributeToLayer(){
            if (this.selectedAttributeType){
                
                this.layer.attributes.push(
                    {idOrdine: (this.layer.attributes.length || 0) + 1,
                        nillable: false,
                        nume: "",
                        type: this.selectedAttributeType.type
                    })
            }
        }
        public onRemoveAttributeFromLayer = (item: any) => {
            let idAttr = this.layer.attributes.indexOf(item);
            this.layer.attributes.splice(idAttr,1);
         }
         public onAddAttributeToConnectedLayer(){
            if (this.selectedAttributeTypeCL){
                
                this.connectedLayer.attributes.push(
                    {idOrdine: (this.connectedLayer.attributes.length || 0) + 1,
                        nillable: false,
                        nume: "",
                        type: this.selectedAttributeTypeCL.type
                    })
            }
        }
        public onRemoveAttributeFromConnectedLayer = (item: any) => {
            let idAttr = this.connectedLayer.attributes.indexOf(item);
            this.connectedLayer.attributes.splice(idAttr,1);
         }

        private initLayerConsultarePublica() {
            
            //default data
            this.layer = {
                id: null,
                name: null,
                description: null,
                url: null,
                featureType: featureType.polyReportCP,
                projection: null,
                color: null,
                fileName: null,
                asset: null,
                visible: false,
                internalLayer: null,
                category: this.providedCategory,
                reports: []
            };

            this.layer.attributes = [
                {
                    idOrdine : 1,
                    nume: "nume",
                    nillable: true,
                    type: "java.lang.String"
                }
            ]

            this.connectedLayer = {
                id: null,
                name: null,
                description: null,
                url: null,
                featureType: featureType.point,
                projection: null,
                color: null,
                fileName: null,
                asset: null,
                visible: false,
                internalLayer: null,
                category: this.providedCategory,
                reports: []
            };

            this.connectedLayer.attributes = [
                {
                    idOrdine : 1,
                    nume: "nume",
                    nillable: true,
                    type: "java.lang.Integer"
                },
                {
                    idOrdine : 2,
                    nume: "nume",
                    nillable: true,
                    type: "java.lang.String"
                }
            ]
            //
            let resFilter = this.availableSourceType.filter((type) => type.name === sourceType.table);
            if (resFilter && resFilter.length > 0) {
                this.layer.sourceType = sourceType.table;
                this.selectedSourceType = resFilter[0];
            }
        }

        public displayAttributeType(type): string {
            let rez = type;
            let fil = this.attributeTypeList.filter((item)=> item.type == type);
            if(fil){
                rez = fil[0].nume;
            }
            return rez;
        }

        public onAddProperyForTextDescription(){
            if(this.layer.textKeyColumn == null){
                this.layer.textKeyColumn = [];
            }
            this.layer.textKeyColumn.push("");
        }

        public onRemovePropertyFromTextDescription(indexa){
            if(this.layer.textKeyColumn && this.layer.textKeyColumn.length > 0){
                //let index = this.layer.textKeyColumn.indexOf(item);
                if(indexa >= 0){
                    this.layer.textKeyColumn.splice(indexa, 1);
                }
            }
        }
        public trackByIdentityOrIndex = (index: number, item: any) => {
            if (typeof item === 'object') return item;
            return index;
         }

        private checkDupicateTextKey():string {
            let resultstr = "";
            if (this.layer.featureType === featureType.pointText
                || this.layer.featureType === featureType.polyText
                || this.layer.featureType === featureType.polyReport
                ||  this.layer.featureType === featureType.polyReportCP
                ) {
                if (this.layer.textKeyColumn == null || this.layer.textKeyColumn.length == 0){
                    resultstr = "nu sunt definite porprietati sursa pentru eticheta";
                    
                } else {
                    this.layer.textKeyColumn.forEach((sitem) => {
                        if (sitem === "") {
                            resultstr += " proprietate fara denumire \n";
                        } else {
                            let results = this.layer.textKeyColumn.filter((fitem) => fitem === sitem);
                            if (results && results.length > 1) {
                                resultstr += " duplicat pentru nume proprietate: " + sitem + " \n";
                            }
                        }
                    });
                }
                
            }
            return resultstr;
        }

        public changeAccess(){
            this.disableInput = true;
            this.errorResponse = "";
            let setStatus = this.layer.idClient == null? "privat" : "partajat";
            return this.userSettingsService.layer.setUpdateLayerClientAccess(this.layer,setStatus )
                .then((result) => {
                    if (result.status == false && result.data != null){
                        this.$log.error("strat partajat cu alti clienti", getExMessage(JSON.stringify(result.data)));
                        this.errorResponse = "strat partajat cu alti clienti, trebuie ca ceilalti clienti sa il scoata din useri/roluri";
                    } else {
                        this.activeModal.close(true);
                    }
                }).catch((reason) => {
                    this.$log.error("eroare la schimbare acces strat pe client");
                    this.errorResponse = "eroare la schimbare acces strat pe client";
                   // this.activeModal.close(false);
                }).finally(() => {
                    //
                    this.disableInput = false;
                });
        }
    }
