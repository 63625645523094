//NG+
import { AppSettings } from "../app-settings";
import { UserSettingsService } from "./user-settings-service";

import { NGXLogger } from "ngx-logger";
import { HttpClient, HttpParams } from "@angular/common/http";

export interface IDbStoreGrupText {
    getGrupText(): Promise<any>;
    addGrupText(category: { nume: string, descriere: string }): Promise<any>;
    updateGrupText(category: { id: number, nume: string, descriere: string }): Promise<any>;
    updateDictionarGrupText(grup: string, dictionar: { id: number, text: string }[]): Promise<any>;
    getTextPredefinitDictionar(grup: string): Promise<{ id: string, text: string }[]>;
}
export class DbStoreGrupText implements IDbStoreGrupText {
    constructor(private $http: HttpClient, private $log: NGXLogger, private settingsService: UserSettingsService) { }

    public getGrupText(): Promise<any> {
        return this.$http.get('/data/get-gruptext-predefinit',
        {observe: "response"})
            .toPromise()
            .then(res => res)
    }

    public addGrupText({ nume, descriere }): Promise<any> {
        return this.$http.post('/data/add-gruptext-predefinit',
         { nume, descriere },
         {observe: "response"})
            .toPromise()
            .then(res => res)
    }

    public updateGrupText({ id, nume, descriere }): Promise<any> {
        return this.$http.post('/data/update-gruptext-predefinit',
         { id, nume, descriere }
         ,{observe: "response"})
            .toPromise()
            .then(res => res)
    }

    public updateDictionarGrupText(grup: string, dictionar: { id: number, text: string }[]): Promise<any> {
        return this.$http.post('/data/update-dictionar-gruptext-predefinit',
         { grup, dictionar },
         {observe: "response", responseType: "text"})
            .toPromise()
            .then(res => res);
    }

    getTextPredefinitDictionar(grup: string): Promise<{ id: string, text: string }[]> {
        return this.$http.post(
            AppSettings.serverPath + 'data/get-text-predefinit-pentru-grup',
            JSON.stringify({ grup: grup }),
            {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                },
                observe: "response"
            })
            .toPromise()
            .then((response) => {
                let res: { id: string, text: string }[] = [];
                if (response.body == null) {
                    return res;
                }
                let resList = response.body as any[];
                try {
                    for (let i = 0; i < resList.length; i++) {
                        let item = {
                            id: resList[i].id,
                            text: resList[i].text,
                        };
                        if (item) {
                            res.push(item);
                        }
                    }
                } catch (e) {
                    throw new Error('Eroare dictionar text predefinit ' + e.message);
                }
                return res;
            })
    }
}
