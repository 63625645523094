//NG+
import { AppSettings } from "../app-settings";
import { ILayerFile, layerFilesStatus } from "../definitions";
import { UserSettingsService } from "./user-settings-service";

import { NGXLogger } from "ngx-logger";
import { HttpClient, HttpParams } from "@angular/common/http";
import { getExMessage, isObject } from "../map/map-utils";


export interface IDbStoreLayerFiles {
    setUploadFile(settings: any, loadFile: any): Promise<any>;
    getDownloadFile(id: number): Promise<any>;
    getFileStatus(id: number): Promise<any>;
    getLayerFileByStore(idLayerFile: number): Promise<ILayerFileInStore>;
}
export interface ILayerFileInStore {
    id: number,
    localURL: string,
    file: File

}
export class DbStoreLayerFiles implements IDbStoreLayerFiles {
    public layerFilesLocalStore: Array<ILayerFileInStore> = [];
    //
    constructor(
        private $http: HttpClient,
        private $log: NGXLogger,
        private settingsService: UserSettingsService) { }
    //
    getLayerFileByStore(idLayerFile: number): Promise<ILayerFileInStore> {
        let returnFile: ILayerFileInStore = null;
        let resultSearchLocal = this.layerFilesLocalStore.filter((item) => item.id === idLayerFile);
        //
        if (resultSearchLocal && resultSearchLocal.length > 0) {
            returnFile = resultSearchLocal[0];
            return Promise.resolve(returnFile);
        } else {
            return this.getDownloadFile(idLayerFile)
                .then((item) => {
                    returnFile = {
                        id: idLayerFile,
                        localURL: URL.createObjectURL(item),
                        file: item
                    }
                    this.layerFilesLocalStore.push(returnFile);
                    //limit store size at 200 files
                    if (this.layerFilesLocalStore.length > 200) {
                        this.layerFilesLocalStore.shift();
                    }
                    return returnFile;
                }).catch((reason) => {
                    this.$log.error("eroare download document", getExMessage(reason));
                    return Promise.resolve(null);
                })
        }
    }

    //
    setUploadFile(settings: ILayerFile, loadFile: any): Promise<ILayerFile> {
        let result: any =
        {
            id: -1, type: settings.type, idClient: settings.idClient, idLayer: settings.idLayer, descriere: '',
            status: layerFilesStatus.initializat, timestamp: '', filename: settings.fileName,
            seqTime: settings.seqTime

        };
        let tmpSettings = {
            id: settings.id,
            type: settings.type,
            idClient: settings.idClient,
            idLayer: settings.idLayer,
            idRefLayerItem: settings.idRefLayerItem,
            idColName: settings.idColName,
            fileColName: settings.fileColName,
            //status: settings.status,
            //description: settings.description,
            fileName: settings.fileName,
            //: settings.,

        }
        let formData = new FormData();
        formData.append("settings", JSON.stringify(tmpSettings));
        formData.append("file", loadFile);

        return this.$http.post(
            AppSettings.serverPath + '/layer-files/load',
            formData,
            {
                headers: { /*'Content-Type': 'multipart/form-data'*/ },
                observe: "response"
            }

        )
            .toPromise()
            .then((response) => {
                //
                if (response.body && isObject(response.body)) {
                    let data = response.body as any;
                    try {
                        result = {
                            id: data['id'],
                            type: data['type'],
                            idClient: data['idClient'],
                            idLayer: data['idLayer'],
                            idRefLayerItem: data['idRefLayerItem'],
                            idColName: data['idColName'],
                            fileColName: data['fileColName'],
                            status: data['status'],
                            description: data['description'],
                            timestamp: data['timestamp'],
                            fileName: data['fileName'],
                            diskFileName: data['diskFileName'],
                            fileSize: data['fileSize'],
                            file: null,
                            seqTime: settings.seqTime
                        }
                    } catch (error) {
                        result.status = 'eroare';
                        result.description = 'eroare extragere date task';
                        this.$log.error("eroare extragere date task");
                    }
                } else {
                    this.$log.error("eroare salvare document");
                    throw new Error("Eroare format obiect json");
                }
                return result;
            }).catch((reason) => {
                result.status = "eroare";
                result.description = " eroare interogare cadastru";
                if (reason.status && reason.statusText) {
                    result.description = " status code: " + reason.status + " , text: " + reason.statusText;
                }
                return result;
            })
    }
    //
    getDownloadFile(id: number): Promise<any> {
        return this.$http.get(
            AppSettings.serverPath + '/layer-files/download/' + id,
            {
                responseType: 'arraybuffer',
                observe: 'response'
            })
            .toPromise()
            .then((response: any) => {
                return new Blob([response.body], { type: 'application/pdf' });
            }).catch((reason) => {
                this.$log.error("Eroare interogare document pentru strat ", getExMessage(reason));
                return null;
            })
    }
    //
    getFileStatus(id: number): Promise<ILayerFile> {
        return this.$http.get(
            AppSettings.serverPath + '/layer-files/status/' + id,
            { observe: "response" }
        )
            .toPromise()
            .then((response: any) => {
                let data = response['body'];
                return {
                    id: data['id'],
                    type: data['type'],
                    idClient: data['idClient'],
                    idLayer: data['idLayer'],
                    idRefLayerItem: data['idRefLayerItem'],
                    idColName: data['idColName'],
                    fileColName: data['fileColName'],
                    status: data['status'],
                    description: data['description'],
                    timestamp: data['timestamp'],
                    fileName: data['fileName'],
                    diskFileName: data['diskFileName'],
                    fileSize: data['fileSize'],
                    file: null
                }
            }).catch((reason) => {
                this.$log.error("Eroare interogare status document pentru strat ", getExMessage(reason));
                return null;
            })
    }
    //
    setFileStatus(id: number, status: string, description: string, idRefLayerItem: number): Promise<{ id: number, success: boolean, message: string }> {
        let result = { id: id, success: true, message: '' };
        return this.$http.post(
            AppSettings.serverPath + 'layer-files/set-status',
            { id: id, status: status, description: description, idRefLayerItem: idRefLayerItem },
            {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                },
                observe: "response"
            })
            .toPromise()
            .then((res) => {
                return result;
            }).catch((reason) => {
                this.$log.error("Eroare setare status document pentru strat ", getExMessage(reason));
                result.success = false;
                result.message = 'eroare setare status' + reason.message || '';
                return result;
            })
    }
}
