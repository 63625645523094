 
 
//
import { MatSliderChange } from "@angular/material/slider";
import { AppSettings } from "../app-settings";
import { ICategory, authOpt, authAs, authType, authItemAccess, ILayer, IMenuLayerItem, menuAction, featureType, featureTypeForVector } from "../definitions";
import { MapController } from "./map-controller";
import { getExMessage, isNumber } from "./map-utils";
import { NGXLogger } from "ngx-logger";


import Control from 'ol/control/Control';
import VectorLayer from 'ol/layer/Vector';
import Draw from 'ol/interaction/Draw';
import Modify from 'ol/interaction/Modify';
import Snap from "ol/interaction/Snap";
import Feature from 'ol/Feature';
import TileLayer from 'ol/layer/Tile';
import VectorSource from "ol/source/Vector";
import  TileWMSSource  from "ol/source/TileWMS";
import ClusterSource from 'ol/source/Cluster';
import OlStyle from "ol/style/Style";
import StrokeStyle from "ol/style/Stroke";
import IconStyle from "ol/style/Icon";
import CircleStyle from "ol/style/Circle";
import FillStyle from "ol/style/Fill";

    export class MapMenuLayers {
        public constructor(public mapCtrl: MapController, private $log : NGXLogger) {

        };

        public hideCategoryIfEmpty(category: ICategory) {
            if (category.layers && category.layers.length > 0) {
                return false;
            } else {
                let strAuth = this.mapCtrl.userSettingsSrvs.isAuthForItemOption(authOpt.in_hide_menu_category, authAs.menu_category_hide_if_empty, category.code, authType.object);
                return strAuth === authItemAccess.true;
            }

        }
        //
        public showAddLayer() {
            if (this.mapCtrl.greentopClassifyMinimizeStateOn === true){
                return false;
            }
            return this.mapCtrl.userSettingsSrvs.isAuthForResource(authAs.data_add_layer, authType.route);
        }
        //
        public setOpacityToLayer(vm: MapController, layer: ILayer) {
            if (layer.opacity <= 10 && layer.opacity >= 0) {
                layer.internalLayer.setOpacity(layer.opacity / 10);
            }
        }
        public setOpacityToLayerAtValue(vm: MapController, layer: ILayer, event: MatSliderChange) {
            let opacity = event.value;
            if (opacity <= 10 && opacity >= 0) {
                layer.internalLayer.setOpacity(opacity / 10);
                if (layer.opacity != opacity){
                    layer.opacity = opacity;
                }
            }
        }

        public onShowHideLayer(vm: MapController, layer: ILayer) {
            if (layer.visible != layer.internalLayer.getVisible()) {
                layer.internalLayer.setVisible(layer.visible);
                if (layer.visible === true) {
                    layer.internalLayer.changed();
                    if (this.mapCtrl.oneActiveLayerButtonStateOn === true) {
                        this.mapCtrl.categories.forEach((catItem) => {
                            catItem.layers.forEach((layitem) => {
                                try {
                                    if (layitem.id !== layer.id) {
                                        layitem.visible = false;
                                        this.showHideLayer(null, layitem);
                                    }
                                } catch (error) {
                                    this.$log.error("Eroare in setare visibilitate strat: ", error)
                                }
                            });
                        });
                    }
                }
            }
        }

        //
        public showHideLayer(vm: MapController, layer: ILayer) {
            if (layer.visible != layer.internalLayer.getVisible()) {
                layer.internalLayer.setVisible(layer.visible);
                if (layer.visible === true) {
                    layer.internalLayer.changed();
                }
            }
        }
        //
        

        private layerMenuAction(vm: MapController, layer: ILayer, menuLayerItem: IMenuLayerItem, data: any) {
            if (layer && menuLayerItem) {
                switch (menuLayerItem.action) {
                    case menuAction.addFeature:
                        vm.mapMenuLayers.addFeatureMenuAction(vm, layer, menuLayerItem, data || null);
                        break;
                    case menuAction.editLayer:
                        vm.mapMenuLayers.editLayerMenuAction(vm, layer, menuLayerItem);
                        break;
                    case menuAction.refreshLayer:
                        vm.mapMenuLayers.refreshLayerMenuAction(vm, layer, menuLayerItem);
                        break;
                    case menuAction.setAsTopLayer:
                        vm.mapMenuLayers.setAsTopLayerMenuAction(vm, layer, menuLayerItem);
                        break;
                    case menuAction.generateRoute:
                        vm.mapCtrlTransportRoute.generateRoute(vm, layer, menuLayerItem);
                        break;
                    //
                    case menuAction.animateTimeRaster:
                        vm.mapMenuLayers.animateTimeRaster(vm, layer, menuLayerItem);
                        break;
                        case menuAction.animateTimeRasterGroup:
                        vm.mapMenuLayers.animateTimeRasterGroup(vm, layer, menuLayerItem);
                        break;
                    case menuAction.downloadShapefile:
                        vm.mapMenuLayers.downloadShapefile(vm, layer, menuLayerItem);
                        break;
                    case menuAction.downloadRasterfile:
                        vm.mapMenuLayers.downloadRasterfile(vm, layer, menuLayerItem);
                        break;
                    //transport
                    case menuAction.addTransportRoute:
                        this.addTransportRoute(vm, menuLayerItem, layer);
                        break;
                    case menuAction.editTransportRoute:
                        this.editTransportRoute(vm, menuLayerItem, layer, data);
                        break;
                    case menuAction.regenerateRoutes:
                        vm.transportDataService.regenerateRoutesForType(layer);
                        break;
                    case menuAction.addStation:
                        vm.transportDataService.regenerateRoutesForType(layer);
                        vm.transportDataService.regenerateRoutesForType(layer);
                        vm.mapMenuLayers.addTransportStation(vm, menuLayerItem, layer);
                        break;
                    case menuAction.rasterStyleScript:
                        this.editRasterStyleScript(layer);
                    break;
                    default:
                }
            }
        }

        private addFeatureMenuAction(vm: MapController, layer: ILayer, menuLayerItem: IMenuLayerItem, data: any) {
            //check if allready active
            if (this.mapCtrl.showAddFeature || this.mapCtrl.showAddFeature){
                this.$log.log("editarea este deja activa");
                return;
            }
            //
            this.mapCtrl.editFeatureReferenceLayer = layer;
            this.mapCtrl.showMainMenu = false;
            this.mapCtrl.showAddFeature = true;
            this.mapCtrl.newFeature = null;
            this.mapCtrl.addFeatureData = data;
            let source = new VectorSource();

            if (layer.featureType === featureType.point || layer.featureType === featureType.icon) {
                this.mapCtrl.editLayerFeature = new VectorLayer<any>({
                    source: source,
                    style: new OlStyle({
                        image: new IconStyle({
                            crossOrigin: 'anonymous',
                            src: AppSettings.serverPath + '/img/featureEdit.png',
                            anchor: [0.5, 0.0],
                            anchorOrigin: 'bottom-left'
                        })
                    })
                });

                this.mapCtrl.selectModifyInteraction = new Modify({
                    source: this.mapCtrl.editLayerFeature.getSource(),
                    style: new OlStyle({
                        fill: new FillStyle({
                            color: 'rgba(255, 255, 255, 0.2)'
                        }),
                        stroke: new StrokeStyle({
                            color: '#ffcc33',
                            width: 2
                        }),
                        image: new CircleStyle({
                            radius: 7,
                            fill: new FillStyle({
                                color: '#ffcc33'
                            })
                        })
                    })
                } as any);

                this.mapCtrl.map.addInteraction(this.mapCtrl.selectModifyInteraction);

                this.mapCtrl.selectDrawInteraction = new Draw({
                    source: source,
                    type: 'Point'
                })
            }

            if (layer.featureType === featureType.line) {
                this.mapCtrl.editLayerFeature = new VectorLayer<any>({
                    source: source,
                    style: new OlStyle({
                        stroke: new StrokeStyle({
                            color: '#ffcc33',
                            width: 4
                        })
                    })
                });

                this.mapCtrl.selectModifyInteraction = new Modify({
                    source: this.mapCtrl.editLayerFeature.getSource(),
                    //style: new OlStyle({
                    //    stroke: new StrokeStyle({
                    //        color: '#fffa34',
                    //        width: 2
                    //    })
                    //})
                } as any);

                this.mapCtrl.map.addInteraction(this.mapCtrl.selectModifyInteraction);

                this.mapCtrl.selectDrawInteraction = new Draw({
                    source: source,
                    type: 'LineString'
                })
            }

            if (layer.featureType === featureType.poly 
                || layer.featureType === featureType.polyReport
                || layer.featureType === featureType.polyText
                ) {
                this.mapCtrl.editLayerFeature = new VectorLayer<any>({
                    source: source,
                    style: new OlStyle({
                        stroke: new StrokeStyle({
                            color: 'rgba(123, 45, 25, 1)',
                            width: 4
                        }),
                        fill: new FillStyle({
                            color: 'rgba(28, 15, 125, 0.5)'
                        })
                    })
                });
                this.mapCtrl.selectModifyInteraction = new Modify({
                    source: this.mapCtrl.editLayerFeature.getSource()
                } as any);

                this.mapCtrl.map.addInteraction(this.mapCtrl.selectModifyInteraction);

                this.mapCtrl.selectDrawInteraction = new Draw({
                    source: source,
                    type: 'Polygon'
                })
            }

            this.mapCtrl.map.addInteraction(this.mapCtrl.selectDrawInteraction);
            let snap = new Snap({ source: source });
            this.mapCtrl.map.addInteraction(snap);

            this.mapCtrl.editLayerFeature.getSource().addFeature(new Feature());
            this.mapCtrl.map.addLayer(this.mapCtrl.editLayerFeature);

            this.mapCtrl.selectDrawInteraction.on('drawend', evt => {
                this.mapCtrl.selectDrawInteraction.setActive(false);
                this.mapCtrl.newFeature = evt.feature;
            });
        }

        private editLayerMenuAction(vm: MapController, layer: ILayer, menuLayerItem: IMenuLayerItem) {
            if (layer) {
                vm.mapDialogs.showEditLayerDialog(vm, null, layer);
            } else {
                this.$log.log("actiune strat, lipseste sursa ")
            }
        }

        private refreshLayerMenuAction(vm: MapController, layer: ILayer, menuLayerItem: IMenuLayerItem) {
            if (layer.featureType === featureType.polyReport) {
                layer.manualRefresh = true;
            }
            let name = (layer.internalLayer as VectorLayer<any>).get('name');
            if (name != null && name === 'Cluster'){
                ((layer.internalLayer as VectorLayer<any>).getSource() as ClusterSource<any>).getSource().refresh();
            }
            //
            if (layer.featureType === featureType.tile){
                let srcL = (layer.internalLayer as TileLayer<any>).getSource() as any;
                srcL.tileCache.expireCache({});
                srcL.tileCache.clear();
                const tmpDate = new Date();
                srcL.updateParams({ localRefresh: `${tmpDate.getMinutes()}_${tmpDate.getSeconds()}` });
            } else {
                (layer.internalLayer as VectorLayer<any>).getSource().refresh();
            }
            //close popup
            (this.mapCtrl.infoOverlay as any).hide();
            
        }

        private setAsTopLayerMenuAction(vm: MapController, layer: ILayer, menuLayerItem: IMenuLayerItem) {
            //check if layer is allready the top layer and then deactivate
            if (layer.setAsTopLayer != null && layer.setAsTopLayer === true) {
                layer.setAsTopLayer = false;
                layer.internalLayer.setZIndex(layer.defaultIndex);
            } else {
                 //clear other layers top setting
                let maxIndex = 1;
                for (let cat of this.mapCtrl.categories) {
                    for (let lay of cat.layers) {
                        if (lay.setAsTopLayer != null && lay.setAsTopLayer === true) {
                            lay.setAsTopLayer = false;
                            //set layer index base on id
                            lay.internalLayer.setZIndex(lay.defaultIndex);
                        }
                        if (lay.defaultIndex > maxIndex) {
                            maxIndex = lay.defaultIndex;
                        }
                    }
                }
                //set top layer for current 
                layer.setAsTopLayer = true;
                layer.internalLayer.setZIndex(maxIndex + 1);
            }
            //refresh settings
        }

        private editRasterStyleScript(layer: ILayer){
            if(layer.sldStyle && layer.sldStyle.xmlSrv){
                layer.sldStyle.visible = true;
            }
        }
        private closeRasterScript(layer: ILayer){
            if(layer.sldStyle){
                layer.sldStyle.visible = false;
            }
        }
        private setRasterScript(layer: ILayer){
            this.mapCtrl.mapOlInit.prepareRasterStyle(layer);
            this.refreshLayerMenuAction(null,layer, null);
        }

        private animateTimeRaster(vm: MapController, layer: ILayer, menuLayerItem: IMenuLayerItem) {
            vm.mapCtrlAnimateTimeRaster.onAnimateTimeRasterAction(layer, null);

        }

        private animateTimeRasterGroup(vm: MapController, layer: ILayer, menuLayerItem: IMenuLayerItem) {
            this.initControlAnimareGrupDateSatelitare(layer);
        }

        private closeAnimateTimeRasterGroup(layer: ILayer){

        }

        //downloadShapefile
        private downloadShapefile(vm: MapController, layer: ILayer, menuLayerItem: IMenuLayerItem) {
            let extent = vm.map.getView().calculateExtent(vm.map.getSize());
            vm.mapOlInit.loadShapefileFromServer(layer, extent);
        }
        //downloadRasterfile
        private downloadRasterfile(vm: MapController, layer: ILayer, menuLayerItem: IMenuLayerItem) {
            let extent = vm.map.getView().calculateExtent(vm.map.getSize());
            vm.mapOlInit.loadRasterfileFromServerNewWindow(layer, extent);
        }

        private addTransportRoute(vm: any, menuLayerItem: any, layer: any) {
            //
            let source: ILayer = vm.getSourceLayerFromAction(vm, menuLayerItem);
            if (layer) {
                vm.mapDialogs.showAddRouteDialog(layer, source);
            } else {
                this.$log.log("actiune strat ruta, lipseste sursa sau ruta")
            }
        }

        private editTransportRoute(vm: any, menuLayerItem: any, layer: any, data: any) {
            //
            if (this.mapCtrl.transportRouteShowEdit !== 'hide'){
                this.$log.log("o alta ruta este in editare");
                return;
            }
            let routeId = null;
            if ( data && data['id']!= null && isNumber(data['id'])){
                routeId = data['id']
            }
            //
            let source: ILayer = vm.getSourceLayerFromAction(vm, menuLayerItem);
            if (layer) {
                vm.mapDialogs.showEditRouteDialog(layer, source, routeId);
            } else {
                this.$log.log("actiune strat ruta, lipseste sursa sau ruta")
            }
        }

        public addTransportStation(vm: any, menuLayerItem: any, layer: any) {
            if (layer) {
                vm.mapDialogs.showAddStationDialog(vm, layer);
            } else {
                this.$log.log("actiune strat statie, lipseste ruta")
            }
        }

        //
        public menuActionFromMessage(layerName: string, actionName: string, data: object) {
            try {
                if (this.mapCtrl.hasActiveDialogs()) {
                    throw new Error("sunt dialoguri active");
                }
                if (this.mapCtrl.hasActiveDrawInteractions()) {
                    throw new Error("sunt active interactiuni harta");
                }
                if (this.mapCtrl.hasActiveLowerBandMenu()){
                    throw new Error("sunt active meniuri banda ");
                }
                let mapLayer: ILayer = null;
                this.mapCtrl.categories.forEach(icat => {
                    if (mapLayer === null) {
                        icat.layers.forEach(ilay => {
                            if (mapLayer === null) {
                                if (ilay.name === layerName) {
                                    mapLayer = ilay;
                                }
                            }
                        })
                    }
                });
                //
                if (mapLayer) {
                    let layitemsRes = mapLayer.menuLayerItems.filter((imen) => imen.action === actionName);
                    if (layitemsRes && layitemsRes.length > 0) {
                        this.layerMenuAction(this.mapCtrl, mapLayer, layitemsRes[0], data);
                    } else {
                        throw new Error(`Nu a fost gasita actiunea pentru strat: ${actionName}`);
                    }
                } else {
                    throw new Error(`Nu a fost gasit stratul ${layerName} pentru mesaj actiune meniu`);
                }
            } catch (error) {
                this.$log.error("eroare in mesaj actiune meniu strat: ", getExMessage(error));
            }
        }

        public layerViewsFromMessage(layerViews: Array<{ layer: string, visible: boolean, opacity: number }>): void {
            this.mapCtrl.categories.forEach((catItem) => {
                catItem.layers.forEach((layitem) => {
                    try {
                        let filtItems = layerViews.filter((fitem) => fitem.layer === layitem.name);
                        if (filtItems && filtItems.length > 0) {
                            let tmpsetting = filtItems[0];
                            if (typeof tmpsetting.visible === 'boolean') {
                                layitem.visible = tmpsetting.visible;
                                this.showHideLayer(null, layitem);
                            }
                            if ("opacity" in tmpsetting && typeof tmpsetting.opacity === 'number') {
                                if (tmpsetting.opacity <= 10 && tmpsetting.opacity >= 0) {
                                    layitem.opacity = tmpsetting.opacity;
                                } else if (tmpsetting.opacity < 0) {
                                    layitem.opacity = 0;
                                } else if (tmpsetting.opacity > 10) {
                                    layitem.opacity = 10;
                                }
                                this.setOpacityToLayer(null, layitem);
                            }
                        }
                    } catch (error) {
                        this.$log.error("Eroare in setare visibilitate strat din mesaj: ", getExMessage(error))
                    }
                });
            });
        }

        public addOneActiveLayerButton() {
            this.mapCtrl.oneActiveLayerButton = document.createElement('button');
            this.mapCtrl.oneActiveLayerButton.innerHTML = '<i class="fas fa-clipboard-check"></i>';
            this.mapCtrl.oneActiveLayerButton.title = 'activează/dezactivează un singur strat visibil';
            $(this.mapCtrl.oneActiveLayerButton).tooltip({placement: "right"});
            let element = document.createElement('div');
            element.className = 'one-active-layer-button ol-unselectable ol-control';
            element.appendChild(this.mapCtrl.oneActiveLayerButton);

            this.mapCtrl.oneActiveLayerButtonCtrl = new Control({
                element: element
            });

            this.mapCtrl.oneActiveLayerButton.addEventListener('click', this.onClickOneActiveLayerButton);
            this.mapCtrl.map.addControl(this.mapCtrl.oneActiveLayerButtonCtrl);
            
        }

        public onClickOneActiveLayerButton = (event) => {
            if (!this.mapCtrl.oneActiveLayerButtonStateOn) {
               $(this.mapCtrl.oneActiveLayerButtonCtrl["element"]).addClass('select-button-on');
                this.mapCtrl.oneActiveLayerButtonStateOn = true;
                //deactivate all layers
                this.mapCtrl.categories.forEach((catItem) => {
                    catItem.layers.forEach((layitem) => {
                        try {
                            layitem.visible = false;
                            this.showHideLayer(null, layitem);
                        } catch (error) {
                            this.$log.error("Eroare in setare visibilitate strat: ", getExMessage(error))
                        }
                    });
                });
            } else {
                $(this.mapCtrl.oneActiveLayerButtonCtrl["element"]).removeClass('select-button-on');
                this.mapCtrl.oneActiveLayerButtonStateOn = false;
            }
        }

        public showOneActiveLayerIfHasAccess(){
            try {
                //check
                this.mapCtrl.oneActiveLayerButtonStateVisible = this.mapCtrl.userSettingsSrvs.isAuthForResource(authAs.control_one_active_layer_button_visible, authType.object);
                if (this.mapCtrl.oneActiveLayerButtonCtrl) {
                    if (this.mapCtrl.oneActiveLayerButtonStateVisible ) {
                        $(this.mapCtrl.oneActiveLayerButtonCtrl["element"]).removeClass('greentop-button-hide');
                        
                    } else {
                        $(this.mapCtrl.oneActiveLayerButtonCtrl["element"]).addClass('greentop-button-hide');
                        this.mapCtrl.oneActiveLayerButtonStateOn = false;
                    }
                }
            } catch (error) {
                this.$log.error("eroare afisare buton un singur strat activ ");
            }
            
        }

        ///animate group
        public initControlAnimareGrupDateSatelitare(layer:ILayer){
            try {
                
                if (featureTypeForVector(layer.featureType) == false ){
                    throw Error("stratul nu este tip vector");
                }
                //
                if (layer.animateGroup == null){
                    layer.animateGroup = {
                        sourceVectorColumn:null,
                        menuControlVisible: false,
                        availableMomenteDateSatelitare: [],
                        groupLayers: [],
                        selectedMomentDateSatelitare: null
                    }
                }
                layer.animateGroup.menuControlVisible = false;
                //
                this.setAnimareGrupTimestamps(layer, false);
                //get group layers
                try {
                    layer.animateGroup.groupLayers = [];
                    for (var i = 0; i < this.mapCtrl.categories.length; i++) {
                        let catItem = this.mapCtrl.categories[i];
                        for (var j = 0; j < catItem.layers.length; j++) {
                            let sourceLayerName = this.mapCtrl.userSettingsSrvs.isAuthForOptionFullInfo(authOpt.animate_time_raster_source, catItem.layers[j].name, authType.layer);
                            if (sourceLayerName && sourceLayerName.descriere && sourceLayerName.descriere === layer.name) {
                                layer.animateGroup.groupLayers.push(catItem.layers[j]);
                            }
                        }
                    }
                } catch (reason) {
                    this.$log.error("eroare cautare strat grup animatie ", getExMessage(reason));
                }
                //set exiting or first
                if (layer.animateGroup.selectedMomentDateSatelitare && layer.animateGroup.selectedMomentDateSatelitare.length > 0){
                    let exists = layer.animateGroup.availableMomenteDateSatelitare.filter((item) => item === layer.animateGroup.selectedMomentDateSatelitare);
                    if (exists == null || exists.length == 0) {
                        if (layer.animateGroup.availableMomenteDateSatelitare.length > 0){
                            layer.animateGroup.selectedMomentDateSatelitare = layer.animateGroup.availableMomenteDateSatelitare[0];
                        } else {
                            layer.animateGroup.selectedMomentDateSatelitare = null;
                        }
                    }
                } else if (layer.animateGroup.selectedMomentDateSatelitare == null && layer.animateGroup.availableMomenteDateSatelitare.length > 0){
                    layer.animateGroup.selectedMomentDateSatelitare = layer.animateGroup.availableMomenteDateSatelitare[0];
                }
                if (layer.animateGroup.selectedMomentDateSatelitare){
                    this.onChangeMomentDateSatelitare(layer);
                }
                layer.animateGroup.menuControlVisible = true;
            } catch (reason) {
                this.$log.error("Eroare animare strat temporal ", getExMessage(reason));
            }
        }

        public setAnimareGrupTimestamps(layer: ILayer, limitBbox: boolean) {
            if (featureTypeForVector(layer.featureType) == false) {
                throw Error("stratul nu este tip vector");
            }//extract source column animate_time_raster_column
            let sourceColumnName = this.mapCtrl.userSettingsSrvs.isAuthForOptionFullInfo(authOpt.animate_time_raster_column, layer.name, authType.layer);
            if (sourceColumnName === undefined || sourceColumnName.descriere === undefined || sourceColumnName.descriere === '') {
                throw new Error("lipseste nume strat connex");
            }
            layer.animateGroup.sourceVectorColumn = sourceColumnName.descriere;
            //get the steps from  layer
            let features = (layer.internalLayer as VectorLayer<any>).getSource().getFeatures();
            layer.animateGroup.availableMomenteDateSatelitare = [];
            features.forEach((fitem) => {
                let strDate = fitem.get(layer.animateGroup.sourceVectorColumn);
                let extent = this.mapCtrl.map.getView().calculateExtent(this.mapCtrl.map.getSize());
                let intExtent = true;
                if(limitBbox){
                  intExtent = (fitem as Feature).getGeometry().intersectsExtent(extent);
                } 
                //
                if (intExtent && strDate) {
                    let exists = layer.animateGroup.availableMomenteDateSatelitare.filter((item) => item === strDate);
                    if (exists == null || exists.length == 0) {
                        layer.animateGroup.availableMomenteDateSatelitare.push(strDate);
                    }
                }
            });
            layer.animateGroup.availableMomenteDateSatelitare.sort();
        }

        public onFocusControlAnimareGrupTimestaps(layer: ILayer){
            try {
                let authBbox = this.mapCtrl.userSettingsSrvs.isAuthForOption(authOpt.animate_group_layers_bbox_filter, layer.name, authType.layer);
                if (authBbox){
                    this.setAnimareGrupTimestamps(layer, true);
                }
            } catch (error) {
                this.$log.error("Eroare setare lista selectia: " + getExMessage(error))
            }
        }


        public cancelControlAnimareGrupDateSatelitare(layer: ILayer){
            layer.animateGroup.menuControlVisible = false;
        }

        public onChangeMomentDateSatelitare(layer:ILayer) {
            let tmpMoment = "1000-01-01";// no data
            if (layer.animateGroup.selectedMomentDateSatelitare && layer.animateGroup.selectedMomentDateSatelitare.length > 0) {
                tmpMoment = layer.animateGroup.selectedMomentDateSatelitare;
            }
            
            layer.animateGroup.groupLayers.forEach((indInd) => {
                if (indInd && indInd.internalLayer) {
                    this.setMomentOnTileLayer(indInd, tmpMoment);
                }
            })
        }
        //
        onClickNextMomentDateSatelitare(layer:ILayer){
            if (layer.animateGroup.selectedMomentDateSatelitare == null 
                && layer.animateGroup.availableMomenteDateSatelitare.length > 0){
                    layer.animateGroup.selectedMomentDateSatelitare =   layer.animateGroup.availableMomenteDateSatelitare[0];
                    this.onChangeMomentDateSatelitare(layer);
                    return;
            } else if (layer.animateGroup.selectedMomentDateSatelitare.length > 0 
                && layer.animateGroup.availableMomenteDateSatelitare.length > 0){
                    let indCurrent = layer.animateGroup.availableMomenteDateSatelitare.indexOf(layer.animateGroup.selectedMomentDateSatelitare);
                    if (indCurrent >= 0 && indCurrent < layer.animateGroup.availableMomenteDateSatelitare.length - 1){
                        layer.animateGroup.selectedMomentDateSatelitare = layer.animateGroup.availableMomenteDateSatelitare[indCurrent + 1];
                        this.onChangeMomentDateSatelitare(layer);
                    }
                }
        }
        //
        onClickPrevMomentDateSatelitare(layer: ILayer){
            if (layer.animateGroup.selectedMomentDateSatelitare == null 
                && layer.animateGroup.availableMomenteDateSatelitare.length > 0){
                    layer.animateGroup.selectedMomentDateSatelitare =   layer.animateGroup.availableMomenteDateSatelitare[layer.animateGroup.availableMomenteDateSatelitare.length - 1];
                    this.onChangeMomentDateSatelitare(layer);
                    return;
            } else if (layer.animateGroup.selectedMomentDateSatelitare.length > 0 
                && layer.animateGroup.availableMomenteDateSatelitare.length > 0){
                    let indCurrent = layer.animateGroup.availableMomenteDateSatelitare.indexOf(layer.animateGroup.selectedMomentDateSatelitare);
                    if (indCurrent > 0 && indCurrent <= layer.animateGroup.availableMomenteDateSatelitare.length){
                        layer.animateGroup.selectedMomentDateSatelitare = layer.animateGroup.availableMomenteDateSatelitare[indCurrent - 1];
                        this.onChangeMomentDateSatelitare(layer);
                    }
                }
        }
        //
        private setMomentOnTileLayer(layer: ILayer, moment: string){
            let source = (layer.internalLayer as TileLayer<any>).getSource() as TileWMSSource;
                let params = source.getParams();
                params['time'] = moment;//time=2009-11-01
                source.updateParams(params);
                source.changed();
        }


       
    }
