//NG+
import { AppSettings } from "../app-settings";
import { ILayer } from "../definitions";

import { Inject, Injectable } from "@angular/core";
import { HttpClient, HttpParams, HttpResponse } from "@angular/common/http";

import moment, { Moment } from "moment";
import { NGXLogger } from "ngx-logger";
import { getExMessage } from "../map/map-utils";


import Feature from 'ol/Feature';
import Polygon from 'ol/geom/Polygon';
import WKTFormater from "ol/format/WKT";

export const TaskStatus = {
    initiat: "initiat",
    in_lucru: "in lucru",
    finalizat: "finalizat"
}

export const TaskActiune = {
    start: "start",
    stop: "stop"
}

export interface IDescarcareDateSatelitare {
    id?: number,
    denumire: string,
    descriere: string,
    sursaDate: string,
    geom: string | Polygon,
    startPerioada: Moment | any,
    endPerioada: Moment | any,
    momentCreare?: string,
    momentModificare?: string,
    status: string,
    mesajStatus: string,
    actiune?: string,
    granuleSatelitare?: Array<IGranulaSatelitara>,
    produseDescarcare?: Array<IProdusDescarcare>,
    indiciDescarcare?: Array<IIndiceDescarcare>
}
export interface IGranulaSatelitara {
    id?: number,
    codGranula: string,
    sursaDate: string,
    geom: string
}
export interface IProdusDescarcare {
    id?: number,
    codGranula: string,
    sursaDate: string,
    numeProdus: string,
    dataProdus: string,
    uuid: string,
    geom: string,
    previzualizareS: string,
    previzualizareL: string,
    status?: string,
    mesajStatus?: string,
    feature?: Feature,
    nr?: number,
    selected?: boolean,
    disponibilLocal?: boolean,
    statusIndici?: string
}
export interface IInfoDescarcareDateSatelitare {
    id?: number,
    denumire: string,
    descriere: string,
    sursaDate: string,
    momentCreare?: string,
    status: string,
}
export interface IIndiceDescarcare {
    id?: number,
    idDescarcare?: number,
    idIndice?: number,
    codIndice: string,
    sursaDate: string,
}

export interface IAntrenamentModelClasificare {
    id?: number,
    denumire: string,
    descriere: string,
    idAntrenamentParinte: number,
    idAlgoritm: number,
    denumireAlgoritm?: string,
    idStructuraDate: number,
    denumireStructuraDate?: string,
    status: string,
    mesajStatus: string,
    actiune?: string,
    momentCreare?: string,
    momentModificare?: string,
    descarcariAntrenament: Array<any>,
    zoneAntrenament: Array<any>,
    indiciAntrenament: Array<any>,
    progresStatus?: number,
    progresDetalii?: string
}

export interface IInfoAntrenamentModelClasificare {
    id?: number,
    denumire: string,
    descriere: string,
    momentCreare?: string,
    status: string,
    mesajStatus: string
}

export interface IZonaAntrenament {
    id?: number,
    idAntrenament?: number,
    idStratOrigine: number,
    idZonaOrigine: number | string,
    geom: string,
    idUtilizareTeren: number,
    denumireUtilizareTeren?: string,
    status: string,
    mesajStatus?: string,
    momentModificare?: string,
    descriere?: string,
    anUtilizare: number,
    startPerioadaUtilizare: string | { luna: number, decada: number },
    endPerioadaUtilizare: string | { luna: number, decada: number },
    feature?: Feature
}

export interface IDescarcareAntrenament {
    id?: number,
    idAntrenament?: number,
    idDescarcare: number,
    denumire?: string,
    descriere?: string,
    sursaDate?: string,
    geom?: string | Polygon,
    startPerioada?:  Moment | any,
    endPerioada?:  Moment | any,
    produseDescarcare?: Array<IProdusDescarcare>,
    indiciDescarcare?: Array<IIndiceDescarcare>
}

export interface IIndiceAntrenament {
    id?: number,
    idAntrenament?: number,
    idIndice?: number,
    codIndice: string,
    idStratSursaIndici: number,
    stratAsociat?: ILayer,
    sursaDate: string
}
export interface IInfoAlgoritmClasificare {
    id?: number,
    denumire: string,
    descriere: string
}
export interface IInfoStructuraDateAlgoritm {
    id?: number,
    denumire: string,
    descriere: string
}
export interface IInfoUtilizareTeren {
    id?: number,
    cod: number,
    denumire: string,
    descriere: string,
    startPerioadaUtilizare: string,
    endPerioadaUtilizare: string
}

export interface IInfoIndice {
    id: number,
    codIndice: string,
    descriere: string,
    sursaDate?: string,
    implicit?: boolean
}
export interface IInfoStil {
    id: number,
    denumire: string,
    descriere: string,
    grup?: string
}
export interface IInfoClasificareDateSatelitare {
    id?: number,
    denumire: string,
    descriere: string,
    momentCreare?: string,
    status: string,
    mesajStatus: string
}

export interface IClasificareDateSatelitare {
    id?: number,
    denumire: string,
    descriere: string,
    geom: string | Polygon,
    startPerioada: string | Moment,
    endPerioada: string | Moment,
    idDescarcare: number,
    idAntrenament: number,
    status: string,
    mesajStatus: string,
    actiune?: string,
    momentCreare?: string,
    momentModificare?: string,
    descarcare?: IDescarcareDateSatelitare,
    antrenament?: IAntrenamentModelClasificare,
    zoneRezultat?: Array<IClasificareZonaRezultat>,
    progresStatus?: number,
    progresDetalii?: string

}
export interface IClasificareZonaRezultat {
    id?: number,
    idClasificare?: number,
    geom: string,
    dataProdus: string,
    idUtilizareTeren: number,
    denumireUtilizareTeren?: string,
    rezultat?: string,
    feature?: Feature
}
export interface IReferintaIndiciUtilizareTeren {
    id: number,
    idTipUtilizareTeren: number,
    tipUtilizareTeren: string,
    codIndice: string,
    decada: number,
    valoare: number
}

export interface IGreentopDataService {
    getGranuleSatelitarePentruZonaInteres(zonaInteres: Polygon, sursaDate: Array<string>): Promise<Array<IGranulaSatelitara>>;
    getProduseDisponibileDescarcarePentruZonaInteres(startPerioada: string, endPerioada: string, zonaInteres: Polygon, sursaDate: Array<string>): Promise<any>;
    getDescarcariDateSatelitare(status: string[]): Promise<Array<IInfoDescarcareDateSatelitare>>;
    getDescarcareDateSatelitare(id: number, detalii?: boolean): Promise<IDescarcareDateSatelitare>;
    setSalvareDescarcareDateSatelitare(descarcare: IDescarcareDateSatelitare): Promise<any>;
    setNotificareDescarcareDateSatelitare(idDescarcare: number, config: any): Promise<any>;
    //
    getAntrenamenteModelClasificare(status: Array<string>): Promise<Array<IInfoAntrenamentModelClasificare>>;
    getAntrenamentModelClasificare(idAntrenament: number): Promise<IAntrenamentModelClasificare>;
    // setSalvareAntrenamentModelClasificare(antrenament: IAntrenamentModelClasificare): Promise<number>;
    setNotificareAntrenamentModelClasificare(idAntrenament: number, config: any): Promise<any>;

    getAlgoritmiClasificare(): Promise<Array<IInfoAlgoritmClasificare>>;
    getAvailableUtilizariTeren(): Promise<Array<IInfoUtilizareTeren>>;
    getAvailableIndici(): Promise<Array<IInfoIndice>>;

    //
    getClasificariiDateSatelitare(status: string[]): Promise<Array<IInfoClasificareDateSatelitare>>;
    getClasificareDateSatelitare(id: number, detalii: boolean): Promise<IClasificareDateSatelitare>;
    setSalvareClasificareDateSatelitare(clasificare: IClasificareDateSatelitare): Promise<Number>;
    setNotificareClasificareDateSatelitare(idClasificare: number, config: any): Promise<any>
}

@Injectable({
    providedIn: 'root',
})
export class GreentopDataService implements IGreentopDataService {
    public constructor(
        @Inject(HttpClient) private $http: HttpClient,
        @Inject(NGXLogger) private $log: NGXLogger,
        //private $log: ng.ILogService,
        // private $q: ng.IQService)
    ) {

    }
    //
    public getGranuleSatelitarePentruZonaInteres(zonaInteres: Polygon, sursaDate: Array<string>): Promise<Array<IGranulaSatelitara>> {
        let wkt = new WKTFormater();
        let paylod = {
            zonaInteres: wkt.writeGeometry(zonaInteres),
            sursaDate: sursaDate
        }
        return this.$http.post(
            AppSettings.serverPath + 'greentop/granule-pentru-zona-interes',
            JSON.stringify(paylod),
            {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                },
                observe: "response",
            }
        )
            .toPromise()
            .then((response) => {
                let grSat = [];
                let arrRes = response.body as Array<IGranulaSatelitara>;
                try {
                    for (const itgr of arrRes) {
                        let tmpgr: IGranulaSatelitara = {
                            codGranula: itgr.codGranula,
                            sursaDate: itgr.sursaDate,
                            geom: itgr.geom
                        }
                        grSat.push(tmpgr);
                    }
                } catch (e) {
                    throw new Error(" eroare date la interogare granule satelitare pentru zona interes" + e.message || "");
                }
                return grSat;
            });
    }

    public getProduseDisponibileDescarcarePentruZonaInteres(startPerioada: string, endPerioada: string, zonaInteres: Polygon, sursaDate: Array<string>): Promise<any> {
        let wkt = new WKTFormater();
        let paylod = {
            startPerioada: startPerioada,
            endPerioada: endPerioada,
            zonaInteres: wkt.writeGeometry(zonaInteres),
            sursaDate: sursaDate
        }
        return this.$http.post(
            AppSettings.serverPath + 'greentop/produse-disponibile-descarcare',
            JSON.stringify(paylod),
            {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                },
                observe: "response",
            })
            .toPromise()
            .then((response) => {

                let grSat = [];
                let arrRes = response.body as Array<IProdusDescarcare>;
                try {
                    for (const itgr of arrRes) {
                        let tmpgr: IProdusDescarcare = {
                            codGranula: itgr.codGranula,
                            sursaDate: itgr.sursaDate,
                            numeProdus: itgr.numeProdus,
                            dataProdus: itgr.dataProdus,
                            previzualizareS: "greentop/imagine-previzualizare-date-satelitare/mica/" + itgr.uuid + "?sursaDate=" + itgr.sursaDate,
                            previzualizareL: "greentop/imagine-previzualizare-date-satelitare/mare/" + itgr.uuid + "?sursaDate=" + itgr.sursaDate,
                            uuid: itgr.uuid,
                            geom: itgr.geom,
                            disponibilLocal: itgr.disponibilLocal
                        }
                        grSat.push(tmpgr);
                    }
                } catch (e) {
                    throw new Error(" eroare date la interogare produse disponibile descarcare pentru zona interes" + e.message || "");
                }
                return grSat;
            });
    }
    //
    public getDescarcariDateSatelitare(status: string[]): Promise<Array<IInfoDescarcareDateSatelitare>> {
        let paylod = {
            status: status
        }
        return this.$http.post(
            AppSettings.serverPath + 'greentop/descarcari-date-satelitare',
            JSON.stringify(paylod),
            {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                },
                observe: "response",
            })
            .toPromise()
            .then((response) => {
                let returnInfoDes: Array<IInfoDescarcareDateSatelitare> = [];
                let arrInfoDes = response.body as Array<IInfoDescarcareDateSatelitare>;
                try {
                    for (const itgr of arrInfoDes) {
                        let tmpgr: IInfoDescarcareDateSatelitare = {
                            id: itgr.id,
                            denumire: itgr.denumire,
                            descriere: itgr.descriere,
                            sursaDate: itgr.sursaDate,
                            status: itgr.status,
                            momentCreare: itgr.momentCreare
                        }
                        returnInfoDes.push(tmpgr);
                    }
                } catch (e) {
                    throw new Error(" eroare date la interogare taskuri descarcari date satelitare" + e.message || "");
                }
                return returnInfoDes;
            });
    }
    public getDescarcareDateSatelitare(id: number, detalii: boolean = true): Promise<IDescarcareDateSatelitare> {
        return this.$http.get(
            AppSettings.serverPath + 'greentop/descarcare-date-satelitare/' + id,
            {
                params: new HttpParams().set('detalii', detalii.toString()),
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                },
                observe: "response",
            })
            .toPromise()
            .then((response) => {
                let returnDes: IDescarcareDateSatelitare = {
                    id: null,
                    denumire: null,
                    descriere: null,
                    sursaDate: null,
                    geom: null,
                    startPerioada: null,
                    endPerioada: null,
                    momentCreare: null,
                    momentModificare: null,
                    status: null,
                    mesajStatus: null,
                    actiune: null,
                    granuleSatelitare: [],
                    produseDescarcare: [],
                    indiciDescarcare: []
                };
                let dataDes = response.body as IDescarcareDateSatelitare;
                try {
                    returnDes.id = dataDes.id;
                    returnDes.denumire = dataDes.denumire;
                    returnDes.descriere = dataDes.descriere;
                    returnDes.sursaDate = dataDes.sursaDate;
                    returnDes.geom = dataDes.geom;
                    //returnDes.startPerioada = dataDes.startPerioada;
                    //returnDes.endPerioada = dataDes.endPerioada;
                    if(dataDes.startPerioada && dataDes.startPerioada.length > 0){
                        returnDes.startPerioada = moment(dataDes.startPerioada,"YYYY-MM-DD")
                    }
                    if(dataDes.endPerioada && dataDes.endPerioada.length > 0){
                        returnDes.endPerioada = moment(dataDes.endPerioada,"YYYY-MM-DD")
                    }
                    returnDes.momentCreare = dataDes.momentCreare;
                    returnDes.momentModificare = dataDes.momentModificare;
                    returnDes.status = dataDes.status;
                    returnDes.mesajStatus = dataDes.mesajStatus;
                    returnDes.actiune = dataDes.actiune;
                    if (detalii === true) {
                        for (const itgr of dataDes.granuleSatelitare) {
                            let tmpgr: IGranulaSatelitara = {
                                codGranula: itgr.codGranula,
                                sursaDate: itgr.sursaDate,
                                geom: itgr.geom
                            }
                            returnDes.granuleSatelitare.push(tmpgr);
                        }
                        for (const itgr of dataDes.produseDescarcare) {
                            let tmpgrdes: IProdusDescarcare = {
                                codGranula: itgr.codGranula,
                                sursaDate: itgr.sursaDate,
                                numeProdus: itgr.numeProdus,
                                dataProdus: itgr.dataProdus,
                                previzualizareS: "greentop/imagine-previzualizare-date-satelitare/mica/" + itgr.uuid + "?sursaDate=" + itgr.sursaDate,
                                previzualizareL: "greentop/imagine-previzualizare-date-satelitare/mare/" + itgr.uuid + "?sursaDate=" + itgr.sursaDate,
                                uuid: itgr.uuid,
                                geom: itgr.geom,
                                status: itgr.status,
                                mesajStatus: itgr.mesajStatus,
                                statusIndici: itgr.statusIndici
                            }
                            returnDes.produseDescarcare.push(tmpgrdes);
                        }
                        for (const itind of dataDes.indiciDescarcare) {
                            let tmpinddes: IIndiceDescarcare = {
                                id: itind.id,
                                codIndice: itind.codIndice,
                                idIndice: itind.idIndice,
                                sursaDate: returnDes.sursaDate
                            }
                            returnDes.indiciDescarcare.push(tmpinddes);
                        }
                    }
                } catch (e) {
                    throw new Error(" eroare date la interogare task descarcari date satelitare" + e.message || "");
                }
                return returnDes;
            });
    }
    public setSalvareDescarcareDateSatelitare(descarcare: IDescarcareDateSatelitare): Promise<Number> {
        let payload = descarcare;
        return this.$http.post(
            AppSettings.serverPath + 'greentop/salvare-descarcare-date-satelitare',
            JSON.stringify(payload),
            {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                },
                observe: "response",
            })
            .toPromise()
            .then((response) => {
                let idDescarcare = null;
                try {
                    idDescarcare = response.body["idDescarcare"];
                } catch (e) {
                    throw new Error(" eroare date la salvare task descarcare date satelitare" + e.message || "");
                }
                return idDescarcare;
            });
    }
    //
    public setNotificareDescarcareDateSatelitare(idDescarcare: number, config: any): Promise<any> {
        let payload = config || {};
        return this.$http.post(
            AppSettings.serverPath + 'greentop/notificare-descarcare-date-satelitare/' + idDescarcare,
            JSON.stringify(payload),
            {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                },
                observe: "response"
            })
            .toPromise()
            .then((response) => {
                let resData = null;
                try {
                    resData = response.body;
                } catch (e) {
                    throw new Error(" eroare notificare descarcare date satelitare" + e.message || "");
                }
                return resData;
            });
    }

    //
    //
    //

    public getAntrenamenteModelClasificare(status: Array<string>): Promise<Array<IInfoAntrenamentModelClasificare>> {
        let paylod = {
            status: status
        }
        return this.$http.post(
            AppSettings.serverPath + 'greentop/antrenamente-model-clasificare',
            JSON.stringify(paylod),
            {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                },
                observe: "response"
            })
            .toPromise()
            .then((response) => {
                let returnInfoAnt: Array<IInfoAntrenamentModelClasificare> = [];
                let arrInfoAnt = response.body as Array<IInfoAntrenamentModelClasificare>;
                try {
                    for (const itgr of arrInfoAnt) {
                        let tmpgr: IInfoAntrenamentModelClasificare = {
                            id: itgr.id,
                            denumire: itgr.denumire,
                            descriere: itgr.descriere,
                            status: itgr.status,
                            momentCreare: itgr.momentCreare,
                            mesajStatus: itgr.mesajStatus || "",
                        }
                        returnInfoAnt.push(tmpgr);
                    }
                } catch (e) {
                    throw new Error(" eroare date la interogare taskuri antrenamente model clasificare" + e.message || "");
                }
                return returnInfoAnt;
            });
    }
    //
    public getAntrenamentModelClasificare(idAntrenament: number): Promise<IAntrenamentModelClasificare> {
        return this.$http.get(
            AppSettings.serverPath + 'greentop/antrenament-model-clasificare/' + idAntrenament,
            {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                },
                observe: "response",
            })
            .toPromise()
            .then((response) => {
                let returnAnt: IAntrenamentModelClasificare = {
                    id: null,
                    denumire: null,
                    descriere: null,
                    idAntrenamentParinte: null,
                    idAlgoritm: null,
                    denumireAlgoritm: "",
                    idStructuraDate: null,
                    denumireStructuraDate: "",
                    status: "",
                    mesajStatus: "",
                    actiune: null,
                    momentCreare: null,
                    momentModificare: null,
                    progresStatus: null,
                    progresDetalii: "",
                    descarcariAntrenament: [],
                    zoneAntrenament: [],
                    indiciAntrenament: []
                };
                let dataAnt = response.body as IAntrenamentModelClasificare;
                try {
                    returnAnt.id = dataAnt.id;
                    returnAnt.denumire = dataAnt.denumire;
                    returnAnt.descriere = dataAnt.descriere;
                    returnAnt.idAntrenamentParinte = dataAnt.idAntrenamentParinte;
                    returnAnt.idAlgoritm = dataAnt.idAlgoritm;
                    returnAnt.denumireAlgoritm = dataAnt.denumireAlgoritm;
                    returnAnt.idStructuraDate = dataAnt.idStructuraDate,
                        returnAnt.denumireStructuraDate = dataAnt.denumireStructuraDate,

                        returnAnt.momentCreare = dataAnt.momentCreare;
                    returnAnt.momentModificare = dataAnt.momentModificare;
                    returnAnt.status = dataAnt.status;
                    returnAnt.mesajStatus = dataAnt.mesajStatus;
                    returnAnt.actiune = dataAnt.actiune;
                    returnAnt.progresStatus = dataAnt.progresStatus;
                    returnAnt.progresDetalii = dataAnt.progresDetalii;
                    for (const itgr of dataAnt.descarcariAntrenament) {
                        let tmpgr: IDescarcareAntrenament = {
                            id: itgr.id,
                            idDescarcare: itgr.idDescarcare,
                            denumire: itgr.denumire,
                            descriere: itgr.descriere,
                            sursaDate: itgr.sursaDate,
                            geom: itgr.geom,
                            //startPerioada: itgr.startPerioada,
                            //endPerioada: itgr.endPerioada,
                            produseDescarcare: itgr.produseDescarcare,
                            indiciDescarcare: itgr.indiciDescarcare
                        }
                        if(itgr.startPerioada && itgr.startPerioada.length > 0){
                            tmpgr.startPerioada = moment(itgr.startPerioada,"YYYY-MM-DD")
                        }
                        if(itgr.endPerioada && itgr.endPerioada.length > 0){
                            tmpgr.endPerioada = moment(itgr.endPerioada,"YYYY-MM-DD")
                        }
                        returnAnt.descarcariAntrenament.push(tmpgr);
                    }
                    for (const itgr of dataAnt.zoneAntrenament) {
                        let tmpzoant: IZonaAntrenament = {
                            id: itgr.id,
                            idStratOrigine: itgr.idStratOrigine,
                            idZonaOrigine: itgr.idZonaOrigine,
                            geom: itgr.geom,
                            idUtilizareTeren: itgr.idUtilizareTeren,
                            denumireUtilizareTeren: itgr.denumireUtilizareTeren,
                            status: itgr.status,
                            mesajStatus: itgr.mesajStatus,
                            momentModificare: itgr.momentModificare,
                            descriere: itgr.descriere,
                            anUtilizare: itgr.anUtilizare,
                            startPerioadaUtilizare: itgr.startPerioadaUtilizare,
                            endPerioadaUtilizare: itgr.endPerioadaUtilizare
                        }
                        returnAnt.zoneAntrenament.push(tmpzoant);
                    }
                    for (const itgr of dataAnt.indiciAntrenament) {
                        let tmpinant: IIndiceAntrenament = {
                            id: itgr.id,
                            idIndice: itgr.idIndice,
                            codIndice: itgr.codIndice,
                            sursaDate: itgr.sursaDate,
                            idStratSursaIndici: itgr.idStratSursaIndici
                        }
                        returnAnt.indiciAntrenament.push(tmpinant);
                    }

                } catch (e) {
                    throw new Error(" eroare date la interogare task antrenament model clasificare" + e.message || "");
                }
                return returnAnt;
            });
    }

    public setSalvareAntrenamentModelClasificare(antrenament: IAntrenamentModelClasificare): Promise<number> {
        //
        let payload = antrenament;
        return this.$http.post(
            AppSettings.serverPath + 'greentop/salvare-antrenament-model-clasificare',
            JSON.stringify(payload),
            {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                },
                observe: "response"
            })
            .toPromise()
            .then((response) => {
                let idAntrenament = null;
                try {
                    idAntrenament = response.body["idAntrenament"];
                } catch (e) {
                    throw new Error(" eroare date la salvare task antrenament model clasificare" + e.message || "");
                }
                return idAntrenament;
            });
    }

    public setNotificareAntrenamentModelClasificare(idAntrenament: number, config: any): Promise<any> {
        let payload = config || {};
        return this.$http.post(
            AppSettings.serverPath + 'greentop/notificare-antrenament-model-clasificare/' + idAntrenament,
            JSON.stringify(payload),
            {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                },
                observe: "response"
            })
            .toPromise()
            .then((response) => {
                let resData = null;
                try {
                    resData = response.body;
                } catch (e) {
                    throw new Error(" eroare notificare antrenament model clasificare" + e.message || "");
                }
                return resData;
            });
    }

    public getAlgoritmiClasificare(): Promise<Array<IInfoAlgoritmClasificare>> {

        return this.$http.get(
            AppSettings.serverPath + 'greentop/algoritmi-clasificare',
            {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                },
                observe: "response"
            })
            .toPromise()
            .then((response) => {
                let returnInfoDes: Array<IInfoAlgoritmClasificare> = [];
                let arrInfoDes = response.body as Array<IInfoAlgoritmClasificare>;
                try {
                    for (const itgr of arrInfoDes) {
                        let tmpgr: IInfoAlgoritmClasificare = {
                            id: itgr.id,
                            denumire: itgr.denumire,
                            descriere: itgr.descriere,
                        }
                        returnInfoDes.push(tmpgr);
                    }
                } catch (e) {
                    throw new Error(" eroare date la interogare algoritmi clasificare" + e.message || "");
                }
                return returnInfoDes;
            });
    }

    public getStructuraDateAlgoritmi(): Promise<Array<IInfoStructuraDateAlgoritm>> {

        return this.$http.get(
            AppSettings.serverPath + 'greentop/structura-date-algoritm',
            {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                },
                observe: "response"
            })
            .toPromise()
            .then((response) => {
                let returnInfoDes: Array<IInfoStructuraDateAlgoritm> = [];
                let arrInfoDes = response.body as Array<IInfoStructuraDateAlgoritm>;
                try {
                    for (const itgr of arrInfoDes) {
                        let tmpgr: IInfoStructuraDateAlgoritm = {
                            id: itgr.id,
                            denumire: itgr.denumire,
                            descriere: itgr.descriere,
                        }
                        returnInfoDes.push(tmpgr);
                    }
                } catch (e) {
                    throw new Error(" eroare date la interogare structuri date algoritmi " + e.message || "");
                }
                return returnInfoDes;
            });
    }

    public getAvailableUtilizariTeren(): Promise<Array<IInfoUtilizareTeren>> {

        return this.$http.get(
            AppSettings.serverPath + 'greentop/utilizari-teren',
            {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                },
                observe: "response"
            })
            .toPromise()
            .then((response) => {
                let returnInfoVe: Array<IInfoUtilizareTeren> = [];
                let arrInfoVe = response.body as Array<IInfoUtilizareTeren>;
                try {
                    for (const itgr of arrInfoVe) {
                        let tmpgr: IInfoUtilizareTeren = {
                            id: itgr.id,
                            cod: itgr.cod,
                            denumire: itgr.denumire,
                            descriere: itgr.descriere,
                            startPerioadaUtilizare: itgr.startPerioadaUtilizare,
                            endPerioadaUtilizare: itgr.endPerioadaUtilizare

                        }
                        returnInfoVe.push(tmpgr);
                    }
                } catch (e) {
                    throw new Error(" eroare date la interogare tipuri de utilizari teren" + e.message || "");
                }
                return returnInfoVe;
            });
    }

    public getAvailableIndici(): Promise<Array<IInfoIndice>> {

        return this.$http.get(
            AppSettings.serverPath + 'greentop/indici',
            {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                },
                observe: "response"
            })
            .toPromise()
            .then((response) => {
                let returnInfoIn: Array<IInfoIndice> = [];
                let arrInfoIn = response.body as Array<IInfoIndice>;
                try {
                    for (const itgr of arrInfoIn) {
                        let tmpgr: IInfoIndice = {
                            id: itgr.id,
                            codIndice: itgr.codIndice,
                            descriere: itgr.descriere,
                            sursaDate: itgr.sursaDate,
                            implicit: itgr.implicit
                        }
                        returnInfoIn.push(tmpgr);
                    }
                } catch (e) {
                    throw new Error(" eroare date la interogare tipuri indici" + e.message || "");
                }
                return returnInfoIn;
            });
    }

    public getAvailableStiluri(): Promise<Array<IInfoStil>> {

        return this.$http.get(
            AppSettings.serverPath + 'greentop/stiluri',
            {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                },
                observe: "response"
            })
            .toPromise()
            .then((response) => {
                let returnInfoIn: Array<IInfoStil> = [];
                let arrInfoIn = response.body as Array<IInfoStil>;
                try {
                    for (const itgr of arrInfoIn) {
                        let tmpgr: IInfoStil = {
                            id: itgr.id,
                            denumire: itgr.denumire,
                            descriere: itgr.descriere,
                            grup: itgr.grup
                        }
                        returnInfoIn.push(tmpgr);
                    }
                } catch (e) {
                    throw new Error(" eroare date la interogare tipuri stil" + e.message || "");
                }
                return returnInfoIn;
            });
    }

    //
    //Clasificare
    //
    //
    public getClasificariiDateSatelitare(status: string[]): Promise<Array<IInfoClasificareDateSatelitare>> {
        let paylod = {
            staus: status
        }
        return this.$http.post(
            AppSettings.serverPath + 'greentop/clasificari-date-satelitare',
            JSON.stringify(paylod),
            {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                },
                observe: "response"
            })
            .toPromise()
            .then((response) => {
                let returnInfoCls: Array<IInfoClasificareDateSatelitare> = [];
                let arrInfoCls = response.body as Array<IInfoClasificareDateSatelitare>;
                try {
                    for (const itgr of arrInfoCls) {
                        let tmpgr: IInfoClasificareDateSatelitare = {
                            id: itgr.id,
                            denumire: itgr.denumire,
                            descriere: itgr.descriere,
                            status: itgr.status,
                            mesajStatus: itgr.mesajStatus || "",
                            momentCreare: itgr.momentCreare
                        }
                        returnInfoCls.push(tmpgr);
                    }
                } catch (e) {
                    throw new Error(" eroare date la interogare taskuri clasificari date satelitare" + e.message || "");
                }
                return returnInfoCls;
            });
    }
    //
    public getClasificareDateSatelitare(id: number, detalii: boolean = true): Promise<IClasificareDateSatelitare> {
        return this.$http.get(
            AppSettings.serverPath + 'greentop/clasificare-date-satelitare/' + id,
            {
                params: new HttpParams().set('detalii', detalii.toString()),
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                },
                observe: "response"
            })
            .toPromise()
            .then((response) => {
                let returnCls: IClasificareDateSatelitare = {
                    id: null,
                    denumire: null,
                    descriere: null,
                    geom: null,
                    startPerioada: null,
                    endPerioada: null,
                    idDescarcare: null,
                    idAntrenament: null,
                    momentCreare: null,
                    momentModificare: null,
                    status: null,
                    mesajStatus: null,
                    actiune: null,
                    descarcare: null,
                    antrenament: null,
                    zoneRezultat: [],
                    progresStatus: 0,
                    progresDetalii: ""
                };
                let dataCls = response.body as IClasificareDateSatelitare;
                try {
                    returnCls.id = dataCls.id;
                    returnCls.denumire = dataCls.denumire;
                    returnCls.descriere = dataCls.descriere;
                    returnCls.geom = dataCls.geom;
                    returnCls.startPerioada = moment(dataCls.startPerioada,"YYYY-MM-DD");
                    returnCls.endPerioada = moment(dataCls.endPerioada,"YYYY-MM-DD");
                    returnCls.idDescarcare = dataCls.idDescarcare;
                    returnCls.idAntrenament = dataCls.idAntrenament;

                    returnCls.momentCreare = dataCls.momentCreare;
                    returnCls.momentModificare = dataCls.momentModificare;
                    returnCls.status = dataCls.status;
                    returnCls.mesajStatus = dataCls.mesajStatus;
                    returnCls.actiune = dataCls.actiune;
                    returnCls.progresStatus = dataCls.progresStatus;
                    returnCls.progresDetalii = dataCls.progresDetalii;
                    if (detalii === true) {
                        returnCls.descarcare = dataCls.descarcare;
                        returnCls.descarcare.startPerioada = moment(dataCls.descarcare.startPerioada,"YYYY-MM-DD");
                        returnCls.descarcare.endPerioada = moment(dataCls.descarcare.endPerioada,"YYYY-MM-DD");
                        returnCls.antrenament = dataCls.antrenament;
                        returnCls.antrenament.descarcariAntrenament[0].startPerioada 
                                    = moment(dataCls.antrenament.descarcariAntrenament[0].startPerioada,"YYYY-MM-DD");
                        returnCls.antrenament.descarcariAntrenament[0].endPerioada 
                                    = moment(dataCls.antrenament.descarcariAntrenament[0].endPerioada,"YYYY-MM-DD");
                        for (const itgr of dataCls.zoneRezultat) {
                            let tmpzoant: IClasificareZonaRezultat = {
                                id: itgr.id,
                                geom: itgr.geom,
                                dataProdus: itgr.dataProdus,
                                idUtilizareTeren: itgr.idUtilizareTeren,
                                denumireUtilizareTeren: itgr.denumireUtilizareTeren,
                            }
                            returnCls.zoneRezultat.push(tmpzoant);
                        }
                    }
                } catch (e) {
                    throw new Error(" eroare date la interogare task clasificare date satelitare" + e.message || "");
                }
                return returnCls;
            });
    }
    //
    public setSalvareClasificareDateSatelitare(clasificare: IClasificareDateSatelitare): Promise<Number> {
        let payload = clasificare;
        return this.$http.post(
            AppSettings.serverPath + 'greentop/salvare-clasificare-date-satelitare',
            JSON.stringify(payload),
            {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                },
                observe: "response"
            })
            .toPromise()
            .then((response) => {
                let idClasificare = null;
                try {
                    idClasificare = response.body["idClasificare"];
                } catch (e) {
                    throw new Error(" eroare date la salvare task clasificare date satelitare" + e.message || "");
                }
                return idClasificare;
            });
    }
    //
    public setNotificareClasificareDateSatelitare(idClasificare: number, config: any): Promise<any> {
        let payload = config || {};
        return this.$http.post(
            AppSettings.serverPath + 'greentop/notificare-clasificare-date-satelitare/' + idClasificare,
            JSON.stringify(payload),
            {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                },
                observe: "response"
            })
            .toPromise()
            .then((response) => {
                let resData = null;
                try {
                    resData = response.body;
                } catch (e) {
                    throw new Error(" eroare notificare clasificare date satelitare" + e.message || "");
                }
                return resData;
            });
    }
    //
    //
    public loadResultClasificareLegend(greentopClassifyData) {
        if (greentopClassifyData == null &&
            greentopClassifyData.currentClasificare == null) {
            return;
        }
        let sursaDate = greentopClassifyData.currentClasificare.descarcare.sursaDate;
        return this.$http.get(
            AppSettings.serverPath + '/greentop/load-clasify-raster-legend/' + greentopClassifyData.currentClasificare.antrenament.id + '?REQUEST=GetLegendGraphic&sursaDate=' + sursaDate,
            {
                headers: {
                    'Accept': 'image/webp,image/apng,image/*,*/*;q=0.8'
                },
                responseType: 'arraybuffer',
                observe: "response"
            })
            .toPromise()
            .then((response) => {
                if (response.status == 200 &&
                    response.headers.get('content-type') === 'image/png') {
                    var arrayBufferView = Uint8Array.from(response.body as any);
                    var blob = new Blob([response.body as any], { type: 'image/png' });
                    var urlCreator = window.URL || window['webkitURL'];
                    greentopClassifyData.legendaClasificareUrl = urlCreator.createObjectURL(blob);
                    this.$log.log('clasificare legenda url ', greentopClassifyData.legendaClasificareUrl);
                } else {
                    if (response.body == Object(response.body)) {
                        this.$log.error("Eroare raspuns extragere legenda raster ", JSON.stringify(response.body));
                        return;
                    }
                }
            }).catch((reason) => {
                this.$log.error("Eroare extragere legenda raster ", getExMessage(reason));
                return null;
            })
    }

    //referinta-indici-utilizare-teren
    public getReferintaIndiciUtilizareTeren(codIndice, idTipUtilizareTeren): Promise<any> {

        return this.$http.get(
            AppSettings.serverPath + 'greentop/referinta-indici-utilizare-teren/' + codIndice + '/' + idTipUtilizareTeren,
            {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                },
                observe: "response"
            })
            .toPromise()
            .then((response) => {
                let retRef = [];
                try {

                    let arrRef = response.body as Array<IReferintaIndiciUtilizareTeren>;
                    for (const itgr of arrRef) {
                        let tmpRez: IReferintaIndiciUtilizareTeren = {
                            id: itgr.id,
                            idTipUtilizareTeren: itgr.idTipUtilizareTeren,
                            tipUtilizareTeren: itgr.tipUtilizareTeren,
                            codIndice: itgr.codIndice,
                            decada: itgr.decada,
                            valoare: itgr.valoare
                        }
                        retRef.push(tmpRez);
                    }
                } catch (e) {
                    throw new Error(" eroare la interogare referinta indici" + e.message || "");
                }
                return retRef;
            });
    }

    public getReferintaUtilizareTeren(codIndice): Promise<Array<IInfoUtilizareTeren>> {

        return this.$http.get(
            AppSettings.serverPath + 'greentop/referinta-tip-utilizare-teren/' + codIndice,
            {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                },
                observe: "response"
            })
            .toPromise()
            .then((response) => {
                let returnInfoVe: Array<IInfoUtilizareTeren> = [];
                let arrInfoVe = response.body as Array<IInfoUtilizareTeren>;
                try {
                    for (const itgr of arrInfoVe) {
                        let tmpgr: IInfoUtilizareTeren = {
                            id: itgr.id,
                            cod: itgr.cod,
                            denumire: itgr.denumire,
                            descriere: itgr.descriere,
                            startPerioadaUtilizare: null,//,itgr.startPerioadaUtilizare,
                            endPerioadaUtilizare: null//itgr.endPerioadaUtilizare

                        }
                        returnInfoVe.push(tmpgr);
                    }
                } catch (e) {
                    throw new Error(" eroare date la interogare tipuri de utilizari teren" + e.message || "");
                }
                return returnInfoVe;
            });
    }

}
