//NG+
import { AppSettings } from "../app-settings";
import { UserSettingsService } from "./user-settings-service";

import { NGXLogger } from "ngx-logger";
import { HttpClient, HttpParams, HttpResponse } from "@angular/common/http";
import { layerTypeName } from "../definitions";
import { taskFileCF, taskFileUrb, uploadFileCF, uploadFileUrb } from "../roles/settings-components/settings-definitions";
import { getExMessage, isArray, isObject } from "../map/map-utils";


export interface IDbStoreUrbanism {
    setUploadDocUrbanism(loadFile: uploadFileUrb, tipDoc: string): Promise<any>;
    getIstoricTaskDocUrbanism(pagina: number, tipDoc: string): Promise<{ maxPages: number, tasks: Array<taskFileUrb> }>;
    //getDocUrbanism(path: string): Promise<Blob>;
    getGenerareArhivaGPKG(tipDoc: string): Promise<any>;
}

export class DbStoreUrbanism implements IDbStoreUrbanism {

    constructor(
        private $http: HttpClient,
        private $log: NGXLogger,
        private settingsService: UserSettingsService) { }

    //
    public setUploadDocUrbanism(loadFile: uploadFileUrb, tipDoc: string): Promise<taskFileUrb> {
        let result: taskFileUrb =
        {
            id: -1, fisier: '', stare: '',
            status: '', time: '', fisier_gpkg: '', hash: '', tip: ''

        };
        //
        let formData = new FormData();
        formData.append("tipDoc", tipDoc);
        //formData.append("numarCadastral", loadFile.files.idURB);
        formData.append("gpkg", loadFile.files.file);
        //
        return this.$http.post(
            AppSettings.serverPath + '/docUrbanism/load-doc-urbanism',
            formData,
            {
                headers: { /*'Content-Type': 'multipart/form-data'*/ },
                observe: "response"
            })
            .toPromise()
            .then((response) => {
                if (response.body && isObject(response.body)) {
                    let restsk = response.body as taskFileUrb;
                    try {
                        result.id = restsk.id;
                        result.fisier = restsk.fisier;
                        result.stare = restsk.stare;
                        result.status = restsk.status;
                        result.fisier_gpkg = restsk.fisier_gpkg;
                        result.hash = restsk.hash;
                        result.tip = restsk['tip_task'];
                    } catch (error) {
                        result.status = 'eroare';
                        result.stare = 'eroare extragere date task';
                        this.$log.error("eroare extragere date task");
                    }
                } else {
                    this.$log.error("eroare salvare strat");
                    throw new Error("Eroare format obiect json");
                }
                return result;
            }).catch((reason) => {
                result.status = "eroare";
                result.stare = " eroare interogare docUrbanism";
                if (reason.status && reason.statusText) {
                    result.stare = " status code: " + reason.status + " , text: " + reason.statusText;
                }
                return result;
            })
    }

    //
    public getIstoricTaskDocUrbanism(pagina: number, tipDoc: string): Promise<{ maxPages: number, tasks: Array<taskFileUrb> }> {
        return this.$http.get(
            AppSettings.serverPath + '/docUrbanism/tasks-history/' + pagina,
            {
                params: { "tipDoc": tipDoc },
                observe: "response"
            })
            .toPromise()
            .then((response) => {
                let result: { maxPages: number, tasks: Array<taskFileUrb> } = { maxPages: 0, tasks: [] };
                if (response.body && isObject(response.body)
                    && response.body.hasOwnProperty('nrMaxPagini')
                    && response.body.hasOwnProperty('date')
                    && isArray(response.body['date'])) {
                    result.maxPages = response.body['nrMaxPagini'] || 0;
                    let resArray = response.body['date'] as Array<any>;
                    for (let index = 0; index < resArray.length; index++) {
                        let resItem: taskFileUrb =
                        {
                            id: -1, time: '', fisier: '',
                            stare: '', status: '', fisier_gpkg: '', hash: '', tip: ''
                        };
                        try {
                            const restsk = resArray[index];
                            //
                            resItem.id = restsk.id;
                            resItem.time = (restsk.stamp as string).replace('T', " ");

                            resItem.fisier = restsk.fisier;
                            resItem.stare = restsk.stare;
                            resItem.status = restsk.status;
                            resItem.fisier_gpkg = restsk.fisier_gpkg;
                            resItem.hash = restsk.hash;
                            resItem.tip = restsk.tip_task;
                        } catch (error) {
                            resItem.status = 'eroare';
                            resItem.stare = 'eroare extragere date task';
                            this.$log.error("eroare extragere date task");
                        }
                        result.tasks.push(resItem);
                    }
                }
                return result;
            });
    }

    //
    public getGenerareArhivaGPKG(tipDoc: string): Promise<any> {
        return this.$http.get(
            AppSettings.serverPath + '/docUrbanism/generate-doc-urbanism',
            {
                params: { tipDoc: tipDoc },
                //responseType: 'arraybuffer',
                observe: "response"
            })
            .toPromise()
            .then((response: any) => {
                if (isObject(response.body)) {
                    return response.body;
                } else {
                    throw Error("eroare raspuns generare");
                }
            });
    }
}
