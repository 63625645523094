 
 
//
import { NGXLogger } from "ngx-logger";
import { ILayer, searchType, ISelectedFeatures, ISelectFeatureConnected, authAs, authType } from "../definitions";
import { routeDialogReturn } from "../routes/route-components/add-edit-route.component";
import { MapController } from "./map-controller";


import VectorLayer from 'ol/layer/Vector';
import Feature from 'ol/Feature';
import Collection from "ol/Collection";
import Layer from 'ol/layer/Layer';
import * as olProj from 'ol/proj';

    export class MapDialogs {
       
        public constructor(public mapCtrl: MapController, private $log : NGXLogger) {
            
        };

        public showAddLayerDialog(vm: MapController, category: string) {
            this.mapCtrl.layerDialogServiceNg.showAddEditLayerDialog(category, null, true)
                .then((data) => {
                    if (data === true) {
                        //reload categories
                        this.mapCtrl.reloadUserConfigAndLayers();
                    }
                })
        }

        public showEditLayerDialog(vm: MapController, category: string, layer: ILayer) {
            this.mapCtrl.layerDialogServiceNg.showAddEditLayerDialog(category, layer, false)
                .then((data) => {
                    if (data === true) {
                        //reload categories
                        this.mapCtrl.reloadUserConfigAndLayers();
                    }
                })
        }


        public showEditSettingsDialog(vm: MapController) {
            if (this.isAuthForSettingDialog()) 
            {
                let mapData = {
                    center: olProj.transform(this.mapCtrl.map.getView().getCenter(), this.mapCtrl.map.getView().getProjection(), 'EPSG:4326'),
                    zoom: this.mapCtrl.map.getView().getZoom()
                }
                vm.settingsDialogsServiceNg.showSettingsDialog(mapData)
                    .then((data) => {
                        if (data === true) {
                            this.mapCtrl.reloadUserConfigAndLayers();
                        }
                    });
            } else {
                this.$log.log("Nu sunteti autorizat ")
            }
        }

        public isAuthForSettingDialog(){
            if(this.mapCtrl.userSettingsSrvs.isAuthForResource(authAs.data_save_resursa_roles_optiuni, authType.route)
            || this.mapCtrl.userSettingsSrvs.isAuthForResource(authAs.data_save_resursa_optiuni, authType.route)
            || this.mapCtrl.userSettingsSrvs.isAuthForResource(authAs.data_add_role, authType.route)
            || this.mapCtrl.userSettingsSrvs.isAuthForResource(authAs.data_update_role, authType.route)
            || this.mapCtrl.userSettingsSrvs.isAuthForResource(authAs.data_add_resursa_interna, authType.route)
            || this.mapCtrl.userSettingsSrvs.isAuthForResource(authAs.data_update_resursa_interna, authType.route)
            || this.mapCtrl.userSettingsSrvs.isAuthForResource(authAs.data_add_client, authType.route)
            || this.mapCtrl.userSettingsSrvs.isAuthForResource(authAs.data_edit_client, authType.route)
            || this.mapCtrl.userSettingsSrvs.isAuthForResource(authAs.data_add_mapview_settings, authType.route)
            || this.mapCtrl.userSettingsSrvs.isAuthForResource(authAs.data_save_mapview_settings, authType.route)
            || this.mapCtrl.userSettingsSrvs.isAuthForResource(authAs.data_add_style_settings, authType.route)
            || this.mapCtrl.userSettingsSrvs.isAuthForResource(authAs.data_save_style_settings, authType.route)
            || this.mapCtrl.userSettingsSrvs.isAuthForResource(authAs.data_add_internal_resource, authType.route)
            || this.mapCtrl.userSettingsSrvs.isAuthForResource(authAs.devices_generate_devices_day_routes, authType.route)
            || this.mapCtrl.userSettingsSrvs.isAuthForResource(authAs.data_add_category, authType.route)
            || this.mapCtrl.userSettingsSrvs.isAuthForResource(authAs.data_edit_category, authType.route)
            || this.mapCtrl.userSettingsSrvs.isAuthForResource(authAs.data_client_edit_category, authType.route)
            || this.mapCtrl.userSettingsSrvs.isAuthForResource(authAs.data_add_gruptext_predefinit, authType.route)
            || this.mapCtrl.userSettingsSrvs.isAuthForResource(authAs.data_update_gruptext_predefinit, authType.route)
            || this.mapCtrl.userSettingsSrvs.isAuthForResource(authAs.data_update_dictionar_gruptext_predefinit, authType.route)
            || this.mapCtrl.userSettingsSrvs.isAuthForResource(authAs.cadastru_load_carte_funciara, authType.route)){
                return true;
            } else {
                return false;
            }
        }

        public showLoginDialog(vm: MapController) {
            this.mapCtrl.showMainMenu = false;
            vm.authDialogsServiceNg.showLoginDialog()
                .then((data) => {
                    if (data === true) {
                        try {
                            this.mapCtrl.loadConfig();
                            this.mapCtrl.reloadLayers();
                            this.mapCtrl.mapOlInit.setMapView();
                            this.mapCtrl.enableControls();
                        } catch (e) {
                            this.$log.error("eroare in reinitializare harta");
                        }
                        
                    }
                }).finally(() => {
                    this.mapCtrl.showMainMenu = true;
                });
        }

        public showRegisterDialog(vm: MapController) {
            if (this.isAuthForUserDialog())
               {
                vm.authDialogsServiceNg.showRegisterDialog()
                    .then((data) => {
                        if (data === true) {
                            this.mapCtrl.reloadUserConfigAndLayers();
                        }
                    });
            } else {
                this.$log.log("Nu sunteti autorizat ")
            }
        }

        public isAuthForUserDialog() {
            if (this.mapCtrl.userSettingsSrvs.isAuthForResource(authAs.auth_change_current_password, authType.route)
                || this.mapCtrl.userSettingsSrvs.isAuthForResource(authAs.auth_change_password, authType.route)
                || this.mapCtrl.userSettingsSrvs.isAuthForResource(authAs.data_save_current_user_info, authType.route)
                || (this.mapCtrl.userSettingsSrvs.isAuthForResource(authAs.data_save_user_info, authType.route) &&
                    this.mapCtrl.userSettingsSrvs.isAuthForResource(authAs.data_user_info, authType.route))
                || this.mapCtrl.userSettingsSrvs.isAuthForResource(authAs.auth_local_register, authType.route)
                || this.mapCtrl.userSettingsSrvs.isAuthForResource(authAs.data_save_user_roles, authType.route)) {
                return true;
            } else {
                return false;
            }
        }

        public showEditInfoDialog(feature: Feature, layer: Layer, isEdtieElseInsert: boolean, data: Array<{key: string, value: any}>) {
            this.mapCtrl.infoDialogsServiceNg.showEditFeatureInfoDialog(feature, layer, isEdtieElseInsert, data)
                .then((data) => {
                    if (data) {
                        (layer as VectorLayer<any>).getSource().refresh();
                    }

                })
        }

        public showEditSearchDialog() {
            this.mapCtrl.searchText = '';
            let vectLayers: Array<ILayer> = [];
            this.mapCtrl.map.getLayers().forEach((litem) => {
                if (litem instanceof VectorLayer && MapController.appLayer in litem) {
                    vectLayers.push(litem[MapController.appLayer]);
                }
            });

            this.mapCtrl.infoDialogsServiceNg.showEditSearchInfoDialog(this.mapCtrl.searchSettings, vectLayers, this.mapCtrl.mapConfig)
                .then((data) => {
                    if (data) {
                        //set button color if search active
                        this.mapCtrl.searchActive = true;
                        if (this.mapCtrl.searchSettings.type === searchType.layerfeature) {
                            let center = turf.centerOfMass(this.mapCtrl.searchSettings.geometry);
                            let centerProj = olProj.transform(center['geometry']['coordinates'], 'EPSG:4326', this.mapCtrl.mapConfig.projection);
                            if (center) {
                                this.mapCtrl.map.getView().setCenter(centerProj);
                            }
                        }
                    }
                    else {
                        this.mapCtrl.searchActive = false;
                    }
                    this.mapCtrl.map.render();
                })
        }

        public showDetailsFeaturesInfoDialog(selectedFeaturesOnLayers: Collection<ISelectedFeatures>) {

            this.mapCtrl.infoDialogsServiceNg.showDetailsFeatureInfoDialog(selectedFeaturesOnLayers)
                .then((data) => {
                    if (data) {
                        //todo
                    }
                })
        }

        public showGrupFeaturesInfoDialog(selectedFeaturesOnLayer: ISelectedFeatures) {
            this.mapCtrl.infoDialogsServiceNg.showGroupFeatureInfoDialog(selectedFeaturesOnLayer)
                .then((data) => {
                    if (data) {
                        //todo
                    }
                })
        }
        
        public showInfoConnectedFeaturesDialog(raportFeature: Feature, connectedFeatures: Array<Feature>, raportLayer: ILayer, connectedLayer: ILayer) {
            this.mapCtrl.infoDialogsServiceNg.showInfoConnectedFeaturesDialog(raportFeature, connectedFeatures, raportLayer, connectedLayer)
                .then((data) => {
                    if (data) {
                        //todo
                    }
                })
        }

        public showPrintFeaturesInfoDialog(selectedFeaturesOnLayers: Collection<ISelectedFeatures>, mapImgUrl) {

            this.mapCtrl.infoDialogsServiceNg.showPrintFeaturesInfoDialog(selectedFeaturesOnLayers, mapImgUrl)
                .then((data) => {
                    if (data) {
                        //todo
                    }
                })
        }

        public showAddRouteDialog(layer: ILayer, source: ILayer) {
            this.mapCtrl.routeDialogServiceNg.showAddEditRouteDialog(layer, source, true, null, null)
                .then((data) => {
                    if (data === routeDialogReturn.closeAdd || data === routeDialogReturn.closeEdit) {
                        (layer.internalLayer as VectorLayer<any>).getSource().refresh();
                    } else if (data === routeDialogReturn.editOnMap) {
                        //todo edit map
                        this.mapCtrl.mapCtrlTransportRoute.editTransportRouteOnMap();
                    }
                });
        }

        public showEditRouteDialog(layer: ILayer, source: ILayer, routeId: number) {
            this.mapCtrl.routeDialogServiceNg.showAddEditRouteDialog(layer, source, false, null, routeId)
                .then((data) => {
                    if (data === routeDialogReturn.closeAdd || data === routeDialogReturn.closeEdit) {
                        (layer.internalLayer as VectorLayer<any>).getSource().refresh();
                    } else if (data === routeDialogReturn.editOnMap) {
                        //todo edit map
                        this.mapCtrl.mapCtrlTransportRoute.editTransportRouteOnMap();
                    }
                });
        }

        public showAddEditAferOnMapRouteDialog(layer: ILayer, source: ILayer) {
            this.mapCtrl.routeDialogServiceNg.showAddEditRouteDialog(layer, source, false, routeDialogReturn.editOnMap, null)
                .then((data) => {
                    if (data === routeDialogReturn.closeAdd || data === routeDialogReturn.closeEdit) {
                        (layer.internalLayer as VectorLayer<any>).getSource().refresh();
                    } else if (data === routeDialogReturn.editOnMap) {
                        //todo edit map
                        this.mapCtrl.mapCtrlTransportRoute.editTransportRouteOnMap();
                    }
                });
        }

        public showAddStationDialog(vm: MapController, layer: ILayer) {
            vm.routeDialogServiceNg.showAddEditStationDialog(layer, null, true)
                .then((data) => {
                    if (data) {
                        (layer.internalLayer as VectorLayer<any>).getSource().refresh();
                    }
                });
        }

        public showEditStationDialog(layer: ILayer, feature: Feature) {
            this.mapCtrl.routeDialogServiceNg.showAddEditStationDialog(layer, feature, false)
                .then((data) => {
                    if (data) {
                        (layer.internalLayer as VectorLayer<any>).getSource().refresh();
                    }
                });
        }

        public showFisaSpatiuluiVerdeDialog(selectedFeaturesConnectedOnLayers: Array<ISelectFeatureConnected>, mapImgUrl: string) {
            this.mapCtrl.infoDialogsServiceNg.showFisaSpatiuluiVerdeDialog(selectedFeaturesConnectedOnLayers, mapImgUrl)
                .then((data) => {
                    if (data) {
                        //todo
                        //(layer.internalLayer as VectorLayer<any>).getSource().refresh();
                    }
                });
        }

        public showInfoFeatureImagesDialog(imageData: {imagename: string, imageurl:string}[]) {
            this.mapCtrl.infoDialogsServiceNg.showInfoFeatureImagesDialog(imageData)
                .then((data) => {
                    if (data) {
                        //todo
                    }
                })
        }

        public showInfoClickGraphDialog(graphData: {name: string, data:any, type: string, coordinate: any}, enableGraficTipUtilizareTeren: boolean, status: any) {
            this.mapCtrl.infoDialogsServiceNg.showInfoClickGraphDialog(graphData, enableGraficTipUtilizareTeren, status)
                .then((data) => {
                    if (data) {
                        //todo
                    }
                })
        }

        public showInfoClick3DGraphDialog(graphData: { layer: ILayer,  bbox: Array<number>, coordinate: any, time: string, width: number, offset: number, offsetOsm: number, scale:number, nullValue: number, data: any}) {
            this.mapCtrl.infoDialogsServiceNg.showInfoClick3DGraphDialog(graphData)
                .then((data) => {
                    if (data) {
                        //todo
                    }
                })
        }
    }
