//NG+
import { AppSettings } from "../app-settings";
import { ILayer, ILayerReportSettings } from "../definitions";
import { UserSettingsService } from "./user-settings-service";


import { NGXLogger } from "ngx-logger";
import { HttpClient, HttpParams } from "@angular/common/http";
import { isObject, toJson } from "../map/map-utils";

export interface IDbStoreLayer {
    getLayerFromStorage(layerId): Promise<ILayer>;
    setAddLayerToStorage(layer: ILayer, uploadAction: string, image: any, layers: any, styles: any[], reportSettings: ILayerReportSettings[]): Promise<number>;
    setUpdateLayerToStorage(layer: ILayer, uploadAction: string, image: any, layers: any, styles: any[], reportSettings: ILayerReportSettings[]): Promise<boolean>;
    //getLayerReportSettings(layerId): Promise<Array<ILayerReportSettings>>;
}

export class DbStoreLayer {
    constructor(private $http: HttpClient, private $log: NGXLogger, private settingsService: UserSettingsService) { }

    //layer
    public getLayerFromStorage(layerId): Promise<ILayer> {
        return this.$http.get(
            AppSettings.serverPath + '/data/layer/' + layerId,
            { observe: "response" }
        )
            .toPromise()
            .then((response) => {
                let layer: ILayer = null;
                if (response.body && isObject(response.body)) {
                    try {
                        let rdata = response.body;
                        layer = {
                            id: rdata['id'],
                            name: rdata['nume'],
                            description: rdata['descriere'],
                            category: rdata['categorie'],
                            projection: rdata['proiectie'],
                            featureType: rdata['layerType'],
                            url: rdata['url'],
                            asset: rdata['icon'],
                            fileName: '',
                            color: rdata['culoare'],
                            visible: null,
                            internalLayer: null,
                            styleType: rdata['styleType'],
                            styleKeyColumn: rdata['styleKeyColumn'],
                            textKeyColumn: rdata['textKeyColumn'],
                            idClient: rdata['idClient']
                        }
                    } catch (e) {
                        this.$log.error("eroare in extragere date strat");
                    }
                }
                return layer;
            });
    }

    public setAddLayerToStorage(layer: ILayer, tipSursa: string, uploadAction: string, image: any, layers: any, styles: any[] = null,
        reportSettings: ILayerReportSettings[], connectedLayer: ILayer): Promise<number> {
        let newLayer = {
            categorie: layer.category,
            nume: layer.name,
            descriere: layer.description,
            url: layer.url,
            proiectie: layer.projection,
            layerType: layer.featureType,
            culoare: layer.color,
            styleType: layer.styleType,
            styleKeyColumn: layer.styleKeyColumn,
            textKeyColumn: layer.textKeyColumn,
            //fileName: layer.fileName,
            attributes: layer.attributes
        }

        let newConnectedLayer = null;
        if (connectedLayer) {
            newConnectedLayer = {
                categorie: layer.category,
                nume: connectedLayer.name,
                descriere: layer.description,
                url: connectedLayer.url,
                proiectie: layer.projection,
                layerType: connectedLayer.featureType,
                culoare: layer.color,
                styleType: layer.styleType,
                styleKeyColumn: layer.styleKeyColumn,
                attributes: connectedLayer.attributes
            }
        }


        let formData = new FormData();
        formData.append("layer", toJson(newLayer));
        formData.append("connectedLayer", toJson(connectedLayer));
        formData.append("tipSursa", tipSursa);
        formData.append("uploadAction", uploadAction);
        formData.append("styles", toJson(styles));
        formData.append("reportLayerInfo", toJson(reportSettings));
        formData.append("asset", image || null);
        if (layers && layers.length > 0) {
            for (let i = 0; i < layers.length; i++) {
                formData.append(layers[i].name, layers[i]);
            }
        }

        return this.$http.post(
            AppSettings.serverPath + '/data/add-layer',
            formData,
            {
                headers: { /* 'Content-Type': 'multipart/form-data' */},
                observe: "response"

            })
            .toPromise()
            .then((response) => {
                if (response.body && isObject(response.body) && response.body['layerId']) {
                    let layerId = response.body['layerId'];
                    if (isNaN(layerId)) {
                        return -1;
                    } else {
                        return layerId;
                    }
                } else {
                    this.$log.error("eroare salvare strat");
                    return -1;
                }
            })
    }

    public setUpdateLayerToStorage(layer: ILayer, tipSursa: string, uploadAction: string, image: any, layers: any, styles: any[], reportSettings: ILayerReportSettings[]): Promise<boolean> {
        let nupdateLayer = {
            id: layer.id,
            nume: layer.name,
            descriere: layer.description,
            categorie: layer.category,
            url: layer.url,
            proiectie: layer.projection,
            layerType: layer.featureType,
            culoare: layer.color,
            icon: layer.asset,
            fileName: layer.fileName,
            styleType: layer.styleType,
            styleKeyColumn: layer.styleKeyColumn,
            textKeyColumn: layer.textKeyColumn
        }
        let formData = new FormData();
        formData.append("layer", toJson(nupdateLayer));
        formData.append("uploadAction", uploadAction);
        formData.append("styles", toJson(styles));
        formData.append("reportLayerInfo", toJson(reportSettings));
        formData.append("asset", image);
        if (layers && layers.length > 0) {
            for (let i = 0; i < layers.length; i++) {
                formData.append(layers[i].name, layers[i]);
            }
        }
        return this.$http.post(
            AppSettings.serverPath + '/data/update-layer',
            formData,
            {
                headers: { /*'Content-Type': 'multipart/form-data'*/ },
                observe: "response",
                responseType: 'text'
            })
            .toPromise()
            .then((response) => {
                if (response.status === 200) {
                    return true;
                } else {
                    this.$log.error("eroare salvare strat");
                    return false;
                }
            })
    }

    //
    public setUpdateLayerClientAccess(layer: ILayer, access: string): Promise<{status: boolean, data: any}> {
        let nupdateLayer = {
            layerId: layer.id,
            access: access
        }
        
        return this.$http.post(
            AppSettings.serverPath + '/data/change-layer-client-access-public-private',
            nupdateLayer,
            {
                headers: { /*'Content-Type': 'multipart/form-data'*/ },
                observe: "response"
            })
            .toPromise()
            .then((response) => {
                if (response.body == null) {
                    return { status: true, data: null}
                } else if(response.body){
                    return {status: false, data: response.body}
                }
            })
    }

}
