//NG+
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AppSettings } from '../../app-settings';
import { ILayer, authAs, authOpt, authType, featureType } from '../../definitions';
import { MapController } from '../map-controller';
import { ITreeOptions, KEYS, TREE_ACTIONS } from '@circlon/angular-tree-component';

@Component({
    selector: 'map-layers',
    providers: [],
    templateUrl: './app/map/map-components/map-layers.component.html',
    styleUrls: ['./app/map/map-components/map-layers.component.css']
})
export class MapLayersComponent implements OnInit, OnChanges {

    @Input("mapctrl") mapCtrl: MapController = null;
    //
    public layerTreeOptions: ITreeOptions = {
        //displayField: 'name',
        isExpandedField: 'expanded',
        idField: 'id',
        hasChildrenField: 'children',
        actionMapping: {
          mouse: {
            dblClick: (tree, node, $event) => {
                  if (node.hasChildren) TREE_ACTIONS.TOGGLE_EXPANDED(tree, node, $event);
                }
          },
          keys: {
            [KEYS.ENTER]: (tree, node, $event) => {
              node.expandAll();
            }
          }
        },
        nodeHeight: 23,
        allowDrag: (node) => {
          return false;
        },
        allowDrop: (node) => {
          return false;
        },
        allowDragoverStyling: false,
        levelPadding: 10,
        useVirtualScroll: true,
        animateExpand: true,
        scrollOnActivate: true,
        animateSpeed: 30,
        animateAcceleration: 1.2,
        //scrollContainer: document.documentElement // HTML
      }
    public ngOnInit(): void {
        //
    }

    public ngOnChanges(changes: SimpleChanges) {
        //
    }

}
