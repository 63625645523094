//NG+
import { AppSettings } from "../app-settings";
import { UserSettingsService } from "./user-settings-service";

import { NGXLogger } from "ngx-logger";
import { HttpClient, HttpParams } from "@angular/common/http";

export interface IDbStoreClient {
    addNewClient(resursa: {}): Promise<boolean>;
    addOptResCli(data: {}): Promise<boolean>;
    getClient(): Promise<any>;
    getClients(): Promise<any>;
    getOptions(): Promise<any>;
    getClientRoles(id: number): Promise<any>;
    editClient(resursa: {}): Promise<boolean>;
    getJudete(): Promise<any>;
    getTipClienti(): Promise<any>;
    getLocalitati(id: number): Promise<any>;
    getResources(type: string): Promise<any>;
}

export class DbStoreClient implements IDbStoreClient {
    constructor(private $http: HttpClient, private $log: NGXLogger, private settingsService: UserSettingsService) { }

    public addNewClient(data): Promise<boolean> {
        return this.$http.post(AppSettings.serverPath + 'data/add-client',
            { data },
            { observe: "response" })
            .toPromise()
            .then(res => true);
    }

    public addOptResCli(data): Promise<boolean> {
        return this.$http.post(AppSettings.serverPath + 'data/add-res-opt',
            { data }, { observe: "response", responseType: 'text' })
            .toPromise()
            .then(res => true);
    }

    public editClient(data): Promise<boolean> {
        return this.$http.post(AppSettings.serverPath + 'data/edit-client',
            data, { observe: "response" })
            .toPromise()
            .then(res => true);
    }

    public getJudete(): Promise<any> {
        return this.$http.get(AppSettings.serverPath + 'data/get-judete',
            { observe: "response" })
            .toPromise()
            .then(res => res);
    }

    public getTipClienti(): Promise<any> {
        return this.$http.get(AppSettings.serverPath + 'data/get-tip-clienti',
            { observe: "response" })
            .toPromise()
            .then(res => res);
    }

    public getLocalitati(id: number): Promise<any> {
        return this.$http.get(AppSettings.serverPath + 'data/get-localitati/' + id,
            { observe: "response" })
            .toPromise()
            .then(res => res);
    }

    public getResources(type: string): Promise<any> {
        return this.$http.post(AppSettings.serverPath + 'data/get-resources',
            { type },
            { observe: "response" })
            .toPromise()
            .then(res => res, error => error);
    }

    public getClient(): Promise<any> {
        return this.$http.get(AppSettings.serverPath + 'data/get-client',
            { observe: "response" })
            .toPromise()
            .then(res => res, error => error);
    }

    public getClients(): Promise<any> {
        return this.$http.get(AppSettings.serverPath + 'data/get-clients',
            { observe: "response" })
            .toPromise()
            .then(res => res, error => error);
    }

    public getOptions(): Promise<any> {
        return this.$http.get(AppSettings.serverPath + 'data/get-options',
            { observe: "response" })
            .toPromise()
            .then(res => res, error => error);
    }

    public getClientRoles(id: number): Promise<any> {
        return this.$http.get(AppSettings.serverPath + 'data/get-roles/' + id,
            { observe: "response" })
            .toPromise()
            .then(res => res, error => error);
    }

}
