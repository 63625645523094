import { IAnimate, IAnimateTimeRaster, IMeasure, IPositionData, ITranzitData, ToolButtonType } from "../definitions";
import { graphType } from "../features/features-components/info-click-graph.component";
import { IGreentopDownloadData, IGreentopTrainingData, IGreentopClassifyData, listaSursaDateClasificare } from "./map-components/map-greentop-definitions";
import { IIntegrateLandsatDownloadData, IIntegrateLandsatHotspotData, IIntegrateLandsatProcesareIndiceConexData, IIntegrateLandsatProcesareDateSezoniereData, listaSursaDateClasificareIL } from "./map-components/map-integrate.definitions";

export interface IMapCtrlDefaults {
    animate: IAnimate;
    animateTimeRasterData: IAnimateTimeRaster;
    measure: IMeasure;
    tranzitData: ITranzitData;
    positionData: IPositionData;
    greentopDownloadData: IGreentopDownloadData;
    greentopTrainingData: IGreentopTrainingData;
    greentopClassifyData: IGreentopClassifyData;
    integrateLandsatDownloadData: IIntegrateLandsatDownloadData;
    integrateLandsatHotspotData: IIntegrateLandsatHotspotData;
    integrateLandsatProcIndConexData : IIntegrateLandsatProcesareIndiceConexData;
    integrateLandsatProcDateSezoniereData : IIntegrateLandsatProcesareDateSezoniereData;
    treeCatMenu: any[];

}
export const mapCtrlDefaults : IMapCtrlDefaults = {
     animate:  {
        layer: null,
        feature: null,
        styles: null,
        polyline: null,
        route: null,
        routeCoords: null,
        routeLength: null,
        routeFeature: null,
        geoMarker: null,
        vectorLayer: null,
        index: 0,
        isAnimating: false,
        speed: 1,
        maxSpeed: 11,
        minSpeed: 1,
        sliderValue: 1,
        routeDist: null,
        startPointIndex: 0
    },
    //
     animateTimeRasterData: {
        //timesource
        sourceVectorColumn: null,
        sourceVectorLayer: null,
        sourceRasterLayer: null,
        //
        index: 0,
        isAnimating: false,
        ticks: 0,
        speed: 3,
        maxSpeed: 11,
        minSpeed: 1,
        sliderValue: 3,
        steps: [],
        startPointIndex: 0,
        info: '',
        stepValues: [],
        graphType: graphType.bar,
        
        startInterval: null,
        endInterval: null,
        selectSteps: [],
        startSteps: [],
        endSteps: []
    },
    //
     measure: {
        distance: {
            button: null,
            buttonCtrl: null,
            buttonStateOn: false,
        },
        area: {
            button: null,
            buttonCtrl: null,
            buttonStateOn: false,
        },
        layer: null,
        drawInteraction: null,
        type: ToolButtonType.distance,
        tooltipElement: null,
        tooltipOverlay: null,
        onChangeListener: null
    },
    //
    tranzitData: {
        tripPlan: null,
        activeConfigTab0: false,
        activeConfigTab1: false,
        activeConfigTab: 0,
        momentPlecare: 'Acum',
        input: {
            start: null,
            stop: null
        },
        coordonate:{
            start: [0,0],
            stop: [0,0]
        },
        locatii: {
            start: null, //feature
            stop: null  //feature
        },
        stratLocatii: null,
        stratTranzit: null,
        locatiiModifyInteraction: {start: null, stop: null},
        optPlecareImediata: 'now',
        optDataPlecare: "",//this.moment(),
        //optOraPlecare: "",
        optOraMinPlecare: {hour: 12, minute: 0},
        optFormatDataPlecare: "YYYY-MM-DD",
        optFormatOraPlecare: "HH:mm",
        activeNoTrips: false,
        activeSearchTrips: false,
        optMaxWalkDistance: 5000,
        geoLocation: null,
        toGetStartOrStopFromGeolocation: null,
        ctrlSearchIsCollapsed: false,
        ctrlRuteIsCollapsed: false,
        //
        funcInit: null,
        funcClear: null,
        funcSetStartCoordinates: null,
        funcSetStopCoordinates: null,
    },
    positionData: {
        selectedOption: 'search',
        searchText: '',
        disableSearch: false,
        locations: [],
        activeSearchLocations: false,
        numaiUnitatiTeritoriale: false,
        locationLayer: null,
        //
        funcInit: null,
        funcClear: null,
    },
    greentopDownloadData: {
        ctrlSearchIsCollapsed: false,
        ctrlProductsIsCollapsed: false,
        ctrlDownloadDetailsIsColapsed: true,
        ctrlExistingDetalisIsColapsed: false,
        activeTab: 0,
        activeNewTab0: true,
        activeExistsTab1: false,
        availableDescarcari: [],
        selectedDescarcare: null,
        optFormatDataPerioada: "YYYY-MM-DD",
        currentDescarcare: null,
        selectButtonStateOn: false,
        selectDrawInteraction: null,
        selectLayer: null,
        loadingSearchProductsActive: false,
        errorMessage: "",
        productsLayer: null,
        selectedSursaDate: null,
        availableSursaDate: listaSursaDateClasificare,
        selectednewIndice: null,
        availableIndici: [],
        filteredIndici: [],
        //
        funcInit: null,
        funcClear: null,
        
    },
    greentopTrainingData: {
        ctrlSearchIsCollapsed: false,

        activeTab: 0,
        activeNewTab0: true,
        activeExistsTab1: false,

        activeDataTab: 0,

        loadingActive: false,

        optFormatDataPerioada: "YYYY-MM-DD",

        selectedAntrenament: null,
        availableAntrenamente: [],

        currentAntrenament: {} as any,

        selectedDescarcare: null,
        availableDescarcari: [],
        interestAreaLayer: null,

        selectedAlgoritm: null,
        availableAlgoritmi: [],

        selectedStructuraDate: null,
        availableStructuriDateAlgoritmi: [],

        trainingZonesLayer: null,
        importZonesLayer:null,

        selectLayer: null,
        selectDrawInteraction: null,
        selectEditInteraction: null,
        selectButtonStateOn: false,

        selectedneweditUtilizareTeren: null,
        availableUtilizariTeren: [],
        neweditZona: null,
        selectedZonaAntrenament: null,

        availableImportLayers: [],
        selectedImportLayer: null,
        availableImportFields: [],
        selectedImportUtilizare: null,
        selectedImportAn: null,
        selectedImportStartPerioada: null,
        selectedImportEndPerioada: null,
        selectedImportDescriere: null,
        importPeriodFromDefault: false,
        importSettings: null,

        selectednewIndice: null,
        filteredIndici: [],

        istoolActive_neweditTrainingZone: false,
        istoolActive_importTrainingZones: false,
        errorMessage: "",
        errorMessageZona: "",
        errorMessageImport: "",
        resultMessageImport: "",
        //
        autorefreshInterval: 30,
        autorefreshTimeout: 30,
        resIntervalRef: null,
        //
        funcInit: null,
        funcClear: null,
    },
    //
    greentopClassifyData: {
        ctrlSearchIsCollapsed: false,

        activeTab: 0,
        activeNewTab0: true,
        activeExistsTab1: false,

        activeDataTab: 0,

        loadingActive: false,

        selectedClasificare: null,
        availableClasificari: [],

        currentClasificare: null,

        selectedDescarcare: null,
        availableDescarcari: [],
        downloadAreaLayer: null,

        selectedAntrenament: null,
        availableAntrenamente: [],

        selectedStil: null,
        availableStiluri: [],

        selectedAnPerioada: null,
        availableAniPerioada: [],

        selectedMomentDateSatelitare: null,
        availableMomenteDateSatelitare: [],

        resultTileLayer: null,
        resultLayer: null,
        selectLayer: null,
        selectDrawInteraction: null,
        selectButtonStateOn: null,

        optFormatDataPerioada: "YYYY-MM-DD",
        errorMessage: "",
        //
        autorefreshInterval: 30,
        autorefreshTimeout: 30,
        resIntervalRef: null,
        //
        funcInit: null,
        funcClear: null,
    },
    //
    integrateLandsatDownloadData: {
        ctrlSearchIsCollapsed: false,
        ctrlProductsIsCollapsed: false,
        ctrlDownloadDetailsIsColapsed: true,
        ctrlDownloadUnitatiAdministrativeIsColapsed: true,
        ctrlExistingDetalisIsColapsed: false,
        activeTab: 0,
        activeNewTab0: true,
        activeExistsTab1: false,
        availableUnitatiAdministrative: [],
        selectedUnitateAdministrativa: null,
        availableDescarcari: [],
        selectedDescarcare: null,
        optFormatDataPerioada: "YYYY-MM-DD",
        currentDescarcare: null,
        selectButtonStateOn: false,
        selectDrawInteraction: null,
        selectLayer: null,
        loadingSearchProductsActive: false,
        errorMessage: "",
        productsLayer: null,
        selectedSursaDate: null,
        availableSursaDate: listaSursaDateClasificareIL,
        //
        funcInit: null,
        funcClear: null,
    },

     integrateLandsatHotspotData: {
        ctrlSearchIsCollapsed: false,
    
        loadingActive: false,
        doneActive: false,
        
        currentHotspot: null,
        downloadAreaLayer: null,
    
        selectedMomentDateSatelitare: null,
        availableMomenteDateSatelitare: [],

        selectedImpermeabilitate: null,
        availableImpermeabilitate:[],
    
        selectedPrag: null,
        availablePraguri: [],
        numarHotspoturi: 0,
        selectedGeom: null,

        resultTileLayer: null,
        resultTileLayer2: null,
        resultLayer: null,
        selectLayer: null,
        selectDrawInteraction: null,
        selectButtonStateOn: null,
    
        optFormatDataPerioada: "YYYY-MM-DD",
        errorMessage: "",
        //
        autorefreshInterval: 30,
        autorefreshTimeout: 30,
        resIntervalRef: null,
        //
        funcInit: null,
        funcClear: null,
        //
        indiciHotspot: []
    },
    //
    integrateLandsatProcIndConexData : {
        ctrlSearchIsCollapsed: false,
        ctrlUnitatiAdministrativeIsColapsed: false,
        activeTab: 0,
        activeNewTab0: true,
        activeExistsTab1: false,
    
        activeDataTab: 0,
    
        loadingActive: false,
    
        selectedProcesare: null,
        availableProcesari: [],
    
        currentProcesare: null,
    
        selectedUnitateAdministrativa: null,
        availableUnitatiAdministrative: [],

        availableMomenteDSBaza: [],
        selectedMomentDSBaza: null,
        indiceBaza: null,
        indiceRezultat: null,
    
        availableMomenteDSReferinta: [],
        selectedMomentDSReferinta: null,
        indiceReferinta: null,
        indiceConex: null,

        availableIndiciConex: [],
        //
        selectedAlgoritmIC: null,
        availableAlgoritmiIC: [],
        //
        productsLayer:null,
        selectLayer: null,
        selectDrawInteraction: null,
        selectButtonStateOn: null,
        loadingSearchProductsActive: false,
        errorMessage: "",
        //
        funcInit: null,
        funcClear: null,
    },

    integrateLandsatProcDateSezoniereData : {
        ctrlSearchIsCollapsed: false,
        ctrlUnitatiAdministrativeIsColapsed: false,
        activeTab: 0,
        activeNewTab0: true,
        activeExistsTab1: false,
    
        activeDataTab: 0,
    
        loadingActive: false,
    
        selectedProcesare: null,
        availableProcesari: [],
    
        currentProcesare: null,
        sezonierAnual: true,
        selectedUnitateAdministrativa: null,
        availableUnitatiAdministrative: [],

        selectedSezonDate: null,
        availableSezonDate: [],

        productsLayer:null,
        selectLayer: null,
        selectDrawInteraction: null,
        selectButtonStateOn: null,
        //loadingSearchProductsActive: false,
        errorMessage: "",
        //
        funcInit: null,
        funcClear: null,
    },
    treeCatMenu: [
        {
          id: 1,
          name: 'root1',
          children: [
            { id: 2, name: 'child1' },
            { id: 3, name: 'child2' }
          ]
        },
        {
          id: 4,
          name: 'root2',
          children: [
            { id: 5, name: 'child2.1' },
            {
              id: 6,
              name: 'child2.2',
              children: [
                { id: 7, name: 'subsub' }
              ]
            }
          ]
        }
      ]
    //
   
  
}