
import { Inject, Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root',
})
    export class PopoverService {
        public data: string[]
        constructor() {
            this.data = [];
        }
    }
