 
 
//
import { NGXLogger } from "ngx-logger";
import { AppSettings } from "../app-settings";
import { ILayer, featureType, isFeatureTypeForCluster, styleType } from "../definitions";
import { MapController } from "./map-controller";


import OlStyle from "ol/style/Style";
import StrokeStyle from "ol/style/Stroke";
import IconStyle from "ol/style/Icon";
import CircleStyle from "ol/style/Circle";
import FillStyle from "ol/style/Fill";
import TextStyle from "ol/style/Text";
import * as olColor from 'ol/color';

    export class MapOlLayerStyle {

        public constructor(public mapCtrl: MapController, private $log : NGXLogger) {

        };

        //
        public buildDefaultStyleForVectorLayer(layer: ILayer): OlStyle[] {
            let layerStyle: Array<OlStyle> = [];
            let layerSettings: any;
            let iconStyleDefSettings = {
                image: {
                    crossOrigin: 'anonymous',
                    src: AppSettings.serverPath + "/data/layer-img/" + layer.id,
                    anchor: [0.5, 0.0],
                    anchorOrigin: "bottom-left",
                    scale: 1
                }
            }
            //
            let tmpColor = '#3399CC';
            if (layer.color && layer.color !== '') {
                tmpColor = layer.color;
            }
            let pointStyleDefSettings = {
                image: {
                    fill: {
                        color: 'rgba(255,255,255,0.4)'
                    },
                    stroke: {
                        color: tmpColor,
                        width: 1.25
                    },
                    radius: 3
                },
                fill: {
                    color: 'rgba(255, 255, 255, 0.4)'
                },
                stroke: {
                    color: tmpColor,
                    width: 1.25
                }
            }
            //
            let lineStyleDefSettings = {
                stroke: {
                    color: tmpColor,
                    width: 2
                }
            }
            //
            let polygonStyleDefSettings = {
                fill: {
                    color: tmpColor
                },
                stroke: {
                    color: tmpColor,
                    width: 1.25
                }
            }
            let polygonReportStyleDefSettings = {
                fill: {
                    color: tmpColor
                },
                stroke: {
                    color: tmpColor,
                    width: 1.25
                },
                text: {
                    text: '',
                    scale: 1,
                    offsetX: 0,
                    offsetY: 0,
                    fill: {
                        color: 'rgba(0, 0, 0, 1)'
                    },
                    zIndex: 2
                }
            }

            let pointTextDefSetting = {
                image: {
                    fill: {
                        color: 'rgba(255,255,255,0.4)'
                    },
                    stroke: {
                        color: tmpColor,
                        width: 1.25
                    },
                    radius: 15,
                    zIndex: 1
                },
                text: {
                    text: '',
                    scale: 1,
                    offsetX: 0,
                    offsetY: 0,
                    fill: {
                        color: 'rgba(0, 0, 0, 1)'
                    },
                    backgroundFill: new FillStyle({
                        color: 'rgba(255,255,255,0.1)'
                    }),
                    padding: [2,2,2,2],
                    zIndex: 2
                }
            }

            let clusterDefSettings = {
                //todo
            }

            //
            switch (layer.featureType) {
                case featureType.icon:
                case featureType.clusterIcon:
                    layerSettings = iconStyleDefSettings;
                    break;
                case featureType.point:
                case featureType.cluster:
                    layerSettings = pointStyleDefSettings;
                    break;
                case featureType.line:
                    layerSettings = lineStyleDefSettings;
                    break;
                case featureType.poly:
                    layerSettings = polygonStyleDefSettings;
                    break;
                case featureType.polyText:
                case featureType.polyReport:
                    layerSettings = polygonReportStyleDefSettings;
                    break;
                case featureType.pointText:
                case featureType.clusterText:
                    layerSettings = pointTextDefSetting;
                    break;
            }
            //for cluster we have the point style and the clustered style
            let selectFeatureType = layer.featureType;
            switch (layer.featureType) {
                case featureType.clusterText:
                    selectFeatureType = featureType.pointText;
                    break;
                case featureType.clusterIcon:
                    selectFeatureType = featureType.icon;
                    break;
                case featureType.cluster:
                    selectFeatureType = featureType.point;
                    break;
            }
            layerStyle = this.buildStyleForLayerType(selectFeatureType, layerSettings);
            if (!layer.style) { layer.style = {} as any }
            layer.style.default = layerStyle;
            //build legend default
            layer.style.defaultStyleLegenda = this.mapCtrl.mapCtrlLegend.buildDefaultLegendStyleForLayerType(layer.featureType, layer.color, layerSettings);
            //build cluster style
            if (isFeatureTypeForCluster(layer.featureType)) {
                layer.style.defaultStyleCluster = this.buildStyleForLayerType(featureType.cluster, layerSettings)
            }

            //
            return layer.style.default;
        }

        public buildMultiStyleForVectorLayer(layer: ILayer) {
            try {
                if (layer.styleType && (layer.styleType === styleType.multiStyle || layer.styleType === styleType.singleStyle)) {
                    let userStyles = this.mapCtrl.userSettingsSrvs.getCurrentUser().styles;
                    if (userStyles && userStyles.length > 0) {
                        let layerStylesSettings = userStyles.filter((st) => st.idResursa === layer.id);
                        if (layerStylesSettings && layerStylesSettings.length > 0) {
                            layer.style = {
                                settings: layerStylesSettings,
                                default: null,
                                defaultStyleCluster: null,
                                defaultStyleLegenda: {},
                                list: new Array<{ key: string, style: OlStyle[], styleOnSelect: OlStyle[], legenda: string, styleLegenda: {}}>(),
                                listCluster:[]
                            }
                            //
                            let forfeatureType = layer.featureType;
                            if (layer.featureType === featureType.clusterText) {
                                forfeatureType = featureType.pointText;
                            } else if (layer.featureType === featureType.clusterIcon) {
                                forfeatureType = featureType.icon;
                            } else if (layer.featureType === featureType.cluster) {
                                forfeatureType = featureType.point;
                            }
                            //
                            layerStylesSettings.forEach(styleSettItem => {
                                try {
                                    if (styleSettItem.style) {
                                        //for icon set the style image path if not set
                                        if (styleSettItem.layerType === featureType.icon
                                            && styleSettItem.style["image"]
                                            && (!styleSettItem.style["image"]["src"] || styleSettItem.style["image"]["src"] === "")) {
                                            styleSettItem.style["image"]["src"] = AppSettings.serverPath + "/data/style-img/" + styleSettItem.id;
                                            
                                        }
                                        //
                                        let tmpStyle = this.buildStyleForLayerType(forfeatureType, styleSettItem.style);
                                        let tmpLegedaStyle = this.mapCtrl.mapCtrlLegend.buildLegendStyleForLayerType(forfeatureType, styleSettItem.style);
                                        if (tmpStyle) {
                                            let tmpStyleOnSelect = null;
                                            if (styleSettItem.styleOnSelect) {
                                                //for icon set the style image path if not set
                                                if (styleSettItem.layerType === featureType.icon
                                                    && styleSettItem.styleOnSelect["image"]
                                                    && (!styleSettItem.styleOnSelect["image"]["src"] || styleSettItem.styleOnSelect["image"]["src"] === "")) {
                                                    styleSettItem.styleOnSelect["image"]["src"] = AppSettings.serverPath + "/data/style-img/" + styleSettItem.id;
                                                }
                                                tmpStyleOnSelect = this.buildStyleForLayerType(forfeatureType, styleSettItem.styleOnSelect);
                                            }
                                            layer.style.list.push({ key: styleSettItem.styleKey, style: tmpStyle, styleOnSelect: tmpStyleOnSelect, legenda: styleSettItem.legenda, styleLegenda: tmpLegedaStyle });
                                        }
                                    }

                                } catch (e) {
                                    this.$log.error("eroare la generare stil pentru multistil strat");
                                }
                            })
                        } else {
                            this.$log.log("nu sunt stiluri asignate utilizatorului", layer.id);
                        }
                    } else {
                        this.$log.log("nu sunt stiluri asignate utilizatorului", layer.id);
                    }
                }
            } catch (e) {
                this.$log.log("eroare multistyle: " + e.message);
            }
        }

        public buildStyleForLayerType(layerType: string, settings: any): Array<OlStyle> {
            let layerStyle: OlStyle[] = null;
            switch (layerType) {
                case featureType.icon:
                    layerStyle = this.buildStyleForIcon(settings);
                    break;
                case featureType.point:
                    layerStyle = this.buildStyleForPoint(settings);
                    break;
                case featureType.line:
                    layerStyle = this.buildStyleForLine(settings);
                    break;
                case featureType.poly:
                    layerStyle = this.buildStyleForPolygon(settings);
                    break;
                case featureType.polyText:
                case featureType.polyReport:
                    layerStyle = this.buildStyleForPolygonText(settings);
                    break;
                case featureType.pointText:
                    layerStyle = this.buildStyleForPointText(settings);
                    break;
                case featureType.cluster:
                    //todo
                    layerStyle = this.buildStyleForCluster(settings);
                    break;
            }
            return layerStyle;
        }
        //
        public buildStyleForCluster(setting: any): Array<OlStyle> {
            //todo, stilul este implemetat la nivel de strat/selectie, doar culoare
            //in caz ca se doreste sa se extinda in configurari se va implemeta aici
            return [];
        }
        //
        public buildStyleForIcon(settings: any): Array<OlStyle> {
            let tmpStyle: OlStyle[] = [];
            try {
                tmpStyle.push(new OlStyle({
                    image: new IconStyle({
                        crossOrigin: 'anonymous',
                        src: settings.image.src,
                        anchor: settings.image.anchor,
                        anchorOrigin: settings.image.anchorOrigin,
                        scale: settings.image.scale || 1
                    })
                }));
                //cache style image
                let img = new Image();
                img.src = settings.image.src;
                this.mapCtrl.multiLayerStyleImageCache.push(img);
                this.$log.log("image cache: ", settings.image.src || "");
            } catch (e) {
                this.$log.error("eroare creare stil icon " + e.message);
            }
            return tmpStyle;
        }
        //
        public buildStyleForPoint(settings: any): Array<OlStyle> {
            let tmpStyle: OlStyle[] = [];
            try {
                tmpStyle.push(new OlStyle({
                    image: new CircleStyle({
                        fill: new FillStyle({
                            color: settings.image.fill.color
                        }),
                        stroke: new StrokeStyle({
                            color: settings.image.stroke.color,
                            width: settings.image.stroke.width
                        }),
                        radius: settings.image.radius
                    })
                }));
            } catch (e) {
                this.$log.error("eroare creare stil punct " + e.message);
            }
            return tmpStyle;
        }
        //
        public buildStyleForLine(settings: any): Array<OlStyle> {
            let tmpStyle: OlStyle[] = [];
            try {
                tmpStyle.push(new OlStyle({
                    stroke: new StrokeStyle({
                        color: olColor.asArray(settings.stroke.color),
                        width: settings.stroke.width
                    })
                }));
            } catch (e) {
                this.$log.error("eroare creare stil linie " + e.message);
            }
            return tmpStyle;
        }
        //
        public buildStyleForPolygon(setting: any): Array<OlStyle> {
            let tmpStyle: OlStyle[] = [];
            try {
                tmpStyle.push(new OlStyle({
                    fill: new FillStyle({
                        color: olColor.asArray(setting.fill.color)
                    }),
                    stroke: new StrokeStyle({
                        color: setting.stroke.color,
                        width: setting.stroke.width
                    })
                }));
            } catch (e) {
                this.$log.error("eroare creare stil poligon " + e.message);
            }
            return tmpStyle;
        }
        //
        public buildStyleForPolygonText(settings: any): Array<OlStyle> {
            let tmpStyle: OlStyle[] = [];
            try {
                tmpStyle.push(new OlStyle({
                    fill: new FillStyle({
                        color: olColor.asArray(settings.fill.color)
                    }),
                    stroke: new StrokeStyle({
                        color: settings.stroke.color,
                        width: settings.stroke.width
                    })
                }));

                tmpStyle.push(new OlStyle({
                    text: new TextStyle({
                        text: settings.text.text,
                        scale: settings.text.scale,
                        offsetX: settings.text.offsetX,
                        offsetY: settings.text.offsetY,
                        fill: new FillStyle({
                            color: settings.text.fill.color
                        })
                    })
                }))
            } catch (e) {
                this.$log.error("eroare creare stil poligon " + e.message);
            }
            return tmpStyle;
        }
        //
        public buildStyleForPointText(settings: any): Array<OlStyle> {
            let tmpStyle: Array<OlStyle> = [];
            try {
                tmpStyle.push(new OlStyle({
                    image: new CircleStyle({
                        fill: new FillStyle({
                            color: settings.image.fill.color
                        }),
                        stroke: new StrokeStyle({
                            color: settings.image.stroke.color,
                            width: settings.image.stroke.width
                        }),
                        radius: settings.image.radius
                    })
                }));
                let defbackcolor = [255,255,255,0.1];
                if (settings.text.backgroundFill && settings.text.backgroundFill.color){
                    defbackcolor = settings.text.backgroundFill.color;
                }
                tmpStyle.push(new OlStyle({
                    text: new TextStyle({
                        text: settings.text.text,
                        scale: settings.text.scale,
                        offsetX: settings.text.offsetX,
                        offsetY: settings.text.offsetY,
                        fill: new FillStyle({
                            color: settings.text.fill.color
                        }),
                        backgroundFill: new FillStyle({
                            color: defbackcolor as any
                        }),
                        padding: [2,2,2,2]
                    } as any)
                }))
            } catch (e) {
                this.$log.error("eroare creare stil punct " + e.message);
            }
            return tmpStyle;
        }
    }
