import { Inject, Injectable, forwardRef } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class InternalMessageService {
// Observable string streams
public sfBroadcast$:Observable<{name: string; content: any}>;
// Observable string sources
private sfBroadcastSource = new Subject<{name: string; content: any}>();

    constructor() { 
        this.sfBroadcast$ = this.sfBroadcastSource.asObservable();
    }
    public broadcast(name: string, content: any = null) {
        this.sfBroadcastSource.next({name, content});
    }
}