//NG
import { Component, Inject, Input, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { NGXLogger } from "ngx-logger";

@Component({
    selector: 'info-feature-images',
    providers: [],
    templateUrl: './app/features/features-components/info-feature-images.component.html',
    styleUrls: ['./app/features/features-components/info-feature-images.component.css']
})
export class InfoFeatureImagesComponent implements OnInit {

    private imageData: { imagename: string, imageurl: string, id: number }[] = [];

    @Input() data: any;
    public constructor(
        @Inject(NgbActiveModal) private activeModal: NgbActiveModal,
        @Inject(NGXLogger) private $log: NGXLogger,
        @Inject(DomSanitizer) private sanitizer: DomSanitizer,
    ) {

    }
    //
    public ngOnInit(): void {
        if ("imageData" in this.data) {
            let idata = this.data["imageData"] as Array<{ imagename: string, imageurl: string }>;
            for (let index = 0; index < idata.length; index++) {
                const element = idata[index];
                this.imageData.push({ imagename: element.imagename, imageurl: element.imageurl, id: index })
            }

        } else {
            this.$log.warn("nu exita imagine in date");
        }
    }
    //
    public sanitize(url: string) {
        return this.sanitizer.bypassSecurityTrustUrl(url);
    }
    //
    public cancel(): void {
        this.activeModal.close(false);
    }
}
