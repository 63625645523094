 
 
//
import { NGXLogger } from "ngx-logger";
import { MapClickMessageMode, ILayer, featureTypeForVector, authOpt, authType, ISelectedFeatures, isFeatureTypeForCluster, featureType } from "../definitions";
import { MapController } from "./map-controller";
import { getExMessage, isArray, isNumber } from "./map-utils";


import BaseLayer from 'ol/layer/Base';
import VectorLayer from 'ol/layer/Vector';
import Feature from 'ol/Feature';
import Collection from "ol/Collection";
import Polygon from 'ol/geom/Polygon';
import  MultiPolygon  from "ol/geom/MultiPolygon";
import MultiLineString from "ol/geom/MultiLineString";
import Layer from 'ol/layer/Layer';
import HeatmapLayer from "ol/layer/Heatmap";
import VectorSource from "ol/source/Vector";
import ClusterSource from 'ol/source/Cluster';
import GeoJSONFormater from "ol/format/GeoJSON";
import * as olProj from 'ol/proj';

    export class MapOlFeatures {
        public constructor(public mapCtrl: MapController, private $log : NGXLogger) {
           
        };

        //
        public doClickMapOnMessage(mode: string, layer_name: string, coordinates: any, crsCoordinates: any, parameters: Array<{ key: string, value: any }>) {
            try {
                if (this.mapCtrl.hasActiveDialogs()) {
                    throw new Error("sunt dialoguri active");
                }
                if (this.mapCtrl.hasActiveDrawInteractions()) {
                    throw new Error("sunt active interactiuni harta");
                }
                if (this.mapCtrl.hasActiveLowerBandMenu()){
                    throw new Error("sunt active meniuri banda ");
                }
                if (parameters) {
                    this.convertParametersToLocal(layer_name, parameters);
                }
                //convert coordinates to map projection
                if (mode === MapClickMessageMode.coordinates || mode === MapClickMessageMode.coordinates_propperties) {
                    if (Array.isArray(coordinates) === false || coordinates.length !== 2) {
                        throw new Error("coordonate nu sunt corecte" + JSON.stringify(coordinates));
                    }
                    if (!isNumber(coordinates[0]) || !isNumber(coordinates[1])) {
                        throw new Error("coordonatele nu sunt numerice");
                    }
                    //epsg 
                    let coordCrs = this.mapCtrl.mapConfig.projection;
                    if (crsCoordinates != null && crsCoordinates.length > 0) {
                        coordCrs = crsCoordinates;
                    }
                    coordinates = olProj.transform([coordinates[0], coordinates[1]], coordCrs, this.mapCtrl.mapConfig.projection);
                }

                switch (mode) {
                    case MapClickMessageMode.coordinates: { this.doClickMapOnCoordinates(coordinates); }
                        break;
                    case MapClickMessageMode.coordinates_propperties: { this.doClickMapOnCoordinatesFilter(layer_name, coordinates, parameters); }
                        break;
                    case MapClickMessageMode.properties: { this.doClickMapOnFilter(layer_name, parameters); }
                        break;
                    default:
                }
            } catch (error) {
                this.$log.error(' eroare la executie mesaj map click: ' + error.message || '');
            }
        }
        //
        public doClickMapOnCoordinates(coordinates: any) {
            let first = false;
            let data = (this.mapCtrl.map as any).forEachFeatureAtPixel(this.mapCtrl.map.getPixelFromCoordinate(coordinates), (feature, layer) => {
                if (first === false) {
                    this.mapCtrl.mapCtrlSelectFeature.buildFeatureInfoOverlay(coordinates, this.mapCtrl.infoOverlay.getElement(), layer, feature);
                    first = true;
                    this.mapCtrl.mapCtrlSelectFeature.setSelectInteractionToFeature(feature, layer);
                }
            }, { hitTolerance: 4 });
        }
        //
        public doClickMapOnCoordinatesFilter( layer_name: string, coordinates: any, parameters: Array<{ key: string, value: any }>) {
            let found = false;
            let data = (this.mapCtrl.map as any).forEachFeatureAtPixel(
                this.mapCtrl.map.getPixelFromCoordinate(coordinates),
                (feature: Feature, layer) => {
                    let match: boolean;
                    match = true;
                    for (var i = 0; i < parameters.length; i++) {
                        try {
                            if (feature.get(parameters[i].key) !== parameters[i].value) {
                                match = false;
                            }
                        } catch (reason) {
                            match = false;
                        }
                    }

                    if (match === true && found === false){
                        found = true;
                        this.mapCtrl.mapCtrlSelectFeature.buildFeatureInfoOverlay(coordinates, this.mapCtrl.infoOverlay.getElement(), layer, feature);
                        this.mapCtrl.mapCtrlSelectFeature.setSelectInteractionToFeature(feature, layer);
                    }
                    
                },
                {
                    layerFilter: (layer: Layer) => {
                        try {
                            if (layer["appLayer"]) {
                                if (layer["appLayer"]["name"] === layer_name) {
                                    return true;
                                }
                            }
                            return false;
                        } catch (reason) {
                            return false;
                        }
                    },
                    hitTolerance: 4
                }
            );
        }

        public doClickMapOnFilter(layerName: string, parameters: Array<{ key: string, value: any }>) {
            try {
                //search layer
                let routeLayer: ILayer = this.searchForVectorLayer(layerName);
                if (!routeLayer) {
                    throw new Error("Nu exista strat cu numele" + layerName);
                }
                if (!featureTypeForVector(routeLayer.featureType)) {
                    throw new Error("Stratul nu este de tip geometrie");
                }
                if (parameters.length == 0) {
                    throw new Error("nu sunt specificate proprietati pt filtrare");
                }
                //search feature
                let feature = this.searchForFirstFeatureOnLayer(routeLayer, parameters);
                if (feature == null) {
                    throw new Error("nu exista feature")
                }
                //
                let coords = (feature.getGeometry() as any).getCoordinates();
                if (isArray(coords[0])) {
                    coords = coords[0].map((item) => item);
                    if (isArray(coords[0])) {
                        coords = coords[0].map((item) => item);
                    }
                }
                this.mapCtrl.mapCtrlSelectFeature.buildFeatureInfoOverlay(coords, this.mapCtrl.infoOverlay.getElement(), routeLayer.internalLayer, feature);
                this.mapCtrl.mapCtrlSelectFeature.setSelectInteractionToFeature(feature, routeLayer.internalLayer as VectorLayer<any>);

            } catch (reason) {
                this.$log.error("Eroare la mesaj pentru click on map" , getExMessage(reason));
            }
        }

        private convertParametersToLocal(layer: string, parameters: Array<{ key: string, value: any }>): void {
            let localDateParams: Array<{ param: { key: string, value: any }, type: string }> = [];
            //
            parameters.forEach((infoItem) => {
                //
                let str = this.mapCtrl.userSettingsSrvs.isAuthForItemOption(authOpt.in_utc_to_local_convert, layer, infoItem.key, authType.layer);
                if (str && str.length > 0) {
                    localDateParams.push({ param: infoItem, type: str });
                }
            });
            //
            localDateParams.forEach((localPItem) => {
                try {
                    localPItem.param.value = this.mapCtrl.mapOlInit.convertValueToLocalTime(localPItem.param.value, localPItem.type);
                } catch (reason) {
                    this.$log.error('Eroare conversie parametrii date time' , getExMessage(reason))
                }
            })
        }

        //
        //set map center and/or zoom
        //
        public setMapViewByMessage(center: Array<number>, crsCenter: string, zoom: any, centerByFeature: { layerName: string, properties: Array<{ key: string, value: any }> }) {
            try {
                if (center == null && centerByFeature == null && zoom == null) {
                    throw new Error("nu exista centru sau zoom")
                }
                //center by coordinates
                if (center && centerByFeature == null) {
                    if (!isArray(center) || center.length !== 2) {
                        throw new Error("coordonate centru nu sunt corecte" + JSON.stringify(center));
                    }
                    if (!isNumber(center[0]) || !isNumber(center[1])) {
                        throw new Error("coordonatele centru nu sunt numerice");
                    }
                    //epsg trebuie sa fie 4326
                    let coordCrs = 'EPSG:4326';
                    if (crsCenter != null && crsCenter.length > 0) {
                        coordCrs = crsCenter;
                    }
                    let newCenter = olProj.transform([center[0], center[1]], coordCrs, this.mapCtrl.mapConfig.projection);
                    this.mapCtrl.map.getView().setCenter(newCenter);
                    //
                }
                //center by layer feature
                if (centerByFeature && center == null) {
                    if (!centerByFeature.layerName || centerByFeature.properties.length === 0) {
                        throw new Error("nu exista proprietati pentru cautarea dupa feature");
                    }
                    let layer = this.searchForVectorLayer(centerByFeature.layerName);
                    if (!layer) {
                        throw new Error("layerul nu a fost gasit");
                    }
                    let feature = this.searchForFirstFeatureOnLayer(layer, centerByFeature.properties)
                    if (feature == null) {
                        throw new Error("nu exista locatie incarcata in harta")
                    }
                    //
                    let coords = (feature.getGeometry() as any).getFirstCoordinate();
                    //if (isArray(coords[0])) { coords = coords[0]; }
                    this.mapCtrl.map.getView().setCenter(coords);
                }
                //set zoom
                if (zoom) {
                    if (!isNumber(zoom)) {
                        throw new Error("zoom nu este numeric");
                    }
                    if (zoom < this.mapCtrl.mapConfig.minZoom || zoom > this.mapCtrl.mapConfig.maxZoom) {
                        throw new Error("zoom nu se incadreaza in min max");
                    }
                    this.mapCtrl.map.getView().setZoom(zoom);
                }
            } catch (reason) {
                this.$log.error("Eroare la mesaj pentru setare view harta" , getExMessage(reason));
            }
        }

        //
        //
        //
        public searchForVectorLayer(layerName: string): ILayer {
            try {
                let tmpLayer: ILayer = null;
                for (var i = 0; i < this.mapCtrl.categories.length; i++) {
                    let catItem = this.mapCtrl.categories[i];
                    for (var j = 0; j < catItem.layers.length; j++) {
                        if (catItem.layers[j].name === layerName) {
                            tmpLayer = catItem.layers[j];
                            break;
                        }
                    }
                    if (tmpLayer) { break; }
                }
                if (!tmpLayer) {
                    throw new Error("Nu exista strat cu numele" + layerName);
                }
                if (!featureTypeForVector(tmpLayer.featureType)) {
                    throw new Error("Stratul nu este de tip geometrie");
                }
                return tmpLayer;
            } catch (reason) {
                this.$log.error("Eroare cautare strat" , getExMessage(reason));
                return null;
            }
        }
        //
        public searchForFirstFeatureOnLayer(layer: ILayer, properties: Array<{ key: string, value: any }>): Feature {
            let routeFeature: Feature = null;
            try {
                //search layer
               
                if (properties.length == 0) {
                    throw new Error("nu sunt specificate proprietati pt filtrare");
                }

                //search feature
                let layerFeatures = (layer.internalLayer as VectorLayer<any>).getSource().getFeatures();
               
                for (var i = 0; i < layerFeatures.length; i++) {
                    let featItem = layerFeatures[i];
                    let match: boolean;
                    match = true;
                    for (var j = 0; j < properties.length; j++) {
                        try {
                            if (featItem.get(properties[j].key) !== properties[j].value) {
                                match = false;
                            }
                        } catch (reason) {
                            match = false;
                        }
                    }
                    //
                    if (match === true) {
                        routeFeature = featItem;
                        break;
                    }
                }
                
                return routeFeature;
            } catch (reason) {
                this.$log.error("Eroare cautare feature " , getExMessage(reason));
                return null;
            }
        }

        //
        public getLayerFeaturesForPolygon(geometry: Polygon, layer: BaseLayer) {
            var geomWGS = geometry.clone().transform(this.mapCtrl.mapConfig.projection, 'EPSG:4326')
            var geojsonFormat = new GeoJSONFormater();
            var polySelectionGeoJson = geojsonFormat.writeGeometryObject(geomWGS);
            return this.getLayerFeaturesForPolygonGeoJson(polySelectionGeoJson, layer);
        }
        //
        public getLayerFeaturesForPolygonGeoJson(polySelectionGeoJson: any, layer: BaseLayer): ISelectedFeatures {
            if ((layer instanceof VectorLayer || layer instanceof HeatmapLayer) && layer.getVisible()) {
                let tmpSelectFeatureOnLayer: ISelectedFeatures =
                    { layer: layer as any, features: new Collection<Feature>() };
                let vectorSource = layer.getSource() as VectorSource;
                let appLayer = layer[MapController.appLayer] as ILayer;
                if (appLayer == null){
                    return null;
                }
                //for cluster
                if (isFeatureTypeForCluster(appLayer.featureType)) {
                    vectorSource = (vectorSource as ClusterSource<any>).getSource();
                }
                vectorSource.forEachFeature((feature) => {
                    if (!(MapController.searchFilterOut in feature)
                        || ((MapController.searchFilterOut in feature) && feature[MapController.searchFilterOut] === "false")) {

                        if (this.mapCtrl.mapOlFeatures.isFeatureInPolygon(appLayer.featureType, feature, polySelectionGeoJson)) {
                            tmpSelectFeatureOnLayer.features.push(feature);
                        }
                    }
                })
                return tmpSelectFeatureOnLayer;
            }
        }
        //
        public isFeatureInPolygon(featureTypeIn: string, feature: Feature, polySelectionGeoJson: JSON): boolean {
            var geojsonFormat = new GeoJSONFormater();
            let bResult: boolean = false;
            let projGeom = feature.clone().getGeometry().transform(this.mapCtrl.mapConfig.projection, 'EPSG:4326');
            switch (featureTypeIn) {
                case featureType.point:
                case featureType.pointText:
                case featureType.cluster:
                case featureType.clusterText:
                case featureType.clusterIcon:
                case featureType.heatmap:
                    var pointGeoJson = geojsonFormat.writeGeometryObject(projGeom);
                    bResult = turf.booleanContains(polySelectionGeoJson as any, pointGeoJson as any);
                    break;
                case featureType.line:
                    // containing
                    var lineGeoJson = geojsonFormat.writeGeometryObject(projGeom);
                    if (projGeom.getType() === "LineString") {
                        bResult = turf.booleanContains(polySelectionGeoJson, lineGeoJson)
                    }
                    else if (projGeom.getType() === "MultiLineString") {
                        let geom = (projGeom as MultiLineString);
                        let lines = geom.getLineStrings();
                        for (var i = 0; i < lines.length; i++) {
                            lineGeoJson = geojsonFormat.writeGeometryObject(lines[i]);
                            if (turf.booleanContains(polySelectionGeoJson, lineGeoJson)) {
                                bResult = true;
                                break;
                            }
                        }
                    }
                    // intersect
                    if (!bResult) {
                        var intersect = turf.intersect(lineGeoJson as any, polySelectionGeoJson as any)
                        bResult = intersect && (intersect['geometry'] || (intersect['features']['length']) > 0) ? true : false;
                    }
                    break;
                case featureType.poly:
                case featureType.polyText:
                case featureType.polyReport:
                    //containing
                    if (projGeom.getType() === "Polygon") {
                        let polyGeoJson = geojsonFormat.writeGeometryObject(projGeom);
                        bResult = turf.booleanContains(polySelectionGeoJson as any, polyGeoJson as any)

                    }
                    else if (projGeom.getType() === "MultiPolygon") {
                        let geom = (projGeom as MultiPolygon);
                        let polygons = geom.getPolygons();
                        for (var i = 0; i < polygons.length; i++) {
                            let polyGeoJson = geojsonFormat.writeGeometryObject(polygons[i]);
                            bResult = turf.booleanContains(polySelectionGeoJson as any, polyGeoJson as any);
                            if (bResult) { break; }
                        }
                    }
                //todo intersect
                //var intersect = turf.intersect(polyGeoJson as any, polySelectionGeoJson as any)
                //bResult = intersect && (intersect['features']['length']) > 0 ? true : false;
            }
            //
            return bResult;
        }

    }
