import { ComponentFactory, ComponentFactoryResolver, NgModule, Inject } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FormsModule } from "@angular/forms";
//import { NgSelectModule } from "@ng-select/ng-select";
import { PrintSectionDirective, PrintHideDirective, PrintRemoveDirective, PrintOnlyDirective, PrintAvoidBreakDirective, PrintBtnDirective, PrintIfDirective, PrintLandscapeDirective, PrintTableDirective } from "./map-print-directives";
import { ColorPickerRGBAComponent } from "./color-picker-rgba.component";
import { ColorPickerModule } from "@iplab/ngx-color-picker";


@NgModule({
    declarations: [
        PrintSectionDirective,
        PrintHideDirective,
        PrintRemoveDirective,
        PrintOnlyDirective,
        PrintAvoidBreakDirective,
        PrintBtnDirective,
        PrintIfDirective,
        PrintLandscapeDirective,
        PrintTableDirective,
        ColorPickerRGBAComponent
    ],
    imports: [
        FormsModule,
        CommonModule,
        NgbModule,
        ColorPickerModule,
        //NgSelectModule,
        ],
    exports: [
        PrintSectionDirective,
        PrintHideDirective,
        PrintRemoveDirective,
        PrintOnlyDirective,
        PrintAvoidBreakDirective,
        PrintBtnDirective,
        PrintIfDirective,
        PrintLandscapeDirective,
        PrintTableDirective,
        ColorPickerRGBAComponent
    ],
    entryComponents:[
        ColorPickerRGBAComponent
    ]

})
export class SharedModule {
    constructor(@Inject(ComponentFactoryResolver) private componentFactoryResolver: ComponentFactoryResolver) {
        //
     }

    //  public resolveComponent(): ComponentFactory<FisaSpatiuluiVerdeComponent> {
    //      return this.componentFactoryResolver.resolveComponentFactory(FisaSpatiuluiVerdeComponent);
    //  }
}