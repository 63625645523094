 
 
//
import { NGXLogger } from "ngx-logger";
import { wayRestrictType, RoutingType, LocationType, ILayer, authOpt, authType, searchType, searchConditions, IMenuLayerItem, wayRestrictTypeList, RouteType } from "../definitions";
import { MapController } from "./map-controller";
import { isNumber } from "./map-utils";


import VectorLayer from 'ol/layer/Vector';
import Draw from 'ol/interaction/Draw';
import Modify from 'ol/interaction/Modify';
import Snap from "ol/interaction/Snap";
import Feature from 'ol/Feature';
import LineString from 'ol/geom/LineString';
import MultiPoint from 'ol/geom/MultiPoint';
import VectorSource from "ol/source/Vector";
import WKTFormater from "ol/format/WKT";
import OlStyle from "ol/style/Style";
import StrokeStyle from "ol/style/Stroke";
import CircleStyle from "ol/style/Circle";
import FillStyle from "ol/style/Fill";
import TextStyle from "ol/style/Text";
import * as olColor from 'ol/color';
import * as olCoordinate from 'ol/coordinate';
import { MapBrowserEvent } from "ol";


    export class MapCtrlTransportRoute {
        public constructor(public mapCtrl: MapController, private $log : NGXLogger) {

        };
        public infoEdit = "Ordonarea, adăugarea și ștergerea stațiilor aferente unei rute sunt acțiuni care pot fi efectuate în secțiunea “Modificare traseu” accesată cu ajutorul butonului “Editare stații”. Traseul propus spre editare este marcat cu linie verde punctată. Punctele intermediare pot să fie mutate prin tragere, șterse cu ALT+click și adăugate prin click. Propunerea poate să fie previzualizată cu ajutorul “Previzualizează ruta”..";
        public startGenerateRoute(optimizeOrderTSP: boolean = false) {
            let features = this.mapCtrl.routeLayerFeature.getSource().getFeatures();
            //
            if (features.length) {
                //
                this.mapCtrl.map.removeInteraction(this.mapCtrl.routeModifyInteraction);
                this.mapCtrl.map.removeInteraction(this.mapCtrl.routeDrawInteraction);
                this.mapCtrl.routeShowEdit = this.mapCtrl.routeShowType.disable;
                //
                let coordinates = (features[0].getGeometry() as any).getCoordinates();
                let multip = new MultiPoint(coordinates);
                let multipTr = multip.transform(this.mapCtrl.mapConfig.projection, 'EPSG:4326')
                let pointarr = multip.getCoordinates();
                let restrictType = null;
                if (this.mapCtrl.restrictTypeSelected && this.mapCtrl.restrictTypeSelected.name != wayRestrictType.none) {
                    restrictType = this.mapCtrl.restrictTypeSelected.name;
                }
                let routingType = RoutingType.foot;
                if (this.mapCtrl.routingTypeSelected) {
                    routingType = this.mapCtrl.routingTypeSelected.name;
                }
                let idRoute = -1;
                //todo 
                this.mapCtrl.transportDataService.setAddAdhocRoute(
                    pointarr, optimizeOrderTSP, this.mapCtrl.restrictByClientArea,
                    restrictType, routingType, LocationType.point)
                    .then((infoRoute) => {
                        if (infoRoute && infoRoute.id) {
                            idRoute = infoRoute.id;
                        } else {
                            throw new Error("id ruta e null");
                        }
                    }).catch((reason: Error) => {
                        this.$log.error("eroare la generare ruta adhoc: " + reason.message);
                        alert("eroare la generare ruta");
                        this.mapCtrl.routeShowEdit = this.mapCtrl.routeShowType.show;
                    }).finally(() => {
                        if (idRoute > 0) {
                            this.mapCtrl.routeShowEdit = this.mapCtrl.routeShowType.previewResult;
                            this.searchRouteResultOnLayers(idRoute, this.mapCtrl.routeFeatureReferenceLayer);
                            //
                            this.$log.log("ruta adhoc a fost generata cu id: ", idRoute);
                            //alert("ruta adhoc a fost generata cu id: " + idRoute);
                        }
                        //this.mapCtrl.map.removeLayer(this.mapCtrl.routeLayerFeature);
                        //this.mapCtrl.showMainMenu = true;
                    })
            } else {
                this.cancelGenerateRoute();
            }
        }

        public searchRouteResultOnLayers(idRoute: number, routeLayer: ILayer) {
            //check if is disabled
            if (this.mapCtrl.userSettingsSrvs.isAuthForOption(authOpt.route_disable_search_after_edit, routeLayer.name, authType.layer)){
                this.$log.log("cautarea ruta si componente in search dezactivata");
                this.mapCtrl.searchActive = false;
                //this.mapCtrl.searchSettings = null;
                return;
            }
            //
            (routeLayer.internalLayer as VectorLayer<any>).getSource().refresh();
            //
            this.mapCtrl.searchActive = true;
            this.mapCtrl.searchSettings.type = searchType.multilayer;
            routeLayer.search = {
                layer: routeLayer,
                conditions: [{
                    propertyName: 'id',// '_id_', to do finde the correct id
                    condition: searchConditions[0],
                    searchText: idRoute.toString()
                },]
            };
            (routeLayer.internalLayer as VectorLayer<any>).getSource().refresh();

            // route points 
            let pointOptRes = this.mapCtrl.userSettingsSrvs.isAuthForOptionFullInfo(authOpt.route_points_layer, routeLayer.name, authType.layer)
            if (pointOptRes && pointOptRes.descriere) {
                let allLayers: Array<ILayer> = [];
                this.mapCtrl.categories.forEach(icat => {
                    icat.layers.forEach(ilay => { allLayers.push(ilay); })
                });
                let pointLayers = allLayers.filter((ifilt) => { return ifilt.name === pointOptRes.descriere; });
                if (pointLayers && pointLayers.length > 0) {
                    pointLayers.forEach((litem) => {
                        litem.search = {
                            layer: litem,
                            conditions: [{
                                propertyName: 'idRoute',
                                condition: searchConditions[0],
                                searchText: idRoute.toString()
                            },]
                        };
                        //
                        (litem.internalLayer as VectorLayer<any>).getSource().refresh();
                    })
                }
            }
            //route segments
            let segmentOptRes = this.mapCtrl.userSettingsSrvs.isAuthForOptionFullInfo(authOpt.route_segments_layer, routeLayer.name, authType.layer)
            if (segmentOptRes && segmentOptRes.descriere) {
                let allLayers: Array<ILayer> = [];
                this.mapCtrl.categories.forEach(icat => {
                    icat.layers.forEach(ilay => { allLayers.push(ilay); })
                });
                let segmentLayers = allLayers.filter((ifilt) => { return ifilt.name === segmentOptRes.descriere; });
                if (segmentLayers && segmentLayers.length > 0) {
                    segmentLayers.forEach((litem) => {
                        litem.search = {
                            layer: litem,
                            conditions: [{
                                propertyName: 'idRoute',
                                condition: searchConditions[0],
                                searchText: idRoute.toString()
                            },]
                        };
                        (litem.internalLayer as VectorLayer<any>).getSource().refresh();
                    })
                }
            }
        }

        public cancelGenerateRoute() {
            this.mapCtrl.map.removeInteraction(this.mapCtrl.routeModifyInteraction);
            this.mapCtrl.map.removeInteraction(this.mapCtrl.routeDrawInteraction);
            this.mapCtrl.map.removeLayer(this.mapCtrl.routeLayerFeature);
            this.mapCtrl.routeShowEdit = this.mapCtrl.routeShowType.hide;
            this.mapCtrl.showMainMenu = true;
        }


        //
        public generateRoute(vm: MapController, layer: ILayer, menuLayerItem: IMenuLayerItem) {
            this.mapCtrl.routeFeatureReferenceLayer = layer;
            this.mapCtrl.showMainMenu = false;
            this.mapCtrl.routeShowEdit = this.mapCtrl.routeShowType.show;
            let source = new VectorSource();
            let styleArr = [
                new OlStyle({
                    stroke: new StrokeStyle({
                        color: '#ff0000',
                        width: 4,
                        lineDash: [4, 6]
                    })
                }),
                new OlStyle({
                    image: new CircleStyle({
                        radius: 6,
                        fill: new FillStyle({
                            color: 'orange'
                        }),

                    }),
                    geometry: function (feature: Feature) {
                        // return the coordinates of the first ring of the polygon
                        let tmpgeom = feature.getGeometry();
                        let coordinates = (tmpgeom as any).getCoordinates();
                        return new MultiPoint(coordinates);
                    }
                })
            ];

            this.mapCtrl.routeLayerFeature = new VectorLayer<any>({
                source: source,
                style: styleArr
            });

            this.mapCtrl.routeModifyInteraction = new Modify({
                source: this.mapCtrl.routeLayerFeature.getSource(),
            } as any);

            this.mapCtrl.map.addInteraction(this.mapCtrl.routeModifyInteraction);

            this.mapCtrl.routeDrawInteraction = new Draw({
                source: source,
                type: 'LineString',
                style: styleArr
            });
            this.mapCtrl.map.addInteraction(this.mapCtrl.routeDrawInteraction);
            //
            let snap = new Snap({ source: source });
            this.mapCtrl.map.addInteraction(snap);
            //
            this.mapCtrl.map.addLayer(this.mapCtrl.routeLayerFeature);
            //
            this.mapCtrl.routeDrawInteraction.on('drawend', evt => {
                this.mapCtrl.routeDrawInteraction.setActive(false);
                this.mapCtrl.routeFeature = evt.feature;
            });
            //get restrict options
            this.mapCtrl.transportDataService.getAvailableRestrictWaysTypes()
                .then((resTypes) => {
                    this.mapCtrl.restrictTypeList = [];
                    this.mapCtrl.restrictTypeList.push(wayRestrictTypeList[0]);
                    if (resTypes && resTypes.length > 0) {
                        resTypes.forEach((resItem) => {
                            this.mapCtrl.restrictTypeList.push(resItem);
                        })
                    }
                    this.mapCtrl.restrictTypeSelected = wayRestrictTypeList[0];
                })
                .catch((reason) => {
                    this.$log.error('Eroare la incarcare tipuri de restrictii' + reason.message);
                })

        }

        //
        public editTransportRouteOnMap() {
            this.mapCtrl.showMainMenu = false;
            this.mapCtrl.transportRouteShowEdit = this.mapCtrl.routeShowType.show;
            let source = new VectorSource();
            let styleArr = [
                new OlStyle({
                    stroke: new StrokeStyle({
                        color: olColor.asArray([0, 255, 0, 0.8]),
                        width: 4,
                        lineDash: [4, 6]
                    })
                }),
                new OlStyle({
                    image: new CircleStyle({
                        radius: 6,
                        fill: new FillStyle({
                            color: 'orange'
                        }),

                    }),
                    geometry: function (feature: Feature) {
                        // return the coordinates of the first ring of the polygon
                        let tmpgeom = feature.getGeometry();
                        let coordinates = (tmpgeom as any).getCoordinates();
                        return new MultiPoint(coordinates);
                    }
                }),
            ];
            //
            let stationStyleFunction = function (feature, resoulution) {
                let color = '#000000'
                if (feature.get('type') === "start") {
                    color = "#0000FF";
                } else if (feature.get('type') === "end") {
                    color = "#FF0000";
                }
                let name = feature.get('name') || '';
                let order = feature.get('order') || ''
                return new OlStyle({
                    image: new CircleStyle({
                        radius: 8,
                        fill: new FillStyle({
                            color: color
                        }),
                    }),
                    text: new TextStyle({
                        text: order + '. ' + name,
                        scale: 1,
                        offsetX: 1,
                        offsetY: -10,
                        textAlign: "left",
                        fill: new FillStyle({
                            color: "#000000"
                        })
                    })
                });
            };



            this.mapCtrl.routeLayerFeature = new VectorLayer<any>({
                source: source,
                style: styleArr
            });
            //
            this.mapCtrl.routeModifyInteraction = new Modify({
                condition: (event: MapBrowserEvent<any>) => {
                    this.$log.log(event);
                    let evCoord = event['coordinate'] as olCoordinate.Coordinate;
                    if (evCoord) {
                        let features = this.mapCtrl.routeLayerFeature.getSource().getFeatures();
                        for (var i = 0; i < features.length; i++) {
                            let featItemFirstCoord = (features[i].getGeometry() as LineString).getFirstCoordinate();
                            let featItemLastCoord = (features[i].getGeometry() as LineString).getLastCoordinate();
                            if ((evCoord[0] === featItemFirstCoord[0] && evCoord[1] === featItemFirstCoord[1])
                                || (evCoord[0] === featItemLastCoord[0] && evCoord[1] === featItemLastCoord[1])) {
                                return false;
                            }
                        }
                    }
                    return true;
                },
                source: this.mapCtrl.routeLayerFeature.getSource(),
            } as any);
            //
            this.mapCtrl.map.addInteraction(this.mapCtrl.routeModifyInteraction);
            //
            let snap = new Snap({ source: source });
            this.mapCtrl.map.addInteraction(snap);
            //
            this.mapCtrl.map.addLayer(this.mapCtrl.routeLayerFeature);
            //to do add features to layer
            let routeFeature = this.mapCtrl.routeDataService.getRouteLines();
            for (let i = 0; i < routeFeature.length; i++) {
                routeFeature[i].setGeometry(routeFeature[i].getGeometry().transform("EPSG:4326", this.mapCtrl.mapConfig.projection));
                this.mapCtrl.routeLayerFeature.getSource().addFeature(routeFeature[i]);
            }

            let stations = this.mapCtrl.routeDataService.getRouteStations();
            //add station points
            for (let i = 0; i < stations.length; i++) {
                stations[i].setGeometry(stations[i].getGeometry().transform("EPSG:4326", this.mapCtrl.mapConfig.projection));
                stations[i].setStyle(stationStyleFunction as any);
                //
                this.mapCtrl.routeLayerFeature.getSource().addFeature(stations[i]);
            }
            //previw route layer
            this.mapCtrl.routeLayerPreview = new VectorLayer<any>({
                source: new VectorSource(),
                style: new OlStyle({
                    stroke: new StrokeStyle({
                        color: olColor.asArray([0, 0, 255, 1]),
                        width: 6,
                       // lineDash: [4, 6]
                    })
                }),
            });
            this.mapCtrl.map.addLayer(this.mapCtrl.routeLayerPreview);

            //refresh and filter
            let transData = this.mapCtrl.routeDataService.getRouteData();
            //test this.mapCtrl.mapCtrlTransportRoute.searchRouteResultOnLayers(Number(transData.newRoute.text), transData.refLayer);

        }

        public returnToEditTransportRouteStations() {
            let transFeatures: Array<Feature> = [];
            this.mapCtrl.routeLayerFeature.getSource().getFeatures().forEach((fitem) => {
                if (fitem.getGeometry().getType() == 'LineString') {
                    let tmpItem = fitem.clone();
                    tmpItem.setGeometry(tmpItem.getGeometry().transform(this.mapCtrl.mapConfig.projection, "EPSG:4326"));
                    transFeatures.push(tmpItem);
                }
            });
            this.mapCtrl.routeDataService.setRouteLines(transFeatures);
            this.cancelTransportRouteEdit();
            this.mapCtrl.mapDialogs.showAddEditAferOnMapRouteDialog(null, null);
        }

        public cancelTransportRouteEdit() {
            this.mapCtrl.map.removeInteraction(this.mapCtrl.routeModifyInteraction);
            this.mapCtrl.map.removeLayer(this.mapCtrl.routeLayerFeature);
            this.mapCtrl.map.removeLayer(this.mapCtrl.routeLayerPreview);
            this.mapCtrl.transportRouteShowEdit = this.mapCtrl.routeShowType.hide;
            this.mapCtrl.showMainMenu = true;
        }

        //
        public generateTransportRoute() {
            //set route data
            let transFeatures: Array<Feature> = [];
            this.mapCtrl.routeLayerFeature.getSource().getFeatures().forEach((fitem) => {
                if (fitem.getGeometry().getType() == 'LineString') {
                    let tmpItem = fitem.clone();
                    tmpItem.setGeometry(tmpItem.getGeometry().transform(this.mapCtrl.mapConfig.projection, "EPSG:4326"));
                    transFeatures.push(tmpItem);
                }
            });
            this.mapCtrl.routeDataService.setRouteLines(transFeatures);
            //get data from service
            let transData = this.mapCtrl.routeDataService.getRouteData();
            //get stations
            let pointArr: Array<{ id: number, coordinates: number[] }> = [];
            //
            transData.pointList.forEach((pitem) => {
                pointArr.push({ id: pitem.id, coordinates: [pitem.long, pitem.lat] });
            });
            //check if has an id route for preview and use that
            let hasIdPreviw = isNumber(this.mapCtrl.routeIdPreview) && this.mapCtrl.routeIdPreview > 0;
            Promise.resolve(true)
                .then(() => {
                    if (hasIdPreviw) {
                        return this.mapCtrl.transportDataService.setChangeAdhocRoute(this.mapCtrl.routeIdPreview, pointArr, false, true, null, RoutingType.foot, 'statie_transport', RouteType.ad_hoc, "transport preview route", 'route-info-geom') as any
                    }
                })
                .then((rez) => {
                    if (hasIdPreviw) {
                        return rez;
                    } else {
                        return this.mapCtrl.transportDataService.setAddAdhocRoute(pointArr, false, true, null, RoutingType.foot, 'statie_transport', RouteType.ad_hoc, "transport preview route", 'route-info-geom')
                    }
                })
                .then((route) => {
                    if (route && route['geom']) {
                        this.mapCtrl.routeLayerPreview.getSource().clear();
                        let format = new WKTFormater();
                        let geom = format.readFeature(route['geom'], { dataProjection: 'EPSG:4326', featureProjection: this.mapCtrl.mapConfig.projection });
                        this.mapCtrl.routeLayerPreview.getSource().addFeature(geom);
                        //
                        if (hasIdPreviw == false && typeof route['id'] === 'number') {
                            this.mapCtrl.routeIdPreview = route['id'];
                        }
                    } else {
                        this.$log.error("nu exista geometrie in raspunsult de la server");
                    }
                })
                .catch((reason) => {
                    this.$log.error("eroare la modificare ruta");
                })
                .finally(() => {
                    // this.disableInput = false;
                })
            //}
        }
    }
